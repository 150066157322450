import React, { useEffect, useState } from "react";
import { FaPlusCircle } from "react-icons/fa";
import { CustomButton } from "../../common";
import { useNavigate } from "react-router-dom";
import { apiConnector } from "../../../networking/ApiConnector";
import { shiftEndPoints } from "../../../networking/Endpoints";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../redux/slice/loadingSlice";
import { useContext } from "react";
import { CustomAlertContext } from "../../../context";
import ProjectHeading from "../../project/Heading";
import { MdOutlineEdit } from "react-icons/md";

const TableHeads = ["Day", "Clock IN", "Clock Out", "Half Day"];

const ShiftSetting = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token, user } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.loading);
  const [shifts, setShifts] = useState([]);
  const { setToastNotification } = useContext(CustomAlertContext);

  const GetAllShifts = async () => {
    dispatch(setLoading(true));
    try {
      const shift_response = await apiConnector(
        "GET",
        shiftEndPoints?.SHIFT_SCHEDULE_API,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setShifts(shift_response?.data?.data);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };
  useEffect(() => {
    GetAllShifts();
  }, []);
  return (
    <div className="w-full h-full bg-custom-white rounded-md p-4">
      <section className="flex md:flex-row flex-col gap-2 justify-between bg-custom-white items-center p-2 rounded-t-md">
        <h1 className="font-semibold text-xl">Company Shift</h1>
        <div className="">
          <CustomButton
            title={
              <div className="flex justify-center items-center gap-2 ">
                <FaPlusCircle />
                <span>Add Shift</span>
              </div>
            }
            buttonType="button"
            onClick={() => navigate("/shift-setting-form")}
            classname="bg-gradient-custom text-custom-white font-semibold text-sm px-5 py-2 rounded-md"
          />
        </div>
      </section>
      <section className="w-full my-2">
        {shifts?.length
          ? shifts?.map((item, index) => (
              <div className="w-full p-2 my-2 border-b pb-4">
                <div className="flex justify-between items-center">
                  <h1 className="text-lg font-semibold">
                    {item?.shift_name ?? "--"}
                  </h1>
                  <span
                    onClick={() =>
                      navigate("/shift-setting-form", {
                        state: {
                          shift: item,
                        },
                      })
                    }
                    className={`p-1 border border-custom-blue text-custom-blue hover:text-custom-white hover:bg-custom-blue  hover:border-custom-white rounded-md `}
                  >
                    <MdOutlineEdit size={16} />
                  </span>
                </div>
                <span className="text-sm font-semibold">
                  <span className="text-slate-400">Late Arrival Allowed</span> :{" "}
                  {item?.buffer_time + " Minutes" ?? "--"}
                </span>
                <div className="mt-2">
                  {
                    <table className="w-full bg-custom-white rounded-lg overflow-auto">
                      <thead>
                        <tr className="w-full bg-slate-100 rounded-md hover:bg-slate-200 p-4 text-sm font-medium">
                          {TableHeads?.map((item, index) => (
                            <td key={item} className="p-2">
                              {item}
                            </td>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {item?.timing?.map((item, index) => (
                          <tr
                            key={item}
                            className={`w-full ${
                              index % 2 !== 0 ? "bg-slate-50" : ""
                            }`}
                          >
                            <td className="p-2 text-sm">{item?.day}</td>
                            <td className="p-2 text-sm">{item?.clock_in}</td>
                            <td className="p-2 text-sm">{item?.clock_out}</td>
                            <td className="p-2 text-s">{item?.half_day}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  }
                </div>
              </div>
            ))
          : "NO Shifts Available"}
      </section>
    </div>
  );
};

export default ShiftSetting;

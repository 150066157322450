import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./slice/authSlice";
import departmentReducer from "./slice/departmentSlice";
import employeeReducer from "./slice/employeeSlice";
import projectReducer from "./slice/projectSlice";
import taskReducer from "./slice/taskSlice";
import designationReducer from "./slice/designationSlice";
import officeLocationReducer from "./slice/locationSlice";
import companyReducer from "./slice/companySlice";
import filterReducer from "./slice/filterSlice";
import loadingReducer from "./slice/loadingSlice";
import meetingCategoryReducer from "./slice/meetingCategorySlice";
import externalInviteReducer from "./slice/externalInviteSlice";

const combinedReducer = combineReducers({
  auth: authReducer,
  department: departmentReducer,
  designation: designationReducer,
  officeLocation: officeLocationReducer,
  employee: employeeReducer,
  project: projectReducer,
  task: taskReducer,
  company: companyReducer,
  filter: filterReducer,
  loading: loadingReducer,
  meetingCategory: meetingCategoryReducer,
  externalInvite: externalInviteReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET") {
    //We are calling this RESET, but call what you like!
    state = {};
  }
  return combinedReducer(state, action);
};
export default rootReducer;

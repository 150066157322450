import React, { useContext, useEffect, useRef, useState } from "react";
import { CustomButton } from "../common";
import useOnClickOutside from "../../helper/onClickOutside";
import { dateFormatter, groupByDate } from "../../helper/formatDate";
import { PiUserLight, PiUsersThreeLight } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/slice/loadingSlice";
import { apiConnector } from "../../networking/ApiConnector";
import { notesEndPoints } from "../../networking/Endpoints";
import { toast } from "react-toastify";

import { LiaHandPointRightSolid } from "react-icons/lia";
import { IoMdSend } from "react-icons/io";
import { BiEditAlt } from "react-icons/bi";
import Skeleton from "react-loading-skeleton";
import { CustomAlertContext } from "../../context";
import { FaArrowsRotate } from "react-icons/fa6";
import { LuFileEdit } from "react-icons/lu";

const baseUrl = process.env.REACT_APP_BASE_URL;

const ProjectNotes = ({ project, getProjectDetails }) => {
  const { token, user } = useSelector((state) => state.auth),
    { loading } = useSelector((state) => state.loading),
    groupedTeamNotes = groupByDate(project?.project_notes || []),
    { setToastNotification } = useContext(CustomAlertContext),
    dispatch = useDispatch(),
    scrollRef = useRef(),
    [showShareDrop, setShowShareDrop] = useState(false),
    refSendButton = useRef(),
    [message, setMessage] = useState(""),
    [activeTab, setActiveTab] = useState("team"),
    [personalNotes, setPersonalNotes] = useState([]),
    [personalNotesLoading, setPersonalNotesLoading] = useState(false),
    [editNotes, setEditNotes] = useState(""),
    [notesId, setNotesId] = useState("");

  useOnClickOutside(refSendButton, () => setShowShareDrop(false));

  const getPersonalNotes = async () => {
    setPersonalNotesLoading(true);
    try {
      const personal_notes_response = await apiConnector(
        "GET",
        `${notesEndPoints?.PRIVATE_NOTES_API}/${project?._id}`,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setPersonalNotes(personal_notes_response?.data?.notes);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      setPersonalNotesLoading(false);
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    if (activeTab === "personal") {
      getPersonalNotes();
    }
  }, [activeTab]);

  const groupedPersonalNotes = groupByDate(personalNotes ?? []);

  const addNotesApiHandler = async (isPrivateNotes = false) => {
    dispatch(setLoading(true));
    try {
      const add_notes_response = await apiConnector(
        "POST",
        `${notesEndPoints?.NOTES_API}/${project?._id}`,
        {
          title: message,
          isPrivateNotes: isPrivateNotes,
        },
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setToastNotification(add_notes_response?.data?.message, "success");
      setMessage("");
      setShowShareDrop(false);
      if (getProjectDetails) {
        await getProjectDetails();
      }
      if (isPrivateNotes && getPersonalNotes) {
        getPersonalNotes();
      }
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };
  const shareWithTeamHandler = () => {
    let isPrivateNotes = false;
    addNotesApiHandler(isPrivateNotes);
  };
  const keepItPersonalHandler = () => {
    let isPrivateNotes = true;
    addNotesApiHandler(isPrivateNotes);
  };
  const updateNotesApiHandler = async (notes_id, isPrivateNotes = false) => {
    setPersonalNotesLoading(true);
    try {
      const update_notes_response = await apiConnector(
        "PUT",
        `${notesEndPoints?.NOTES_API}/${notes_id}`,
        {
          title: message,
          isPrivateNotes: isPrivateNotes,
        },
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setToastNotification(update_notes_response?.data?.message, "success");
      if (isPrivateNotes && activeTab === "personal") {
        getPersonalNotes();
      }
      setMessage("");
      setEditNotes("");
      setNotesId("");
      if (getProjectDetails) {
        await getProjectDetails();
      }
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      setPersonalNotesLoading(false);
    }
  };

  const scrollToBottom = () => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [groupedPersonalNotes]);
  return (
    <div className="w-full h-full">
      <div className="border-b  border-dashed px-4 rounded-t-lg bg-[#FFF5BD]">
        <div className="flex justify-between items-center ">
          <h1 className="text-custom-black text-lg font-semibold py-3">
            Notes
          </h1>
          <button
            onClick={() => {
              getProjectDetails();
            }}
            className={`hover:text-custom-blue font-semibold ${
              loading ? "animate-spin" : ""
            }`}
          >
            <FaArrowsRotate />
          </button>
        </div>
        <div className="w-full flex justify-around items-center gap-5 border-gray-300 ">
          <h2
            className={`w-1/2 cursor-pointer pb-2 text-center ${
              activeTab === "personal"
                ? "border-b-2 border-[#A19205] text-[#A19205] "
                : "text-custom-black"
            }`}
            onClick={() => handleTabClick("personal")}
          >
            Personal
          </h2>
          <h2
            className={`w-1/2 cursor-pointer pb-2 text-center ${
              activeTab === "team"
                ? " border-b-2 border-[#A19205] text-[#A19205] "
                : "text-custom-black"
            }`}
            onClick={() => handleTabClick("team")}
          >
            Team
          </h2>
        </div>
      </div>
      <div className="p-4 w-full h-96  overflow-x-hidden overflow-y-auto">
        {personalNotesLoading ? (
          [1, 2, 3, 4]?.map((item) => (
            <div className="border-b border-[#EFE9CF] w-full space-y-2">
              <div className="flex justify-between items-center gap-2">
                <div className="flex items-center gap-4">
                  {/* Avatar Skeleton */}
                  <Skeleton circle width={28} height={28} />

                  {/* Name Skeleton */}
                  <Skeleton width={80} height={16} />
                </div>

                {/* Edit Icon Skeleton */}
                <Skeleton circle width={32} height={32} />
              </div>

              {/* Note Text Skeleton */}
              <div className="space-y-2">
                <Skeleton width="100%" height={16} />
                <Skeleton width="75%" height={16} />
              </div>

              {/* Timestamp Skeleton */}
              <div className="flex justify-end">
                <Skeleton width={60} height={12} />
              </div>
            </div>
          ))
        ) : (
          <>
            {activeTab === "personal" &&
              (Object?.entries(groupedPersonalNotes)?.length > 0 ? (
                Object?.entries(groupedPersonalNotes)?.map(([date, notes]) => (
                  <div key={date}>
                    <h2 className="font-medium text-sm flex items-center my-2">
                      <span className="flex-grow border-t border-[#EFE9CF] mr-3"></span>
                      {date}
                      <span className="flex-grow border-t border-[#EFE9CF] ml-3"></span>
                    </h2>

                    {notes?.map((item, index) => (
                      <div
                        key={index}
                        className={`${
                          notes?.length - 1 !== index &&
                          "border-b border-[#EFE9CF]"
                        }  w-full py-2 space-y-2 group`}
                      >
                        <div className="flex justify-between gap-2">
                          <div className="flex justify-start gap-2 w-[90%]">
                            <div>
                              <LiaHandPointRightSolid
                                className="text-[#78776C]"
                                size={24}
                              />
                            </div>
                            <div className="text-sm text-justify text-slate-600">
                              {item?.title}
                            </div>
                          </div>
                          <span
                            onClick={(event) => {
                              event.stopPropagation();
                              setMessage(item?.title);
                              setEditNotes("personal");
                              setNotesId(item?._id);
                            }}
                            className="w-6 h-6 rounded-full flex justify-center items-center bg-green-500 text-custom-white opacity-0 group-hover:opacity-100 transition-opacity cursor-pointer"
                          >
                            <LuFileEdit size={21} />
                          </span>
                        </div>
                        <span className="text-xs text-justify text-slate-400 flex justify-end items-center">
                          {item?.createdAt !== item?.updatedAt ? (
                            <>
                              Edited{" "}
                              {dateFormatter(item?.updatedAt, {
                                format: "MMM D, YYYY hh:mm A",
                                includeTimeForToday: true,
                              })}
                            </>
                          ) : (
                            dateFormatter(item?.updatedAt, {
                              format: "hh:mm A",
                              includeTimeForToday: true,
                            })
                          )}
                        </span>
                      </div>
                    ))}
                  </div>
                ))
              ) : (
                <div className="text-center font-semibold text-sm ">
                  No notes available
                </div>
              ))}
            {activeTab === "team" &&
              (Object?.entries(groupedTeamNotes)?.length > 0 ? (
                Object?.entries(groupedTeamNotes)
                  ?.reverse()
                  ?.map(([date, notes]) => (
                    <div key={date}>
                      <h2 className="font-normal flex items-center my-2">
                        <div className="flex-grow border-t border-[#EFE9CF]" />
                        <div className="border rounded-full px-2 py-1 text-sm">
                          {date}
                        </div>
                        <div className="flex-grow border-t border-[#EFE9CF]" />
                      </h2>

                      {notes?.reverse()?.map((item, index) => (
                        <div
                          key={index}
                          className={`${
                            notes?.length - 1 !== index &&
                            "border-b border-[#EFE9CF]"
                          }  w-full py-2 space-y-2 group`}
                        >
                          <div className="flex justify-between items-center gap-2 ">
                            <div className="flex items-center gap-4">
                              <img
                                src={
                                  item?.created_by?.avatar
                                    ? `${baseUrl}/${item?.created_by?.avatar}`
                                    : `https://api.dicebear.com/5.x/initials/svg?seed=${item?.created_by?.user_name}`
                                }
                                alt={item?.created_by?.user_name[0] || "image"}
                                loading="lazy"
                                className="w-7 h-7 rounded-full"
                              />
                              <span className="text-custom-black text-sm font-semibold">
                                {item?.created_by?._id === user?._id
                                  ? "You"
                                  : item?.created_by?.user_name}
                              </span>
                            </div>

                            {item?.created_by?._id === user?._id && (
                              <span
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setMessage(item?.title);
                                  setEditNotes("team");
                                  setNotesId(item?._id);
                                }}
                                className="w-6 h-6 rounded-full flex justify-center items-center bg-green-500 text-custom-white opacity-0 group-hover:opacity-100 transition-opacity cursor-pointer"
                              >
                                <LuFileEdit size={18} fontSize={40} />
                              </span>
                            )}
                          </div>
                          <p className="text-sm text-justify text-slate-600">
                            {item?.title}
                          </p>
                          <span className="text-xs text-justify text-slate-400 flex justify-end items-center">
                            {item?.createdAt !== item?.updatedAt ? (
                              <>
                                Edited{" "}
                                {dateFormatter(item?.updatedAt, {
                                  format: "MMM D, YYYY hh:mm A",
                                  includeTimeForToday: true,
                                })}
                              </>
                            ) : (
                              dateFormatter(item?.updatedAt, {
                                format: "hh:mm A",
                                includeTimeForToday: true,
                              })
                            )}
                          </span>
                        </div>
                      ))}
                    </div>
                  ))
              ) : (
                <div className="text-center font-semibold text-sm ">
                  No notes available
                </div>
              ))}
            <div ref={scrollRef} />
          </>
        )}
      </div>
      <div className="w-full relative rounded-b-lg bg-[#F4EA8D] bg-opacity-40 flex justify-between items-end">
        <textarea
          name="message"
          placeholder="Type Here..."
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          value={message}
          rows={2}
          className={`outline-none bg-[#F4EA8D] bg-opacity-40 rounded-s-md w-full h-40 p-2 pr-10 resize-none`}
        />
        {showShareDrop && (
          <div ref={refSendButton}>
            <div className="bg-custom-white rounded-md shadow-[2px_3px_10px_6px_#257CBB0F] text-custom-black px-4 py-2 absolute z-10  right-2 bottom-12 space-y-4">
              <CustomButton
                title={
                  <div className="flex items-center gap-4">
                    <span className="bg-custom-light-blue text-custom-blue flex justify-center items-center p-1 w-8 h-8 rounded-full">
                      <PiUsersThreeLight size={22} className="font-semibold" />
                    </span>
                    <span>Share with Team!</span>
                  </div>
                }
                buttonType="button"
                disabled={loading}
                onClick={shareWithTeamHandler}
                classname={`text-sm font-semibold rounded-md block ${
                  loading ? "cursor-not-allowed" : "cursor-pointer"
                }`}
              />
              <CustomButton
                title={
                  <div className="flex items-center gap-4">
                    <span className="bg-custom-light-blue text-custom-blue flex justify-center items-center p-1 w-8 h-8 rounded-full">
                      <PiUserLight size={20} className="font-semibold" />
                    </span>
                    <span>Keep it Personal!</span>
                  </div>
                }
                buttonType="button"
                disabled={loading}
                onClick={keepItPersonalHandler}
                classname={`text-sm font-semibold rounded-md ${
                  loading ? "cursor-not-allowed" : "cursor-pointer"
                }`}
              />
            </div>
            <div className="w-5 h-5 rotate-45  bg-custom-white right-3 bottom-11 absolute z-10" />
          </div>
        )}
        <div className="flex items-center gap-2 absolute right-2 bottom-1">
          <CustomButton
            title={"Cancel"}
            classname={`border px-3 py-1 rounded-md text-custom-white ${
              message.length > 0
                ? "bg-custom-black bg-opacity-100 cursor-pointer"
                : "bg-gray-300  bg-opacity-50 cursor-text"
            }`}
            onClick={() => {
              setNotesId("");
              setMessage("");
            }}
          />
          <CustomButton
            title={
              <div className="flex justify-center items-center gap-1 p-1.5">
                <IoMdSend />
                <span>Send</span>
              </div>
            }
            buttonType={"button"}
            onClick={() => {
              if (editNotes === "team") {
                let isPrivateNotes = false;
                updateNotesApiHandler(notesId, isPrivateNotes);
              } else if (editNotes === "personal") {
                let isPrivateNotes = true;
                updateNotesApiHandler(notesId, isPrivateNotes);
              } else if (!editNotes && message) {
                setShowShareDrop(true);
              }
            }}
            classname={`rounded-md text-custom-white flex justify-center items-center text-sm font-semibold
             ${
               message.length > 0
                 ? "bg-custom-blue bg-opacity-100 cursor-pointer"
                 : "bg-gray-300 bg-opacity-50 cursor-text"
             }
             transition-opacity`}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectNotes;

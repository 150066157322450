import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiConnector } from "../../networking/ApiConnector";
import { meetingEndPoints } from "../../networking/Endpoints";

// Define an async thunk
export const fetchMeetingCategory = createAsyncThunk(
  "fetchMeetingCategory",
  async ({ token, currentPage, itemsPerPage }) => {
    let queryParams = {};
    if (currentPage && itemsPerPage) {
      queryParams.page_number = currentPage;
      queryParams.page_size = itemsPerPage;
    }
    const response = await apiConnector(
      "GET",
      meetingEndPoints.MEETING_CATEGORY_API,
      null,
      {
        Authorization: `Bearer ${token}`,
      },
      queryParams
    );
    return response?.data;
  }
);

// Create a slice
const meetingCategorySlice = createSlice({
  name: "meetingCategory",
  initialState: {
    meetingCategoryData: null,
    meetingCategoryLoading: false,
    meetingCategoryError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMeetingCategory.pending, (state) => {
        state.meetingCategoryLoading = true;
        state.meetingCategoryData = null;
        state.meetingCategoryError = null;
      })
      .addCase(fetchMeetingCategory.fulfilled, (state, action) => {
        state.meetingCategoryData = action.payload;
        state.meetingCategoryLoading = false;
        state.meetingCategoryError = null;
      })
      .addCase(fetchMeetingCategory.rejected, (state, action) => {
        state.meetingCategoryError = action.error.message;
        state.meetingCategoryLoading = false;
        state.meetingCategoryData = null;
      });
  },
});

export default meetingCategorySlice.reducer;

import React from "react";
import { CustomInput, CustomSelect } from "../common";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
const leaveType = [
  { name: "Casual", value: "casual" },
  { name: "Sick", value: "sick" },
  { name: "Earned", value: "earned" },
];
const leaveDuration = [
  { name: "Full Day", value: "full day" },
  { name: "Multiple Days", value: "multiple days" },
  { name: "First Half", value: "first half" },
  { name: "Second Half", value: "second half" },
];
const leaveValidation = yup.object().shape({
  title: yup.string().required("Task Name is required").trim(),
  leave_type: yup.string().required("Project is required"),
  milestone: yup.string().required("Milestone is required"),
  start_date: yup.string().required("Start Date is required"),
  status: yup.string().notRequired(),
  assign_to: yup
    .array()
    .of(yup.string().required("Each member must be a valid string"))
    .min(1, "You must select at least one member")
    .required("Assign Task to Members"),
  estimated_time: yup
    .string()
    .matches(
      /^(?:\d{2}|\d{3}):([0-5]\d)$/,
      "Estimated Time must be in the format HH:MM"
    )
    .required("Estimated Time is required"),
  hours_logged: yup
    .string()
    .matches(
      /^(?:\d{2}|\d{3}):([0-5]\d)$/,
      "Hours Logged must be in the format HH:MM"
    )
    .when("status", {
      is: (status) => {
        return status === "Completed";
      },
      then: (schema) => schema.required("Hours Logged is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  completed_on: yup.string().when("status", {
    is: (status) => {
      return status === "Completed";
    },
    then: (schema) => schema.required("Completed_on is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  completed_by: yup
    .array()
    .of(yup.string().required("Each member must be a valid string"))
    .when("status", {
      is: (status) => {
        return status === "Completed";
      },
      then: (schema) =>
        schema
          .min(1, "Completed_by is required")
          .required("Completed_by is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
});
const LeaveForm = () => {
  const onSubmitHandler = async (data) => {};
  const {
    control,
    handleSubmit,
    reset,
    setError,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(leaveValidation),
  });
  const isEditMode = false;
  return (
    <div className=" w-full border bg-custom-white rounded-md">
      <form
        className="w-full p-6 "
        noValidate
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <div className="text-xl font-semibold text-custom-black">
          {isEditMode ? "Edit Leave" : "Add Leave"}
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 sm:gap-5">
          <div className="my-2 sm:my-4 ">
            <CustomSelect
              name="leave_type"
              label="Leave Type"
              inputType="text"
              placeholder="Select a leave type"
              control={control}
              required={true}
              options={leaveType}
              error={errors?.leave_type?.message}
              // isEditable={defaultProject ? true : task?.leave_type ? true : false}
            />
          </div>

          <div className="my-2 sm:my-4">
            <CustomSelect
              name="leave_duration"
              label="Leave Duration"
              inputType="text"
              placeholder="Select a leave duration"
              control={control}
              required={true}
              options={leaveDuration}
              error={errors?.leave_duration?.message}
              // isEditable={defaultProject ? true : task?.leave_type ? true : false}
            />
          </div>
          <div className="my-2 sm:my-4 ">
            <CustomInput
              name="start_date"
              label="Start Date"
              inputType="date"
              placeholder="Select Date"
              control={control}
              required={true}
              error={errors?.start_date?.message}
            />
          </div>
          <div className="my-2 sm:my-4 ">
            <CustomInput
              name="end_date"
              label="End Date"
              inputType="date"
              placeholder="Select Date"
              control={control}
              required={true}
              error={errors?.end_date?.message}
            />
          </div>

          <div className="my-2 sm:my-4 ">
            <CustomSelect
              name="isApproved"
              label="Approved"
              inputType="text"
              placeholder="Approved"
              control={control}
              required={true}
              options={[
                { name: "Yes", value: "yes" },
                { name: "No", value: "no" },
              ]}
              error={errors?.isApproved?.message}
              // isEditable={defaultProject ? true : task?.leave_type ? true : false}
            />
          </div>
          <div className="my-2 sm:my-4 ">
            <CustomSelect
              name="approved_by"
              label="Approved By"
              inputType="text"
              placeholder="Approved By"
              control={control}
              required={true}
              options={[
                { name: "Yes", value: "yes" },
                { name: "No", value: "no" },
              ]}
              error={errors?.approved_by?.message}
              // isEditable={defaultProject ? true : task?.leave_type ? true : false}
            />
          </div>
        </div>
        <div className="my-2 sm:my-4">
          <CustomInput
            name="reason"
            label="Reason"
            inputType="text-area"
            placeholder="Reason"
            control={control}
          />
        </div>
        <div className="my-2 sm:my-4">
          <CustomInput
            name="Rm_remark"
            label="Task Description"
            inputType="text-area"
            placeholder="Task description"
            control={control}
          />
        </div>
      </form>
    </div>
  );
};

export default LeaveForm;

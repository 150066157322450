import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  CommonValidation,
  CustomButton,
  CustomFileUpload,
  CustomInput,
} from "../common";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setLoading } from "../../redux/slice/loadingSlice";
import { apiConnector } from "../../networking/ApiConnector";
import { productEndPoints } from "../../networking/Endpoints";
import { CustomAlertContext } from "../../context";
const baseUrl = process.env.REACT_APP_BASE_URL;

const ProductSchemaValidation = yup.object().shape({
  name: yup.string().required("Name is required"),
  // url: CommonValidation.nameValidation,
  // logo: CommonValidation.nameValidation,
  description: yup.string().required("Description is required"),
});
const AddProduct = () => {
  const location = useLocation();

  const productData = location?.state;

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ProductSchemaValidation),
    defaultValues: {
      name: productData?.name,
      url: productData?.link,
      logo: productData?.logo,
      description: productData?.description,
    },
    // context: { isEditable: isEditable },
  });
  const { setToastNotification } = useContext(CustomAlertContext);

  const { loading } = useSelector((state) => state.loading),
    { token, user } = useSelector((state) => state.auth),
    navigate = useNavigate();
  const dispatch = useDispatch();

  const isEditable = productData ? true : false;
  const isEditMode = false;
  const product = "!23123";

  const onSubmitHandler = async (data) => {
    const formData = new FormData();
    formData.append("name", data?.name);
    formData.append("url", data?.url);
    formData.append("logo", data?.logo);
    formData.append("description", data?.description);

    dispatch(setLoading(true));
    try {
      const apiMethod = isEditable ? "PUT" : "POST";
      const apiUrl = isEditable
        ? `${productEndPoints?.PRODUCT_API}/${productData?._id}`
        : productEndPoints?.PRODUCT_API;
      const project_response = await apiConnector(apiMethod, apiUrl, formData, {
        Authorization: `Bearer ${token}`,
      });
      setToastNotification(project_response?.data?.message, "success");
      navigate("/our_products");
      reset();
    } catch (error) {
      setToastNotification(error?.message, "error");
      reset();
    } finally {
      dispatch(setLoading(false));
    }
  };
  return (
    <div className="w-full border bg-custom-white rounded-md">
      <form
        onSubmit={handleSubmit(onSubmitHandler)}
        className="w-full px-6 py-6"
        noValidate
      >
        <div className="text-xl font-semibold text-custom-black">
          {/* {isEditable ? "Edit Employee" : "Add Employee"} */}
          {productData ? "Edit Products" : "Add Product"}
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
          <div className="my-4">
            <CustomInput
              name="name"
              label="Name"
              inputType="text"
              placeholder="John.doe"
              control={control}
              required={true}
              error={errors?.name?.message}
            />
          </div>
          <div className="my-4">
            <CustomInput
              name="url"
              label="URL"
              inputType="text"
              placeholder="Enter Url"
              control={control}
              required={true}
              error={errors?.url?.message}
            />
          </div>
          <div className="my-4">
            <CustomFileUpload
              name="logo"
              label="Logo"
              // document={true}
              //   defaultValue={productData?.logo}
              defaultValue={
                productData?.logo ? `${baseUrl}/${productData?.logo}` : ""
              }
              setValue={setValue}
              control={control}
              required={true}
              error={errors?.logo?.message}
            />
          </div>
          <div className="my-4">
            <CustomInput
              name="description"
              label="Description"
              inputType="text-area"
              placeholder="test"
              control={control}
              required={true}
              error={errors?.description?.message}
            />
          </div>
        </div>
        {/* <div className="my-4">
      <CustomInput
        name="address"
        label="Address"
        inputType="text-area"
        placeholder="Address"
        control={control}
        error={errors?.address?.message}
      />
    </div> */}
        <div className="flex justify-end items-center gap-5 my-10">
          <CustomButton
            tabIndex={0}
            title={
              loading ? (
                <svg
                  aria-hidden="true"
                  className="w-4 h-4 me-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              ) : (
                "Save"
              )
            }
            buttonType="submit"
            disabled={loading}
            classname={`bg-custom-blue font-semibold text-custom-white px-7 py-1.5 rounded-md ${
              loading ? "cursor-not-allowed" : "cursor-pointer"
            }`}
          />
        </div>
      </form>
    </div>
  );
};

export default AddProduct;

import React, { useContext, useEffect, useRef, useState } from "react";
import {
  CustomButton,
  CustomInput,
  NoDataFound,
  Pagination,
  PaginationText,
  TableHeader,
} from "../common";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { FaPlusCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { dateFormatter } from "../../helper/formatDate";
import { setLoading } from "../../redux/slice/loadingSlice";
import { apiConnector } from "../../networking/ApiConnector";
import { milestoneEndPoints, taskEndPoints } from "../../networking/Endpoints";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Roles } from "../../constant/Constant";
import { getAllProjectMilestone } from "../../redux/slice/projectSlice";
import { GrView } from "react-icons/gr";
import { MdOutlineEdit } from "react-icons/md";
import { CustomAlertContext } from "../../context";

const TableHeads = [
  "Milestone Name",
  "Start Date",
  "Deadline",
  "Completion Percentage",
  "Action",
];
const milestoneValidation = yup.object().shape({
  milestone_name: yup.string().required("Milestone Name is required"),
  start_date: yup.string().required("Date is required"),
  deadline_date: yup.string().required("Date is required"),
});
const MilestoneForm = ({
  projectId,
  setMilestoneActive,
  projectStartDate,
  projectDeadline,
  fetchMilestone,
  milestone,
}) => {
  const {
    control,
    handleSubmit,
    reset,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(milestoneValidation),
    defaultValues: {
      milestone_name: milestone?.milestone_name,
      start_date: milestone?.start_date?.replaceAll(",", "-"),
      deadline_date: milestone?.deadline_date?.replaceAll(",", "-"),
    },
  });
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state?.auth);
  const { loading } = useSelector((state) => state.loading);
  const { setToastNotification } = useContext(CustomAlertContext);

  const onSubmitHandler = async (data) => {
    dispatch(setLoading(true));
    const apiMethod = milestone ? "PUT" : "POST";

    const apiUrl = milestone
      ? `${milestoneEndPoints?.MILESTONE_API}/${milestone?._id}`
      : milestoneEndPoints?.MILESTONE_API;
    try {
      const mileStone_response = await apiConnector(
        apiMethod,
        apiUrl,
        milestone ? data : { ...data, project: projectId },
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setToastNotification(mileStone_response?.data?.message, "success");
      if (fetchMilestone) {
        fetchMilestone();
      }
      reset({
        milestone_name: "",
        start_date: "",
        deadline_date: "",
      });
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      setMilestoneActive(false);
      dispatch(setLoading(false));
    }
  };

  return (
    <div className="w-full bg-custom-white rounded-md p-5">
      <form noValidate onSubmit={handleSubmit(onSubmitHandler)}>
        <div className="text-xl font-semibold text-custom-black">
          Add Milestone
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 sm:gap-5">
          <div className="my-2 sm:my-4">
            <CustomInput
              name="milestone_name"
              label="Milestone Name"
              inputType="text"
              placeholder=""
              control={control}
              required={true}
              error={errors?.milestone_name?.message}
            />
          </div>

          <div className="my-2 sm:my-4 ">
            <CustomInput
              name="start_date"
              label="Start Date"
              inputType="date"
              placeholder=""
              control={control}
              required={true}
              min={projectStartDate?.split("T")[0] ?? ""}
              error={errors?.start_date?.message}
            />
          </div>
          <div className="my-2 sm:my-4 ">
            <CustomInput
              name="deadline_date"
              label="Due Date"
              inputType="date"
              placeholder=""
              required={true}
              max={projectDeadline?.split("T")[0] ?? ""}
              min={projectStartDate?.split("T")[0] ?? ""}
              control={control}
              error={errors?.deadline_date?.message}
            />
          </div>
        </div>
        <div className="flex justify-end items-center my-10 gap-4">
          <CustomButton
            title={"Cancel"}
            buttonType="button"
            onClick={() => {
              setMilestoneActive(false);
              // navigate(-1);
            }}
            classname={`border font-semibold text-custom-black px-5 py-1.5 rounded-md `}
          />
          <CustomButton
            title={
              loading ? (
                <svg
                  aria-hidden="true"
                  className="w-4 h-4 me-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              ) : (
                "Save"
              )
            }
            disabled={loading}
            buttonType="submit"
            classname={`bg-custom-blue font-semibold text-custom-white px-5 py-1.5 rounded-md ${
              loading ? "cursor-not-allowed" : "cursor-pointer"
            }`}
          />
        </div>
      </form>
    </div>
  );
};

const ProjectMilestone = ({ projectId, projectStartDate, projectDeadline }) => {
  const { token, user } = useSelector((state) => state.auth);
  const { projectMilestoneLoading } = useSelector((state) => state.project);
  const { setToastNotification } = useContext(CustomAlertContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [milestones, setMilestones] = useState([]);
  const [milestoneTableData, setMilestoneData] = useState([]);
  const [milestoneCount, setMilestoneCount] = useState(0);
  const [isEditmode, setIsEditmode] = useState(false);
  const [milestoneActive, setMilestoneActive] = useState(false);
  const [editMilestoneActive, setEditMilestoneActive] = useState(false);
  const [singleMileStoneDetails, setSingleMilestoneActiveDetails] =
    useState(null);

  const filteredTableHeads =
    user?.roleId === Roles?.team_lead
      ? TableHeads.filter((head) => head !== "Action")
      : TableHeads;
  const fetchMilestone = async () => {
    try {
      const response = await dispatch(
        getAllProjectMilestone({
          token,
          currentPage,
          itemsPerPage,
          projectId,
        })
      ).unwrap();

      if (response?.data) {
        setMilestones(response?.data?.milestone);
        setMilestoneData(response?.data?.milestone);
        setMilestoneCount(response?.data?.milestone_count);
      }
    } catch (error) {
      setToastNotification(error?.message, "error");
    }
  };
  useEffect(() => {
    if (projectId) {
      fetchMilestone();
    }
  }, [projectId, currentPage, itemsPerPage]);
  const pageCount = Math.ceil(10 / itemsPerPage);
  const handlePageClick = (event) => {
    setCurrentPage(event?.selected + 1);
  };
  return (
    <>
      {!milestoneActive ? (
        <div className="w-full bg-custom-white rounded-md">
          <section className="flex sm:flex-row flex-col justify-between items-center p-4 py-2 rounded-md">
            <h1 className="font-semibold">Milestone List</h1>
            <div className="flex flex-col md:flex-row justify-center items-center gap-4">
              {/* <div className="relative ">
                <IoMdSearch className="absolute z-10 top-3 left-1 text-slate-400" />
                <input
                  name="searchString"
                  label=""
                  type="text"
                  placeholder="Search"
                  // onChange={(e) => filterTask(e)}
                  // value={searchString}
                  className="bg-custom-white px-6 py-2 border outline-none rounded-md"
                />
              </div>
              <CustomButton
                title={ <div className="flex justify-center items-center font-semibold">
                <MdOutlineFilterAlt size={20} />
                <span>Filters</span>
              </div>}
                buttonType="submit"
                classname="bg-custom-white text-slate-400 p-2 rounded-md border"
              /> */}

              {user?.roleId !== Roles?.employee &&
                user?.roleId !== Roles?.team_lead && (
                  <CustomButton
                    onClick={() => {
                      setMilestoneActive(!milestoneActive);
                      setEditMilestoneActive(false);
                      setSingleMilestoneActiveDetails(null);
                    }}
                    title={
                      <div className="flex justify-center items-center gap-2 ">
                        <FaPlusCircle />
                        <span>Add Milestone</span>
                      </div>
                    }
                    buttonType="button"
                    classname="bg-gradient-custom text-custom-white font-semibold text-sm px-5 py-2 rounded-md"
                  />
                )}
            </div>
          </section>
          <section className="w-full h-full">
            <table className="w-full border rounded-lg overflow-auto">
              <thead>
                <TableHeader TableHeads={filteredTableHeads} />
              </thead>
              {projectMilestoneLoading ? (
                <tbody>
                  <tr>
                    <td
                      colSpan={filteredTableHeads?.length}
                      className="text-center font-semibold text-sm p-3"
                    >
                      <Skeleton
                        count={5}
                        width={"100%"}
                        height={"22px"}
                        className="my-2"
                      />
                    </td>
                  </tr>
                </tbody>
              ) : milestoneTableData && milestoneTableData?.length > 0 ? (
                <>
                  <tbody>
                    {milestoneTableData?.map((item, index) => {
                      return (
                        <tr
                          key={item?._id}
                          className="w-full border-b hover:bg-slate-100"
                        >
                          <td className="p-2 text-sm">
                            {item?.milestone_name ? item?.milestone_name : "--"}
                          </td>
                          <td className="p-2 text-sm">
                            {item?.start_date
                              ? dateFormatter(item?.start_date, {
                                  format: "MMM DD, YYYY",
                                  includeTimeForToday: false,
                                })
                              : "--"}
                          </td>
                          <td className="p-2 text-sm">
                            {item?.deadline_date
                              ? dateFormatter(item?.deadline_date, {
                                  format: "MMM DD, YYYY",
                                  includeTimeForToday: false,
                                })
                              : "--"}
                          </td>
                          <td className="p-2 text-sm">
                            {item?.completion_percentage
                              ? item?.completion_percentage
                              : "--"}
                          </td>
                          {user?.roleId !== Roles?.employee &&
                            user?.roleId !== Roles?.team_lead && (
                              <td className="flex items-center gap-2 cursor-pointer p-1">
                                {/* <div
                                  onClick={() => {
                                    navigate("/");
                                  }}
                                  className="p-1 border border-green-600 rounded-md cursor-pointer"
                                >
                                  <GrView className="text-green-600" />
                                </div> */}
                                <div
                                  onClick={() => {
                                    setMilestoneActive(true);
                                    setSingleMilestoneActiveDetails(item);
                                  }}
                                  className="p-1 border border-custom-blue text-custom-blue hover:bg-custom-blue hover:text-custom-light-blue rounded-md cursor-pointer"
                                >
                                  <MdOutlineEdit />
                                </div>
                              </td>
                            )}
                        </tr>
                      );
                    })}
                  </tbody>
                </>
              ) : (
                <tbody>
                  <tr>
                    <td
                      colSpan={filteredTableHeads?.length}
                      className="text-center font-semibold text-sm p-4"
                    >
                      <NoDataFound />
                    </td>
                  </tr>
                </tbody>
              )}
              <tfoot className="relative">
                <tr>
                  <td className="text-slate-400 text-sm px-2 py-4" colSpan={6}>
                    <PaginationText
                      currentPage={currentPage}
                      itemsPerPage={itemsPerPage}
                      dataCount={milestoneCount}
                      dataLength={milestones?.length}
                    />
                  </td>
                  <td className="absolute right-2 top-1.5">
                    <Pagination
                      handlePageClick={handlePageClick}
                      pageRangeDisplayed={itemsPerPage}
                      pageCount={pageCount}
                      name={"pageSize"}
                      onChange={(e) => setItemsPerPage(e?.target?.value)}
                    />
                  </td>
                </tr>
              </tfoot>
            </table>
          </section>
        </div>
      ) : (
        <MilestoneForm
          projectId={projectId}
          setMilestoneActive={setMilestoneActive}
          projectStartDate={projectStartDate}
          projectDeadline={projectDeadline}
          fetchMilestone={fetchMilestone}
          milestone={milestoneActive ? singleMileStoneDetails : null}
        />
      )}
    </>
  );
};

export default ProjectMilestone;

import React from "react";
import { Roles } from "../../../constant/Constant";
import { useNavigate } from "react-router-dom";
const Base_url = process.env.REACT_APP_BASE_URL;

const DashboardProfileCard = ({ dashboardData }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate("/profile")}
      className="relative w-full lg:w-1/4 bg-custom-white border border-slate-200 rounded-md col-span-2 row-span-2 cursor-pointer"
    >
      <div className=" w-full h-16 bg-gradient-custom rounded-t-md" />

      <div className="absolute top-5 right-[40%] w-20 h-20 rounded-full bg-custom-white">
        <img
          src={
            dashboardData?.user?.avatar
              ? `${Base_url}/${dashboardData?.user?.avatar}`
              : `https://api.dicebear.com/5.x/initials/svg?seed=${
                  dashboardData?.user?.user_name ?? "--"
                }`
          }
          alt={`${dashboardData?.user?.user_name}`}
          className="w-full h-full rounded-full p-0.5"
        />
      </div>
      <div className="gap-1 p-4 mt-6">
        <span className="text-lg flex justify-center items-center text-slate-700 font-bold">
          {dashboardData?.user?.user_name ?? "--"}
        </span>
        <div className="flex justify-center items-center gap-3 text-[#4E5052] mt-2">
          <span className="text-sm px-2 py-1 rounded-md font-semibold bg-[#EFEFEF]">
            {dashboardData?.user?.designation
              ? dashboardData?.user?.designation
              : dashboardData?.user?.role === 2
              ? "Admin"
              : ""}
          </span>
          {/* <span className="text-sm  px-2 py-1 rounded-md font-semibold bg-[#EFEFEF]">
            {"Experience "}
          </span> */}
        </div>
        <div className="flex justify-between items-center my-2">
          <span className="text-[#717C85] font-semibold">EmployeeId</span>
          <span className="text-slate-800 font-semibold">
            {dashboardData?.user?.employee_id ?? ""}
          </span>
        </div>
        <div className="flex justify-between items-center my-2">
          <span className="text-[#717C85] font-semibold">Email</span>
          <span className="text-slate-800 font-semibold">
            {dashboardData?.user?.email ?? ""}
          </span>
        </div>
        <div className="flex justify-between items-center my-2">
          <span className="text-[#717C85] font-semibold">Mobile</span>
          <span className="text-slate-800 font-semibold">
            {(dashboardData?.user?.country_code ?? "") +
              " " +
              (dashboardData?.user?.mobile ?? "")}
          </span>
        </div>
        <div className="flex justify-between items-center mt-2">
          <span className="text-[#717C85] font-semibold">Role</span>
          <span className="text-slate-800 font-semibold capitalize">
            {Object.keys(Roles)
              ?.find((key) => Roles[key] === dashboardData?.user?.role)
              ?.replace("_", " ")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default DashboardProfileCard;

import React from "react";
import { useSelector } from "react-redux";
const Base_url = process.env.REACT_APP_BASE_URL;

const DashboardBirthdayCard = ({ groupedBirthdays }) => {
  const { user } = useSelector((state) => state.auth);
  return (
    <div className="border rounded-md row-span-1 bg-[#F2F6F8]">
      <div className="w-full p-2 bg-[#BFCFD7] bg-opacity-20 rounded-t-md">
        <h2 className="font-semibold text-slate-700 ">Birthday</h2>
      </div>
      <div className="w-full h-[285px] overflow-auto px-4">
        {Object?.entries(groupedBirthdays)?.length > 0 ? (
          Object?.entries(groupedBirthdays)?.map(([date, birthdays]) => (
            <div key={date}>
              <h2 className="font-semibold text-sm flex items-center my-2">
                {date}
              </h2>
              {birthdays?.map((item, index) => (
                <div
                  key={item?.userId}
                  className={`${
                    birthdays?.length - 1 !== index &&
                    "border-b border-[#EFE9CF]"
                  }  w-full p-2  space-y-2 flex items-center gap-4 bg-custom-white rounded-md my-2`}
                >
                  <div className="w-10 h-10  rounded-full bg-custom-white">
                    <img
                      src={
                        item?.avatar
                          ? `${Base_url}/${item?.avatar}`
                          : `https://api.dicebear.com/5.x/initials/svg?seed=${item?.name}`
                      }
                      alt={`${item?.name}`}
                      className="w-full h-full rounded-full p-1"
                    />
                  </div>
                  <div className="flex flex-col text-left gap-1">
                    <span className="text-sm font-semibold">
                      {item?.userId === user?._id ? "You" : item?.name}
                    </span>
                    <span className="text-xs text-slate-400 font-semibold">
                      {item?.designation ? item?.designation : "Admin"}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          ))
        ) : (
          <div className="text-center font-semibold text-sm ">
            No Data available
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardBirthdayCard;

import React, { useContext, useEffect, useRef, useState } from "react";
import dayjs, { utc } from "dayjs";
import { IoIosCheckmarkCircleOutline, IoMdSearch } from "react-icons/io";
import { RxCrossCircled } from "react-icons/rx";
import { apiConnector } from "../../../networking/ApiConnector";
import { setLoading } from "../../../redux/slice/loadingSlice";
import { useDispatch, useSelector } from "react-redux";
import { CustomAlertContext } from "../../../context";
import { MdOutlineFilterAlt, MdOutlineStar } from "react-icons/md";
import {
  attendanceEndPoints,
  holidayEndPoints,
} from "../../../networking/Endpoints";
import {
  CommonTooltip,
  CustomButton,
  CustomDrawer,
  CustomModal,
  FilterSelect,
  NoDataFound,
} from "../../common";
import { defaultYear, yearOptions } from "../../dashboard/SuperAdminDashboard";
import { getFullMonths, Roles } from "../../../constant/Constant";
import { TbClockDown } from "react-icons/tb";
import Skeleton from "react-loading-skeleton";

const attendanceFilterType = [
  {
    name: "All",
    value: "",
  },
  {
    name: "Today Present",
    value: "today_present",
  },

  {
    name: "Today Absent",
    value: "today_absent",
  },
];
dayjs.extend(utc);
const BASE_URL = process.env.REACT_APP_BASE_URL;

const now = dayjs(); // Get the current date

const currentYear = new Date().getFullYear();
const currentMonth = dayjs(new Date()).format("MM");

const Attendance = () => {
  const dispatch = useDispatch();
  const { token, user } = useSelector((state) => state.auth);
  const [filterYear, setFilterYear] = useState(currentYear);
  const [filterMonth, setFilterMonth] = useState(currentMonth);
  const [attendanceFilter, setAttendanceFilter] = useState("");

  const [attendanceData, setAttendanceData] = useState([]);
  const [holidayData, setHolidayData] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const { setToastNotification } = useContext(CustomAlertContext);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);

  const drawerRef = useRef();

  const getAllDates = () => {
    if (
      attendanceFilter == "today_absent" ||
      attendanceFilter == "today_present"
    ) {
      return [dayjs().utc().toISOString()];
    }
    const dates = [];

    const totalDays = dayjs(
      `${filterYear}-${filterMonth}-01`,
      "YYYY-M-D"
    ).daysInMonth();

    for (let i = 1; i <= totalDays; i++) {
      const date = dayjs
        .utc(
          `${filterYear}-${filterMonth.toString().padStart(2, "0")}-${i
            .toString()
            .padStart(2, "0")}`
        )
        .toISOString();
      dates.push(date);
    }

    return dates;
  };
  const allDates = getAllDates();

  const GetAttendanceDetails = async () => {
    // dispatch(setLoading(true));
    setLoading(true);
    try {
      const attendance_response = await apiConnector(
        "GET",
        `${attendanceEndPoints?.ATTENDANCE_API}/all${
          attendanceFilter == "today_absent" ||
          attendanceFilter == "today_present"
            ? `?filter=${attendanceFilter}`
            : `?year=${filterYear}&month=${filterMonth}`
        }`,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setAttendanceData(attendance_response?.data?.data);
      setEmployees(attendance_response?.data?.data);
      setLoading(false);
    } catch (error) {
      setToastNotification(error?.message, "error");
      setLoading(false);
    } finally {
      setLoading(false);

      // dispatch(setLoading(false));
    }
  };
  const GetHolidays = async () => {
    // dispatch(setLoading(true));
    setLoading(true);

    try {
      const holiday_response = await apiConnector(
        "GET",
        `${holidayEndPoints?.HOLIDAY_API}?year=${filterYear}&month=${filterMonth}`,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setHolidayData(holiday_response?.data?.holiday);
      setLoading(false);
    } catch (error) {
      setToastNotification(error?.message, "error");
      setLoading(false);
    } finally {
      // dispatch(setLoading(false));

      setLoading(false);
    }
  };

  useEffect(() => {
    GetAttendanceDetails();
    GetHolidays();
    getAllDates();
  }, [filterMonth, filterYear, attendanceFilter]);

  const filterEmployee = (e) => {
    const val = e?.target?.value;
    setSearchString(e?.target?.value);
    if (val?.length > 0) {
      const filterCompanies = employees?.filter((item) =>
        item?.user_name?.toLowerCase().includes(val.toLowerCase())
      );
      setAttendanceData(filterCompanies);
    } else {
      setAttendanceData(employees);
    }
  };

  return (
    <>
      <div className="w-full bg-custom-white border border-slate-200 p-4 rounded-md ">
        <h1 className="font-semibold text-lg ">Attendance List</h1>

        <div className="w-full flex flex-col ">
          {/* First Row - Notes Section */}
          <div className="w-full bg-white py-3 rounded-md shadow-sm flex flex-col sm:flex-row sm:items-center gap-4">
            <span className=" font-semibold text-gray-800 ">Note :</span>
            <div className="flex flex-wrap items-center gap-3 text-sm">
              <span className="flex  items-center ">
                <TbClockDown color="#00A0D0" /> - Clock-In
              </span>
              |
              <span className="flex  items-center ">
                <IoIosCheckmarkCircleOutline className="text-custom-status-completed" />{" "}
                - Present
              </span>
              |
              <span className="flex  items-center ">
                <RxCrossCircled className="text-custom-status-cancelled" /> -
                Absent
              </span>
              |
              <span className="flex  items-center ">
                <MdOutlineStar className="text-custom-status-in-progress " /> -
                Holiday
              </span>
            </div>
          </div>

          <div className="w-full bg-white py-3  flex flex-wrap items-center gap-4 mb-5">
            {Roles?.admin == user?.roleId || Roles?.hr == user?.roleId ? (
              <>
                <input
                  name="searchString"
                  label=""
                  type="text"
                  placeholder="Search"
                  // onChange={handleChange}
                  onChange={(e) => filterEmployee(e)}
                  value={searchString}
                  className="bg-custom-white w-full md:w-60 px-5 py-2 text-sm font-semibold border outline-none rounded-md mt-2"
                />

                <FilterSelect
                  options={attendanceFilterType}
                  icon={false}
                  defaultValue={attendanceFilter}
                  onChange={(e) => {
                    setAttendanceFilter(e?.value);
                  }}
                />
              </>
            ) : (
              ""
            )}
            <FilterSelect
              options={getFullMonths}
              icon={false}
              defaultValue={filterMonth}
              isEditable={
                attendanceFilter == "today_absent" ||
                attendanceFilter == "today_present"
                  ? true
                  : false
              }
              onChange={(e) => {
                setFilterMonth(e?.value);
              }}
            />
            <FilterSelect
              options={yearOptions}
              icon={false}
              defaultValue={defaultYear}
              isEditable={
                attendanceFilter == "today_absent" ||
                attendanceFilter == "today_present"
                  ? true
                  : false
              }
              onChange={(e) => {
                setFilterYear(e?.value);
              }}
            />
          </div>
        </div>
        <div className="overflow-x-auto ">
          <table className="min-w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-100">
                <th className="border border-gray-300 px-2 py-1 text-left text-sm">
                  Employee
                </th>
                {allDates.map((date, index) => (
                  // currentYear == filterYear && currentMonth == filterMonth ? true : false
                  <th
                    key={index}
                    className={`border 
                      ${
                        currentYear == filterYear &&
                        currentMonth == filterMonth &&
                        dayjs(date).format("DD") == dayjs().format("DD")
                          ? `bg-custom-blue text-white`
                          : `border-gray-300`
                      } 
                         px-2 py-1 text-center text-xs `}
                  >
                    <div className="flex flex-col">
                      <span>{dayjs(date).format("ddd")}</span>
                      <span>{dayjs(date).format("DD")}</span>
                    </div>
                  </th>
                ))}

                {attendanceFilter == "today_absent" ||
                attendanceFilter == "today_present" ? (
                  ""
                ) : (
                  <th className="border border-gray-300 px-2 py-1 text-center text-sm">
                    Total
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td
                    colSpan={allDates?.length}
                    className="text-center font-semibold text-sm p-3"
                  >
                    <Skeleton
                      count={5}
                      width={"100%"}
                      height={"22px"}
                      className="my-2"
                    />
                  </td>
                </tr>
              ) : attendanceData && attendanceData.length > 0 ? (
                attendanceData.map((employee, empIndex) => (
                  <tr
                    key={empIndex}
                    className="border border-gray-300 hover:bg-slate-100"
                  >
                    <td className="border border-gray-300 px-2 py-1">
                      <div className="font-semibold text-sm">
                        {employee?.user_name}
                      </div>
                      <div className="text-sm text-gray-500 ">
                        {employee?.role}
                      </div>
                    </td>

                    {allDates.map((date, dayIndex) => {
                      const attendanceEntry = employee?.Attendance?.find(
                        (entry) =>
                          entry?.clock_in_time?.startsWith(
                            dayjs(date).format("YYYY-MM-DD")
                          )
                      );
                      const isPastOrToday =
                        dayjs(date).isBefore(dayjs(), "day") ||
                        dayjs(date).isSame(dayjs(), "day");

                      const isSunday = dayjs(date).day() === 0;
                      const holidayEntry = holidayData.find((holiday) =>
                        holiday.holiday_date.startsWith(
                          dayjs(date).format("YYYY-MM-DD")
                        )
                      );

                      return (
                        <td
                          key={dayIndex}
                          className="border border-gray-300 px-2 py-1 text-center"
                        >
                          {holidayEntry ? (
                            // Show holiday name in red
                            <span className="text-red-500 font-bold">
                              {/* {holidayEntry.holiday_name} */}
                              <CommonTooltip
                                tooltip={holidayEntry?.holiday_name}
                              >
                                <MdOutlineStar className="text-custom-status-in-progress " />
                              </CommonTooltip>
                            </span>
                          ) : attendanceEntry?.clock_in_time ? (
                            <div
                              className="text-green-500 flex  justify-center items-center"
                              onClick={() => {
                                setModal(true);
                                setModalData({
                                  ...attendanceEntry,
                                  userName: employee?.user_name,
                                  userImage: employee?.avatar,
                                });
                              }}
                            >
                              {attendanceEntry?.clock_in_time &&
                              attendanceEntry?.clock_out_time ? (
                                <IoIosCheckmarkCircleOutline className="text-custom-status-completed cursor-pointer" />
                              ) : (
                                <TbClockDown
                                  color="#00A0D0"
                                  className="cursor-pointer"
                                />
                              )}
                              {/* <IoIosCheckmarkCircleOutline className="text-custom-status-completed cursor-pointer" />
                            <br /> */}
                              {/* <span className="text-xs text-gray-500">
                              {attendanceEntry?.clock_in_time
                                ? dayjs(attendanceEntry?.clock_in_time).format(
                                    "h:mm A"
                                  )
                                : "--"}
                              <br></br>
                              
                              {attendanceEntry?.clock_out_time
                                ? dayjs(attendanceEntry?.clock_out_time).format(
                                    "h:mm A"
                                  )
                                : "--"}
                            </span> */}
                            </div>
                          ) : isSunday ? (
                            // Show "S" if it's Sunday and no attendance
                            <span className="text-blue-500 font-semibold">
                              S
                            </span>
                          ) : (
                            <div className="flex  justify-center items-center">
                              {/* <RxCrossCircled className="text-custom-status-cancelled" /> */}
                              {isPastOrToday ? (
                                <RxCrossCircled className="text-custom-status-cancelled" />
                              ) : (
                                "--"
                              )}
                            </div>
                          )}
                        </td>
                      );
                    })}

                    {attendanceFilter == "today_absent" ||
                    attendanceFilter == "today_present" ? (
                      ""
                    ) : (
                      <td className="flex px-2 py-1 text-center font-bold text-xs min-w-max justify-center  ">
                        <span>
                          {employee?.Attendance?.length} / {allDates?.length}
                        </span>
                      </td>
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={allDates?.length}
                    className="text-center font-semibold text-sm p-4"
                  >
                    <NoDataFound />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <CustomModal
        isOpen={modal}
        onClose={() => {
          setModal(false);
        }}
      >
        <div className="flex flex-col items-center justify-center bg-white   w-80">
          <h2 className="text-xl font-bold text-gray-800 mb-2 flex">
            Attendance Details
          </h2>
          <div className="flex justify-center text-center items-center my-5">
            <img
              src={
                modalData?.userImage
                  ? `${BASE_URL}/${modalData?.userImage}`
                  : `https://api.dicebear.com/5.x/initials/svg?seed=${modalData?.userName}`
              }
              // alt={modalData?.userName}
              className="w-16 h-16 rounded-full bg-slate-300 p-0.5 mr-2"
            />{" "}
            {modalData?.userName ? modalData?.userName : ""}
          </div>
          <p className="text-gray-600 text-sm">
            Date:{" "}
            <span className="font-medium">
              {modalData?.clock_in_time
                ? dayjs(modalData?.clock_in_time).format("DD/MM/YYYY")
                : "--"}
            </span>
          </p>

          <div className="flex flex-col items-center justify-center gap-y-2 mt-4">
            <div className="bg-gray-100 p-3 rounded-xl w-full text-center">
              <span className="text-gray-700 font-medium">
                Clock In:{" "}
                {modalData?.clock_in_time
                  ? dayjs(modalData?.clock_in_time).format("h:mm A")
                  : "--"}
              </span>
            </div>
            <div className="bg-gray-100 p-3 rounded-xl w-full text-center">
              <span className="text-gray-700 font-medium">
                Clock Out:{" "}
                {modalData?.clock_out_time
                  ? dayjs(modalData?.clock_out_time).format("h:mm A")
                  : "--"}
              </span>
            </div>
            <div className="bg-blue-100 p-3 rounded-xl w-full text-center mt-2 text-blue-700 font-semibold">
              Total:{" "}
              {modalData?.clock_in_time && modalData?.clock_out_time
                ? (() => {
                    const diff = dayjs(modalData?.clock_out_time).diff(
                      dayjs(modalData?.clock_in_time),
                      "minute"
                    );
                    const hours = Math.floor(diff / 60);
                    const minutes = diff % 60;
                    return `${hours}h ${minutes}m`;
                  })()
                : "--"}
            </div>
          </div>
        </div>
      </CustomModal>
      {openFilterDrawer && (
        <CustomDrawer
          ref={drawerRef}
          open={openFilterDrawer}
          setOpen={setOpenFilterDrawer}
          filterName="Task Filter"
        >
          <div>asdf</div>
          <div className="w-full absolute bottom-0 right-0 p-4">
            <div className="w-full h-[1px] bg-slate-200 my-2" />
            <div className="flex items-center justify-between ">
              <CustomButton
                title={"Clear"}
                onClick={() => {
                  // dispatch(setTaskFilter(null));
                  // fetchAllTasks("", "", "", "", "", "");
                  setOpenFilterDrawer(false);
                }}
                buttonType="submit"
                classname={"border px-5 py-1 rounded-md"}
              />
              <CustomButton
                title={"Apply"}
                onClick={() => {
                  // filterTaskApiHandler();
                }}
                buttonType="submit"
                classname={
                  "border px-5 py-1 rounded-md bg-custom-status-completed text-custom-white"
                }
              />
            </div>
          </div>
        </CustomDrawer>
      )}
    </>
  );
};

export default Attendance;

export const tooltip = {
  //Employee Module
  user_name: "Enter Name",
  password: "Enter password",
  email: "Enter Correct Email",
  mobile_number: "Enter Mobile Number",
  date_of_birth: "Select a Date",
  gender: "Select a Gender",
  marital_status: "Select an option",
  department: "Create Department first from setting Tab.",
  roleId: "Select a Role",
  reporting_manager: "Select a Reporting Manager",
  joining_date: "Select a Date",
  shift: "Select a Shift",
  clock_in_permission: "Select an option",
  designation: "Create Designation first from setting Tab.",
  office_location: "Create an Office first from setting Tab.",
  work_mode: "Select a work mode.",
  //Project Module
  project_name: "Enter Project Title",
  project_manager: "Create a Project Manager first from Employee tab.",
  project_start_date: "Select a date.",
  project_deadline: "Select a date.",
  team_lead: "Select a TL.",
  members: "Create Members first from Employee tab.",
  projectStatus: "Select a Status.",
  priority: "Select a priority.",
  project_description: "Enter decription.",
  project_notes: "Enter notes.",
  //Task Module
  title: "Enter Task Title.",
  project: "Create a project first.",
  start_date: "Select a date",
  due_date: "Select a date.",
  status: "Select a status.",
  milestone: "Create Milestone first from project view section",
  assign_to: "Select a member.",
  description: "Enter decription.",
  estimated_time: "Enter estimated time",
  hours_logged: "Enter hours logged",
  completed_by: "Select a user.",
  completed_on: "Select a date.",
  // Department Module
  department_name: "Enter Department Name.",
  //Office Location MOdule
  city: "Enter City",
  address: "Enter Address",
  coordinates: "Office Location Cordinates in desired format",
  radius: "Radius in Meters",
  public_IPs: "Public IP",
  //Company Module
  company_name: "Enter Company Name",
  company_email: "Enter Company Email",
  company_contact_no: "Enter Company Contact Number",
  company_address: "Enter Company Address",
  department_limit: "Enter Department Limit",
  designation_limit: "Enter Designation Limit",
  office_location_limit: "Enter Office Limit",
  //Meeting Module
  meeting_title: "Enter Meeting Title",
  meeting_category: "Select a Meeting Category",
  frequency: "Select a Meeting Frequency",
  dayOfWeek: "Select a Day",
  startDate: "Select a date",
  endDate: "Select a date",
  time: "Select a Time",
  external_invites: "Select an External Invite",
  agenda: "Enter Meeting agenda",
  meeting_duration: "Enter Meeting duration",
  date_time: "Enter Meeting Date and time",
  meeting_result: "Enter Meeting Result",
  location: "Enter Meeting location",
  link_of_meeting: "Enter Meeting link",
  participants: "Select Meeting Participants",
  //External Invite Module
  level_of_seniority: "Select a Designation",
  linked_in_profile: "Enter LinkedIn Profile URL",
  website_url: "Enter Website URL",
  company_detail: "Enter Company Details",
  company_industry: "Select Industry Type",
  region_of_interest: "Select a Region",
  sector: "Select a Sector",
  stage_type: "Select a Stage Type",
  type_of_organization: "Select Organization Type",
  x_profile: "Enter X Profile URL",
  biography: "Enter Biography",
  country: "Select a country",
  CIN: "Enter Company CIN",
  GSTN: "Enter Company GSTN",
  PAN: "Enter Company PAN",
  TAN: "Enter Company TAN",
};

import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  CommonValidation,
  CustomButton,
  CustomInput,
  CustomSwitch,
} from "../common";
import * as yup from "yup";
import { companyEndPoints } from "../../networking/Endpoints";
import { setLoading } from "../../redux/slice/loadingSlice";
import { useDispatch, useSelector } from "react-redux";
import { apiConnector } from "../../networking/ApiConnector";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchCompanyDetails } from "../../redux/slice/companySlice";
import { CustomAlertContext } from "../../context";
import { Icons } from "../common/CommonIcon";

const CreateAdminValidation = yup.object().shape({
  company_name: yup
    .string()
    .required("Company Name is required")
    .matches(
      /^[a-zA-Z](?!.*(?:[_&]{2,}))[a-zA-Z0-9@&.,'()\- ]*$/u,
      `Company Name must not contain standalone special characters invalid digits or emoji`
    )
    .min(2, "Company Name must be greater than 2 characters")
    .max(125, "Company Name must be less than 125 characters"),
  company_email: CommonValidation?.emailValidation,
  company_contact_no: yup
    .string()
    // .matches(/^[6-9]\d{9}$/, "Please enter valid Mobile number")
    .required("Contact Number is required"),
  department_limit: yup
    .string()
    .matches(
      /^[0-9]+$/,
      "Department Limit must be a positive number and cannot contain letters or special characters"
    )
    .test(
      "max-value",
      "Department Limit should be less than 99",
      (value) => !value || parseInt(value, 10) <= 99
    )
    .required("Department Limit is required"),
  designation_limit: yup
    .string()
    .matches(
      /^[0-9]+$/,
      "Designation Limit must be a positive number and cannot contain letters or special characters"
    )
    .test(
      "max-value",
      "Designation Limit should be less than 99",
      (value) => !value || parseInt(value, 10) <= 99
    )
    .required("Designation Limit is required"),
  office_location_limit: yup
    .string()
    .matches(
      /^[0-9]+$/,
      "Office Location Limit must be a positive number and cannot contain letters or special characters"
    )
    .test(
      "max-value",
      "Office Location Limit should be less than 99",
      (value) => !value || parseInt(value, 10) <= 99
    )
    .required("Department Limit is required"),

  company_address: yup
    .string()
    .required("Company address is required")
    .min(10, "Company address must be at least 10 characters.")
    .max(255, "Company address must be at most 255 characters.")
    .test(
      "valid-chars",
      "Company address must contain at least one letter and can contain numbers and special characters.",
      (value) => {
        if (!value) return true;
        // Regex to allow alphabets, numbers, spaces, hyphens, commas, and periods, but require at least one alphabet.
        return /[A-Za-z]/.test(value) && /^[A-Za-z0-9\s\-,.]+$/.test(value);
      }
    )
    .test("no-emojis", "Company address must not contain emojis.", (value) => {
      if (!value) return true;
      // Regex to detect emojis
      const emojiRegex =
        /[\u{1F600}-\u{1F64F}]|[\u{1F300}-\u{1F5FF}]|[\u{1F680}-\u{1F6FF}]|[\u{1F700}-\u{1F77F}]|[\u{1F780}-\u{1F7FF}]|[\u{1F800}-\u{1F8FF}]|[\u{1F900}-\u{1F9FF}]|[\u{1FA00}-\u{1FA6F}]|[\u{1FA70}-\u{1FAFF}]|[\u{2600}-\u{26FF}]|[\u{2700}-\u{27BF}]/gu;
      return !emojiRegex.test(value); // Pass if no emojis are found
    }),
  user_name: CommonValidation?.nameValidation,
  email: CommonValidation?.emailValidation,
  mobile_number: yup
    .string()
    // .matches(/^[6-9]\d{9}$/, "Please enter valid Mobile number")
    .required("Mobile Number is required"),
});

const CreateCompanyForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setToastNotification } = useContext(CustomAlertContext);
  const { token } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.loading);
  const companyId = location?.state?.companyId;
  const [isEditMode, setIsEditMode] = useState(false);
  const [companyDetails, setCompanyDetails] = useState(null);
  const [isCompanyActive, setIsCompanyActive] = useState(true);
  const getCompanyDetails = async () => {
    const response = await dispatch(
      fetchCompanyDetails({ token, companyId })
    ).unwrap();

    if (response?.data) {
      setCompanyDetails(response?.data?.company_details);
      setIsCompanyActive(response?.data?.company_details?.isActive);
    }
  };
  useEffect(() => {
    if (companyId) {
      getCompanyDetails();
    }
  }, [companyId]);
  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CreateAdminValidation),
  });
  useEffect(() => {
    if (companyId && companyDetails) {
      setIsEditMode(true);
      reset({
        company_name: companyDetails?.company_name ?? undefined,
        company_email: companyDetails?.company_email ?? undefined,
        company_contact_no:
          companyDetails?.company_country_code +
            " " +
            companyDetails?.company_contact_no ?? undefined,
        company_address: companyDetails?.company_address ?? undefined,
        user_name: companyDetails?.admin?.[0]?.user_name ?? undefined,
        email: companyDetails?.admin?.[0]?.actual_email ?? undefined,
        mobile_number:
          companyDetails?.admin?.[0]?.country_code +
            " " +
            companyDetails?.admin?.[0]?.actual_mobile_number ?? undefined,
        department_limit: companyDetails?.department_limit ?? undefined,
        designation_limit: companyDetails?.designation_limit ?? undefined,
        office_location_limit:
          companyDetails?.office_location_limit ?? undefined,
      });
    }
  }, [companyId, companyDetails]);

  const getUpdatedFields = (companyDetails, data) => {
    let updatedFields = {};

    Object.keys(companyDetails).forEach((key) => {
      if (companyDetails[key] != data[key]) {
        updatedFields[key] = data[key];
      }
    });

    return updatedFields;
  };
  const onSubmitHandler = async (data) => {
    let payload = isEditMode
      ? {
          ...getUpdatedFields(companyDetails, data),
          company_country_code:
            companyDetails?.company_country_code !==
            data?.company_contact_no?.split(/(?<=^\S+)\s/)[0]
              ? data?.company_contact_no?.split(/(?<=^\S+)\s/)[0]
              : undefined,
          company_contact_no:
            companyDetails?.company_contact_no !==
            data?.company_contact_no?.split(/(?<=^\S+)\s/)[1]
              ? data?.company_contact_no?.split(/(?<=^\S+)\s/)[1]
              : undefined,
          isActive:
            companyDetails?.isActive !== isCompanyActive
              ? isCompanyActive
              : undefined,
        }
      : {
          ...data,
          country_code: data?.mobile_number?.split(/(?<=^\S+)\s/)[0],
          mobile_number: data?.mobile_number?.split(/(?<=^\S+)\s/)[1],
          company_country_code:
            data?.company_contact_no?.split(/(?<=^\S+)\s/)[0],
          company_contact_no: data?.company_contact_no?.split(/(?<=^\S+)\s/)[1],
        };
    dispatch(setLoading(true));
    try {
      let api_Method = isEditMode ? "PUT" : "POST";
      let api_Url = isEditMode
        ? `${companyEndPoints?.COMPANY_API}/${companyId}`
        : companyEndPoints?.COMPANY_API;

      const company_response = await apiConnector(
        api_Method,
        api_Url,
        payload,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setToastNotification(company_response?.data?.message, "success");
      navigate("/company");
      reset();
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };
  return (
    <div className=" w-full border bg-custom-white rounded-md">
      <form
        className="w-full p-6"
        noValidate
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <div className="">
          <h1 className="text-xl font-bold my-4">
            {isEditMode ? "Edit Company" : "Add Company"}
          </h1>
        </div>
        <div className="">
          <h2 className="text-lg font-semibold my-4">{`Company's Information`}</h2>
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
          <div className="relative my-4">
            <CustomInput
              name="company_name"
              label="Comapny's Name"
              inputType="text"
              placeholder="Company Name"
              control={control}
              required={true}
              icon={Icons?.companyIcon}
              error={errors?.company_name?.message}
            />
          </div>
          <div className="my-4">
            <CustomInput
              name="company_email"
              label="Company's Email"
              inputType="text"
              placeholder="info@company.com"
              icon={Icons?.mailIcon}
              control={control}
              required={true}
              error={errors?.company_email?.message}
            />
          </div>
          <div className="my-4">
            <CustomInput
              name="company_contact_no"
              label="Company's Contact"
              inputType="tel"
              placeholder="99XXXXXXXX"
              control={control}
              icon={Icons?.phoneIcon}
              required={true}
              error={errors?.company_contact_no?.message}
            />
          </div>
          <div className="my-4">
            <CustomInput
              name="department_limit"
              label="Department Limit"
              inputType="text"
              placeholder="Department Limit (eg - 10)"
              control={control}
              icon={Icons?.departmentIcon}
              required={true}
              error={errors?.department_limit?.message}
            />
          </div>
          <div className="my-4">
            <CustomInput
              name="designation_limit"
              label="Designation Limit"
              inputType="text"
              placeholder="Designation Limit (eg - 10)"
              control={control}
              icon={Icons?.designationIcon}
              required={true}
              error={errors?.designation_limit?.message}
            />
          </div>
          <div className="my-4">
            <CustomInput
              name="office_location_limit"
              label="Office Location Limit"
              inputType="text"
              placeholder="Office Location Limit (eg - 10)"
              icon={Icons?.companyIcon}
              control={control}
              required={true}
              error={errors?.office_location_limit?.message}
            />
          </div>
          <div className="my-4">
            <CustomInput
              name="company_address"
              label="Company's Address"
              inputType="text-area"
              placeholder="Address"
              icon={Icons?.locationIcon}
              control={control}
              required={true}
              error={errors?.company_address?.message}
            />
          </div>
          {isEditMode && (
            <div className="my-4 ">
              <label className="font-semibold text-sm text-slate-600">
                Company's Status
              </label>
              <div className="mt-2 w-fit">
                <CustomSwitch
                  isActive={isCompanyActive}
                  handleStatus={(status) => setIsCompanyActive(status)}
                />
              </div>
            </div>
          )}
        </div>
        <div className="">
          <h2 className="text-lg font-semibold my-2">{`Admin's Information`}</h2>
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
          <div className="my-4">
            <CustomInput
              name="user_name"
              label="Name"
              inputType="text"
              placeholder="John Deo"
              control={control}
              required={true}
              icon={Icons?.userIcon}
              error={errors?.user_name?.message}
            />
          </div>
          <div className="my-4">
            <CustomInput
              name="email"
              label="Email"
              inputType="text"
              placeholder="John.doe@gamil.com"
              control={control}
              icon={Icons?.mailIcon}
              required={true}
              error={errors?.email?.message}
              isEditable={isEditMode}
            />
          </div>
          <div className="my-4">
            <CustomInput
              name="mobile_number"
              label="Mobile Number"
              inputType="tel"
              placeholder="89XXXXXXXX"
              control={control}
              setValue={setValue}
              icon={Icons?.phoneIcon}
              required={true}
              error={errors?.mobile_number?.message}
              isEditable={isEditMode}
            />
          </div>
        </div>

        <div className="flex justify-center items-center gap-5 my-10">
          <CustomButton
            title={"Cancel"}
            buttonType="button"
            onClick={() => {
              reset();
              navigate(-1);
            }}
            classname="text-custom-black hover:bg-custom-black hover:text-custom-white border px-10 py-2 rounded-full"
          />
          <CustomButton
            tabIndex={0}
            title={
              loading ? (
                <svg
                  aria-hidden="true"
                  className="w-4 h-4 me-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              ) : (
                "Save"
              )
            }
            buttonType="submit"
            disabled={loading}
            classname="bg-gradient-custom text-custom-white px-10 py-2 rounded-full"
          />
        </div>
      </form>
    </div>
  );
};

export default CreateCompanyForm;

import React from "react";
// import { RatingStars } from "../../common";
import { FaStar } from "react-icons/fa";
import ReactStars from "react-rating-stars-component";
import comingsoon from "../../../assets/comingsoon.svg";

const EmployeeTimesheetCard = ({
  timesheetRatings = [{ _id: 1, title: "Week 01", rating: 3 }],
}) => {
  return (
    <div className="w-full border p-2 rounded-md">
      {/*<div className="font-semibold text-slate-700">Timesheet Rating</div>
      <div className="w-full h-72 overflow-auto">
        {timesheetRatings?.length
          ? timesheetRatings?.map((item) => (
              <div
                key={item?._id}
                // onClick={() => navigate("/calender")}
                className={` w-full p-2 space-y-2 bg-[#F4F4F4] rounded-md mb-2 cursor-pointer`}
              >
                <div className="flex justify-between items-center text-xs font-semibold">
                  <div className="text-sm font-semibold line-clamp-1">
                    {item?.title ?? "--"}
                  </div>
                  <div className="flex justify-start items-center gap-2">
                    <ReactStars
                      count={5}
                      value={item?.rating}
                      size={20}
                      edit={false}
                      activeColor="#ffd700"
                      emptyIcon={<FaStar />}
                      fullIcon={<FaStar />}
                    />
                    <span className="text-lg font-semibold">
                      {item?.rating}
                    </span>
                  </div>
                </div>
              </div>
            ))
          : "No Ratings Available"}
      </div> */}
      <div className="flex justify-center items-center w-full h-full bg-custom-white rounded-lg p-4">
        <img src={comingsoon} alt="coming-soon" className="w-96 h-60" />
      </div>
    </div>
  );
};

export default EmployeeTimesheetCard;

import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomAlertContext } from "../../../context";
import Skeleton from "react-loading-skeleton";
import { apiConnector } from "../../../networking/ApiConnector";
import { taskEndPoints } from "../../../networking/Endpoints";
import { CustomChart } from "../../common";
import { chartColors, Status } from "../../../constant/Constant";
const task_labels = [
  "Not Started Task",
  "In Progress Task",
  "On Hold Task",
  "Completed Task",
  "Cancelled Task",
];
const task_colors = [
  chartColors?.not_started,
  chartColors?.in_progress,
  chartColors?.on_hold,
  chartColors?.completed,
  chartColors?.cancelled,
];
const EmployeeTaskChart = ({ employeeDetails = {} }) => {
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { setToastNotification } = useContext(CustomAlertContext);
  const [taskData, setTaskData] = useState([]);
  const [loading, setLoading] = useState(false);
  const GetTaskAPI = async () => {
    setLoading(true);
    try {
      const task_response = await apiConnector(
        "GET",
        taskEndPoints?.TASK_API,
        null,
        {
          Authorization: `Bearer ${token}`,
        },
        {
          assign_to: employeeDetails?._id,
        }
      );
      let in_progress_count = 0,
        on_hold_count = 0,
        completed_count = 0,
        not_started_count = 0,
        cancelled_count = 0;
      task_response?.data?.data?.tasks?.map((item) => {
        switch (item?.status) {
          case Status?.in_progress:
            return in_progress_count++;
          case Status?.on_hold:
            return on_hold_count++;
          case Status?.cancelled:
            return cancelled_count++;
          case Status?.completed:
            return completed_count++;
          case Status?.not_started:
            return not_started_count++;
          default:
            return "";
        }
      });
      setTaskData([
        not_started_count,
        in_progress_count,
        on_hold_count,
        completed_count,
        cancelled_count,
      ]);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (employeeDetails?._id) GetTaskAPI();
  }, [employeeDetails]);
  let totalTask = 0;
  taskData?.map((item) => (totalTask += item));
  return (
    <div className="border border-[#E9EDF0] rounded-lg p-4 flex flex-col justify-between items-center">
      <h1 className="font-semibold text-sm my-2 text-slate-500">Tasks</h1>
      {loading ? (
        <Skeleton width={250} height={150} />
      ) : taskData?.length > 0 ? (
        <CustomChart
          labelName={""}
          chartType="doughnut"
          labels={task_labels ?? []}
          data={taskData ?? []}
          colors={task_colors ?? []}
        />
      ) : (
        <span>No Task Available</span>
      )}
      <h1>Total Task - {totalTask}</h1>
    </div>
  );
};

export default EmployeeTaskChart;

import React from "react";
import {
  Gantt,
  Task,
  EventOption,
  StylingOption,
  ViewMode,
  DisplayOption,
} from "gantt-task-react";
import "gantt-task-react/dist/index.css";
const CustomGanttChart = ({
  tasks = [],
  columnWidth = 300,
  hideChildren = true,
  headerHeight = 80,
  rowHeight = 80,
  listCellWidth = "200px",
  viewMode = "Month",
}) => {
  return (
    <div className="scrollbargantt">
      <Gantt
        tasks={tasks}
        viewMode={viewMode}
        columnWidth={columnWidth}
        hideChildren={hideChildren}
        // TaskListTable={}
        // todayColor="#2780C1"
        listCellWidth={listCellWidth}
        headerHeight={headerHeight}
        rowHeight={rowHeight}
        viewDate={new Date()}
        fontSize="14px"
      />
    </div>
  );
};

export default CustomGanttChart;

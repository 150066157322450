import React, { useContext, useEffect, useState } from "react";
import {
  CommonValidation,
  CustomButton,
  CustomInput,
  CustomSelect,
} from "../../common";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { apiConnector } from "../../../networking/ApiConnector";
import { externalInviteEndPoints } from "../../../networking/Endpoints";
import { CustomAlertContext } from "../../../context";
import {
  companyIndustryOptions,
  externalInviteDesignationOptions,
  levelOfSeniorityOptions,
  regionOfInterestOptions,
  sectorOptions,
  StageOptions,
  typeOfOrganizationOptions,
} from "../../../constant/Constant";
import { fetchAllCountries } from "../../../networking/apis";
import { useLocation, useNavigate } from "react-router-dom";
import { setLoading } from "../../../redux/slice/loadingSlice";
import { FaPlusCircle, FaTrash } from "react-icons/fa";

const externalUserValidation = yup.object().shape({
  user_name: CommonValidation?.nameValidation,
  // email: CommonValidation?.emailValidation,
  // mobile_number: CommonValidation?.mobileValidation,
  // level_of_seniority: yup.string().required("Level of Seniority is Required *"),
  CIN: yup
    .string()
    .nullable()
    .max(21, "CIN must be exactly 21 characters")
    .test(
      "CIN_test",
      "CIN must be exactly 21 characters and follow the correct format",
      (val) =>
        !val ||
        (val.length === 21 &&
          /^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/i.test(
            val
          ))
    ),
  // .required("CIN is required *")
  GSTN: yup
    .string()
    .nullable()
    .max(15, "GSTN must be exactly 15 characters")
    .test(
      "GSTN_test",
      "GSTN must be exactly 15 characters and follow the correct format",
      (val) =>
        !val ||
        (val.length === 15 &&
          /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/i.test(val))
    ),
  // .required("GSTN is required *")
  PAN: yup
    .string()
    .nullable()
    .max(10, "PAN must be exactly 10 characters")
    .test(
      "PAN_test",
      "PAN must be exactly 10 characters and follow the correct format",
      (val) =>
        !val || (val.length === 10 && /[A-Z]{5}[0-9]{4}[A-Z]{1}/i.test(val))
    ),
  // .required("PAN is required *")
  TAN: yup
    .string()
    .nullable()
    .max(10, "TAN must be exactly 10 characters")
    .test(
      "TAN_test",
      "TAN must be exactly 10 characters and follow the correct format",
      (val) =>
        !val || (val.length === 10 && /^[A-Z]{4}[0-9]{5}[A-Z]{1}$/i.test(val))
    ),
  // .required("TAN is required *")
  company_person: yup.array().of(
    yup.object().shape({
      name: CommonValidation?.nameValidation,
      // email: CommonValidation?.emailValidation,
      // mobile: CommonValidation?.mobileValidation,
      DIN: yup
        .string()
        .nullable()
        .matches(/^\d{8}$/, "DIN must be exactly 8 digits"),
      // .required("DIN is required *")
      designation: yup.string().required("Designation is required *"),
    })
  ),
});

const ExternalInvitesForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = useSelector((state) => state.auth);
  const { setToastNotification } = useContext(CustomAlertContext);
  const [loader, setLoader] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [externalInviteDetails, setExternalInviteDetails] = useState(null);
  const [companyPersonCount, setCompanyPersonCount] = useState(1);
  const external_user_id = location?.state?.external_user_id;
  useEffect(() => {
    if (external_user_id) {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
  }, [external_user_id]);

  const GetExternalUserDetails = async () => {
    dispatch(setLoading(true));
    try {
      const external_invite_response = await apiConnector(
        "GET",
        externalInviteEndPoints?.EXTERNAL_INVITE_API +
          `/${external_user_id ?? externalInviteDetails?._id}`,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setExternalInviteDetails(external_invite_response?.data?.data);
      setCompanyPersonCount(
        external_invite_response?.data?.data?.company_person?.length
      );
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };
  useEffect(() => {
    if (external_user_id) GetExternalUserDetails();
  }, [external_user_id]);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(externalUserValidation),
    // reValidateMode: "onSubmit",
  });
  useEffect(() => {
    reset({
      user_name: externalInviteDetails?.user_name ?? undefined,
      email: externalInviteDetails?.email ?? undefined,
      mobile_number: externalInviteDetails?.mobile_number ?? undefined,
      company_name: externalInviteDetails?.company_name ?? undefined,
      level_of_seniority: externalInviteDetails?.designation ?? undefined,
      linked_in_profile: externalInviteDetails?.linked_in_profile ?? undefined,
      website_url: externalInviteDetails?.website_url ?? undefined,
      company_detail: externalInviteDetails?.company_detail ?? undefined,
      company_address: externalInviteDetails?.company_address ?? undefined,
      company_industry: externalInviteDetails?.company_industry ?? undefined,
      region_of_interest:
        externalInviteDetails?.region_of_interest ?? undefined,
      sector: externalInviteDetails?.sector ?? undefined,
      stage_type: externalInviteDetails?.stage_type ?? undefined,
      type_of_organization:
        externalInviteDetails?.type_of_organization ?? undefined,
      x_profile: externalInviteDetails?.x_profile ?? undefined,
      biography: externalInviteDetails?.biography ?? undefined,
      country: externalInviteDetails?.country ?? undefined,
      CIN: externalInviteDetails?.CIN ?? undefined,
      GSTN: externalInviteDetails?.GSTN ?? undefined,
      PAN: externalInviteDetails?.PAN ?? undefined,
      TAN: externalInviteDetails?.TAN ?? undefined,
      company_person: externalInviteDetails?.company_person?.map((item) => ({
        designation: item?.designation ?? "",
        name: item?.name ?? "",
        email: item?.email ?? "",
        mobile: item?.mobile ?? "",
        DIN: item?.DIN ?? "",
      })),
    });
  }, [externalInviteDetails]);
  const getCountries = async () => {
    const countries_response = await fetchAllCountries(
      dispatch,
      setToastNotification
    );
    setCountryList(countries_response?.data?.data);
  };

  useEffect(() => {
    getCountries();
  }, []);
  const countryOptions = countryList?.map((item) => ({
    name: item?.country,
    value: item?.country,
    designation: item?.Continent,
    avatar: item?.Flag,
  }));

  const onSubmitHandler = async (data) => {
    let payload = isEditMode
      ? {
          user_name:
            externalInviteDetails?.user_name !== data?.user_name
              ? data?.user_name
              : undefined,
          email:
            externalInviteDetails?.email !== data?.email
              ? data?.email
              : undefined,
          mobile_number:
            externalInviteDetails?.mobile_number !== data?.mobile_number
              ? data?.mobile_number
              : undefined,
          company_name:
            externalInviteDetails?.company_name !== data?.company_name
              ? data?.company_name
              : undefined,
          designation:
            externalInviteDetails?.designation !== data?.level_of_seniority
              ? data?.level_of_seniority
              : undefined,
          linked_in_profile:
            externalInviteDetails?.linked_in_profile !== data?.linked_in_profile
              ? data?.linked_in_profile
              : undefined,
          website_url:
            externalInviteDetails?.website_url !== data?.website_url
              ? data?.website_url
              : undefined,
          company_detail:
            externalInviteDetails?.company_detail !== data?.company_detail
              ? data?.company_detail
              : undefined,
          company_address:
            externalInviteDetails?.company_address !== data?.company_address
              ? data?.company_address
              : undefined,
          company_industry:
            externalInviteDetails?.company_industry !== data?.company_industry
              ? data?.company_industry
              : undefined,

          level_of_seniority:
            externalInviteDetails?.level_of_seniority !==
            data?.level_of_seniority
              ? data?.level_of_seniority
              : undefined,

          region_of_interest:
            externalInviteDetails?.region_of_interest !==
            data?.region_of_interest
              ? data?.region_of_interest
              : undefined,
          sector:
            externalInviteDetails?.sector !== data?.sector
              ? data?.sector
              : undefined,
          stage_type:
            externalInviteDetails?.stage_type !== data?.stage_type
              ? data?.stage_type
              : undefined,
          type_of_organization:
            externalInviteDetails?.type_of_organization !==
            data?.type_of_organization
              ? data?.type_of_organization
              : undefined,
          x_profile:
            externalInviteDetails?.x_profile !== data?.x_profile
              ? data?.x_profile
              : undefined,
          biography:
            externalInviteDetails?.biography !== data?.biography
              ? data?.biography
              : undefined,
          company_person: data?.company_person ?? undefined,
        }
      : { ...data, designation: data?.level_of_seniority };
    setLoader(true);
    try {
      const apiMethod = isEditMode ? "PUT" : "POST";
      const apiUrl = isEditMode
        ? `${externalInviteEndPoints?.EXTERNAL_INVITE_API}/${externalInviteDetails?._id}`
        : externalInviteEndPoints?.EXTERNAL_INVITE_API;
      const external_invite_response = await apiConnector(
        apiMethod,
        apiUrl,
        payload,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setToastNotification(external_invite_response?.data?.message, "success");
      reset();
      setExternalInviteDetails(null);
      navigate(-1);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      setLoader(false);
    }
  };
  return (
    <div className="w-full p-4 border bg-custom-white rounded-md">
      <div className="text-xl font-semibold">
        {!isEditMode ? "Add" : "Edit"} External Invite
      </div>
      <form
        className="w-full my-3"
        noValidate
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <section className="w-full border-b border-slate-300 pb-4">
          <h1 className="font-semibold my-2">Company Details</h1>
          <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4">
            <div>
              <CustomInput
                name="company_name"
                label="Organization Name"
                inputType="text"
                placeholder="ABC pvt ltd."
                // required={true}
                control={control}
                error={errors?.company_name?.message}
              />
            </div>
            <div>
              <CustomInput
                name="website_url"
                label="Organization Website URL"
                inputType="text"
                // required={true}
                placeholder="https://google.com"
                control={control}
                error={errors?.website_url?.message}
              />
            </div>
            <div>
              <CustomSelect
                name="company_industry"
                label="Industry"
                inputType="text"
                placeholder="Select..."
                control={control}
                // required={true}
                options={companyIndustryOptions}
                error={errors?.company_industry?.message}
              />
            </div>
            <div>
              <CustomSelect
                name="region_of_interest"
                label="Region"
                inputType="text"
                placeholder="Select..."
                control={control}
                // required={true}
                options={regionOfInterestOptions}
                error={errors?.region_of_interest?.message}
              />
            </div>
            <div>
              <CustomSelect
                name="sector"
                label="Sector"
                inputType="text"
                placeholder="Select..."
                control={control}
                // required={true}
                options={sectorOptions}
                error={errors?.sector?.message}
              />
            </div>

            <div>
              <CustomSelect
                name="stage_type"
                label="Stage Type"
                inputType="text"
                placeholder="Select..."
                control={control}
                // required={true}
                options={StageOptions}
                error={errors?.stage_type?.message}
              />
            </div>

            <div>
              <CustomSelect
                name="type_of_organization"
                label="Organization Type"
                inputType="text"
                placeholder="Select..."
                control={control}
                // required={true}
                options={typeOfOrganizationOptions}
                error={errors?.type_of_organization?.message}
              />
            </div>

            <div>
              <CustomSelect
                name="country"
                label="Country"
                inputType="text"
                placeholder="Select..."
                control={control}
                // required={true}
                options={countryOptions}
                error={errors?.country?.message}
              />
            </div>
            <div>
              <CustomInput
                name="CIN"
                label="Company CIN"
                inputType="text"
                placeholder=""
                // required={true}
                control={control}
                error={errors?.CIN?.message}
              />
            </div>
            <div>
              <CustomInput
                name="GSTN"
                label="Company GSTN"
                inputType="text"
                // required={true}
                placeholder=""
                control={control}
                error={errors?.GSTN?.message}
              />
            </div>
            <div>
              <CustomInput
                name="PAN"
                label="Company PAN"
                inputType="text"
                placeholder=""
                // required={true}
                control={control}
                error={errors?.PAN?.message}
              />
            </div>
            <div>
              <CustomInput
                name="TAN"
                label="Company TAN"
                inputType="text"
                // required={true}
                placeholder=""
                control={control}
                error={errors?.TAN?.message}
              />
            </div>
            <div className="col-span-1 lg:col-span-3 2xl:col-span-2">
              <CustomInput
                name="company_detail"
                label="Company Details"
                // required={true}
                inputType="text-area"
                placeholder="Type Here..."
                control={control}
                error={errors?.company_detail?.message}
              />
            </div>
            <div className="col-span-1 lg:col-span-3 2xl:col-span-2">
              <CustomInput
                name="company_address"
                label="Company Address"
                inputType="text-area"
                // required={true}
                placeholder="Type Here..."
                control={control}
                error={errors?.company_address?.message}
              />
            </div>
          </div>
        </section>
        <section className="w-full border-b border-slate-300 pb-4">
          <h1 className=" font-semibold my-2">Company Person Details</h1>
          {Array?.from({ length: companyPersonCount })?.map((_, index) => (
            <div
              key={index}
              className={`w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4 py-4 ${
                index < companyPersonCount - 1
                  ? "border-b  border-dashed border-slate-300"
                  : ""
              }`}
            >
              <div>
                <CustomSelect
                  name={`company_person[${index}].designation`}
                  label="Designation"
                  inputType="text"
                  placeholder="Select..."
                  control={control}
                  required={true}
                  options={externalInviteDesignationOptions}
                  error={errors?.company_person?.[index]?.designation?.message}
                />
              </div>
              <div>
                <CustomInput
                  name={`company_person[${index}].name`}
                  label="Name"
                  inputType="text"
                  placeholder="john doe"
                  control={control}
                  required={true}
                  error={errors?.company_person?.[index]?.name?.message}
                />
              </div>
              <div>
                <CustomInput
                  name={`company_person[${index}].email`}
                  label="Email"
                  inputType="text"
                  placeholder="john.doe@gmail.com"
                  control={control}
                  // required={true}
                  error={errors?.company_person?.[index]?.email?.message}
                />
              </div>
              <div>
                <CustomInput
                  name={`company_person[${index}].mobile`}
                  label="Mobile Number"
                  inputType="tel"
                  placeholder="98XXXXXXXX"
                  control={control}
                  // required={true}
                  error={errors?.company_person?.[index]?.mobile?.message}
                />
              </div>
              <div>
                <CustomInput
                  name={`company_person[${index}].DIN`}
                  label="DIN"
                  inputType="number"
                  placeholder="Select..."
                  control={control}
                  // required={true}
                  error={errors?.company_person?.[index]?.DIN?.message}
                />
              </div>
            </div>
          ))}
          <div className="flex justify-end items-center gap-2 py-2">
            {companyPersonCount > 1 && (
              <CustomButton
                title={<FaTrash />}
                buttonType="button"
                onClick={() => {
                  setCompanyPersonCount((prev) => prev - 1);
                }}
                classname="text-custom-status-cancelled border border-custom-status-cancelled hover:bg-custom-status-cancelled hover:text-custom-white p-1 rounded-md text-sm font-semibold "
              />
            )}
            <CustomButton
              title={<FaPlusCircle />}
              buttonType="button"
              onClick={() => {
                setCompanyPersonCount((prev) => prev + 1);
              }}
              classname="text-custom-blue border border-custom-blue hover:bg-custom-blue hover:text-custom-white p-1 rounded-md text-sm font-semibold "
            />
          </div>
        </section>
        <section>
          <h1 className=" font-semibold my-2">Meeting Person Details</h1>
          <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4">
            <div>
              <CustomInput
                name="user_name"
                label="Name"
                inputType="text"
                placeholder="john doe"
                control={control}
                required={true}
                error={errors?.user_name?.message}
              />
            </div>
            <div>
              <CustomInput
                name="email"
                label="Email"
                inputType="text"
                placeholder="john.doe@gmail.com"
                control={control}
                // required={true}
                error={errors?.email?.message}
              />
            </div>
            <div>
              <CustomInput
                name="mobile_number"
                label="Mobile Number"
                inputType="tel"
                placeholder="98XXXXXXXX"
                control={control}
                // required={true}
                error={errors?.mobile_number?.message}
              />
            </div>
            <div>
              <CustomSelect
                name="level_of_seniority"
                label="Designation / Level of Seniority"
                inputType="text"
                placeholder="Select..."
                control={control}
                // required={true}
                options={levelOfSeniorityOptions}
                error={errors?.level_of_seniority?.message}
              />
            </div>
            <div>
              <CustomInput
                name="linked_in_profile"
                label="Linkedin"
                inputType="text"
                placeholder="https://linkdin.com"
                // required={true}
                control={control}
                error={errors?.linked_in_profile?.message}
              />
            </div>
            <div>
              <CustomInput
                name="x_profile"
                label="X / Twitter"
                inputType="text"
                // required={true}
                placeholder="https://x.com"
                control={control}
                error={errors?.x_profile?.message}
              />
            </div>
            <div className="col-span-1 md:col-span-2 lg:col-span-3 2xl:col-span-4">
              <CustomInput
                name="biography"
                label="Biography"
                inputType="text-area"
                // required={true}
                placeholder="Type Here..."
                control={control}
                error={errors?.biography?.message}
              />
            </div>
          </div>
        </section>
        <div className="flex justify-end items-center gap-5 mt-4 md:mt-8">
          <CustomButton
            title="Cancel"
            buttonType="button"
            onClick={() => {
              setExternalInviteDetails(null);
              navigate("/external_invites");
            }}
            classname="text-[#363636] hover:border-custom-black border px-5 py-2 rounded-md font-semibold"
          />
          <CustomButton
            title={
              loader ? (
                <svg
                  aria-hidden="true"
                  className="w-4 h-4 me-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              ) : (
                "Save"
              )
            }
            disabled={loader}
            buttonType="submit"
            classname="bg-custom-blue font-semibold text-custom-white px-10 py-2 rounded-md"
          />
        </div>
      </form>
    </div>
  );
};

export default ExternalInvitesForm;

import React, { useContext, useEffect, useMemo, useState } from "react";
import { CustomSelect } from "../common";
import { useDispatch, useSelector } from "react-redux";
import { CustomAlertContext } from "../../context";
import dayjs from "dayjs";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { TimesheetForm } from ".";
import { setLoading } from "../../redux/slice/loadingSlice";
import { apiConnector } from "../../networking/ApiConnector";
import { timesheetEndPoints } from "../../networking/Endpoints";
const TimesheetValidation = yup.object().shape({
  entries: yup.array().of(
    yup.object().shape({
      project: yup.string().notRequired(),
      date: yup.string().notRequired(),
      task: yup.string().notRequired(),
      sub_task: yup.string().notRequired(),
      // Allow HH:MM (or HHH:MM) format for estimated time.
      estimated_time: yup
        .string()
        .matches(/^\d{2,3}:([0-5]\d)$/, "Time must be in HH:MM format")
        .nullable(),
      description: yup.string().notRequired(),
    })
  ),
});
const Timesheets = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { setToastNotification } = useContext(CustomAlertContext);
  const [showTimesheet, setShowTimesheet] = useState(1);
  const {
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(TimesheetValidation),
    defaultValues: {
      yearSelect: dayjs().year(),
      monthSelect: dayjs().month() + 1,
    },
  });
  const { insert, remove } = useFieldArray({ control, name: "entries" });
  const selected_year = watch("yearSelect");
  const selected_month = watch("monthSelect");
  const currentYear = dayjs().year();
  const yearOptions = [
    { name: (currentYear - 1).toString(), value: currentYear - 1 },
    { name: currentYear.toString(), value: currentYear },
  ];
  const monthOptions = [
    { name: "January", value: 1 },
    { name: "February", value: 2 },
    { name: "March", value: 3 },
    { name: "April", value: 4 },
    { name: "May", value: 5 },
    { name: "June", value: 6 },
    { name: "July", value: 7 },
    { name: "August", value: 8 },
    { name: "September", value: 9 },
    { name: "October", value: 10 },
    { name: "November", value: 11 },
    { name: "December", value: 12 },
  ];

  const [timesheetData, setTimesheetData] = useState([]);
  const fetchTimesheet = async () => {
    dispatch(setLoading(true));
    try {
      const timesheet_response = await apiConnector(
        "GET",
        `${timesheetEndPoints?.TIMESHEET_API}`,
        null,
        {
          Authorization: `Bearer ${token}`,
        },
        {
          month: monthOptions?.[selected_month - 1]?.name,
          year: selected_year,
        }
      );
      setTimesheetData(timesheet_response?.data?.timesheet);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };
  useEffect(() => {
    fetchTimesheet();
  }, [selected_month, selected_year]);
  const weeks = useMemo(() => {
    const firstDay = dayjs(`${selected_year}-${selected_month}-01`);
    const lastDay = firstDay.endOf("month");
    const today = dayjs();
    let weeksArr = [];
    let currentWeekStart = firstDay.startOf("isoWeek");

    while (
      currentWeekStart.isBefore(lastDay) ||
      currentWeekStart.isSame(lastDay, "week")
    ) {
      let week = [];
      let isValidWeek = false;

      for (let i = 0; i < 7; i++) {
        const date = currentWeekStart.add(i, "day");
        const formattedDate = date.format("YYYY-MM-DD");

        if (date.month() + 1 === selected_month) {
          week.push(formattedDate);
          if (date.isSameOrBefore(today, "day")) isValidWeek = true;
        } else {
          week.push("");
        }
      }

      if (isValidWeek) weeksArr.push(week);
      else break;

      currentWeekStart = currentWeekStart.add(7, "day");
    }

    return weeksArr;
  }, [selected_year, selected_month]);

  const getFilteredTimesheet = () => {
    const flatWeeksArray = weeks.flat();

    return timesheetData.filter((entry) => {
      const isValid = entry?.work?.some((workItem) =>
        flatWeeksArray.includes(workItem.date.split("T")[0])
      );

      if (!isValid) return false;

      if (
        showTimesheet === 2 &&
        entry?.status === "Approved" &&
        entry?.isSubmit
      )
        return true;
      if (
        showTimesheet === 3 &&
        entry?.status === "Rejected" &&
        entry?.isSubmit
      )
        return true;
      if (showTimesheet === 4 && entry?.status === "Pending" && entry?.isSubmit)
        return true;

      return showTimesheet === 1 && entry?.isSubmit === false;
    });
  };

  // //filter weeks as per timesheetdata to render weeks in tabs
  // const filterWeekData = (weekData, timesheets = []) => {
  //   if (!timesheetData?.length && showTimesheet === 1) return weeks;
  //   if (showTimesheet === 1) {
  //   } else {
  //     return weekData.filter((week) => {
  //       return timesheets?.some((timesheet) => {
  //         const startDate = timesheet.week_start_date.split("T")[0];
  //         const endDate = timesheet.week_end_date.split("T")[0];
  //         return week.includes(startDate) || week.includes(endDate);
  //       });
  //     });
  //   }
  // };
  // const RenderedWeeks = filterWeekData(weeks, getFilteredTimesheet());
  // // if (showTimesheet === 1) RenderedWeeks?.push(weeks[weeks?.length - 1]);
  // console.log(RenderedWeeks, "renderedWeeks");
  // Filter weeks as per timesheet data to render weeks in tabs
  const filterWeekData = (weekData, timesheets = []) => {
    if (!timesheetData?.length && showTimesheet === 1) return weeks;

    switch (showTimesheet) {
      case 1: // Default: Show all unsubmitted or unfilled weeks
        return weekData.filter((week) => {
          return !timesheets?.some((timesheet) => {
            const startDate = timesheet.week_start_date.split("T")[0];
            const endDate = timesheet.week_end_date.split("T")[0];
            return (
              (week.includes(startDate) || week.includes(endDate)) &&
              timesheet.isSubmit === true
            );
          });
        });

      case 2: // Approved weeks
        return weekData.filter((week) => {
          return timesheets?.some((timesheet) => {
            const startDate = timesheet.week_start_date.split("T")[0];
            const endDate = timesheet.week_end_date.split("T")[0];
            return (
              (week.includes(startDate) || week.includes(endDate)) &&
              timesheet.isSubmit === true &&
              timesheet.isApproved === true &&
              timesheet.status === "Approved"
            );
          });
        });

      case 3: // Rejected weeks (filled but not approved)
        return weekData.filter((week) => {
          return timesheets?.some((timesheet) => {
            const startDate = timesheet.week_start_date.split("T")[0];
            const endDate = timesheet.week_end_date.split("T")[0];
            return (
              (week.includes(startDate) || week.includes(endDate)) &&
              timesheet.isSubmit === true &&
              timesheet.status === "Rejected"
            );
          });
        });

      case 4: // Pending weeks
        return weekData.filter((week) => {
          return timesheets?.some((timesheet) => {
            const startDate = timesheet.week_start_date.split("T")[0];
            const endDate = timesheet.week_end_date.split("T")[0];
            return (
              (week.includes(startDate) || week.includes(endDate)) &&
              timesheet.isSubmit === true &&
              timesheet.status === "Pending"
            );
          });
        });

      default:
        return weekData;
    }
  };

  const RenderedWeeks = filterWeekData(weeks, timesheetData);
  console.log(RenderedWeeks, "renderedWeeks");

  return (
    <div className="w-full h-full bg-white rounded-lg shadow-md p-4">
      <section className="flex flex-col sm:flex-row justify-between items-center">
        <h1 className="font-semibold text-xl">Timesheet</h1>
        <div className="flex items-center gap-4">
          <div className="flex flex-col sm:flex-row gap-4 -mt-2">
            <CustomSelect
              control={control}
              name="yearSelect"
              options={yearOptions}
              placeholder="Select Year"
              showInfoButton={false}
            />
            <CustomSelect
              control={control}
              name="monthSelect"
              options={monthOptions}
              placeholder="Select Month"
              showInfoButton={false}
            />
          </div>
          <div className="border border-slate-200 text-sm font-semibold flex rounded-md cursor-pointer">
            <span
              onClick={() => setShowTimesheet(1)}
              className={`p-2 ${showTimesheet === 1 && "bg-slate-200"}`}
            >
              Default
            </span>
            <span
              onClick={() => setShowTimesheet(2)}
              className={`p-2 ${showTimesheet === 2 && "bg-slate-200"}`}
            >
              Approved
            </span>
            <span
              onClick={() => setShowTimesheet(3)}
              className={`p-2 ${showTimesheet === 3 && "bg-slate-200"}`}
            >
              Rejected
            </span>
            <span
              onClick={() => setShowTimesheet(4)}
              className={`p-2 ${showTimesheet === 4 && "bg-slate-200"}`}
            >
              Pending
            </span>
          </div>
        </div>
      </section>
      <TimesheetForm
        weeks={RenderedWeeks}
        fetchTimesheets={fetchTimesheet}
        selected_year={selected_year}
        selected_month={selected_month}
        showTimesheet={showTimesheet}
        timesheetData={getFilteredTimesheet()}
      />
    </div>
  );
};

export default Timesheets;

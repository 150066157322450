import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Roles } from "../../constant/Constant";
import { dateFormatter } from "../../helper/formatDate";
const baseUrl = process.env.REACT_APP_BASE_URL;

const ProjectMembers = ({ project }) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  console.log(project, "project");
  const past_project_members = [];
  project?.team_lead
    ?.filter((member) => member?.isActive === false)
    ?.map((member) => past_project_members.push({ ...member, role: "TL" }));
  project?.members
    ?.filter((member) => member?.isActive === false)
    ?.map((member) => past_project_members.push({ ...member, role: "Member" }));
  console.log(past_project_members, "past_project_members");
  return (
    <div className="w-full bg-custom-white rounded-md">
      <section className="w-full p-4 border-b border-dashed">
        <div className="font-semibold text-sm md:text-lg ">Project Manager</div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4 mt-2 md:mt-4">
          <div
            onClick={() => {
              if (
                user?.roleId !== Roles?.employee &&
                user?.roleId !== Roles?.team_lead
              )
                navigate("/employees/view", {
                  state: { id: project?.project_manager?._id },
                });
            }}
            className="p-4 cursor-pointer border border-[#E3F3FF] rounded-md space-y-2 shadow-[2px_3px_10px_6px_#257CBB0F]"
          >
            <div className="flex justify-start items-center gap-4">
              <img
                src={
                  project?.project_manager?.avatar
                    ? `${baseUrl}/${project?.project_manager?.avatar}`
                    : `https://api.dicebear.com/5.x/initials/svg?seed=${project?.project_manager?.user_name}`
                }
                alt={project?.project_manager?.user_name?.[0]}
                className="w-8 md:w-11 h-8 md:h-11 rounded-full"
              />
              <div>
                <span className="block font-semibold">
                  {project?.project_manager?.user_name}
                </span>
                <span className="block text-xs text-slate-400">
                  {project?.project_manager?.designation?.designation}
                </span>
              </div>
            </div>
            <div className="w-full h-[1px] border border-dashed" />
            <div className="space-y-2 text-sm">
              <div className="flex justify-between items-center">
                <span className="text-[#696969]">Employee ID</span>
                <span className="text-[#363636] font-semibold">
                  {project?.project_manager?.emp_id}
                </span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-[#696969]">Name</span>
                <span className="text-[#363636] font-semibold">
                  {project?.project_manager?.user_name}
                </span>
              </div>
              <div className="flex justify-between items-center gap-5">
                <span className="text-[#696969]">Email</span>
                <span className="text-[#363636] font-semibold employee-ellipsis">
                  {project?.project_manager?.email}
                </span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-[#696969]">Mobile No</span>
                <span className="text-[#363636] font-semibold">
                  {project?.project_manager?.mobile_number}
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="w-full p-4 border-b border-dashed">
        <div className="font-semibold text-sm md:text-lg ">Team Lead</div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4 mt-2 md:mt-4 ">
          {project?.team_lead?.length
            ? project?.team_lead
                ?.filter((member) => member?.isActive === true)
                ?.map(
                  (member, index) =>
                    member?.user && (
                      <div
                        key={member?._id}
                        onClick={() => {
                          if (
                            user?.roleId !== Roles?.employee &&
                            user?.roleId !== Roles?.team_lead
                          )
                            navigate("/employees/view", {
                              state: { id: member?._id },
                            });
                        }}
                        className="p-4 cursor-pointer border border-[#E3F3FF] rounded-md space-y-2 shadow-[2px_3px_10px_6px_#257CBB0F]"
                      >
                        <div className="flex justify-start items-center gap-4">
                          <img
                            src={
                              member?.user?.avatar
                                ? `${baseUrl}/${member?.user?.avatar}`
                                : `https://api.dicebear.com/5.x/initials/svg?seed=${member?.user?.user_name}`
                            }
                            alt={member?.user?.user_name?.[0]}
                            className="w-8 md:w-11 h-8 md:h-11 rounded-full"
                          />
                          <div>
                            <span className="block font-semibold">
                              {member?.user?.user_name}
                            </span>
                            <span className="block text-xs text-slate-400">
                              {member?.user?.designation?.designation}
                            </span>
                          </div>
                        </div>
                        <div className="w-full h-[1px] border border-dashed" />
                        <div className="space-y-2 text-sm">
                          <div className="flex justify-between items-center">
                            <span className="text-[#696969]">Employee ID</span>
                            <span className="text-[#363636] font-semibold">
                              {member?.user?.emp_id}
                            </span>
                          </div>
                          <div className="flex justify-between items-center">
                            <span className="text-[#696969]">Name</span>
                            <span className="text-[#363636] font-semibold">
                              {member?.user?.user_name}
                            </span>
                          </div>
                          <div className="flex justify-between items-center w-full">
                            <span className="text-[#696969]">Email</span>
                            <span className="text-[#363636] font-semibold employee-ellipsis">
                              {member?.user?.email}
                            </span>
                          </div>
                          <div className="flex justify-between items-center">
                            <span className="text-[#696969]">Mobile No</span>
                            <span className="text-[#363636] font-semibold">
                              {member?.user?.mobile_number}
                            </span>
                          </div>
                        </div>
                      </div>
                    )
                )
            : "NO TL Available"}
        </div>
      </section>
      <section className="w-full p-4 border-b border-dashed">
        <div className="font-semibold text-sm md:text-lg ">Project Members</div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4 mt-2 md:mt-4">
          {project?.members?.length
            ? project?.members
                ?.filter((member) => member?.isActive === true)
                ?.map(
                  (member, index) =>
                    member?.user && (
                      <div
                        key={index}
                        onClick={() => {
                          if (
                            user?.roleId !== Roles?.employee &&
                            user?.roleId !== Roles?.team_lead
                          )
                            navigate("/employees/view", {
                              state: { id: member?.user?._id },
                            });
                        }}
                        className="p-4 cursor-pointer border border-[#E3F3FF] rounded-md space-y-2 shadow-[2px_3px_10px_6px_#257CBB0F]"
                      >
                        <div className="flex justify-start items-center gap-4">
                          <img
                            src={
                              member?.user?.avatar
                                ? `${baseUrl}/${member?.user?.avatar}`
                                : `https://api.dicebear.com/5.x/initials/svg?seed=${member?.user?.user_name}`
                            }
                            alt={member?.user?.user_name?.[0]}
                            className="w-8 md:w-11 h-8 md:h-11 rounded-full"
                          />
                          <div>
                            <span className="block font-semibold">
                              {member?.user?.user_name}
                            </span>
                            <span className="block text-xs text-slate-400">
                              {member?.designation?.designation}
                            </span>
                          </div>
                        </div>
                        <div className="w-full h-[1px] border border-dashed" />
                        <div className="space-y-2 text-sm">
                          <div className="flex justify-between items-center">
                            <span className="text-[#696969]">Employee ID</span>
                            <span className="text-[#363636] font-semibold">
                              {member?.user?.emp_id}
                            </span>
                          </div>
                          <div className="flex justify-between items-center">
                            <span className="text-[#696969]">Name</span>
                            <span className="text-[#363636] font-semibold">
                              {member?.user?.user_name}
                            </span>
                          </div>
                          <div className="flex justify-between items-center w-full">
                            <span className="text-[#696969]">Email</span>
                            <span className="text-[#363636] font-semibold employee-ellipsis">
                              {member?.user?.email}
                            </span>
                          </div>
                          <div className="flex justify-between items-center">
                            <span className="text-[#696969]">Mobile No</span>
                            <span className="text-[#363636] font-semibold">
                              {member?.user?.mobile_number}
                            </span>
                          </div>
                          <div className="flex justify-between items-center">
                            <span className="text-[#696969]">
                              Project Assign Date
                            </span>
                            <span className="text-[#363636] font-semibold">
                              {dateFormatter(member?.project_assigned_at, {
                                format: "MMM DD,YYYY",
                                includeTimeForToday: true,
                              })}
                            </span>
                          </div>
                        </div>
                      </div>
                    )
                )
            : "NO Project Member Available"}
        </div>
      </section>
      <section className="w-full p-4 ">
        <div className="font-semibold text-sm md:text-lg ">
          Past Project Members
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4 mt-2 md:mt-4">
          {past_project_members?.length
            ? past_project_members?.map(
                (member, index) =>
                  member?.user && (
                    <div
                      key={member?._id}
                      onClick={() => {
                        if (
                          user?.roleId !== Roles?.employee &&
                          user?.roleId !== Roles?.team_lead
                        )
                          navigate("/employees/view", {
                            state: { id: member?.user?._id },
                          });
                      }}
                      className="p-4 cursor-pointer border border-[#E3F3FF] rounded-md space-y-2 shadow-[2px_3px_10px_6px_#257CBB0F]"
                    >
                      <div className="flex justify-start items-center gap-4">
                        <img
                          src={
                            member?.user?.avatar
                              ? `${baseUrl}/${member?.user?.avatar}`
                              : `https://api.dicebear.com/5.x/initials/svg?seed=${member?.user?.user_name}`
                          }
                          alt={member?.user?.user_name?.[0]}
                          className="w-8 md:w-11 h-8 md:h-11 rounded-full"
                        />
                        <div>
                          <span className="block font-semibold">
                            {member?.user?.user_name}(
                            <span className="text-xs text-slate-500">
                              {member?.role}
                            </span>
                            )
                          </span>
                          <span className="block text-xs text-slate-400">
                            {member?.user?.designation?.designation}
                          </span>
                        </div>
                      </div>
                      <div className="w-full h-[1px] border border-dashed" />
                      <div className="space-y-2 text-sm">
                        <div className="flex justify-between items-center">
                          <span className="text-[#696969]">Employee ID</span>
                          <span className="text-[#363636] font-semibold">
                            {member?.user?.emp_id}
                          </span>
                        </div>
                        <div className="flex justify-between items-center">
                          <span className="text-[#696969]">Name</span>
                          <span className="text-[#363636] font-semibold">
                            {member?.user?.user_name}
                          </span>
                        </div>
                        <div className="flex justify-between items-center w-full">
                          <span className="text-[#696969]">Email</span>
                          <span className="text-[#363636] font-semibold employee-ellipsis">
                            {member?.user?.email}
                          </span>
                        </div>
                        <div className="flex justify-between items-center">
                          <span className="text-[#696969]">Mobile No</span>
                          <span className="text-[#363636] font-semibold">
                            {member?.user?.mobile_number}
                          </span>
                        </div>
                        <div className="flex justify-between items-center">
                          <span className="text-[#696969]">
                            Project Releaving Date
                          </span>
                          <span className="text-[#363636] font-semibold">
                            {dateFormatter(member?.project_unassigned_at, {
                              format: "MMM DD,YYYY",
                              includeTimeForToday: true,
                            })}
                          </span>
                        </div>
                      </div>
                    </div>
                  )
              )
            : "No Past Members Available"}
        </div>
      </section>
    </div>
  );
};

export default ProjectMembers;

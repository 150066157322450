import React, { useRef, useState } from "react";
import ReactDOM from "react-dom";

const CommonTooltip = ({
  tooltip,
  children,
  tooltipClass = "bg-gray-900 dark:bg-gray-700 text-custom-white",
}) => {
  const parentRef = useRef(null);
  const [tooltipCoords, setTooltipCoords] = useState({ left: 0, top: 0 });
  const [visible, setVisible] = useState(false);

  const handleMouseEnter = (e) => {
    if (!parentRef.current) return;
    const rect = parentRef.current.getBoundingClientRect();
    const left = rect.left + rect.width / 2;
    const top = rect.top - 24;
    setTooltipCoords({ left, top });
    setVisible(true);
  };

  const handleMouseLeave = () => {
    setVisible(false);
  };

  const tooltipElement =
    visible && tooltip ? (
      <span
        className={`${tooltipClass} text-xs shadow-sm font-semibold z-[999] transition p-1 rounded absolute`}
        style={{
          left: tooltipCoords.left,
          top: tooltipCoords.top,
          transform: "translateX(-50%)",
          whiteSpace: "nowrap",
        }}
      >
        {tooltip}
      </span>
    ) : null;

  return (
    <div
      ref={parentRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="group relative inline-block cursor-pointer"
    >
      {children}
      {ReactDOM.createPortal(tooltipElement, document.body)}
    </div>
  );
};

export default CommonTooltip;

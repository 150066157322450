import { setLoading } from "../redux/slice/loadingSlice";
import { apiConnector } from "./ApiConnector";
import { countryEndPoints } from "./Endpoints";

export const fetchAllCountries = async (
  //   token,
  dispatch,
  setToastNotification
) => {
  dispatch(setLoading(true));
  try {
    const countries_response = await apiConnector(
      "GET",
      countryEndPoints?.COUNTRY_API,
      null,
      null
    );
    return countries_response;
  } catch (error) {
    setToastNotification(error?.message, "error");
  } finally {
    dispatch(setLoading(false));
  }
};

import { useCallback, useContext, useEffect, useRef } from "react";
import {
  ReactFlow,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  MiniMap,
  ReactFlowProvider,
} from "@xyflow/react";

import "@xyflow/react/dist/style.css";
import { employeeEndPoints } from "../../networking/Endpoints";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CustomAlertContext } from "../../context";
import { setLoading } from "../../redux/slice/loadingSlice";
import { apiConnector } from "../../networking/ApiConnector";
import CustomNodeForReactFlow from "./CustomNodeForReactFlow";

function EmployeeChart({ showEmployeeView = 3 }) {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const navigate = useNavigate(),
    dispatch = useDispatch(),
    { token, user } = useSelector((state) => state.auth),
    { loading } = useSelector((state) => state.loading),
    { setToastNotification } = useContext(CustomAlertContext);
  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );
  const GetEmployees = async () => {
    dispatch(setLoading(true));
    try {
      const employee_response = await apiConnector(
        "GET",
        employeeEndPoints?.EMPLOYEE_HIERARCHY_API,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setNodes(employee_response?.data?.nodes);
      setEdges(employee_response?.data?.edges);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };
  useEffect(() => {
    if (showEmployeeView === 3) GetEmployees();
  }, [showEmployeeView]);
  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      fitView
      nodeTypes={{ custom: CustomNodeForReactFlow }}
      className="w-full h-full"
      panOnDrag
      nodesDraggable
      nodesConnectable={false}
      elementsSelectable={false}
    >
      {/* <MiniMap /> */}
      {/* <Controls />*/}
      <Background />
    </ReactFlow>
  );
}

export default EmployeeChart;

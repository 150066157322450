import React, { useContext, useEffect, useRef, useState } from "react";
import {
  CustomButton,
  CustomDrawer,
  FilterSelect,
  NoDataFound,
  Pagination,
  PaginationText,
  TableHeader,
} from "../../common";
import { MdOutlineEdit, MdOutlineFilterAlt } from "react-icons/md";
import { IoMdSearch } from "react-icons/io";
import { FaPlusCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import useOnClickOutside from "../../../helper/onClickOutside";
import { dateFormatter } from "../../../helper/formatDate";
import { fetchDepartment } from "../../../redux/slice/departmentSlice";
import Skeleton from "react-loading-skeleton";
import { meetingEndPoints } from "../../../networking/Endpoints";
import { setLoading } from "../../../redux/slice/loadingSlice";
import { apiConnector } from "../../../networking/ApiConnector";
import { CustomAlertContext } from "../../../context";
import { fetchMeetingCategory } from "../../../redux/slice/meetingCategorySlice";
import ExternalInvitesForm from "./ExternalInvitesForm";
import { fethAllExternalInvites } from "../../../redux/slice/externalInviteSlice";
import { GrView } from "react-icons/gr";
import ExternalUserView from "./ExternalUserView";
import {
  companyIndustryOptions,
  contentTrackInterestOptions,
  levelOfSeniorityOptions,
  matchMakingReasonOptions,
  regionOfInterestOptions,
  Roles,
  sectorOptions,
} from "../../../constant/Constant";
import { setExternalUserFilter } from "../../../redux/slice/filterSlice";
import { useNavigate } from "react-router-dom";

const ExternalInvites = () => {
  const drawerRef = useRef(),
    dispatch = useDispatch(),
    navigate = useNavigate(),
    { setToastNotification } = useContext(CustomAlertContext),
    { token, user } = useSelector((state) => state.auth),
    { loading } = useSelector((state) => state.loading),
    { externalUserFilter } = useSelector((state) => state.filter),
    [showExternalInviteFormModal, setShowExternalInviteFormModal] =
      useState(false),
    [showExternalInviteViewModal, setShowExternalInviteViewModal] =
      useState(false),
    [externalInviteDetails, setExternalInviteDetails] = useState(null),
    [externalInvitesData, setExternalInvitesData] = useState([]),
    [externalInvitesTableData, setExternalInvitesTableData] = useState([]),
    [searchString, setSearchString] = useState(""),
    [externalInvitesCount, setExternalInvitesCount] = useState(0),
    [itemsPerPage, setItemsPerPage] = useState(100),
    [currentPage, setCurrentPage] = useState(1),
    [openFilterDrawer, setOpenFilterDrawer] = useState(false);

  const TableHeads = [
    "S.No",
    "Name",
    "Email",
    "Mobile Number",
    "Company",
    "Company Industry",
    "Address",
    "Actions",
  ];

  const getExternalInvites = async (
    level_of_seniority = "",
    region_of_interest = "",
    company_industry = "",
    sector = "",
    match_making_reason = ""
  ) => {
    try {
      const response = await dispatch(
        fethAllExternalInvites({
          token,
          currentPage,
          itemsPerPage,
          level_of_seniority,
          region_of_interest,
          company_industry,
          sector,
          match_making_reason,
        })
      ).unwrap();
      if (response) {
        setExternalInvitesData(response?.externalUser);
        setExternalInvitesTableData(response?.externalUser);
        // setExternalInvitesCount(response?.externalUser);
      }
      setOpenFilterDrawer(false);
    } catch (error) {
      setToastNotification(error?.message, "error");
    }
  };

  useEffect(() => {
    getExternalInvites();
  }, [currentPage, itemsPerPage]);

  const filterCategory = (e) => {
    // const val = e?.target?.value;
    // setSearchString(e?.target?.value);
    // if (val?.length > 0) {
    //   const filteredDepartments = departments?.filter(
    //     (item) =>
    //       item?.department_name.toLowerCase().includes(val.toLowerCase()) ||
    //       dateFormatter(item?.createdAt)
    //         .toLowerCase()
    //         .includes(val.toLowerCase()) ||
    //       dateFormatter(item?.updatedAt)
    //         .toLowerCase()
    //         .includes(val.toLowerCase())
    //   );
    //   setDepartmentTableData(filteredDepartments);
    // } else {
    //   setDepartmentTableData(departments);
    // }
  };

  const pageCount = Math.ceil(externalInvitesCount / itemsPerPage);
  const handlePageClick = (event) => {
    setCurrentPage(event?.selected + 1);
    fetchDepartment();
  };
  const filterExternalUserApiHandler = () => {
    const {
      level_of_seniority,
      region_of_interest,
      company_industry,
      sector,
      match_making_reason,
    } = externalUserFilter;
    getExternalInvites(
      level_of_seniority,
      region_of_interest,
      company_industry,
      sector,
      match_making_reason
    );
  };
  return (
    <div className="w-full">
      <section className="flex md:flex-row flex-col gap-2 justify-between bg-custom-white items-center p-2 rounded-t-md">
        <h1 className="font-semibold text-lg">External Invites List</h1>
        <div className="flex md:flex-row flex-col justify-center items-center gap-4">
          {/* <div className="relative">
            <IoMdSearch className="absolute z-10 top-3 left-1 text-slate-400" />
            <input
              name="searchString"
              type="text"
              placeholder="Search"
              onChange={(e) => filterCategory(e)}
              value={searchString}
              className="border px-6 py-2 font-semibold text-sm outline-none rounded-md"
            />
          </div>
          */}
          <CustomButton
            title={
              <div className="relative flex justify-center items-center font-semibold">
                {externalUserFilter && (
                  <div className="absolute w-5 h-5 flex justify-center items-center rounded-full bg-green-500 -right-4 -top-4 text-xs font-semibold text-custom-white">
                    {
                      Object.entries(externalUserFilter).filter(
                        ([key, value]) => value !== ""
                      ).length
                    }
                  </div>
                )}
                <MdOutlineFilterAlt size={20} />
                <span>Filters</span>
              </div>
            }
            buttonType="submit"
            onClick={() =>
              setOpenFilterDrawer((openFilterDrawer) => !openFilterDrawer)
            }
            classname="bg-custom-white text-slate-400 p-2 text-sm font-semibold rounded-md border"
          />
          <CustomButton
            title={
              <div className="flex justify-center items-center gap-2 ">
                <FaPlusCircle />
                <span>Add External Invites</span>
              </div>
            }
            buttonType="button"
            onClick={() =>
              // setShowExternalInviteFormModal(
              //   (showExternalInviteFormModal) => !showExternalInviteFormModal
              // )
              navigate("/external_invites_form")
            }
            classname="bg-gradient-custom text-custom-white font-semibold text-sm px-5 py-2 rounded-md"
          />
        </div>
        {openFilterDrawer && (
          <CustomDrawer
            ref={drawerRef}
            open={openFilterDrawer}
            setOpen={setOpenFilterDrawer}
            filterName="External Invite Filter"
          >
            {/* <div className="my-2">
              <FilterInput
                name="user_name"
                label="Name"
                inputType="text"
                placeholder="Name: John.doe"
                defaultValue={employeeFilter?.user_name}
                onChange={(value) => {
                  dispatch(
                    setEmployeeFilter({
                      user_name: value,
                    })
                  );
                }}
              />
            </div> */}
            <div className="my-2">
              <FilterSelect
                name="level_of_seniority"
                label="Designation / level of seniority"
                placeholder="Select a designation"
                options={levelOfSeniorityOptions}
                defaultValue={externalUserFilter?.level_of_seniority}
                onChange={(selected) => {
                  dispatch(
                    setExternalUserFilter({
                      level_of_seniority: selected?.value,
                    })
                  );
                }}
              />
            </div>
            <div className="my-2">
              <FilterSelect
                name="company_industry"
                label="Company Industry"
                placeholder="Select a industry"
                options={companyIndustryOptions}
                defaultValue={externalUserFilter?.company_industry}
                onChange={(selected) => {
                  dispatch(
                    setExternalUserFilter({
                      company_industry: selected?.value,
                    })
                  );
                }}
              />
            </div>
            {/* <div className="my-2">
              <FilterSelect
                name="sector"
                label="Content Track Interest"
                placeholder="Select an option"
                options={sectorOptions}
                defaultValue={externalUserFilter?.sector}
                onChange={(selected) => {
                  dispatch(
                    setExternalUserFilter({
                      sector: selected?.value,
                    })
                  );
                }}
              />
            </div> 
            <div className="my-2">
              <FilterSelect
                name="match_making_reason"
                label="Match Making Reason"
                placeholder="Select an option"
                options={matchMakingReasonOptions}
                defaultValue={externalUserFilter?.match_making_reason}
                onChange={(selected) => {
                  dispatch(
                    setExternalUserFilter({
                      match_making_reason: selected?.value,
                    })
                  );
                }}
              />
            </div>*/}
            <div className="my-2">
              <FilterSelect
                name="region_of_interest"
                label="Region Of Interest"
                placeholder="Select an option"
                options={regionOfInterestOptions}
                defaultValue={externalUserFilter?.region_of_interest}
                onChange={(selected) => {
                  dispatch(
                    setExternalUserFilter({
                      region_of_interest: selected?.value,
                    })
                  );
                }}
              />
            </div>
            <div className="w-full absolute bottom-0 right-0 p-4">
              <div className="w-full h-[1px] bg-slate-200 my-2" />
              <div className="flex items-center justify-between ">
                <CustomButton
                  title={"Clear"}
                  onClick={() => {
                    setCurrentPage(1);
                    dispatch(setExternalUserFilter(null));
                    getExternalInvites();
                    setOpenFilterDrawer(false);
                  }}
                  buttonType="submit"
                  classname={"border px-5 py-1 rounded-md"}
                />
                <CustomButton
                  title={"Apply"}
                  onClick={() => {
                    setCurrentPage(1);
                    filterExternalUserApiHandler();
                  }}
                  buttonType="submit"
                  classname={
                    "border px-5 py-1 rounded-md bg-custom-status-completed text-custom-white"
                  }
                />
              </div>
            </div>
          </CustomDrawer>
        )}
      </section>
      <section className="w-full overflow-auto">
        <table className="w-full border bg-custom-white rounded-lg overflow-auto">
          <thead>
            <TableHeader TableHeads={TableHeads} />
          </thead>
          {loading ? (
            <tbody>
              <tr>
                <td
                  colSpan={TableHeads?.length}
                  className="text-center font-semibold text-sm p-3"
                >
                  <Skeleton
                    count={5}
                    width={"100%"}
                    height={"22px"}
                    className="my-2"
                  />
                </td>
              </tr>
            </tbody>
          ) : externalInvitesTableData &&
            externalInvitesTableData?.length > 0 ? (
            <tbody>
              {externalInvitesTableData?.map((item, index) => {
                return (
                  <tr
                    key={item?._id}
                    className="w-full border-b hover:bg-slate-100"
                  >
                    <td className="p-2 text-sm font-medium">
                      {(currentPage - 1) * itemsPerPage + index + 1}
                    </td>
                    <td className="p-2 text-sm">{item?.user_name}</td>
                    <td className="p-2 text-sm">{item?.email}</td>
                    <td className="p-2 text-sm">{item?.mobile_number}</td>
                    <td className="p-2 text-sm">{item?.company_name}</td>
                    <td className="p-2 text-sm">{item?.company_industry}</td>
                    <td className="p-2 text-sm">
                      <p className="ellipsis">{item?.company_address}</p>
                    </td>
                    {/* <td className="p-2 text-sm">
                      {item?.createdAt
                        ? dateFormatter(item?.createdAt, {
                            format: "MMM DD, YYYY hh:mm A",
                            includeTimeForToday: true,
                          })
                        : "--"}
                    </td>
                    <td className="p-2 text-sm">
                      {item?.createdAt !== item?.updatedAt
                        ? dateFormatter(item?.updatedAt, {
                            format: "MMM DD, YYYY hh:mm A",
                            includeTimeForToday: true,
                          })
                        : "-"}
                    </td> */}

                    <td className="p-2 flex gap-2 ">
                      <div
                        onClick={() => {
                          setExternalInviteDetails(item);
                          setShowExternalInviteViewModal(true);
                        }}
                        className="p-1 border border-custom-blue rounded-md cursor-pointer"
                      >
                        <GrView className="text-custom-blue" />
                      </div>
                      <div
                        onClick={() => {
                          // setExternalInviteDetails(item);
                          if (
                            user?.roleId === Roles?.admin ||
                            item?.created_by === user?._id
                          )
                            navigate("/external_invites_form", {
                              state: {
                                external_user_id: item?._id,
                              },
                            });
                          // setShowExternalInviteFormModal(true);
                        }}
                        className={`p-1 border border-custom-blue rounded-md ${
                          user?.roleId === Roles?.admin ||
                          item?.created_by === user?._id
                            ? "cursor-pointer"
                            : "cursor-not-allowed"
                        } `}
                      >
                        <MdOutlineEdit className="text-custom-blue" />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td
                  colSpan={TableHeads.length}
                  className="text-center font-semibold text-sm p-4"
                >
                  <NoDataFound />
                </td>
              </tr>
            </tbody>
          )}
          <tfoot className="relative">
            <tr>
              <td className="text-slate-400 text-sm px-2 py-4" colSpan={6}>
                <PaginationText
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  dataCount={externalInvitesCount}
                  dataLength={externalInvitesData?.length}
                />
              </td>
              <td className="absolute right-2 top-1.5">
                <Pagination
                  handlePageClick={handlePageClick}
                  pageRangeDisplayed={itemsPerPage}
                  pageCount={pageCount}
                  name={"pageSize"}
                  onChange={(e) => setItemsPerPage(e?.target?.value)}
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </section>
      {/* {showExternalInviteFormModal && (
        <ExternalInvitesForm
          externalInviteDetails={externalInviteDetails}
          setExternalInviteDetails={setExternalInviteDetails}
          showExternalInviteFormModal={showExternalInviteFormModal}
          setShowExternalInviteFormModal={setShowExternalInviteFormModal}
          fetchAllExternalInvites={getExternalInvites}
        />
      )} */}
      {showExternalInviteViewModal && (
        <ExternalUserView
          showExternalInviteViewModal={showExternalInviteViewModal}
          setShowExternalInviteViewModal={setShowExternalInviteViewModal}
          externalInviteDetails={externalInviteDetails}
          showExternalInviteFormModal={showExternalInviteFormModal}
          setShowExternalInviteFormModal={setShowExternalInviteFormModal}
        />
      )}
    </div>
  );
};

export default ExternalInvites;

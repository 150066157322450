import React, { useContext } from "react";
import CustomButton from "./CustomButton";
import { MdLogout } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setToken, setUser } from "../../redux/slice/authSlice";
import { toast } from "react-toastify";
import CustomModal from "./CustomModal";
import { CustomAlertContext } from "../../context";

const Logout = ({ logout, setLogout }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setToastNotification } = useContext(CustomAlertContext);
  const clickHandler = () => {
    // dispatch(setToken(null));
    // dispatch(setUser(null));
    dispatch({ type: "RESET" });
    setLogout(false);
    setToastNotification("Logout Successfully", "success");
    navigate("/login");
  };
  return (
    <CustomModal isOpen={logout} onClose={() => setLogout(false)}>
      <div className="text-center text-custom-black">
        <h2 className="text-2xl font-bold my-4">Logout</h2>
      </div>
      <div className="w-20 h-20 bg-[#C3E6FF] p-2 rounded-full flex justify-center items-center">
        <div className="bg-custom-blue rounded-full w-16 h-16 flex justify-center items-center">
          <MdLogout size={32} className="text-custom-white" />
        </div>
      </div>
      <div className="text-center text-[#747474]">
        <h2 className="font-medium my-4">Are you sure you want to logout?</h2>
      </div>
      <div className="flex flex-col items-center">
        <CustomButton
          title="Yes"
          buttonType="button"
          onClick={clickHandler}
          classname="bg-gradient-custom text-custom-white px-10 py-2 rounded-full"
        />
      </div>
    </CustomModal>
  );
};

export default Logout;

import React, { useContext, useEffect, useRef, useState } from "react";
import LogoIcon from "../../assets/LogoIcon.png";
import { GoBell } from "react-icons/go";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logout from "./Logout";

import { CgProfile } from "react-icons/cg";
import { LuLogOut } from "react-icons/lu";

import CustomButton from "./CustomButton";
import CustomModal from "./CustomModal";

import useOnClickOutside from "../../helper/onClickOutside";
import { TbLogout } from "react-icons/tb";
import { apiConnector } from "../../networking/ApiConnector";
import { setLoading } from "../../redux/slice/loadingSlice";
import { CustomAlertContext } from "../../context";
import { attendanceEndPoints } from "../../networking/Endpoints";
import { Roles } from "../../constant/Constant";
import { useStopwatch } from "react-timer-hook";
import { dateFormatter } from "../../helper/formatDate";
import dayjs from "dayjs";
const baseUrl = process.env.REACT_APP_BASE_URL;

function MyStopwatch() {
  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    // days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: false });

  return (
    <div style={{ textAlign: "center" }}>
      <div style={{ fontSize: "25px" }}>
        <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
      </div>
      {/* <p>{isRunning ? "Running" : "Not running"}</p> */}
      <button onClick={start}>Start</button>
      <button onClick={pause}>Pause</button>
      {/* <button onClick={reset}>Reset</button> */}
    </div>
  );
}
const Header = () => {
  const dispatch = useDispatch();

  const { token, user } = useSelector((state) => state.auth);
  const [showAttendance, setShowAttendance] = useState(false);
  const [userAttendanceData, setUserAttendanceData] = useState();
  const navigate = useNavigate();
  const dropdownRef = useRef();
  const [openDropdown, setOpenDropDown] = useState(false);
  const [logout, setLogout] = useState(false);
  const [showClockInButton, setShowClockInButton] = useState(true);
  // const [cordinates, setCordinates] = useState(null);
  const { setToastNotification } = useContext(CustomAlertContext);

  const items = [
    {
      name: "Profile",
      id: 1,
      onClick: () => {
        navigate("/profile");
        setOpenDropDown(false);
      },
      icon: <CgProfile />,
    },
    {
      name: "Log Out",
      id: 2,
      onClick: () => {
        setOpenDropDown(false);
        setLogout((logout) => !logout);
      },
      icon: <LuLogOut />,
    },
  ];
  useOnClickOutside(dropdownRef, () => setOpenDropDown(false));

  const UserClockIn = async (cordinates = {}) => {
    dispatch(setLoading(true));
    try {
      const user_attendance_response = await apiConnector(
        "POST",
        attendanceEndPoints?.ATTENDANCE_API,
        // { clock_in_location: cordinates },
        {
          Authorization: `Bearer ${token}`,
        }
      );

      setUserAttendanceData(user_attendance_response?.data?.attendance?._id);

      setShowAttendance(false);
      setShowClockInButton(false);
    } catch (error) {
      setToastNotification(error?.message, "error");
      setShowAttendance(false);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const UserClockOut = async (id, cordinates = {}) => {
    dispatch(setLoading(true));
    try {
      const user_clockOut_response = await apiConnector(
        "POST",
        `${attendanceEndPoints?.ATTENDANCE_API}/${id}`,
        // { clock_out_location: cordinates },

        {
          Authorization: `Bearer ${token}`,
        }
      );
      setShowAttendance(false);
      setShowClockInButton(true);
      setShowClockInButton(true);
    } catch (error) {
      setToastNotification(error?.message, "error");
      setShowAttendance(false);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getAttendanceData = async () => {
    dispatch(setLoading(true));
    try {
      const attendance_response = await apiConnector(
        "GET",
        attendanceEndPoints?.ATTENDANCE_API,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setUserAttendanceData(attendance_response?.data?.attendance?._id);
      setShowClockInButton(
        attendance_response?.data?.attendance?.clock_out_time
          ? true
          : attendance_response?.data?.attendance
          ? false
          : true
      );
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    getAttendanceData();
  }, []);

  const callClockIn = () => {
    // navigator.geolocation.getCurrentPosition(
    //   function (location) {
    //     console.log(location.coords, "cordinates");
    //     setCordinates({
    //       latitude: location.coords.latitude,
    //       longitude: location.coords.longitude,
    //     });
    //   },
    //   () => {},
    //   { enableHighAccuracy: true }
    // );
    // if (cordinates)
    UserClockIn();
  };
  const callClockOut = () => {
    // navigator.geolocation.getCurrentPosition(function (location) {
    //   setCordinates(
    //     {
    //       latitude: location.coords.latitude,
    //       longitude: location.coords.longitude,
    //     },
    //     () => {},
    //     { enableHighAccuracy: true }
    //   );
    // });
    // if (cordinates)
    UserClockOut(userAttendanceData);
  };

  return (
    <>
      <div className="w-full h-16 p-4 flex justify-between items-center md:gap-5 bg-custom-white shadow-md shadow-[#00000014]">
        <div className="flex justify-center items-center gap-40 cursor-pointer">
          <div
            onClick={() => navigate("/dashboard")}
            className="flex justify-center items-center gap-4"
          >
            {/* <CustomButton
              title={<IoMenuOutline size={28} />}
              classname={"text-slate-400 text-lg font-semibold block md:hidden"}
            /> */}
            <div className="bg-custom-blue  rounded-full w-8 h-8 md:w-10 md:h-10 p-2 flex justify-center items-center">
              <img src={LogoIcon} alt="Logo" loading="lazy" />
            </div>
            <h1 className="text-2xl invisible md:visible text-custom-blue font-bold">
              PMS
            </h1>
          </div>
        </div>
        <div className="flex justify-center items-center gap-3 cursor-pointer mr-3">
          {/* {showClockInButton ? (
            <CustomButton
              title="Clock-In"
              classname="border px-5 py-1 rounded-md bg-custom-status-completed text-custom-white"
              onClick={() => {
                setShowAttendance(true);
              }}
            />
          ) : (
            <CustomButton
              title="Clock-Out"
              classname="border px-5 py-1 rounded-md bg-custom-status-cancelled text-custom-white"
              onClick={() => {
                setShowAttendance(true);
              }}
            />
          )} */}
          {user.roleId == Roles.super_admin || user.roleId == Roles.admin ? (
            ""
          ) : (
            <>
              {/* <MyStopwatch /> */}
              <CustomButton
                title={
                  <div
                    className={`flex justify-center items-center gap-2 font-semibold  text-sm p-1 rounded-md`}
                  >
                    <TbLogout size={18} />
                    <span>{showClockInButton ? "Clock In" : "Clock Out"}</span>
                  </div>
                }
                classname={`border rounded-md ${
                  showClockInButton
                    ? "bg-custom-status-completed text-custom-white"
                    : "bg-custom-status-cancelled text-custom-white"
                }`}
                onClick={() => {
                  setShowAttendance((prev) => !prev);
                }}
              />
            </>
          )}

          <div className="relative w-8 h-8  bg-[#E7F1F8] rounded-full md:flex justify-center items-center hidden">
            <div className="absolute top-1 right-2 w-1.5 h-1.5 rounded-full bg-pink-900" />
            <GoBell size={20} />
          </div>
          <div
            className="flex justify-center items-center md:gap-1 transition-all duration-300 ease-linear "
            onClick={() => setOpenDropDown((openDropdown) => !openDropdown)}
          >
            <div className="w-8 h-8">
              <img
                src={
                  user?.avatar
                    ? `${baseUrl}/${user?.avatar}`
                    : `https://api.dicebear.com/5.x/initials/svg?seed=${user?.user_name}`
                }
                alt={user?.user_name}
                loading="lazy"
                className="w-full h-full rounded-full"
              />
            </div>
            <div className="hidden md:block">
              {openDropdown ? <FaAngleUp /> : <FaAngleDown />}
            </div>
          </div>
        </div>
      </div>
      {openDropdown && (
        <div className="relative" ref={dropdownRef}>
          <div className="absolute right-8 z-50 -top-[18px] bg-custom-white w-3 h-3 rotate-45 border-t border-l" />
          <div className="absolute right-6 -top-3 p-4 rounded-md w-40 z-30 bg-custom-white flex flex-col justify-center items-start gap-3 border shadow-[2px_3px_22px_0px_#9BD3F81A]">
            {items?.map((item) => (
              <button
                onClick={item?.onClick}
                key={item?.id}
                className="w-full hover:bg-custom-blue hover:text-custom-white transition-all duration-300 text-sm font-semibold text-custom-blue  ease-linear p-1 rounded-md cursor-pointer flex items-center gap-4"
              >
                <div className="w-6 h-6 rounded-full bg-[#C8E6FC] text-custom-blue font-semibold p-1 flex justify-center items-center">
                  {item?.icon}
                </div>
                <span>{item?.name}</span>
              </button>
            ))}
          </div>
        </div>
      )}
      {logout && <Logout logout={logout} setLogout={setLogout} />}
      <CustomModal
        isOpen={showAttendance}
        onClose={() => {
          setShowAttendance(false);
        }}
      >
        <div className="text-custom-black">
          <h2 className="text-lg font-bold my-4">
            {showClockInButton
              ? "Please hit the Clock-In Button for Clock-In"
              : "Please hit the Clock-Out Button for Clock-Out"}
          </h2>
        </div>
        <div className="flex justify-end items-center gap-5 mt-5">
          <CustomButton
            title={showClockInButton ? "Clock IN" : "Clock Out"}
            onClick={() => {
              showClockInButton ? callClockIn() : callClockOut();
            }}
            buttonType={"button"}
            classname={`${
              showClockInButton
                ? "bg-custom-status-completed"
                : "bg-custom-status-cancelled"
            } px-2 py-1 text-custom-white  font-semibold text-sm  border rounded-md `}
          />
          <CustomButton
            title="No"
            onClick={() => setShowAttendance(false)}
            buttonType={"button"}
            classname={
              " text-custom-white font-semibold text-sm  px-5 py-1 rounded-md bg-slate-500"
            }
          />
        </div>
      </CustomModal>
    </>
  );
};

export default Header;

import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  CustomAssignees,
  CustomButton,
  CustomFileUpload,
  CustomInput,
  CustomModal,
  CustomSelect,
  RenderFileIcon,
} from "../common";
import { useDispatch, useSelector } from "react-redux";
// import { MdKeyboardArrowDown } from "react-icons/md";
import { CustomAlertContext } from "../../context";
import { setLoading } from "../../redux/slice/loadingSlice";
import { apiConnector } from "../../networking/ApiConnector";
import {
  documentEndPoints,
  // employeeEndPoints,
} from "../../networking/Endpoints";
import { CiEdit } from "react-icons/ci";
import ProjectNotes from "./ProjectNotes";
import { dateFormatter } from "../../helper/formatDate";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const AddDocumentValidation = yup.object().shape({
  document_name: yup.string().required("Document Name is required"),
  members: yup
    .array()
    .of(yup.string().required("Each member must be a valid string"))
    .min(1, "You must select at least one member")
    .required("Add Members"),
  document: yup.mixed().required("Document is required"),
});
const baseUrl = process.env.REACT_APP_BASE_URL;

const ProjectDocumentation = ({ project, members, fetchProjectDetails }) => {
  const dispatch = useDispatch();
  const { token, user } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.loading);
  const [showDocumentFormModal, setShowDocumentFormModal] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const { setToastNotification } = useContext(CustomAlertContext);
  const [isEditMode, setIsEditMode] = useState(false);
  const [documentId, setDocumentId] = useState("");

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(AddDocumentValidation),
  });

  const employeeOptions = members?.map((item) => ({
    name: item?.user_name,
    value: item?._id,
    designation: item?.designation?.designation,
    avatar: item?.avatar
      ? `${baseUrl}/${item?.avatar}`
      : `https://api.dicebear.com/5.x/initials/svg?seed=${item?.user_name}`,
  }));

  useEffect(() => {
    if (project) {
      fetchProjectDocuments();
    }
  }, [project]);

  const addDocumentation = async (data) => {
    dispatch(setLoading(true));
    try {
      const employee_response = await apiConnector(
        "POST",
        `${documentEndPoints?.DOCUMENT_API}/${project?._id}`,
        data,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      // toast.success(employee_response?.data?.message);
      reset({ document_name: "", members: [], document: null });
      setToastNotification(employee_response?.data?.message, "success");
      fetchProjectDocuments();
      setShowDocumentFormModal(false);
    } catch (error) {
      // setToastNotification(error?.message, "error");
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };
  const updateDocumentation = async (data) => {
    dispatch(setLoading(true));
    try {
      const employee_response = await apiConnector(
        "PUT",
        `${documentEndPoints?.DOCUMENT_API}/${documentId}`,
        data,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      // toast.success(employee_response?.data?.message);
      reset({ document_name: "", members: [], document: null });
      setToastNotification(employee_response?.data?.message, "success");

      setShowDocumentFormModal(false);
      fetchProjectDocuments();
      setIsEditMode(false);
    } catch (error) {
      // setToastNotification(error?.message, "error");
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };
  const onSubmitHandler = async (data) => {
    const formData = new FormData();

    if (!isEditMode) {
      formData.append("document", data?.document);
    }
    formData.append("document_name", data?.document_name);
    formData.append("members", data?.members);
    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    if (!isEditMode) {
      addDocumentation(formData);
    } else {
      updateDocumentation(formData);
    }
    // dispatch(setLoading(true));
    // try {
    //   const employee_response = await apiConnector(
    //     "POST",
    //     `${documentEndPoints?.DOCUMENT_API}/${project}`,
    //     formData,
    //     {
    //       Authorization: `Bearer ${token}`,
    //     }
    //   );
    //   // toast.success(employee_response?.data?.message);
    //   reset({ document_name: "", members: [], document: null });
    //   setToastNotification(employee_response?.data?.message, "success");

    //   setShowDocumentFormModal(false);
    // } catch (error) {
    //   // setToastNotification(error?.message, "error");
    //   setToastNotification(error?.message, "error");
    // } finally {
    //   dispatch(setLoading(false));
    // }
    // for (let [key, value] of formData.entries()) {
    //   console.log(key, value); // File objects will be shown as [object File]
    // }
  };

  const fetchProjectDocuments = async () => {
    dispatch(setLoading(true));
    try {
      const document_response = await apiConnector(
        "GET",
        `${documentEndPoints?.DOCUMENT_API}/${project?._id}`,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setDocuments(document_response?.data?.documents);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };
  const handleEditClick = (e, item) => {
    e.stopPropagation();
    setSelectedDocument(item);
    setDocumentId(item?._id);
    setValue("document_name", item ? item?.file_name : "");
    setValue(
      "members",
      item ? item?.file_access?.map((item) => item?._id) : []
    );
    setValue("document", item ? item?.file_URL : "");
    setShowDocumentFormModal(true);
    setIsEditMode(true);
  };
  return (
    <div className="w-full h-full flex flex-col lg:flex-row gap-5 p-4">
      <div className="w-full lg:w-2/3 h-full">
        <section className="flex sm:flex-row flex-col justify-between items-center p-4 py-2 rounded-md">
          <h1 className="font-semibold">Document List</h1>
          <div className="flex flex-col md:flex-row justify-center items-center gap-4">
            <CustomButton
              title={"Upload Docs"}
              buttonType="submit"
              classname="bg-gradient-custom text-custom-white font-semibold text-sm px-5 py-2 rounded-md"
              onClick={() => setShowDocumentFormModal(true)}
            />
          </div>
        </section>
        <section className="w-full h-[50%] overflow-auto grid grid-cols-1 xl:grid-cols-2 gap-5 p-2">
          {documents?.length
            ? documents?.map((item) => (
                <div
                  key={item.id}
                  className="p-3 cursor-pointer border border-[#E3F3FF] hover:bg-gray-100 rounded-md space-y-2 shadow-[2px_3px_10px_6px_#257CBB0F]"
                >
                  <div className="flex px-2 justify-between items-center">
                    <div>
                      <h2 className="text-sm font-semibold ">Document Name </h2>
                      <span>{item?.file_name ? item?.file_name : "--"}</span>
                    </div>
                    <div
                      className="w-7 h-7 rounded-full border text-[#626262] font-semibold flex justify-center items-center"
                      onClick={(e) => handleEditClick(e, item)}
                    >
                      <CiEdit />
                    </div>
                  </div>
                  <a
                    href={baseUrl + "/" + item?.file_URL}
                    target="_blank"
                    className="flex items-center"
                  >
                    <span>{RenderFileIcon(item?.file_URL?.split(".")[1])}</span>
                    <span>{item?.file_URL?.split("_")[1]}</span>
                  </a>
                  <div className="px-2">
                    <h2 className="text-sm font-semibold ">Files Access</h2>
                    <CustomAssignees assignees={item?.file_access} />
                  </div>
                  <div className="flex justify-between items-center px-2">
                    <div className="flex flex-col space-y-2">
                      <h2 className="text-sm font-semibold">Uploaded By </h2>
                      <div className="flex items-center gap-2">
                        <img
                          src={
                            item?.file_uploaded_by?.avatar
                              ? `${BASE_URL}/${item?.file_uploaded_by?.avatar}`
                              : `https://api.dicebear.com/5.x/initials/svg?seed=${item?.file_uploaded_by?.user_name}`
                          }
                          alt={item?.file_uploaded_by?.user_name?.[0]}
                          className="w-7 h-7 rounded-full bg-slate-300 p-0.5"
                        />
                        <span className="text-xs">
                          {item?.file_uploaded_by?._id === user?._id
                            ? "You"
                            : item?.file_uploaded_by?.user_name}
                        </span>
                      </div>
                    </div>
                    <div>
                      <h2 className="text-sm font-semibold ">Uploaded At</h2>
                      <span className="text-xs font-semibold">
                        {dateFormatter(item?.createdAt, {
                          format: "MMM DD,YYYY HH:MM:A",
                          includeTimeForToday: true,
                        })}
                      </span>
                    </div>
                    <div>
                      <h2 className="text-sm font-semibold">Last Updated At</h2>
                      <span className="text-xs font-semibold">
                        {dateFormatter(item?.updatedAt, {
                          format: "MMM DD,YYYY HH:MM:A",
                          includeTimeForToday: true,
                        })}
                      </span>
                    </div>
                  </div>
                </div>
              ))
            : "NO Docs Available"}
          {/* <h2>Project Documentation</h2> */}
        </section>
        <CustomModal
          isOpen={showDocumentFormModal}
          onClose={() => setShowDocumentFormModal(false)}
          classname="w-full md:w-[60%]"
        >
          <div className="w-full bg-custom-white rounded-md">
            <form
              className="w-full p-6 "
              noValidate
              onSubmit={handleSubmit(onSubmitHandler)}
            >
              <div className="text-xl font-semibold text-custom-black">
                {isEditMode ? "Edit Document" : "Add Document"}
              </div>
              <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 sm:gap-5">
                <div className="my-2 sm:my-4">
                  <CustomInput
                    name="document_name"
                    label="Document Name"
                    inputType="text"
                    placeholder=""
                    control={control}
                    required={true}
                    error={errors?.document_name?.message}
                  />
                </div>
                <div className="my-2 sm:my-4">
                  <CustomSelect
                    name="members"
                    label="Add Members"
                    inputType="text"
                    placeholder="Select..."
                    control={control}
                    required={true}
                    multiple="true"
                    options={employeeOptions ?? []}
                    error={errors?.members?.message}
                  />
                </div>
                {!isEditMode ? (
                  <div className="my-2 sm:my-4">
                    <CustomFileUpload
                      name="document"
                      label="Document"
                      pdf={true}
                      // document={true}
                      defaultValue={selectedDocument?.file_URL}
                      setValue={setValue}
                      control={control}
                      required={true}
                      error={errors?.document?.message}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="flex justify-end items-center my-10 gap-4">
                <CustomButton
                  title={"Cancel"}
                  buttonType="button"
                  onClick={() => {
                    reset();
                    setShowDocumentFormModal(false);
                    setIsEditMode(false);
                  }}
                  classname={`border font-semibold text-custom-black px-5 py-1 rounded-md 
            `}
                />
                <CustomButton
                  title={
                    loading ? (
                      <svg
                        aria-hidden="true"
                        className="w-4 h-4 me-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.591C100 78.205 77.614 100.591 50 100.591S0 78.205 0 50.591 22.386 0.591 50 0.591 100 22.977 100 50.591ZM9.081 50.591C9.081 73.189 27.401 91.509 50 91.509S90.919 73.189 90.919 50.591 72.599 9.672 50 9.672 9.081 27.992 9.081 50.591Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.968 39.041c2.426-.637 3.896-3.129 3.041-5.486-1.715-4.731-4.137-9.185-7.191-13.206C85.845 15.12 80.883 10.724 75.212 7.413 69.542 4.102 63.275 1.94 56.77 1.051c-5.003-.684-10.072-.604-15.035.228-2.473.414-3.921 2.919-3.284 5.344.637 2.426 3.119 3.849 5.6 3.484 3.801-.559 7.669-.581 11.49-.103 5.324.727 10.453 2.497 15.093 5.206 4.641 2.71 8.702 6.307 11.952 10.586 2.333 3.071 4.215 6.45 5.596 10.035.902 2.34 3.361 3.802 5.787 3.165Z"
                          fill="currentFill"
                        />
                      </svg>
                    ) : isEditMode ? (
                      "Edit Document"
                    ) : (
                      "Add Document"
                    )
                  }
                  buttonType="submit"
                  disabled={loading}
                  classname={`bg-custom-blue font-semibold text-custom-white px-5 py-1 rounded-md ${
                    loading ? "cursor-not-allowed" : "cursor-pointer"
                  }`}
                />
              </div>
            </form>
          </div>
        </CustomModal>
      </div>
      <div className="w-full lg:w-1/3 h-full bg-[#FEF8DC] rounded-lg flex-shrink-0">
        <ProjectNotes
          project={project}
          getProjectDetails={fetchProjectDetails}
        />
      </div>
    </div>
  );
};

export default ProjectDocumentation;

import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const CardSkeleton = ({ height }) => {
  return (
    <div
      className={`w-full ${height}  bg-slate-100 rounded-md p-4 flex items-center gap-2`}
    >
      <div className="w-14 h-14">
        <Skeleton circle={true} height="100%" />
      </div>
      <div className="flex flex-col justify-center w-full">
        <Skeleton width="60%" height={15} />
        <Skeleton width="40%" height={15} style={{ marginTop: "0.5rem" }} />
      </div>
    </div>
  );
};

export default CardSkeleton;

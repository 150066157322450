import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomAlertContext } from "../../../context";
import { Calendar, dayjsLocalizer } from "react-big-calendar";
import dayjs from "dayjs";
import { apiConnector } from "../../../networking/ApiConnector";
import { meetingEndPoints } from "../../../networking/Endpoints";

const EmployeeCalendar = ({ employeeDetails = {} }) => {
  const { token, user } = useSelector((state) => state.auth),
    { loading } = useSelector((state) => state.loading),
    dispatch = useDispatch(),
    { setToastNotification } = useContext(CustomAlertContext),
    [meetingsData, setMeetingsData] = useState([]),
    [attendanceData, setAttendanceData] = useState([]),
    [calendarLoading, setCalendarLoading] = useState(false),
    localizer = dayjsLocalizer(dayjs);
  const [currentMonth, setCurrentMonth] = useState(dayjs().format("MM"));
  const [currentYear, setCurrentYear] = useState(dayjs().format("YYYY"));
  const [currentDate, setCurrentDate] = useState("");
  const [selectedView, setSelectedView] = useState("month");
  const [date, setDate] = useState(new Date());
  const [view, setView] = useState("month");

  const onNavigate = (date) => {
    const current_month = dayjs(date).format("MM");
    const current_year = dayjs(date).format("YYYY");
    setDate(date);
    setCurrentMonth(current_month);
    setCurrentYear(current_year);
  };
  const onView = useCallback((newView) => setView(newView), [setView]);
  const GetAllMeetings = async () => {
    setCalendarLoading(true);
    try {
      const meeting_response = await apiConnector(
        "GET",
        meetingEndPoints?.MEETING_API,
        null,
        {
          Authorization: `Bearer ${token}`,
        },
        {
          month: currentMonth,
          year: currentYear,
          userId: employeeDetails?._id,
        }
      );
      setMeetingsData(meeting_response?.data?.meetings);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      setCalendarLoading(false);
    }
  };
  useEffect(() => {
    if (employeeDetails?._id) GetAllMeetings();
  }, [token, currentMonth, currentYear, employeeDetails]);

  const calenderEvent = meetingsData?.map((item) => {
    const meeting_date_time = dayjs(item?.date_time);

    // Split meeting_duration into hours and minutes
    const [durationHours, durationMinutes] = item?.meeting_duration
      ?.split(":")
      .map(Number) || [0, 0];

    return {
      title: item?.title || "Meeting",
      start: new Date(
        meeting_date_time.year(),
        meeting_date_time.month(),
        meeting_date_time.date(),
        meeting_date_time.hour(),
        meeting_date_time.minute(),
        meeting_date_time.second()
      ),
      end: new Date(
        meeting_date_time.year(),
        meeting_date_time.month(),
        meeting_date_time.date(),
        meeting_date_time.hour() + durationHours,
        meeting_date_time.minute() + durationMinutes,
        meeting_date_time.second()
      ),
      meetingEvent: item,
      meeting: true,
    };
  });
  return (
    <div className="w-full ">
      <div className="text-lg font-semibold">Calendar</div>
      <div className="myCustomHeight">
        <Calendar
          localizer={localizer}
          events={calenderEvent ?? []}
          style={{ height: "75vh" }}
          startAccessor="start"
          endAccessor="end"
          onSelectEvent={(event) => {
            if (event?.meeting) {
              //   setMeetingDetails(event?.meetingEvent);
              //   setShowMeetingView((prev) => !prev);
            }
            // else if (event?.reminder) {
            //   setReminderDetails(event?.reminderEvent);
            //   setReminderView((prev) => !prev);
            // }
          }}
          //   onSelectSlot={handleSelect}
          onNavigate={onNavigate}
          onView={onView}
          view={view}
          dayLayoutAlgorithm={"no-overlap"}
          eventPropGetter={(event) => {
            const customStyle = {
              backgroundColor: event.meetingEvent
                ? event.meetingEvent.participants?.some(
                    (item) =>
                      item?.user?._id === user?._id &&
                      item?.is_invitation_accepted === true
                  )
                  ? "#28a745"
                  : event.meetingEvent.participants?.some(
                      (item) =>
                        item?.user?._id === user?._id &&
                        item?.is_invitation_accepted === false
                    )
                  ? "#FF0613"
                  : "#2780C1"
                : event.reminderEvent
                ? "#FA892F"
                : "#39C1F9",
              color: "#fff",
              borderRadius: "5px",
              border: "none",
              fontSize: "14px",
              fontWeight: "500",
              padding: "5px",
            };
            return { style: customStyle };
          }}
          // components={{
          //   event: ({ event }) => (
          //     <div>
          //       <strong className="text-sm font-semibold">{event.title}</strong>
          //       <div className="text-xs font-semibold">
          //         Date & Time -{" "}
          //         {dateFormatter(event.meetingEvent?.date_time, {
          //           format: "MMM DD,YYYY HH:MM:A",
          //           includeTimeForToday: true,
          //         })}
          //       </div>
          //       <div className="text-xs font-semibold">
          //         Duration - {event.meetingEvent?.meeting_duration}{" "}
          //         {event.meetingEvent?.meeting_duration?.split(":")[0] >= 1
          //           ? "Hrs"
          //           : "Min"}
          //       </div>
          //     </div>
          //   ),
          // }}
        />
      </div>
    </div>
  );
};

export default EmployeeCalendar;

import React from "react";
import { CustomButton, TableHeader } from "../common";
import { IoMdSearch } from "react-icons/io";
import { FaPlusCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const TableHeads = [
  "Leave ID",
  "Name",
  "Start Date",
  "End Date",
  "Status",
  "Status",
];

const Leave = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full bg-custom-white rounded-md">
      <section className="flex sm:flex-row flex-col justify-between items-center p-4 py-2 rounded-md">
        <h1 className="font-semibold">Leave List</h1>
        <div className="flex flex-col md:flex-row justify-center items-center gap-4">
          <div className="relative ">
            <IoMdSearch className="absolute z-10 top-3 left-1 text-slate-400" />
            <input
              name="searchString"
              label=""
              type="text"
              placeholder="Search"
              //   onChange={(e) => filterTask(e)}
              //   value={searchString}
              className="bg-custom-white px-6 py-2 font-semibold text-sm border outline-none rounded-md"
            />
          </div>
          <CustomButton
            title={
              <div className="flex justify-center items-center gap-2 ">
                <FaPlusCircle />
                <span>Add Leave</span>
              </div>
            }
            buttonType="button"
            onClick={() =>
              navigate("/leave_form", {
                // state: { project: defaultProject },
              })
            }
            classname="bg-gradient-custom text-custom-white font-semibold text-sm px-5 py-2 rounded-md"
          />
        </div>
      </section>
      <section className="w-full h-full overflow-auto">
        <table className="w-full border rounded-lg overflow-auto">
          <thead>
            <TableHeader TableHeads={TableHeads} />
          </thead>
          <tbody>asdf</tbody>
        </table>
      </section>
    </div>
  );
};

export default Leave;

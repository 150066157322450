import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiConnector } from "../../networking/ApiConnector";
import { externalInviteEndPoints } from "../../networking/Endpoints";

// Define an async thunk
export const fethAllExternalInvites = createAsyncThunk(
  "fethAllExternalInvites",
  async ({
    token,
    currentPage,
    itemsPerPage,
    level_of_seniority,
    region_of_interest,
    company_industry,
    content_track_interest,
    match_making_reason,
  }) => {
    const queryParams = {};
    if (level_of_seniority) queryParams.level_of_seniority = level_of_seniority;
    if (region_of_interest) queryParams.region_of_interest = region_of_interest;
    if (company_industry) queryParams.company_industry = company_industry;
    if (match_making_reason)
      queryParams.match_making_reason = match_making_reason;
    if (content_track_interest)
      queryParams.content_track_interest = content_track_interest;
    if (currentPage && itemsPerPage) {
      queryParams.page_number = currentPage;
      queryParams.page_size = itemsPerPage;
    }
    const response = await apiConnector(
      "GET",
      externalInviteEndPoints.EXTERNAL_INVITE_API,
      null,
      {
        Authorization: `Bearer ${token}`,
      },
      queryParams
    );
    return response?.data;
  }
);

// Create a slice
const externalInviteSlice = createSlice({
  name: "externalInvite",
  initialState: {
    externalInviteData: null,
    externalInviteLoading: false,
    externalInviteError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fethAllExternalInvites.pending, (state) => {
        state.externalInviteLoading = true;
        state.externalInviteData = null;
        state.externalInviteError = null;
      })
      .addCase(fethAllExternalInvites.fulfilled, (state, action) => {
        state.externalInviteData = action.payload;
        state.externalInviteLoading = false;
        state.externalInviteError = null;
      })
      .addCase(fethAllExternalInvites.rejected, (state, action) => {
        state.externalInviteError = action.error.message;
        state.externalInviteLoading = false;
        state.externalInviteData = null;
      });
  },
});
export default externalInviteSlice.reducer;

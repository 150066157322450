import React, { useEffect, useState } from "react";
import Select from "react-select";
import { BsInfoCircle } from "react-icons/bs";
import CommonTooltip from "./CommonTooltip";
import { tooltip } from "../../constant/TooltipContent";

const FilterSelect = ({
  name = "",
  label = "",
  placeholder = "Select...",
  labelClassName = "text-custom-black",
  classname = "",
  multiple = false,
  searchable = true,
  options,
  isEditable = false,
  defaultValue = "",
  icon = true,
  onChange = () => {},
}) => {
  // Function to format option label
  const [selectedValue, setSelectedValue] = useState(defaultValue || "");

  useEffect(() => {
    setSelectedValue(defaultValue);
  }, [defaultValue]);

  const formatOptionLabel = (option) => (
    <div className="flex justify-start items-center gap-2">
      {option?.avatar && (
        <img
          src={option?.avatar}
          alt={option?.name?.[0]}
          className="w-6 h-6 rounded-full p-0.5 bg-slate-300"
        />
      )}
      <div className="flex items-center gap-1 text-sm">
        <span>{option?.name}</span>
        {option?.designation && (
          <span className="text-gray-400">({option?.designation})</span>
        )}
      </div>
    </div>
  );

  const tooltip_content = tooltip[name];
  return (
    <>
      {icon ? (
        <label
          htmlFor={name}
          className={`flex items-center gap-2 font-medium text-sm text-slate-600 ${labelClassName}`}
        >
          <span>{label}</span>
          <CommonTooltip tooltip={tooltip_content}>
            <BsInfoCircle />
          </CommonTooltip>
        </label>
      ) : (
        ""
      )}

      <Select
        options={options}
        formatOptionLabel={formatOptionLabel}
        getOptionLabel={(option) => option?.name}
        placeholder={placeholder}
        isSearchable={searchable}
        isDisabled={isEditable}
        defaultValue={defaultValue}
        value={options?.find((option) => option?.value === selectedValue)}
        onChange={(selected) => {
          setSelectedValue(selected?.value);
          onChange(selected);
        }}
        isMulti={multiple}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            text: "#000000",
            primary25: "#E3EFF8",
            primary: "#2780C1",
            neutral80: "#000000",
          },
        })}
        className={`${classname} text-sm mt-2 rounded-md ${
          isEditable ? "bg-gray-100 text-custom-black" : ""
        }`}
        styles={{
          control: (provided, state) => ({
            ...provided,
            borderColor: state.isFocused
              ? "#2780C1"
              : state.isHovered
              ? "#2780C1"
              : "#E5E7EB",
            boxShadow: state.isFocused ? "none" : "none",
            borderRadius: "0.375rem",
            "&:hover": {
              borderColor: "#E5E7EB",
            },
            background: isEditable ? "#F3F4F6" : "white",
            color: "#FFFFFF",
          }),
          singleValue: (provided) => ({
            ...provided,
            color: isEditable ? "#000000" : "#000000",
          }),
          multiValue: (provided) => ({
            ...provided,
            color: isEditable ? "#000000" : "#000000",
          }),
        }}
      />
    </>
  );
};

export default FilterSelect;

import React from "react";
import { CommonTooltip, CustomStatusButton, TableHeader } from "../../common";
import { FaRegQuestionCircle } from "react-icons/fa";
import {
  dateFormatter,
  getDateDifferenceStatus,
} from "../../../helper/formatDate";
import { setActiveTabInProject } from "../../../redux/slice/projectSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
const TableHeadsForProjects = [
  "ID",
  "Project Name",
  "Start Date",
  "End Date",
  "Status",
];
const DashboardProjectTable = ({ projects }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="w-full space-y-3">
      <table className="w-full border rounded-lg bg-custom-white overflow-auto">
        <thead>
          <TableHeader TableHeads={TableHeadsForProjects} />
        </thead>

        <tbody>
          {projects?.length
            ? projects?.map((item, index) => {
                return (
                  <tr
                    key={item?._id}
                    className="w-full border-b hover:bg-slate-100"
                  >
                    <td className="px-2 text-sm">{item?.project_id}</td>
                    <td className="px-2 text-sm ellipsis">
                      <div className="flex flex-col justify-start cursor-pointer text-sm">
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            dispatch(setActiveTabInProject("overview"));
                            navigate("/project/view", {
                              state: { id: item?._id },
                            });
                          }}
                          className="text-custom-black font-medium flex items-center gap-2"
                        >
                          <h2 className="ellipsis">{item?.project_name}</h2>
                        </span>
                      </div>
                    </td>
                    <td className="px-2 text-sm">
                      {item?.project_start_date
                        ? dateFormatter(item?.project_start_date, {
                            format: "MMM DD, YYYY",
                            includeTimeForToday: false,
                          })
                        : "--"}
                    </td>
                    <td className="px-2 text-sm">
                      {item?.project_deadline ? (
                        <div className="flex items-center gap-2">
                          <span
                            className={`${
                              new Date() >
                                new Date(
                                  item?.status !== "Completed"
                                    ? item?.project_deadline
                                    : item?.completed_on
                                ) && item?.status !== "Completed"
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {dateFormatter(item?.project_deadline, {
                              format: "MMM DD, YYYY",
                              includeTimeForToday: false,
                            })}
                          </span>
                          <CommonTooltip
                            tooltip={getDateDifferenceStatus(
                              item?.project_deadline,
                              item?.status === "Completed"
                                ? item?.completed_on
                                : null
                              // false
                            )}
                            tooltipClass={
                              "bg-custom-white dark:bg-custom-white text-lg"
                            }
                          >
                            <FaRegQuestionCircle className="text-slate-400" />
                          </CommonTooltip>
                        </div>
                      ) : (
                        "--"
                      )}
                    </td>

                    <td className={`text-sm cursor-pointer`}>
                      <div
                        className={`w-fit relative p-2 cursor-pointer rounded-lg flex justify-between items-center  gap-2  `}
                      >
                        <CustomStatusButton
                          selectedStatus={item?.projectStatus}
                          isDropdown={false}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })
            : "NO Projects Available"}
        </tbody>
      </table>
    </div>
  );
};

export default DashboardProjectTable;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  employeeFilter: null,
  projectFilter: null,
  taskFilter: null,
  subTaskFilter: null,
  departmentFilter: null,
  designationFilter: null,
  officeLocationFilter: null,
  externalUserFilter: null,
};

const filterSlice = createSlice({
  name: "filter",
  initialState: initialState,
  reducers: {
    setEmployeeFilter(state, action) {
      if (action.payload === null) {
        state.employeeFilter = null;
      } else {
        state.employeeFilter = { ...state.employeeFilter, ...action.payload };
      }
    },
    setProjectFilter(state, action) {
      if (action.payload === null) {
        state.projectFilter = null;
      } else {
        state.projectFilter = { ...state.projectFilter, ...action.payload };
      }
    },
    setTaskFilter(state, action) {
      if (action.payload === null) {
        state.taskFilter = null;
      } else {
        state.taskFilter = { ...state.taskFilter, ...action.payload };
      }
    },
    setSubTaskFilter(state, action) {
      if (action.payload === null) {
        state.subTaskFilter = null;
      } else {
        state.subTaskFilter = { ...state.subTaskFilter, ...action.payload };
      }
    },
    setDepartmentFilter(state, action) {
      if (action.payload === null) {
        state.departmentFilter = null;
      } else {
        state.departmentFilter = {
          ...state.departmentFilter,
          ...action.payload,
        };
      }
    },
    setDesignationFilter(state, action) {
      if (action.payload === null) {
        state.designationFilter = null;
      } else {
        state.designationFilter = {
          ...state.designationFilter,
          ...action.payload,
        };
      }
    },
    setOfficeLocationFilter(state, action) {
      if (action.payload === null) {
        state.officeLocationFilter = null;
      } else {
        state.officeLocationFilter = {
          ...state.officeLocationFilter,
          ...action.payload,
        };
      }
    },
    setExternalUserFilter(state, action) {
      if (action.payload === null) {
        state.externalUserFilter = null;
      } else {
        state.externalUserFilter = {
          ...state.externalUserFilter,
          ...action.payload,
        };
      }
    },
  },
});

export const {
  setDepartmentFilter,
  setDesignationFilter,
  setEmployeeFilter,
  setOfficeLocationFilter,
  setProjectFilter,
  setSubTaskFilter,
  setTaskFilter,
  setExternalUserFilter,
} = filterSlice.actions;
export default filterSlice.reducer;

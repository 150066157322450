import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomAlertContext } from "../../context";
import { setLoading } from "../../redux/slice/loadingSlice";
import { apiConnector } from "../../networking/ApiConnector";
import { meetingEndPoints } from "../../networking/Endpoints";
import {
  CustomAssignees,
  CustomButton,
  CustomInput,
  CustomModal,
  NoDataFound,
  Pagination,
  PaginationText,
  TableHeader,
} from "../common";
import * as yup from "yup";
import { MdOutlineEdit, MdOutlineFilterAlt } from "react-icons/md";
import { FaPlusCircle } from "react-icons/fa";
import {
  dateFormatter,
  SortDataByDateInDescending,
} from "../../helper/formatDate";
import Skeleton from "react-loading-skeleton";
import MeetingForm from "../Calendar/MeetingForm";
import MeetingView from "../Calendar/MeetingView";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import RemarkMeeting from "./RemarkMeeting";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const TableHeads = [
  "S.No",
  "Title",
  "Meeting Category",
  "Date & Time",
  "Durations",
  "Meeting Organizer",
  "Participants",
  "External Invites",
  "Actions",
];
const RejectMeetingValidation = yup.object().shape({
  reason: yup.string().required("Reason is required").trim(),
});
const MeetingList = () => {
  const { token, user } = useSelector((state) => state.auth),
    { loading } = useSelector((state) => state.loading),
    dispatch = useDispatch(),
    meetingFormRef = useRef(),
    meetingViewRef = useRef(),
    [showRemarkModal, setShowRemarkModal] = useState(false),
    [showRejectModal, setShowRejectModal] = useState(false),
    [showMeetingForm, setShowMeetingForm] = useState(false),
    [showMeetingView, setShowMeetingView] = useState(false),
    [meetingDetails, setMeetingDetails] = useState(null),
    { setToastNotification } = useContext(CustomAlertContext),
    [meetingsData, setMeetingsData] = useState([]),
    [items, setItems] = useState([]),
    [isLoading, setIsLoading] = useState([]),
    [meetingCount, setMeetingCount] = useState(10);

  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(RejectMeetingValidation),
  });

  const GetAllMeetings = async () => {
    dispatch(setLoading(true));
    setIsLoading(true);
    try {
      const meeting_response = await apiConnector(
        "GET",
        meetingEndPoints?.MEETING_API,
        null,
        {
          Authorization: `Bearer ${token}`,
        },
        {
          page_number: currentPage,
          page_size: itemsPerPage,
        }
      );
      setMeetingsData(meeting_response?.data?.meetings);
      setMeetingCount(meeting_response?.data?.meeting_count);
      setItems((prevItems) => [
        ...prevItems,
        ...meeting_response?.data?.meetings,
      ]);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    GetAllMeetings();
  }, [token, currentPage, itemsPerPage]);
  const pageCount = Math.ceil(meetingCount / itemsPerPage);
  const handlePageClick = (event) => {
    setCurrentPage(event?.selected + 1);
  };
  const MeetingStatusHandler = async (payload) => {
    dispatch(setLoading(true));
    try {
      const meeting_response = await apiConnector(
        "PUT",
        `${meetingEndPoints?.MEETING_STATUS_UPDATE_API}/${meetingDetails?._id}`,
        payload,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setToastNotification(meeting_response?.data?.message, "success");
      GetAllMeetings();
      if (meetingDetails) {
        setShowMeetingView(false);
      }
      setShowRejectModal(false);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };
  const meetingRejectHandler = async (data) => {
    let payload = {
      invitation: "rejected",
      reason: data?.reason,
    };
    MeetingStatusHandler(payload);
  };
  // sort meeting data in descending order
  const sortedMeetingsData = SortDataByDateInDescending(
    meetingsData,
    "date_time"
  );
  return (
    <div className="w-full ">
      <section className="flex md:flex-row flex-col gap-2 justify-between bg-custom-white items-center p-2 rounded-t-md">
        <h1 className="font-semibold text-lg">Meeting List</h1>
        <div className="flex md:flex-row flex-col justify-center items-center gap-4">
          {/* <div className="relative">
          <IoMdSearch className="absolute z-10 top-3 left-1 text-slate-400" />
          <input
            name="searchString"
            type="text"
            placeholder="Search"
              onChange={(e) => filterCategory(e)}
            value={searchString}
            className="border px-6 py-2 font-semibold text-sm outline-none rounded-md"
          />
        </div> */}
          {/* <CustomButton
            title={
              <div className="flex justify-center items-center font-semibold">
                <MdOutlineFilterAlt size={20} />
                <span>Filters</span>
              </div>
            }
            buttonType="submit"
            classname="bg-custom-white text-slate-400 p-2 font-semibold text-sm rounded-md border "
          /> */}
          <CustomButton
            title={
              <div className="flex justify-center items-center gap-2 ">
                <FaPlusCircle />
                <span>Request Meeting</span>
              </div>
            }
            buttonType="button"
            onClick={() => {
              setMeetingDetails(null);
              setShowMeetingForm((prev) => !prev);
            }}
            classname="bg-gradient-custom text-custom-white font-semibold text-sm px-5 py-2 rounded-md"
          />
        </div>
      </section>
      <section className="w-full overflow-auto">
        <table className="w-full border bg-custom-white rounded-lg overflow-auto">
          <thead>
            <TableHeader TableHeads={TableHeads} />
          </thead>
          {loading ? (
            <tbody>
              <tr>
                <td
                  colSpan={TableHeads?.length}
                  className="text-center font-semibold text-sm p-3"
                >
                  <Skeleton
                    count={5}
                    width={"100%"}
                    height={"22px"}
                    className="my-2"
                  />
                </td>
              </tr>
            </tbody>
          ) : sortedMeetingsData && sortedMeetingsData?.length > 0 ? (
            <tbody>
              {sortedMeetingsData?.map((item, index) => {
                return (
                  <tr
                    key={item?._id}
                    className="w-full border-b hover:bg-slate-100"
                  >
                    <td className="p-2 text-sm font-medium">
                      {(currentPage - 1) * itemsPerPage + index + 1}
                    </td>
                    <td
                      className="p-2 text-sm cursor-pointer underline"
                      onClick={() => {
                        setMeetingDetails(item);
                        setShowMeetingView((prev) => !prev);
                      }}
                    >
                      <div className="ellipsis">{item?.title ?? "--"}</div>
                    </td>
                    <td className="p-2 text-sm">
                      <div className="ellipsis">
                        {item?.meeting_category?.category ?? "--"}
                      </div>
                    </td>
                    <td className="p-2 text-sm">
                      {item?.date_time
                        ? dateFormatter(item?.date_time, {
                            format: "MMM DD, YYYY hh:mm A",
                            includeTimeForToday: true,
                          })
                        : "--"}
                    </td>
                    <td className="p-2 text-sm">
                      {item?.meeting_duration ?? "--"}
                    </td>
                    <td className="p-2 text-sm">
                      <div className="flex items-center gap-2">
                        <img
                          src={
                            item?.meeting_organizer?.avatar
                              ? `${BASE_URL}/${item?.meeting_organizer?.avatar}`
                              : `https://api.dicebear.com/5.x/initials/svg?seed=${item?.meeting_organizer?.user_name}`
                          }
                          alt={item?.meeting_organizer?.user_name?.[0]}
                          className="w-7 h-7 rounded-full bg-slate-300 p-0.5"
                        />
                        <span className="text-xs">
                          {item?.meeting_organizer?._id === user?._id
                            ? "You"
                            : item?.meeting_organizer?.user_name}
                        </span>
                      </div>
                    </td>
                    <td className="p-2 text-sm">
                      <CustomAssignees
                        assignees={item?.participants?.map((participant) => ({
                          _id: participant?.user?._id,
                          is_invitation_accepted:
                            participant?.is_invitation_accepted ? true : false,
                          user_name: participant?.user?.user_name,
                          designation: participant?.user?.designation,
                          avatar: participant?.user?.avatar,
                        }))}
                        maxLimit={4}
                      />
                    </td>
                    <td className="p-2 text-sm">
                      <CustomAssignees
                        meeting={true}
                        assignees={item?.external_user?.map(
                          (external_user_participant) => ({
                            _id: external_user_participant?._id,
                            user_name: external_user_participant?.user_name,
                            designation: {
                              designation: external_user_participant?.email,
                            },
                            avatar: external_user_participant?.avatar,
                          })
                        )}
                        maxLimit={3}
                      />
                    </td>
                    <td className="p-2 flex justify-center items-center ">
                      <div
                        onClick={() => {
                          if (item?.meeting_status !== "Completed") {
                            setMeetingDetails(item);
                            setShowMeetingForm(true);
                          } else {
                            setToastNotification(
                              "Meeting Status is Alredy Completed!",
                              "error"
                            );
                          }
                        }}
                        className={`p-1 border border-custom-blue rounded-md ${
                          item?.meeting_organizer?._id === user?._id
                            ? "cursor-pointer"
                            : "cursor-not-allowed"
                        }
                            `}
                      >
                        <MdOutlineEdit className="text-custom-blue" />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td
                  colSpan={TableHeads.length}
                  className="text-center font-semibold text-sm p-4"
                >
                  <NoDataFound />
                </td>
              </tr>
            </tbody>
          )}
          <tfoot className="relative">
            <tr>
              <td className="text-slate-400 text-sm px-2 py-4" colSpan={6}>
                <PaginationText
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  dataCount={meetingCount}
                  dataLength={meetingsData?.length}
                />
              </td>
              <td className="absolute right-2 top-1.5">
                <Pagination
                  handlePageClick={handlePageClick}
                  pageRangeDisplayed={itemsPerPage}
                  pageCount={pageCount}
                  name={"pageSize"}
                  onChange={(e) => setItemsPerPage(e?.target?.value)}
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </section>
      <CustomModal
        isOpen={showMeetingForm}
        onClose={() => setShowMeetingForm(false)}
        ref={meetingFormRef}
        classname={`lg:w-2/3`}
      >
        <MeetingForm
          meetingDetails={meetingDetails ? meetingDetails : null}
          setShowMeetingForm={setShowMeetingForm}
          setShowMeetingView={setShowMeetingView}
          FetchAllMeetings={GetAllMeetings}
        />
      </CustomModal>
      <CustomModal
        isOpen={showMeetingView}
        onClose={() => setShowMeetingView(false)}
        ref={meetingViewRef}
        classname={`w-11/12 lg:w-2/3`}
      >
        <MeetingView
          setShowMeetingForm={setShowMeetingForm}
          meetingDetails={meetingDetails}
          setShowMeetingView={setShowMeetingView}
          MeetingStatusHandler={MeetingStatusHandler}
          setMeetingDetails={setMeetingDetails}
          setShowRejectModal={setShowRejectModal}
          setShowRemarkModal={setShowRemarkModal}
        />
      </CustomModal>
      <CustomModal
        isOpen={showRejectModal}
        onClose={() => setShowRejectModal(false)}
      >
        <div className="text-center text-custom-black">
          <h2 className="text-2xl font-bold my-4">Reason?</h2>
        </div>
        <form
          className="w-full "
          noValidate
          onSubmit={handleSubmit(meetingRejectHandler)}
        >
          <div className="my-2">
            <CustomInput
              name="reason"
              label="Reason"
              inputType="text-area"
              placeholder="Type Here..."
              control={control}
              required={true}
              error={errors?.reason?.message}
            />
          </div>
          <div className="flex justify-end items-center gap-5 mt-5">
            <CustomButton
              title={"Cancel"}
              onClick={() => setShowRejectModal(false)}
              buttonType={"button"}
              classname={"px-2 py-1 bg-custom-white border rounded-md "}
            />
            <CustomButton
              title={"Reject Meeting"}
              buttonType={"submit"}
              disabled={loading}
              classname={"bg-[#FB0F0F] text-custom-white px-2 py-1 rounded-md"}
            />
          </div>
        </form>
      </CustomModal>
      {showRemarkModal && (
        <RemarkMeeting
          showRemarkModal={showRemarkModal}
          setShowRemarkModal={setShowRemarkModal}
          meetingDetails={meetingDetails}
          GetAllMeetings={GetAllMeetings}
        />
      )}
    </div>
  );
};

export default MeetingList;

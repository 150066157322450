import React from "react";
import Skeleton from "react-loading-skeleton";

const TableSkeleton = ({ rows = 5, columns = 3 }) => {
  return (
    <table className="w-full border rounded-lg bg-custom-white overflow-auto">
      <thead>
        <tr>
          {Array(columns)
            .fill(null)
            .map((_, index) => (
              <th key={index}>
                <Skeleton height={20} />
              </th>
            ))}
        </tr>
      </thead>
      <tbody>
        {Array(rows)
          .fill(null)
          .map((_, rowIndex) => (
            <tr key={rowIndex}>
              {Array(columns)
                .fill(null)
                .map((_, colIndex) => (
                  <td key={colIndex} className="p-2 py-4">
                    <Skeleton className="p-2" height={15} />
                  </td>
                ))}
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default TableSkeleton;

import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { CustomButton, CustomInput, CustomModal } from "../common";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/slice/loadingSlice";
import { apiConnector } from "../../networking/ApiConnector";
import { meetingEndPoints } from "../../networking/Endpoints";
import { CustomAlertContext } from "../../context";

const RemarkMeetingValidation = yup.object().shape({
  remark: yup.string().required("Remark is required").trim(),
});
const RemarkMeeting = ({
  showRemarkModal,
  setShowRemarkModal,
  meetingDetails,
  GetAllMeetings,
}) => {
  const { token, user } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.loading);
  const dispatch = useDispatch();
  const { setToastNotification } = useContext(CustomAlertContext);
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(RemarkMeetingValidation),
  });
  const AddRemarkHandler = async (data) => {
    dispatch(setLoading(true));
    try {
      const meeting_response = await apiConnector(
        "PUT",
        `${meetingEndPoints?.MEETING_API}/${meetingDetails?._id}`,
        data,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setToastNotification(meeting_response?.data?.message, "success");
      GetAllMeetings();
      setShowRemarkModal(false);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };
  return (
    <CustomModal
      isOpen={showRemarkModal}
      onClose={() => setShowRemarkModal(false)}
    >
      <div className="text-center text-custom-black">
        <h2 className="text-2xl font-bold my-4">Remark & Suggestion</h2>
      </div>
      <form
        className="w-full "
        noValidate
        onSubmit={handleSubmit(AddRemarkHandler)}
      >
        <div className="my-2">
          <CustomInput
            name="remark"
            label="Remark"
            inputType="text-area"
            placeholder="Type Here..."
            control={control}
            required={true}
            error={errors?.remark?.message}
          />
        </div>
        <div className="flex justify-end items-center gap-5 mt-5">
          <CustomButton
            title={"Cancel"}
            onClick={() => setShowRemarkModal(false)}
            buttonType={"button"}
            classname={"px-2 py-1 bg-custom-white border rounded-md "}
          />
          <CustomButton
            title={"Add Remark"}
            buttonType={"submit"}
            disabled={loading}
            classname={"bg-custom-blue text-custom-white px-2 py-1 rounded-md"}
          />
        </div>
      </form>
    </CustomModal>
  );
};

export default RemarkMeeting;

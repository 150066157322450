import React, { useState, useEffect, useMemo, useContext } from "react";
import { useForm, useFieldArray, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { FaAngleDown, FaAngleUp, FaPlusCircle, FaTrash } from "react-icons/fa";
import { CustomButton, CustomInput, CustomSelect } from "../common";
import { useDispatch, useSelector } from "react-redux";
import { CustomAlertContext } from "../../context";
import { getAllProject } from "../../redux/slice/projectSlice";
import { getAllPersonalTask } from "../../redux/slice/taskSlice";
import { apiConnector } from "../../networking/ApiConnector";
import {
  subTaskEndPoints,
  timesheetEndPoints,
} from "../../networking/Endpoints";
import { setLoading } from "../../redux/slice/loadingSlice";

dayjs.extend(isoWeek);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

// Validation schema (adjust as needed)
const TimesheetValidation = yup.object().shape({
  entries: yup.array().of(
    yup.object().shape({
      weekIndex: yup.number().required(),
      date: yup.string().required(),
      project: yup.string().notRequired(),
      task: yup.string().notRequired(),
      sub_task: yup.string().notRequired(),
      estimated_time: yup
        .string()
        .matches(/^\d{2,3}:([0-5]\d)$/, "Time must be in HH:MM format")
        .nullable()
        .transform((value) => (value === "" ? null : value)),
      description: yup.string().notRequired(),
    })
  ),
});

const TimeSheetForm = ({
  weeks,
  fetchTimesheets = () => {},
  selected_year,
  selected_month,
  showTimesheet = 1,
  timesheetData = [],
}) => {
  console.log(timesheetData, "timesheetData after Filter");
  const dispatch = useDispatch();
  const { token, user } = useSelector((state) => state.auth);
  const { projectData } = useSelector((state) => state.project);
  const { personalTaskData } = useSelector((state) => state.task);
  const { setToastNotification } = useContext(CustomAlertContext);
  const disabled = showTimesheet === 2 || showTimesheet === 4 ? true : false;
  // State to store fetched sub‑tasks keyed by task id
  const [subtasksMapping, setSubtasksMapping] = useState({});

  // Accordion open/close state for each week
  const [openWeeks, setOpenWeeks] = useState(() => weeks?.map(() => false));
  useEffect(() => {
    setOpenWeeks(weeks?.map(() => false));
  }, [weeks]);

  // Set up React Hook Form and field array
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(TimesheetValidation),
    defaultValues: useMemo(() => ({ entries: [] }), []),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "entries",
  });

  // Initialize form entries – prefill if timesheetData exists.
  useEffect(() => {
    if (weeks?.length && timesheetData?.length) {
      const initialEntries = weeks.flatMap((week, wIndex) =>
        week
          ?.filter((date) => date)
          ?.map((date) => {
            let foundEntry = null;
            if (timesheetData && Array.isArray(timesheetData)) {
              // For each timesheet record (which represents one week)
              timesheetData?.forEach((sheet) => {
                const sheetWeekStart = dayjs(sheet?.week_start_date).format(
                  "YYYY-MM-DD"
                );
                const sheetWeekEnd = dayjs(sheet?.week_end_date).format(
                  "YYYY-MM-DD"
                );
                // If current date falls within this sheet's week range…
                if (
                  dayjs(date).isSameOrAfter(sheetWeekStart) &&
                  dayjs(date).isSameOrBefore(sheetWeekEnd) &&
                  sheet?.work &&
                  Array.isArray(sheet?.work)
                ) {
                  sheet?.work?.forEach((workEntry) => {
                    // Compare dates in YYYY-MM-DD format
                    if (dayjs(workEntry.date).format("YYYY-MM-DD") === date) {
                      foundEntry = {
                        ...workEntry,
                        timesheet_id: sheet?._id,
                        isSubmit: sheet?.isSubmit,
                        status: sheet?.status,
                      };
                    }
                  });
                }
              });
            }
            return {
              timesheet_id: foundEntry?.timesheet_id ?? "",
              weekIndex: wIndex,
              weekEdit: foundEntry?.timesheet_id ? true : false,
              isSubmit: foundEntry?.isSubmit,
              status: foundEntry?.status,
              date,
              project: foundEntry ? foundEntry.project?._id : "",
              task: foundEntry ? foundEntry.task?._id || "" : "",
              sub_task: foundEntry ? foundEntry.sub_task || "" : "",
              estimated_time: foundEntry ? foundEntry.time_logged || "" : "",
              description: foundEntry ? foundEntry.description || "" : "",
            };
          })
      );
      //use form doesnt get that reset has new value to update thats why settimeout is used here
      setTimeout(() => reset({ entries: initialEntries }), 0);
    }
  }, [weeks, timesheetData, selected_month, selected_year]);

  const assign_to = user?._id;

  // Fetch projects/tasks if needed
  const fetchProjects = async () => {
    try {
      await dispatch(getAllProject({ token })).unwrap();
    } catch (error) {
      setToastNotification(error?.message, "error");
    }
  };

  const fetchPersonalTask = async () => {
    try {
      await dispatch(getAllPersonalTask({ token, project: "", assign_to }));
    } catch (error) {
      setToastNotification(error?.message, "error");
    }
  };

  useEffect(() => {
    if (!projectData || (projectData?.page_number && projectData?.page_size)) {
      fetchProjects();
    }
  }, []);

  useEffect(() => {
    if (
      !personalTaskData ||
      (personalTaskData?.data?.page_number && personalTaskData?.data?.page_size)
    ) {
      fetchPersonalTask();
    }
  }, []);

  // Prepare options for the project dropdown
  const projectOptions = projectData?.projects?.map((project) => ({
    name: project?.project_name,
    value: project?._id,
  }));

  // Combine tasks into a single array (each task includes its associated project)
  const [allTasks, setAllTasks] = useState([]);
  useEffect(() => {
    if (personalTaskData?.data?.tasks) {
      setAllTasks((prevTasks) => {
        const taskMap = new Map(prevTasks?.map((task) => [task.value, task]));
        personalTaskData.data.tasks.forEach((task) => {
          taskMap.set(task?._id, {
            name: task?.title,
            value: task?._id,
            project: task?.project?._id,
          });
        });
        return Array.from(taskMap.values());
      });
    }
  }, [personalTaskData]);

  // Initialize the form entries based on computed weeks
  useEffect(() => {
    const initialEntries = weeks?.flatMap((week, wIndex) =>
      week
        ?.filter((date) => date)
        ?.map((date) => ({
          weekIndex: wIndex,
          date,
          project: "",
          task: "",
          sub_task: "",
          estimated_time: "",
          description: "",
        }))
    );
    setValue("entries", initialEntries);
  }, [setValue, weeks]);

  // Group entries by weekIndex for accordion rendering
  const groupedEntries = useMemo(() => {
    return weeks?.map((_, wIndex) =>
      fields?.filter((field) => field?.weekIndex === wIndex)
    );
  }, [fields, weeks]);

  // Use useWatch to observe the entire "entries" array
  const entries = useWatch({ control, name: "entries" });

  // When a row's task field changes, fetch subtasks (if not already fetched)
  useEffect(() => {
    entries?.forEach((entry) => {
      if (entry.task && !subtasksMapping[entry.task]) {
        apiConnector(
          "GET",
          `${subTaskEndPoints?.SUBTASK_API}/${entry.task}`,
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        )
          .then((response) => {
            const fetchedSubtasks = response?.data?.data?.sub_tasks || [];
            const mappedSubtasks = fetchedSubtasks?.map((sub) => ({
              name: sub.title,
              value: sub._id,
            }));
            setSubtasksMapping((prev) => ({
              ...prev,
              [entry.task]: mappedSubtasks,
            }));
          })
          .catch((error) => {
            setToastNotification(error?.message, "error");
          });
      }
    });
  }, [entries]);

  // Week-level submit handler (for saving or submitting one week at a time)
  const getWeekBoundaries = (date) => {
    const formattedDate = dayjs(date);
    let weekStart = formattedDate.startOf("week").add(1, "day"); // Monday
    let weekEnd = weekStart.add(6, "day"); // Sunday

    // Special case: First week of the month (if month starts midweek)
    if (formattedDate.date() <= 7 && formattedDate.isoWeekday() !== 1) {
      weekStart = formattedDate.startOf("month"); // Start from the 1st
      weekEnd = weekStart.add(6 - weekStart.isoWeekday() + 1, "day"); // Adjust to the first Sunday
    }

    return {
      weekStartDate: weekStart.format("YYYY-MM-DD"),
      weekEndDate: weekEnd.format("YYYY-MM-DD"),
    };
  };

  const transformTimesheetData = (formData) => {
    const groupedByWeek = {};

    formData.forEach((entry) => {
      const {
        weekIndex,
        date,
        project,
        task,
        sub_task,
        estimated_time,
        description,
        timesheet_id,
        weekEdit,
      } = entry;

      // Ignore empty entries
      if (!project && !description && !estimated_time) return;

      const { weekStartDate, weekEndDate } = getWeekBoundaries(date);

      if (!groupedByWeek[weekIndex]) {
        groupedByWeek[weekIndex] = {
          week_start_date: weekStartDate,
          week_end_date: weekEndDate,
          timesheet_id: timesheet_id,
          weekEdit: weekEdit,
          work: [],
        };
      }

      // Build the work entry
      const workEntry = {
        date: dayjs(date).format("YYYY-MM-DD"),
        project,
        time_logged: estimated_time,
        description,
      };

      if (task) workEntry.task = task;
      if (sub_task) workEntry.sub_task = sub_task;

      groupedByWeek[weekIndex].work.push(workEntry);
    });

    return Object.values(groupedByWeek);
  };

  const onWeekSubmit = async (weekIndex, data, btn = 1) => {
    // Filter out only the entries for this week that have some data.
    console.log(data, "data");
    const weekEntries = data.entries.filter(
      (item) =>
        item.weekIndex === weekIndex &&
        (item.project ||
          item.task ||
          item.sub_task ||
          item.description ||
          item.estimated_time ||
          item?.timesheet_id ||
          item?.weekEdit)
    );
    if (weekEntries?.length === 0) {
      setToastNotification("No data for this week.", "warning");
      return;
    }
    const [payload] = transformTimesheetData(weekEntries);

    console.log("Submitting week", payload);
    let timesheet_form_data =
      btn === 1 && payload?.weekEdit
        ? {
            week_start_date: payload?.week_start_date,
            week_end_date: payload?.week_end_date,
            work: payload?.work,
          }
        : payload?.weekEdit && btn === 2
        ? {
            week_start_date: payload?.week_start_date,
            week_end_date: payload?.week_end_date,
            work: payload?.work,
            isSubmit: true,
          }
        : {
            week_start_date: payload?.week_start_date,
            week_end_date: payload?.week_end_date,
            work: payload?.work,
          };
    console.log("Submitting week 1234", timesheet_form_data);
    dispatch(setLoading(true));
    try {
      const apiMethod =
        (btn === 1 || btn === 2) && payload?.weekEdit ? "PUT" : "POST";
      const apiURl =
        (btn === 1 || btn === 2) && payload?.weekEdit
          ? `${timesheetEndPoints?.TIMESHEET_API}/${payload?.timesheet_id}`
          : timesheetEndPoints?.TIMESHEET_API;
      const timesheet_response = await apiConnector(
        apiMethod,
        apiURl,
        timesheet_form_data,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setToastNotification(timesheet_response?.data?.message, "success");
      if (fetchTimesheets) fetchTimesheets();
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };

  // Function to add a new sub-row (for the same date & week)
  const addSubRow = (fieldIndex, date, weekIndex) => {
    const insertIndex = fields.findIndex(
      (f, i) => f.weekIndex === weekIndex && f.date === date && i > fieldIndex
    );
    append(
      {
        weekIndex,
        date,
        project: "",
        task: "",
        sub_task: "",
        estimated_time: "",
        description: "",
      },
      insertIndex !== -1 ? insertIndex : fields.length
    );
  };

  return (
    // Global form wrapper (we use per‑week buttons to process data)
    <form className="py-2" noValidate onSubmit={handleSubmit(() => {})}>
      {weeks?.length
        ? weeks?.map((week, weekIndex) => {
            const weekDates = week?.filter((date) => date);
            const weekEntries = groupedEntries[weekIndex] || [];
            return (
              <div
                key={weekIndex}
                className="mb-4 border rounded-lg overflow-x-auto transition-all duration-300 ease-linear"
              >
                {/* Accordion Header */}
                <div
                  className="p-2 bg-custom-light-blue cursor-pointer rounded-lg flex justify-between items-center text-custom-blue"
                  onClick={() =>
                    setOpenWeeks((prev) => {
                      const newState = [...prev];
                      newState[weekIndex] = !newState[weekIndex];
                      return newState;
                    })
                  }
                >
                  <h2 className="text-sm font-semibold ">
                    Week Starting :{" "}
                    {weekDates?.length
                      ? `${dayjs(weekDates?.[0]).format(
                          "ddd DD MMM"
                        )} - ${dayjs(weekDates[weekDates?.length - 1]).format(
                          "ddd DD MMM"
                        )}`
                      : `Week ${weekIndex + 1}`}
                  </h2>
                  <span>
                    {openWeeks?.[weekIndex] ? <FaAngleUp /> : <FaAngleDown />}
                  </span>
                </div>
                {/* Accordion Content */}
                {openWeeks[weekIndex] && (
                  <>
                    <table className="w-full text-sm border-collapse border-gray-300">
                      <tbody>
                        {weekDates?.map((date) => {
                          const dateEntries = weekEntries.filter(
                            (entry) => entry.date === date
                          );
                          return dateEntries?.map((field, index) => {
                            const fieldIndex = fields.findIndex(
                              (f) => f.id === field?.id
                            );
                            const isSunday =
                              dayjs(field?.date).isoWeekday() === 7;
                            // Get current row’s project & task via watch
                            const currentProject = watch(
                              `entries.${fieldIndex}.project`
                            );
                            const currentTask = watch(
                              `entries.${fieldIndex}.task`
                            );
                            // Filter tasks based on the selected project
                            const filteredTaskOptions = allTasks
                              ?.filter(
                                (task) => task.project === currentProject
                              )
                              ?.map((task) => ({
                                name: task.name,
                                value: task.value,
                              }));
                            // Get sub-task options (if available) for the current task
                            const subtaskOptions =
                              (currentTask && subtasksMapping[currentTask]) ||
                              [];
                            return (
                              <tr key={field?.id} className="border-b">
                                <td className="p-2">
                                  {index === 0 && (
                                    <span
                                      className={
                                        isSunday
                                          ? "text-red-600 font-semibold"
                                          : ""
                                      }
                                    >
                                      {dayjs(field?.date).format(
                                        "ddd DD MMM YYYY"
                                      )}
                                    </span>
                                  )}
                                </td>
                                {isSunday ? (
                                  <td
                                    colSpan={5}
                                    className="p-2 text-center text-red-600 font-semibold"
                                  >
                                    Holiday
                                  </td>
                                ) : (
                                  <>
                                    <td className="p-2">
                                      <CustomSelect
                                        control={control}
                                        name={`entries.${fieldIndex}.project`}
                                        options={projectOptions || []}
                                        placeholder="Select Project"
                                        showInfoButton={false}
                                        isEditable={disabled}
                                      />
                                    </td>
                                    <td className="p-2">
                                      <CustomSelect
                                        control={control}
                                        name={`entries.${fieldIndex}.task`}
                                        options={filteredTaskOptions}
                                        placeholder="Select Task"
                                        isEditable={disabled}
                                        showInfoButton={false}
                                      />
                                    </td>
                                    <td className="p-2">
                                      <CustomSelect
                                        control={control}
                                        name={`entries.${fieldIndex}.sub_task`}
                                        options={subtaskOptions}
                                        placeholder="Select Sub-task"
                                        isEditable={disabled}
                                        showInfoButton={false}
                                      />
                                    </td>
                                    <td className="p-2">
                                      <CustomInput
                                        control={control}
                                        name={`entries.${fieldIndex}.estimated_time`}
                                        placeholder="00:00"
                                        isEditable={disabled}
                                        showInfoButton={false}
                                      />
                                    </td>
                                    <td className="p-2">
                                      <CustomInput
                                        control={control}
                                        name={`entries.${fieldIndex}.description`}
                                        placeholder="Description"
                                        inputType="text-area"
                                        isEditable={disabled}
                                        showInfoButton={false}
                                      />
                                    </td>
                                  </>
                                )}
                                <td className="p-2 text-center flex gap-2">
                                  {isSunday ? (
                                    <CustomButton
                                      title="Request"
                                      buttonType="button"
                                      classname="bg-yellow-500 text-white px-3 py-1 rounded-md"
                                    />
                                  ) : (
                                    <>
                                      {index === 0 && (
                                        <button
                                          type="button"
                                          onClick={() =>
                                            addSubRow(
                                              fieldIndex,
                                              field.date,
                                              weekIndex
                                            )
                                          }
                                          className="text-blue-600 hover:text-blue-800 flex justify-center items-center"
                                        >
                                          <FaPlusCircle />
                                        </button>
                                      )}
                                      {index > 0 && (
                                        <button
                                          type="button"
                                          onClick={() => remove(fieldIndex)}
                                          className="text-red-600 hover:text-red-800 flex justify-center items-center"
                                        >
                                          <FaTrash />
                                        </button>
                                      )}
                                    </>
                                  )}
                                </td>
                              </tr>
                            );
                          });
                        })}
                      </tbody>
                    </table>
                    {/* Week-specific Save/Submit Buttons */}
                    <div className="flex justify-end items-end mt-4 gap-4 p-2">
                      <CustomButton
                        title="Save"
                        buttonType="button"
                        classname="bg-blue-600 text-white px-5 text-sm font-semibold py-1 rounded-md"
                        onClick={() =>
                          handleSubmit((data) =>
                            onWeekSubmit(weekIndex, data, 1)
                          )()
                        }
                      />
                      <CustomButton
                        title="Submit"
                        buttonType="button"
                        classname="bg-green-600 text-white px-5 text-sm font-semibold py-1 rounded-md"
                        onClick={() =>
                          handleSubmit((data) =>
                            onWeekSubmit(weekIndex, data, 2)
                          )()
                        }
                      />
                    </div>
                  </>
                )}
              </div>
            );
          })
        : "No Data Available"}
    </form>
  );
};

export default TimeSheetForm;

import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { apiConnector } from "../../networking/ApiConnector";
import {
  attendanceEndPoints,
  employeeEndPoints,
} from "../../networking/Endpoints";
import { setLoading } from "../../redux/slice/loadingSlice";
import { toast } from "react-toastify";
import ProjectCard from "../project/ProjectCard";
import Skeleton from "react-loading-skeleton";
import { Heading } from "../project";
import { Roles } from "../../constant/Constant";
import { CustomAlertContext } from "../../context";
import { CustomButton, CustomChart } from "../common";
import { MdOutlineEdit } from "react-icons/md";
import { GrView } from "react-icons/gr";
import { dateFormatter } from "../../helper/formatDate";
import EmployeeDetails from "./employeeview/EmployeeDetails";
import EmployeeAttendanceChart from "./employeeview/EmployeeAttendanceChart";
import EmployeeTaskChart from "./employeeview/EmployeeTaskChart";
import dayjs from "dayjs";
import EmployeeAttendancePieChart from "./employeeview/EmployeeAttendancePieChart";
import EmployeeTimesheetCard from "./employeeview/EmployeeTimesheetCard";
import EmployeeProject from "./employeeview/EmployeeProject";
import EmployeeCalendar from "./employeeview/EmployeeCalendar";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const EmployeeView = () => {
  const { token, user } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.loading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const employee_id = location?.state?.id;
  const [employeeDetails, setEmployeeDetails] = useState(null);
  const [workingHoursLoading, setWorkingHoursLoading] = useState(false);
  const [workingHours, setWorkingHours] = useState([]);
  const { setToastNotification } = useContext(CustomAlertContext);
  const year = dayjs().year();
  const month = dayjs().month() + 1;
  const fetchEmployeeDetails = async () => {
    dispatch(setLoading(true));
    try {
      const employee_response = await apiConnector(
        "GET",
        `${employeeEndPoints?.EMPLOYEE_API}/${employee_id}`,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setEmployeeDetails(employee_response?.data?.data?.[0]);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    if (employee_id) {
      fetchEmployeeDetails();
    }
  }, [employee_id]);

  const GetWorkingHours = async () => {
    setWorkingHoursLoading(true);
    try {
      const working_response = await apiConnector(
        "GET",
        attendanceEndPoints?.WORKING_HOURS_API,
        null,
        {
          Authorization: `Bearer ${token}`,
        },
        {
          month: month,
          year: year,
          userId: employeeDetails?._id,
        }
      );
      setWorkingHours(working_response?.data?.attendanceData);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      setWorkingHoursLoading(false);
    }
  };
  useEffect(() => {
    if (employeeDetails?._id) GetWorkingHours();
  }, [employeeDetails]);
  return loading ? (
    <div className="w-full bg-custom-white p-6 rounded-lg">
      <section className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 border-b border-[#DEE9F0] py-4">
        <Skeleton height={24} width={150} />
        <Skeleton height={24} width={200} />
        <Skeleton height={24} width={250} />
        <Skeleton height={24} width={180} />
        <Skeleton height={24} width={200} />
        <Skeleton height={24} width={180} />
      </section>
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
        {[1, 2, 3, 4, 5, 6]?.map((index) => (
          <div
            key={index}
            className="w-full border border-[#E8F0F6] bg-custom-white p-4 rounded-lg shadow-[2px_3px_10px_6px_#257CBB0F] space-y-3 cursor-pointer flex flex-col"
          >
            <div className="flex justify-between items-center space-x-2">
              <div className="w-40">
                <Skeleton width={"70"} />
              </div>
              <div className="w-[15px] h-[15px]">
                <Skeleton circle width={"100%"} height="100%" />
              </div>
            </div>
            <div>
              <Skeleton count={3} />
            </div>
            <div className="flex space-x-2">
              <div className="w-[25px] h-[25px]">
                <Skeleton circle width={"100%"} height="100%" />
              </div>
              <div className="w-[25px] h-[25px]">
                <Skeleton circle width={"100%"} height="100%" />
              </div>
              <div className="w-[25px] h-[25px]">
                <Skeleton circle width={"100%"} height="100%" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <div className="w-full bg-custom-white rounded-lg p-6">
      <section className="w-full grid grid-cols-1 lg:grid-cols-2 gap-4">
        <EmployeeDetails employeeDetails={employeeDetails} />
        <EmployeeAttendanceChart
          employeeDetails={employeeDetails}
          workingHours={workingHours}
          workingHoursLoading={workingHoursLoading}
        />
      </section>
      <section className="w-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 mt-2">
        <EmployeeTimesheetCard employeeDetails={employeeDetails} />
        <EmployeeTaskChart employeeDetails={employeeDetails} />
        <EmployeeAttendancePieChart
          employeeDetails={employeeDetails}
          workingHours={workingHours}
          workingHoursLoading={workingHoursLoading}
        />
      </section>
      <section className="w-full">
        <EmployeeCalendar employeeDetails={employeeDetails} />
      </section>
      <section className="w-full">
        <EmployeeProject employeeDetails={employeeDetails} />
      </section>
    </div>
  );
};

export default EmployeeView;

import { useEffect, useRef, useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import CommonTooltip from "./CommonTooltip";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

const CustomSidebar = ({ menuItems }) => {
  const location = useLocation();
  const navigate = useNavigate();
  // Initialize openSubItems from localStorage (or use empty object if not set)
  const [openSubItems, setOpenSubItems] = useState(() => {
    const stored = localStorage.getItem("openSubItems");
    return stored ? JSON.parse(stored) : {};
  });

  const [isCollapsed, setIsCollapsed] = useState(false);
  const subItemRef = useRef();

  // Whenever openSubItems changes, persist the new state in localStorage.
  useEffect(() => {
    localStorage.setItem("openSubItems", JSON.stringify(openSubItems));
  }, [openSubItems]);

  // Handle sidebar collapse/expand based on screen width
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 640) {
        // Tailwind's `sm` breakpoint is 640px
        setIsCollapsed(false);
      } else {
        setIsCollapsed(true);
      }
    };

    handleResize(); // Set initial state based on current width

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Checks whether the given item matches the current route
  const matchRoute = (item) => {
    if (!item) return false;
    const { path, active } = item;
    const isPathMatch = path ? matchPath(path, location?.pathname) : false;
    const isActiveMatch = active
      ? active.some((activePath) => matchPath(activePath, location?.pathname))
      : false;
    return isPathMatch || isActiveMatch;
  };

  useEffect(() => {
    const updatedOpenSubItems = { ...openSubItems };

    menuItems.forEach((item, index) => {
      if (item.subItems) {
        const subItemMatch = item.subItems.some((subItem) =>
          matchRoute(subItem)
        );
        // If any subitem matches the current route, ensure its parent menu is open.
        if (subItemMatch) {
          updatedOpenSubItems[index] = true;
        }
      }
    });

    setOpenSubItems(updatedOpenSubItems);
  }, [location.pathname, menuItems]);

  // Toggle the open state for a given menu item
  const handleOpenMenuItems = (index) => {
    setOpenSubItems((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  return (
    <>
      <div
        className={`${
          isCollapsed ? "w-16" : "w-64"
        } h-full overflow-y-auto overflow-x-hidden border-r shadow-md bg-custom-white transition-all duration-500`}
      >
        {/* Menu Items */}
        {menuItems?.map((item, index) => (
          <div
            key={index}
            className={`${index === 0 ? "border-t border-b" : "border-b"}`}
          >
            <div
              className={`${
                matchRoute(item) &&
                "text-custom-blue bg-[#E3EFF8] border-l-4 border-l-custom-blue"
              } flex items-center ${
                isCollapsed ? "justify-center" : "justify-between"
              } gap-4 px-5 py-3 font-medium cursor-pointer`}
              onClick={() => {
                if (item?.subItems) {
                  handleOpenMenuItems(index);
                } else {
                  setOpenSubItems({});
                  item?.path && navigate(item.path);
                }
              }}
            >
              <div className="flex items-center gap-4">
                <span className="text-xl">
                  <CommonTooltip tooltip={isCollapsed ? item?.name : ""}>
                    {item?.icon}
                  </CommonTooltip>
                </span>
                {!isCollapsed && <span>{item?.name}</span>}
              </div>
              {!isCollapsed && item?.subItems && (
                <span>
                  {openSubItems[index] ? <FaAngleUp /> : <FaAngleDown />}
                </span>
              )}
            </div>
            {/* Sub Items */}
            <div
              ref={subItemRef}
              className={`transition-all duration-300 ${
                openSubItems[index] ? "opacity-100" : "max-h-0 opacity-0"
              }`}
            >
              {openSubItems[index] &&
                item?.subItems?.map((subItem, subIndex) => (
                  <div
                    key={subIndex}
                    className={`${
                      matchRoute(subItem) &&
                      "text-custom-blue bg-[#E3EFF8] border-l-4 border-custom-blue"
                    } flex items-center ${
                      isCollapsed ? "justify-center" : "justify-between"
                    } gap-4 px-8 py-3 font-medium cursor-pointer`}
                    onClick={() => {
                      subItem?.path && navigate(subItem?.path);
                    }}
                  >
                    <div className="flex items-center gap-4">
                      <span className="text-xl">
                        <CommonTooltip
                          tooltip={isCollapsed ? subItem?.name : ""}
                        >
                          {subItem?.icon}
                        </CommonTooltip>
                      </span>
                      {!isCollapsed && <span>{subItem?.name}</span>}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
      {/* Toggle Button */}
      <div className="w-full bg-slate-200 flex items-center justify-between">
        <button
          onClick={() => setIsCollapsed((prev) => !prev)}
          className="text-custom-blue w-10 h-10 rounded-full focus:outline-none p-2 flex items-center justify-center cursor-pointer"
        >
          {isCollapsed ? (
            <MdKeyboardArrowRight size={44} fontSize={24} />
          ) : (
            <MdKeyboardArrowLeft size={44} fontSize={24} />
          )}
        </button>
        {!isCollapsed && (
          <span className="text-xs text-slate-500 p-2">v1.1.1</span>
        )}
      </div>
    </>
  );
};

export default CustomSidebar;

// import React, { useEffect, useRef } from "react";
// import Chart from "chart.js/auto";

// const CustomChart = ({
//   labelName,
//   chartType = "",
//   labels = [],
//   data = [],
//   colors = [],
//   className = "",
// }) => {
//   const chartRef = useRef(null);

//   useEffect(() => {
//     const ctx = chartRef.current.getContext("2d");
//     let delayed,
//       // Initialize the chart
//       chartInstance = new Chart(ctx, {
//         type: chartType || "pie",
//         data: {
//           labels: labels,
//           datasets: [
//             {
//               barPercentage: 0.8,
//               label: labelName,
//               data: data,
//               backgroundColor: colors,
//               tension: 0.4,
//               borderRadius: 16,
//               fill: {
//                 target: 9,
//                 above: "rgb(255, 0, 0)",
//                 below: "rgb(0, 0, 255)",
//               },
//             },
//           ],
//         },
//         options: {
//           responsive: true,
//           aspectRatio: 2,
//           scales: {
//             y: {
//               beginAtZero: true,
//             },
//           },
//           animation: {
//             onComplete: () => {
//               delayed = true;
//             },
//             delay: (context) => {
//               let delay = 0;
//               if (
//                 context.type === "data" &&
//                 context.mode === "default" &&
//                 !delayed
//               ) {
//                 delay = context.dataIndex * 100 + context.datasetIndex * 100;
//               }
//               return delay;
//             },
//           },
//           plugins: {
//             tooltip: {
//               usePointStyle: true,
//             },
//             legend: {
//               display: true,
//               labels: { usePointStyle: true },
//               position: "left",
//             },
//           },
//         },
//       });

//     // Cleanup chart instance on unmount
//     return () => {
//       chartInstance.destroy();
//     };
//   }, [data, chartType, labels, labelName]);

//   return <canvas className={className} ref={chartRef}></canvas>;
// };

// export default CustomChart;
import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { position } from "jodit/esm/core/helpers";

const CustomChart = ({
  labelName,
  chartType = "",
  labels = [],
  data = [],
  colors = [],
  className = "",
  position = "left",
  borderRadius = null,
  officeHourLimit = null, // If provided, will split office and extra hours
}) => {
  const chartRef = useRef(null);
  let chartInstance = null;

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");
    let delayed;
    // Destroy previous instance before creating a new one
    if (chartInstance) {
      chartInstance.destroy();
    }

    let chartData;
    if (chartType === "bar" && officeHourLimit !== null) {
      // Split data into office hours and extra hours dynamically
      const officeHours = data.map((hours) => Math.min(hours, officeHourLimit));
      const extraHours = data.map((hours) =>
        Math.max(hours - officeHourLimit, 0)
      );

      chartData = {
        labels,
        datasets: [
          {
            label: "Work Time",
            data: officeHours,
            backgroundColor: "#5FB2EE",
            borderWidth: 1,
            borderRadius: borderRadius,
          },
          {
            label: "Over Time",
            data: extraHours,
            backgroundColor: "#FF2568",
            borderWidth: 1,
            borderRadius: borderRadius,
          },
        ],
      };
    } else {
      // Default behavior for other chart types
      chartData = {
        labels,
        datasets: [
          {
            barPercentage: 0.8,
            label: labelName,
            data: data,
            backgroundColor: colors,
            tension: 0.4,
            borderRadius: borderRadius,
            fill: {
              above: "rgb(255, 0, 0)", // Red above origin
              below: "rgb(0, 0, 255)", // Blue below origin
            },
          },
        ],
      };
    }

    // Initialize the chart
    chartInstance = new Chart(ctx, {
      type: chartType || "pie",
      data: chartData,
      options: {
        responsive: true,
        aspectRatio: 2,
        animation: {
          onComplete: () => {
            delayed = true;
          },
          delay: (context) => {
            let delay = 0;
            if (
              context.type === "data" &&
              context.mode === "default" &&
              !delayed
            ) {
              delay = context.dataIndex * 100 + context.datasetIndex * 100;
            }
            return delay;
          },
        },
        scales:
          chartType === "bar" && officeHourLimit !== null
            ? {
                x: { stacked: true },
                y: { stacked: true, beginAtZero: true },
              }
            : {},
        plugins: {
          tooltip: {
            usePointStyle: true,
            // callbacks: {
            //   label: function (context) {
            //     console.log(context, "o0o0o0");
            //     return "sjkdfhsd";
            //   },
            //   labelPointStyle: function (context) {
            //     return {
            //       rotation: 320,
            //     };
            //   },
            // },
          },
          legend: {
            display: true,
            labels: { usePointStyle: true },
            position: position,
          },
        },
      },
    });

    // Cleanup chart instance on unmount
    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [data, chartType, labels, labelName, officeHourLimit]);

  return <canvas className={className} ref={chartRef}></canvas>;
};

export default CustomChart;

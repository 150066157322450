import React, { useContext, useEffect, useState } from "react";
import ProjectHeading from "./Heading";
import {
  dateFormatter,
  getDateDifferenceStatus,
} from "../../helper/formatDate";
import {
  CustomAssignees,
  CustomButton,
  CustomPriorityButton,
  CustomStatusButton,
} from "../common";
import ProjectNotes from "./ProjectNotes";
import { useNavigate } from "react-router-dom";
import { Roles } from "../../constant/Constant";
import { useDispatch, useSelector } from "react-redux";
import { CustomAlertContext } from "../../context";
import { projectEndPoints } from "../../networking/Endpoints";
import { apiConnector } from "../../networking/ApiConnector";
import { setLoading } from "../../redux/slice/loadingSlice";
import { CheckStatus } from "../../helper/CheckStatus";
import { setActiveTabInProject } from "../../redux/slice/projectSlice";

const ProjectDetails = ({
  project,
  projctDetailName,
  setProjctDetailName,
  fetchProjectDetails,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token, user } = useSelector((state) => state.auth);
  const { setToastNotification } = useContext(CustomAlertContext);
  const [projectImportantDetails, setProjectImportantDetails] = useState(null);
  const fetchProjectImportantDetails = async () => {
    dispatch(setLoading(true));
    try {
      const project_details_response = await apiConnector(
        "GET",
        `${projectEndPoints?.PROJECT_DETAILS_API}/${project?._id}`,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setProjectImportantDetails(project_details_response?.data?.projectDetail);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };
  useEffect(() => {
    if (project) {
      fetchProjectImportantDetails();
    }
  }, [project]);
  console.log(project, "project");
  return (
    <div className="w-full h-full p-4 flex flex-col lg:flex-row justify-center gap-4">
      <div className="w-full md:space-y-3 flex flex-col">
        <div className="relative font-semibold bg-gradient-custom-light-blue rounded-lg p-2 border">
          <h1 className="w-[88%] text-sm md:text-base text-justify">
            {project?.project_name}
          </h1>
          {(user?.roleId === Roles?.admin ||
            (user?.roleId === Roles?.project_manager &&
              user?._id === project?.project_manager?._id)) && (
            <CustomButton
              title={"Edit Project"}
              buttonType={"button"}
              onClick={() => {
                navigate("/project/create", {
                  state: {
                    _id: project?._id ?? "",
                    project_name: project?.project_name ?? "",
                    team_lead:
                      project?.team_lead
                        ?.filter((member) => member?.isActive === true)
                        ?.map((team_lead) => ({
                          name: team_lead?.user?.user_name,
                          value: team_lead?.user?._id,
                        })) ?? [],
                    project_manager: project?.project_manager ?? "",
                    project_start_date:
                      project?.project_start_date?.split("T")[0] ?? "",
                    project_deadline:
                      project?.project_deadline?.split("T")[0] ?? "",
                    department: project?.department ?? "",
                    members:
                      project?.members
                        ?.filter((member) => member?.isActive === true)
                        ?.map((member) => ({
                          name: member?.user?.user_name,
                          value: member?.user?._id,
                        })) ?? [],
                    projectStatus: project?.projectStatus ?? "",
                    priority: project?.priority ?? "",
                    project_description: project?.project_description ?? "",
                  },
                });
              }}
              classname="absolute right-0 top-1 underline text-[#2E6790] px-5 py-1 rounded-md"
            />
          )}
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          <ProjectHeading label="Project ID" value={project?.project_id} />
          <ProjectHeading
            label="Project Manager"
            value={project?.project_manager?.user_name}
          />
          <ProjectHeading
            label="Department"
            value={project?.department?.department_name}
          />
          <ProjectHeading
            label="Start Date"
            value={
              project?.project_start_date
                ? dateFormatter(project?.project_start_date, {
                    format: "MMM DD, YYYY",
                    includeTimeForToday: false,
                  })
                : "--"
            }
          />
          <ProjectHeading
            label="Deadline(Tentative)"
            value={
              project?.project_deadline ? (
                <>
                  {dateFormatter(project?.project_deadline, {
                    format: "MMM DD, YYYY",
                    includeTimeForToday: false,
                  })}{" "}
                  (
                  {getDateDifferenceStatus(
                    project?.project_deadline,
                    project?.projectStatus === "Completed"
                      ? project?.completed_on
                      : null
                  )}
                  )
                </>
              ) : (
                "--"
              )
            }
          />
          <ProjectHeading
            label="Status"
            value={
              <CustomStatusButton selectedStatus={project?.projectStatus} />
            }
          />
          <ProjectHeading
            label="Priority"
            value={<CustomPriorityButton priority={project?.priority} />}
          />
          <ProjectHeading
            label="Assignees"
            value={
              <CustomAssignees
                assignees={project?.members
                  ?.filter((member) => member?.isActive === true)
                  ?.map((member) => member?.user)}
              />
            }
          />
        </div>
        <div className="w-full overflow-auto">
          <ProjectHeading
            label="Description"
            value={project?.project_description}
          />
        </div>
        <div className="w-full h-[1px] bg-slate-200" />
        {/* <div>
          <div className="flex justify-between items-center gap-2">
            <h2 className="font-semibold">Project Links & Status</h2>
            {projectImportantDetails && (
              <span
                className="hover:text-custom-blue underline text-sm font-semibold cursor-pointer"
                onClick={() => {
                  setProjctDetailName("Edit Project Sheet");
                  dispatch(setActiveTabInProject("project_sheet"));
                }}
              >
                Edit Details
              </span>
            )}
          </div>
          <div>
            <section className="w-full space-y-2 mt-5">
              <div className="text-lg font-semibold text-custom-black">
                1. Documentation
              </div>
              {projectImportantDetails ? (
                <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 sm:gap-5">
                  <ProjectHeading
                    label="Business Requirement Details"
                    value={
                      <p
                        className={`text-${CheckStatus(
                          projectImportantDetails?.business_requirement_details_status
                        )}`}
                      >
                        {
                          projectImportantDetails?.business_requirement_details_status
                        }
                      </p>
                    }
                  />
                  <ProjectHeading
                    link={true}
                    label="Link"
                    value={
                      projectImportantDetails?.business_requirement_details_link
                    }
                  />
                </div>
              ) : (
                "No Data Available"
              )}
              {projectImportantDetails && (
                <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 sm:gap-5">
                  <ProjectHeading
                    label="Business Logic Details"
                    value={
                      <p
                        className={`text-${CheckStatus(
                          projectImportantDetails?.business_logic_development_status
                        )}`}
                      >
                        {
                          projectImportantDetails?.business_logic_development_status
                        }
                      </p>
                    }
                  />
                  <ProjectHeading
                    link={true}
                    label="Link"
                    value={
                      projectImportantDetails?.business_logic_development_link
                    }
                  />
                </div>
              )}
            </section>
            <section className="w-full space-y-2 mt-5">
              <div className="text-lg font-semibold text-custom-black">
                2. Design
              </div>
              {projectImportantDetails?.designing?.length
                ? projectImportantDetails?.designing?.map((item, index) => (
                    <div
                      key={item?._id}
                      className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 sm:gap-5"
                    >
                      <ProjectHeading
                        label={item?.title}
                        value={item?.design_tech}
                      />
                      <ProjectHeading
                        label="Status"
                        value={
                          <p
                            className={`text-${CheckStatus(
                              item?.design_status
                            )}`}
                          >
                            {item?.design_status}
                          </p>
                        }
                      />
                      <ProjectHeading
                        link={true}
                        label="Link"
                        value={item?.design_link}
                      />
                    </div>
                  ))
                : "No Data Available"}
            </section>
            <section className="w-full space-y-2 mt-5">
              <div className="text-lg font-semibold text-custom-black">
                3. Development
              </div>
              {projectImportantDetails?.development?.length
                ? projectImportantDetails?.development?.map((item, index) => (
                    <div key={item?._id} className="w-full">
                      <li className="font-semibold text-custom-blue">
                        {item?.title}
                      </li>
                      <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 sm:gap-5">
                        <ProjectHeading
                          label="Frontend"
                          value={item?.details?.frontend?.tech}
                        />
                        <ProjectHeading
                          label="Status"
                          value={
                            <p
                              className={`text-${CheckStatus(
                                item?.details?.frontend?.status
                              )}`}
                            >
                              {item?.details?.frontend?.status}
                            </p>
                          }
                        />
                        <ProjectHeading
                          link={true}
                          label="Link"
                          value={item?.details?.frontend?.link}
                        />
                      </div>
                      <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 sm:gap-5">
                        <ProjectHeading
                          label="Backend"
                          value={item?.details?.backend?.tech}
                        />
                        <ProjectHeading
                          label="Status"
                          value={
                            <p
                              className={`text-${CheckStatus(
                                item?.details?.backend?.status
                              )}`}
                            >
                              {item?.details?.backend?.status}
                            </p>
                          }
                        />
                        <ProjectHeading
                          link={true}
                          label="Link"
                          value={item?.details?.backend?.link}
                        />
                      </div>
                      <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 sm:gap-5">
                        <ProjectHeading
                          label="Mobile App"
                          value={item?.details?.mobile?.tech}
                        />
                        <ProjectHeading
                          label="Status"
                          value={
                            <p
                              className={`text-${CheckStatus(
                                item?.details?.mobile?.status
                              )}`}
                            >
                              {item?.details?.mobile?.status}
                            </p>
                          }
                        />
                        <ProjectHeading
                          link={true}
                          label="Link"
                          value={item?.details?.mobile?.link}
                        />
                      </div>
                    </div>
                  ))
                : "No Data Available"}
            </section>
            <section className="w-full space-y-2 mt-5">
              <div className="text-lg font-semibold text-custom-black">
                4. Deployment
              </div>
              {projectImportantDetails?.deployement?.length
                ? projectImportantDetails?.deployement?.map((item, index) => (
                    <div key={item?._id} className="w-full">
                      <li className="font-semibold text-custom-blue">
                        {item?.title}
                      </li>
                      <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 sm:gap-5">
                        <ProjectHeading
                          label="Frontend"
                          value={item?.details?.frontend?.tech}
                        />
                        <ProjectHeading
                          label="Status"
                          value={
                            <p
                              className={`text-${CheckStatus(
                                item?.details?.frontend?.status
                              )}`}
                            >
                              {item?.details?.frontend?.status}
                            </p>
                          }
                        />
                        <ProjectHeading
                          link={true}
                          label="Link"
                          value={item?.details?.frontend?.link}
                        />
                      </div>
                      <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 sm:gap-5">
                        <ProjectHeading
                          label="Backend"
                          value={item?.details?.backend?.tech}
                        />
                        <ProjectHeading
                          label="Status"
                          value={
                            <p
                              className={`text-${CheckStatus(
                                item?.details?.backend?.status
                              )}`}
                            >
                              {item?.details?.backend?.status}
                            </p>
                          }
                        />
                        <ProjectHeading
                          link={true}
                          label="Link"
                          value={item?.details?.backend?.link}
                        />
                      </div>
                      <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 sm:gap-5">
                        <ProjectHeading
                          label="Mobile App"
                          value={item?.details?.mobile?.tech}
                        />
                        <ProjectHeading
                          label="Status"
                          value={
                            <p
                              className={`text-${CheckStatus(
                                item?.details?.mobile?.status
                              )}`}
                            >
                              {item?.details?.mobile?.status}
                            </p>
                          }
                        />
                        <ProjectHeading
                          link={true}
                          label="Link"
                          value={item?.details?.mobile?.link}
                        />
                      </div>
                    </div>
                  ))
                : "No Data Available"}
            </section>
          </div>
        </div> */}
      </div>
      {/* <div className="w-full lg:w-1/3 h-auto bg-[#FEF8DC] rounded-lg flex-shrink-0">
        <ProjectNotes
          project={project}
          getProjectDetails={fetchProjectDetails}
        />
      </div> */}
    </div>
  );
};

export default ProjectDetails;

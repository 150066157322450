import React, { useEffect } from "react";
import { dateFormatter } from "../../../helper/formatDate";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
const Base_url = process.env.REACT_APP_BASE_URL;

const DashboardMeetingCard = ({ meetingsData }) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const getBackgroundColor = (dateTimeUTC, duration) => {
    const currentTime = dayjs(); // Use local time if your app shows local time
    const itemTime = dayjs(dateTimeUTC);

    const [hours, minutes] = duration.split(":").map(Number);
    const startYellowTime = itemTime.subtract(10, "minute");
    const endGreenTime = itemTime.add(hours, "hour").add(minutes, "minute");

    if (
      currentTime.isAfter(startYellowTime) &&
      currentTime.isBefore(itemTime)
    ) {
      return "bg-[#F1E78D]"; // 10 min before start
    }
    if (
      currentTime.isSameOrAfter(itemTime) &&
      currentTime.isBefore(endGreenTime)
    ) {
      return "bg-[#C4F2AE]"; // During the meeting
    }
    if (currentTime.isAfter(endGreenTime)) {
      return "bg-[#FFBDBD]"; // After the meeting
    }
    return "bg-[#FFFFFF]";
  };
  return (
    <div className="w-full h-72 overflow-auto bg-[#F2F6F8] px-4">
      {meetingsData?.length
        ? meetingsData?.map((item) => (
            <div
              key={item?._id}
              onClick={() => navigate("/calender")}
              className={` w-full p-2 space-y-2  rounded-md mb-2 cursor-pointer ${getBackgroundColor(
                item?.date_time,
                item?.meeting_duration || "00:00"
              )}`}
            >
              <div className="flex justify-between items-center text-xs font-semibold">
                <div className="text-sm font-semibold line-clamp-1">
                  {item?.title ?? "--"}
                </div>
                {/* <div className="flex justify-center items-center gap-2 ">
                  <div className="w-6 h-6 md:w-8 md:h-8 border-slate-300 rounded-full bg-custom-white">
                    <img
                      src={
                        item?.meeting_organizer?.avatar
                          ? `${Base_url}/${item?.meeting_organizer?.avatar}`
                          : `https://api.dicebear.com/5.x/initials/svg?seed=${
                              item?.meeting_organizer?.user_name ?? "--"
                            }`
                      }
                      alt={`${item?.meeting_organizer?.user_name}`}
                      className="w-full h-full rounded-full p-1"
                    />
                  </div>
                  <span className="text-xs font-semibold">
                    {item?.meeting_organizer?._id === user?._id ? 'You':item?.meeting_organizer?.user_name}
                  </span>
                </div> */}
              </div>
              <div className="flex justify-between items-center text-xs font-semibold">
                <span>
                  {dateFormatter(item?.date_time, {
                    format: "MMM DD,YYYY HH:MM:A",
                    includeTimeForToday: true,
                  })}
                </span>
                <span>
                  Duration - {item?.meeting_duration}{" "}
                  {item?.meeting_duration?.split(":")[0] >= 1 ? "Hrs" : "Min"}
                </span>
              </div>
            </div>
          ))
        : "No Upcoming Meetings"}
    </div>
  );
};

export default DashboardMeetingCard;

import React, { memo } from "react";
import { Handle, Position } from "@xyflow/react";
import { CustomModal } from "../common";
import { useSelector } from "react-redux";
const BASE_URL = process.env.REACT_APP_BASE_URL;

function CustomNode({ data }) {
  const { user } = useSelector((state) => state.auth);
  return (
    <div
      className={`max-w-72 max-h-24 px-4 py-2 shadow-md rounded-xl ${
        data?.id === user?._id
          ? "bg-custom-blue text-custom-white"
          : "bg-white border-stone-400"
      } border-2  group `}
    >
      <div className="flex">
        <div className="rounded-full w-12 h-12 flex justify-center items-center bg-gray-100">
          <img
            src={
              data?.image
                ? `${BASE_URL}/${data?.image}`
                : `https://api.dicebear.com/5.x/initials/svg?seed=${data?.label}`
            }
            alt={data?.label?.[0]}
            className="w-8 md:w-11 h-8 md:h-11 rounded-full"
          />
        </div>
        <div className="ml-2">
          <div className="ellipsis text-lg font-bold">
            {data?.label ?? "--"}(L{data?.level})
          </div>
          <div
            className={`ellipsis ${
              data?.id === user?._id ? "text-custom-white" : "text-gray-500"
            }`}
          >
            {data?.designation?.designation ?? "--"}
          </div>
        </div>
      </div>
      <div className="min-w-max absolute left-0 -bottom-[70px] invisible group-hover:visible  hidden group-hover:flex bg-custom-white border rounded-md p-2">
        <div className="rounded-full w-12 h-12 flex justify-center items-center bg-gray-100">
          <img
            src={
              data?.image
                ? `${BASE_URL}/${data?.image}`
                : `https://api.dicebear.com/5.x/initials/svg?seed=${data?.label}`
            }
            alt={data?.label?.[0]}
            className="w-8 md:w-11 h-8 md:h-11 rounded-full"
          />
        </div>
        <div className="min-w-max ml-2">
          <div className="text-lg font-bold capitalize">
            {data?.label ?? "--"}(L{data?.level})
          </div>
          <div className="min-w-max text-gray-500">
            {data?.designation?.designation ?? "--"}
          </div>
        </div>
      </div>
      <Handle
        type="target"
        position={Position.Top}
        className="w-16 !bg-teal-500"
      />
      <Handle
        type="source"
        position={Position.Bottom}
        className="w-16 !bg-teal-500"
      />
    </div>
  );
}

export default memo(CustomNode);

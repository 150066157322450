import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import CommonTooltip from "./CommonTooltip";
import { BsInfoCircle } from "react-icons/bs";
import CustomButton from "./CustomButton";
import { tooltip } from "../../constant/TooltipContent";

export default function ChipInput({
  name = "",
  label = "",
  inputType = "",
  control,
  required = false,
  setValue = () => {},
  error,
  placeholder = "",
  labelClassName = "block text-custom-black",
  classname = "",
  isEditable = false,
  showInfoButton = true,
  max = "",
  min = "",
  icon = "",
}) {
  const [requirement, setRequirement] = useState("");
  const [requirementsList, setRequirementsList] = useState([]);
  const tooltip_content = tooltip[name];

  useEffect(() => {
    // Load initial value for edit case
    control._defaultValues[name] &&
      setRequirementsList(control._defaultValues[name]);
  }, [name, control]);

  useEffect(() => {
    setValue(name, requirementsList);
  }, [requirementsList, name, setValue]);

  const handleAddRequirement = () => {
    if (requirement.trim()) {
      setRequirementsList((prev) => [...prev, requirement.trim()]);
      setRequirement("");
    }
  };

  const handleRemoveRequirement = (index) => {
    setRequirementsList((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <div className="w-full flex flex-col space-y-2">
      <label
        htmlFor={name}
        className={`flex items-center gap-2 font-medium text-sm text-slate-600 ${labelClassName}`}
      >
        <span>
          {label}
          {required && <span className="text-red-500"> *</span>}
        </span>
        {showInfoButton && (
          <CommonTooltip tooltip={tooltip_content}>
            <BsInfoCircle />
          </CommonTooltip>
        )}
      </label>

      <div className="w-full">
        <Controller
          control={control}
          name={name}
          render={() => (
            <input
              tabIndex={0}
              name={name}
              placeholder={placeholder}
              type={inputType}
              value={requirement}
              onChange={(e) => setRequirement(e?.target?.value)}
              readOnly={isEditable}
              max={max}
              min={min}
              className={`border px-3 py-2 mt-2 pr-5 text-sm outline-none w-full rounded-md focus:border-custom-blue ${
                isEditable ? "bg-gray-100 cursor-not-allowed" : ""
              } ${classname}`}
            />
          )}
        />
        <CustomButton
          title="Add"
          buttonType="button"
          onClick={handleAddRequirement}
          classname="font-semibold text-sm text-yellow-500 flex justify-end items-center"
        />
        {requirementsList?.length > 0 && (
          <div className="w-full mt-2">
            {requirementsList?.map((item, index) => (
              <span
                key={item + index}
                className="relative p-1 text-custom-blue bg-custom-light-blue  rounded-full text-xs font-semibold mr-2 mt-2"
              >
                <span>{item}</span>
                <button
                  type="button"
                  className="text-xs text-custom-status-cancelled absolute right-0 -top-2"
                  onClick={() => handleRemoveRequirement(index)}
                >
                  x
                </button>
              </span>
            ))}
          </div>
        )}
        {error && <p className="text-sm text-red-500">{error}</p>}
      </div>
    </div>
  );
}

import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Calendar, dayjsLocalizer } from "react-big-calendar";
import dayjs from "dayjs";
import { MdOutlineEdit } from "react-icons/md";

import "react-big-calendar/lib/css/react-big-calendar.css";
import { CustomButton, CustomInput, CustomModal } from "../common";
import { FaPlusCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { CustomAlertContext } from "../../context";
import { setLoading } from "../../redux/slice/loadingSlice";
import { apiConnector } from "../../networking/ApiConnector";
import {
  attendanceEndPoints,
  meetingEndPoints,
  reminderEndPoints,
} from "../../networking/Endpoints";
import MeetingForm from "./MeetingForm";
import MeetingView from "./MeetingView";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { dateFormatter } from "../../helper/formatDate";
import ProjectHeading from "../project/Heading";
import RemarkMeeting from "../meeting/RemarkMeeting";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const RejectMeetingValidation = yup.object().shape({
  reason: yup.string().required("Reason is required").trim(),
});

const reminderValidation = yup.object().shape({
  title: yup.string().required("Title is required").trim(),
  date_time: yup.string().required("Date and Time is required").trim(),
});

const CalendarFile = () => {
  const { token, user } = useSelector((state) => state.auth),
    { loading } = useSelector((state) => state.loading),
    dispatch = useDispatch(),
    { setToastNotification } = useContext(CustomAlertContext),
    [meetingsData, setMeetingsData] = useState([]),
    [attendanceData, setAttendanceData] = useState([]),
    [reminderData, setReminderData] = useState([]),
    localizer = dayjsLocalizer(dayjs),
    meetingFormRef = useRef(),
    meetingViewRef = useRef(),
    [showMeetingForm, setShowMeetingForm] = useState(false),
    [reminder, setReminder] = useState(false),
    [showMeetingView, setShowMeetingView] = useState(false),
    [reminderView, setReminderView] = useState(false),
    [reminderDetails, setReminderDetails] = useState(null),
    [showRejectModal, setShowRejectModal] = useState(false),
    [showRemarkModal, setShowRemarkModal] = useState(false),
    [meetingDetails, setMeetingDetails] = useState(null),
    [isEditable, setIsEditable] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(dayjs().format("MM"));
  const [currentYear, setCurrentYear] = useState(dayjs().format("YYYY"));
  const [currentDate, setCurrentDate] = useState("");
  const [selectedView, setSelectedView] = useState("month");
  const [date, setDate] = useState(new Date());
  const [view, setView] = useState("month");

  const onNavigate = (date) => {
    const current_month = dayjs(date).format("MM");
    const current_year = dayjs(date).format("YYYY");
    setDate(date);
    setCurrentMonth(current_month);
    setCurrentYear(current_year);
  };
  const onView = useCallback((newView) => setView(newView), [setView]);

  // const reminderFormDefaultValues = {
  //   title: reminder ? reminderDetails?.title : undefined,
  //   date_time: reminder ? reminderDetails?.date_time.split(".")[0] : undefined,
  //   description: reminder ? reminderDetails?.description : undefined,
  // };

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      reminder ? reminderValidation : RejectMeetingValidation
    ),
    // defaultValues: reminder ? reminderFormDefaultValues : {}
  });

  useEffect(() => {
    setValue("title", reminderDetails?.title);
    setValue("date_time", reminderDetails?.date_time.split(".")[0]);
    setValue("description", reminderDetails?.description);
  }, [reminderDetails]);

  const handleSelect = ({ start, end }) => {
    const title = window.prompt("New Event name");
  };
  const GetAllMeetings = async () => {
    dispatch(setLoading(true));
    try {
      const meeting_response = await apiConnector(
        "GET",
        meetingEndPoints?.MEETING_API,
        null,
        {
          Authorization: `Bearer ${token}`,
        },
        {
          month: currentMonth,
          year: currentYear,
        }
      );
      setMeetingsData(meeting_response?.data?.meetings);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };
  const GetAttendance = async () => {
    dispatch(setLoading(true));
    try {
      const working_response = await apiConnector(
        "GET",
        attendanceEndPoints?.WORKING_HOURS_API,
        null,
        {
          Authorization: `Bearer ${token}`,
        },
        {
          month: currentMonth,
          year: currentYear,
        }
      );
      setAttendanceData(working_response?.data?.attendanceData);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };
  const GetReminder = async () => {
    dispatch(setLoading(true));
    try {
      const reminder_response = await apiConnector(
        "GET",
        reminderEndPoints?.REMINDER_API,
        null,
        {
          Authorization: `Bearer ${token}`,
        },
        {
          month: currentMonth,
          year: currentYear,
        }
      );

      setReminderData(reminder_response?.data?.reminders);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    GetAllMeetings();
    GetAttendance();
    GetReminder();
  }, [token, currentMonth, currentYear]);

  const MeetingStatusHandler = async (payload) => {
    dispatch(setLoading(true));
    try {
      const meeting_response = await apiConnector(
        "PUT",
        `${meetingEndPoints?.MEETING_STATUS_UPDATE_API}/${meetingDetails?._id}`,
        payload,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setToastNotification(meeting_response?.data?.message, "success");
      GetAllMeetings();
      if (meetingDetails) {
        setShowMeetingView(false);
      }
      setShowRejectModal(false);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };
  const meetingRejectHandler = async (data) => {
    let payload = {
      invitation: "rejected",
      reason: data?.reason,
    };
    MeetingStatusHandler(payload);
  };

  const attendanceEvents = attendanceData?.map((item) => {
    const attendance_date_time = dayjs(item?.date);

    return {
      title: item?.loggedHoures + " Total Working Hours" || "",
      start: new Date(
        attendance_date_time.year(),
        attendance_date_time.month(),
        attendance_date_time.date(),
        0,
        0,
        0
      ),
      end: new Date(
        attendance_date_time.year(),
        attendance_date_time.month(),
        attendance_date_time.date(),
        0,
        0,
        0
      ),
      attendanceEvent: null,
      attendance: false,
    };
  });
  const meetingEvents = meetingsData?.map((item) => {
    const meeting_date_time = dayjs(item?.date_time);

    // Split meeting_duration into hours and minutes
    const [durationHours, durationMinutes] = item?.meeting_duration
      ?.split(":")
      .map(Number) || [0, 0];

    return {
      title: item?.title || "Meeting",
      start: new Date(
        meeting_date_time.year(),
        meeting_date_time.month(),
        meeting_date_time.date(),
        meeting_date_time.hour(),
        meeting_date_time.minute(),
        meeting_date_time.second()
      ),
      end: new Date(
        meeting_date_time.year(),
        meeting_date_time.month(),
        meeting_date_time.date(),
        meeting_date_time.hour() + durationHours,
        meeting_date_time.minute() + durationMinutes,
        meeting_date_time.second()
      ),
      meetingEvent: item,
      meeting: true,
    };
  });
  const reminderEvents = reminderData?.map((item) => {
    const reminder_date_time = dayjs(item?.date_time);

    return {
      title: item?.title || "Reminder",
      start: new Date(
        reminder_date_time.year(),
        reminder_date_time.month(),
        reminder_date_time.date(),
        reminder_date_time.hour(),
        reminder_date_time.minute(),
        reminder_date_time.second()
      ),
      end: new Date(
        reminder_date_time.year(),
        reminder_date_time.month(),
        reminder_date_time.date(),
        reminder_date_time.hour(),
        reminder_date_time.minute(),
        reminder_date_time.second()
      ),
      reminderEvent: item,
      reminder: true,
    };
  });
  const calenderEvent = [
    ...meetingEvents,
    ...attendanceEvents,
    ...reminderEvents,
  ];

  const reminderHandler = async (data) => {
    let payload = isEditable
      ? {
          title:
            meetingDetails?.title !== data?.title ? data?.title : undefined,

          description:
            meetingDetails?.description !== data?.description
              ? data?.description
              : undefined,
          date_time:
            meetingDetails?.date_time?.split(".")[0] !== data?.date_time
              ? data?.date_time
              : undefined,
        }
      : {
          title: data?.title,

          description: data?.description,
          date_time: data?.date_time,
        };
    dispatch(setLoading(true));
    try {
      const apiMethod = isEditable ? "PUT" : "POST";
      const apiUrl = isEditable
        ? `${reminderEndPoints?.REMINDER_API}/${reminderDetails?._id}`
        : reminderEndPoints?.REMINDER_API;

      const meeting_response = await apiConnector(apiMethod, apiUrl, payload, {
        Authorization: `Bearer ${token}`,
      });
      setToastNotification(meeting_response?.data?.message, "success");
      reset();
      GetReminder();

      setReminder(false);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };
  return (
    <div className="w-full bg-custom-white p-4">
      <section className="flex justify-between items-center mb-2">
        <h2 className="font-semibold">Calender</h2>
        <div>
          <CustomButton
            title={
              <div className="flex justify-center items-center gap-2 ">
                <FaPlusCircle />
                <span>Reminder</span>
              </div>
            }
            buttonType="button"
            onClick={() => {
              setReminder(true);
            }}
            classname="bg-gradient-custom text-custom-white font-semibold text-sm px-5 py-2 rounded-md mr-2"
          />
          <CustomButton
            title={
              <div className="flex justify-center items-center gap-2 ">
                <FaPlusCircle />
                <span>Request Meeting</span>
              </div>
            }
            buttonType="button"
            onClick={() => {
              setMeetingDetails(null);
              // setShowMeetingView(false);
              setShowMeetingForm((prev) => !prev);
            }}
            classname="bg-gradient-custom text-custom-white font-semibold text-sm px-5 py-2 rounded-md"
          />
        </div>
      </section>
      <div className="myCustomHeight">
        <Calendar
          localizer={localizer}
          events={calenderEvent ?? []}
          style={{ height: "75vh" }}
          startAccessor="start"
          endAccessor="end"
          onSelectEvent={(event) => {
            if (event?.meeting) {
              setMeetingDetails(event?.meetingEvent);
              setShowMeetingView((prev) => !prev);
            } else if (event?.reminder) {
              setReminderDetails(event?.reminderEvent);
              setReminderView((prev) => !prev);
            }
          }}
          onSelectSlot={handleSelect}
          onNavigate={onNavigate}
          onView={onView}
          view={view}
          dayLayoutAlgorithm={"no-overlap"}
          eventPropGetter={(event) => {
            const customStyle = {
              backgroundColor: event.meetingEvent
                ? event.meetingEvent.participants?.some(
                    (item) =>
                      item?.user?._id === user?._id &&
                      item?.is_invitation_accepted === true
                  )
                  ? "#28a745"
                  : event.meetingEvent.participants?.some(
                      (item) =>
                        item?.user?._id === user?._id &&
                        item?.is_invitation_accepted === false
                    )
                  ? "#FF0613"
                  : "#2780C1"
                : event.reminderEvent
                ? "#FA892F"
                : "#39C1F9",
              color: "#fff",
              borderRadius: "5px",
              border: "none",
              fontSize: "14px",
              fontWeight: "500",
              padding: "5px",
            };
            return { style: customStyle };
          }}
          // components={{
          //   event: ({ event }) => (
          //     <div>
          //       <strong className="text-sm font-semibold">{event.title}</strong>
          //       <div className="text-xs font-semibold">
          //         Date & Time -{" "}
          //         {dateFormatter(event.meetingEvent?.date_time, {
          //           format: "MMM DD,YYYY HH:MM:A",
          //           includeTimeForToday: true,
          //         })}
          //       </div>
          //       <div className="text-xs font-semibold">
          //         Duration - {event.meetingEvent?.meeting_duration}{" "}
          //         {event.meetingEvent?.meeting_duration?.split(":")[0] >= 1
          //           ? "Hrs"
          //           : "Min"}
          //       </div>
          //     </div>
          //   ),
          // }}
        />
      </div>
      <CustomModal
        isOpen={showMeetingView}
        onClose={() => setShowMeetingView(false)}
        ref={meetingViewRef}
        classname={`w-11/12 lg:w-2/3`}
      >
        <MeetingView
          setShowMeetingForm={setShowMeetingForm}
          meetingDetails={meetingDetails}
          setShowMeetingView={setShowMeetingView}
          MeetingStatusHandler={MeetingStatusHandler}
          setMeetingDetails={setMeetingDetails}
          setShowRejectModal={setShowRejectModal}
          setShowRemarkModal={setShowRemarkModal}
        />
      </CustomModal>
      <CustomModal
        isOpen={reminderView}
        onClose={() => setReminderView(false)}
        ref={meetingViewRef}
        classname={`w-11/12 lg:w-2/3`}
      >
        <div className="w-full">
          <div className="w-full flex justify-between items-center pb-4 border-b border-slate-200">
            <h1 className="text-lg font-semibold">Reminder</h1>

            <div
              onClick={() => {
                setReminder(true);
                setReminderView(false);
                setIsEditable(true);
              }}
              className="p-1 border border-custom-blue text-custom-blue hover:text-custom-white hover:bg-custom-blue  hover:border-custom-white rounded-md cursor-pointer"
            >
              <MdOutlineEdit size={16} />
            </div>
          </div>
          <div className="w-full grid grid-cols-1 md:grid-cols-2  gap-4 mt-4">
            <ProjectHeading
              label={"Title"}
              value={reminderDetails?.title ?? "--"}
            />

            <ProjectHeading
              label={"Date & Time"}
              value={dateFormatter(reminderDetails?.date_time, {
                format: "MMM DD,YYYY HH:MM:A",
                includeTimeForToday: true,
              })}
            />
            <ProjectHeading
              label={"Description"}
              value={reminderDetails?.description ?? "--"}
            />
          </div>
        </div>
      </CustomModal>
      <CustomModal
        isOpen={showMeetingForm}
        onClose={() => setShowMeetingForm(false)}
        ref={meetingFormRef}
        classname={`lg:w-2/3`}
      >
        <MeetingForm
          meetingDetails={meetingDetails ? meetingDetails : null}
          setShowMeetingForm={setShowMeetingForm}
          setShowMeetingView={setShowMeetingView}
          FetchAllMeetings={GetAllMeetings}
        />
      </CustomModal>
      <CustomModal
        isOpen={showRejectModal}
        onClose={() => setShowRejectModal(false)}
      >
        <div className="text-center text-custom-black">
          <h2 className="text-2xl font-bold my-4">Reason?</h2>
        </div>
        <form
          className="w-full "
          noValidate
          onSubmit={handleSubmit(meetingRejectHandler)}
        >
          <div className="my-2">
            <CustomInput
              name="reason"
              label="Reason"
              inputType="text-area"
              placeholder="Type Here..."
              control={control}
              error={errors?.reason?.message}
            />
          </div>
          <div className="flex justify-end items-center gap-5 mt-5">
            <CustomButton
              title={"Cancel"}
              onClick={() => setShowRejectModal(false)}
              buttonType={"button"}
              classname={"px-2 py-1 bg-custom-white border rounded-md "}
            />
            <CustomButton
              title={"Reject Meeting"}
              buttonType={"submit"}
              disabled={loading}
              classname={"bg-[#FB0F0F] text-custom-white px-2 py-1 rounded-md"}
            />
          </div>
        </form>
      </CustomModal>
      <CustomModal
        isOpen={reminder}
        onClose={() => setReminder(false)}
        ref={meetingFormRef}
        classname={`lg:w-2/3`}
      >
        <h2 className="text-xl text-center font-semibold text-custom-black pb-2">
          {isEditable ? "Edit Reminder" : "Add Reminder"}
        </h2>
        <form
          className="w-full mt-2"
          noValidate
          onSubmit={handleSubmit(reminderHandler)}
        >
          <div className="w-full grid grid-cols-1 md:grid-cols-2  gap-4">
            <div>
              <CustomInput
                name="title"
                label="Title"
                inputType="text"
                placeholder="Enter title"
                control={control}
                error={errors?.title?.message}
              />
            </div>
            <div>
              <CustomInput
                name="date_time"
                label="Date"
                inputType="datetime-local"
                placeholder="Enter date_time"
                control={control}
                error={errors?.date_time?.message}
              />
            </div>
            <div className="col-span-2">
              <CustomInput
                name="description"
                label="Description"
                inputType="text-area"
                placeholder="Enter description"
                control={control}
                error={errors?.description?.message}
              />
            </div>
          </div>
          <div className="flex justify-end items-center gap-5 mt-5">
            <CustomButton
              title={"Cancel"}
              onClick={
                () => {}
                //  setShowRejectModal(false)
              }
              buttonType={"button"}
              classname={"px-2 py-1 bg-custom-white border rounded-md "}
            />
            <CustomButton
              title={isEditable ? "Edit Reminder" : "Add Reminder"}
              buttonType={"submit"}
              disabled={loading}
              classname={
                "bg-custom-status-completed text-custom-white px-2 py-1 rounded-md"
              }
            />
          </div>
        </form>
      </CustomModal>

      {showRemarkModal && (
        <RemarkMeeting
          showRemarkModal={showRemarkModal}
          setShowRemarkModal={setShowRemarkModal}
          meetingDetails={meetingDetails}
          GetAllMeetings={GetAllMeetings}
        />
      )}
    </div>
  );
};

export default CalendarFile;

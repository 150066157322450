import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomAlertContext } from "../../../context";
import { setLoading } from "../../../redux/slice/loadingSlice";
import { apiConnector } from "../../../networking/ApiConnector";
import {
  holidayEndPoints,
  meetingEndPoints,
} from "../../../networking/Endpoints";
import {
  CustomAssignees,
  CustomButton,
  FilterSelect,
  NoDataFound,
  Pagination,
  PaginationText,
  TableHeader,
} from "../../common";
import * as yup from "yup";
import { MdOutlineEdit, MdOutlineFilterAlt } from "react-icons/md";
import { FaPlusCircle } from "react-icons/fa";
import { dateFormatter } from "../../../helper/formatDate";
import Skeleton from "react-loading-skeleton";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import {
  defaultMonth,
  defaultYear,
  getFullMonths,
  yearOptions,
} from "../../../constant/Constant";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const TableHeads = ["S.No", "Name", "Date", "Type", "Actions"];

const currentYear = new Date().getFullYear();
const currentMonth = getFullMonths[new Date().getMonth()]?.name;

const HolidayList = () => {
  const { token, user } = useSelector((state) => state.auth),
    { loading } = useSelector((state) => state.loading),
    dispatch = useDispatch(),
    navigate = useNavigate(),
    { setToastNotification } = useContext(CustomAlertContext);
  const [filterYear, setFilterYear] = useState(currentYear);
  const [filterMonth, setFilterMonth] = useState(currentMonth);
  const [holidayTableData, setHolidayTableData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(),
  });

  const GetAllHolidays = async () => {
    dispatch(setLoading(true));
    try {
      const holiday_response = await apiConnector(
        "GET",
        holidayEndPoints?.HOLIDAY_API,
        null,
        {
          Authorization: `Bearer ${token}`,
        },
        {
          year: filterYear,
          month: filterMonth,
        }
      );
      setHolidayTableData(holiday_response?.data?.holiday);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    GetAllHolidays();
  }, [token]);

  const pageCount = Math.ceil(16 / itemsPerPage);
  const handlePageClick = (event) => {
    setCurrentPage(event?.selected + 1);
  };

  return (
    <div className="w-full">
      <section className="flex md:flex-row flex-col gap-2 justify-between bg-custom-white items-center p-2 rounded-t-md">
        <h1 className="font-semibold text-lg">Holiday List</h1>
        <div className="flex md:flex-row flex-col justify-center items-center gap-4">
          <FilterSelect
            options={yearOptions}
            icon={false}
            defaultValue={defaultYear}
            onChange={(e) => {
              setFilterYear(e?.value);
            }}
          />
          <FilterSelect
            options={getFullMonths}
            icon={false}
            defaultValue={defaultMonth}
            onChange={(e) => {
              setFilterMonth(e?.value);
            }}
          />
          {/* <div className="relative">
          <IoMdSearch className="absolute z-10 top-3 left-1 text-slate-400" />
          <input
            name="searchString"
            type="text"
            placeholder="Search"
              onChange={(e) => filterCategory(e)}
            value={searchString}
            className="border px-6 py-2 font-semibold text-sm outline-none rounded-md"
          />
        </div> */}
          {/* <CustomButton
            title={
              <div className="flex justify-center items-center font-semibold">
                <MdOutlineFilterAlt size={20} />
                <span>Filters</span>
              </div>
            }
            buttonType="submit"
            classname="bg-custom-white text-slate-400 p-2 font-semibold text-sm rounded-md border "
          /> */}
          <CustomButton
            title={
              <div className="flex justify-center items-center gap-2 ">
                <FaPlusCircle />
                <span>Add Holiday</span>
              </div>
            }
            buttonType="button"
            onClick={() => navigate("/holiday-form")}
            classname="bg-gradient-custom text-custom-white font-semibold text-sm px-5 py-2 rounded-md"
          />
        </div>
      </section>
      <section className="w-full overflow-auto">
        <table className="w-full border bg-custom-white rounded-lg overflow-auto">
          <thead>
            <TableHeader TableHeads={TableHeads} />
          </thead>
          {loading ? (
            <tbody>
              <tr>
                <td
                  colSpan={TableHeads?.length}
                  className="text-center font-semibold text-sm p-3"
                >
                  <Skeleton
                    count={5}
                    width={"100%"}
                    height={"22px"}
                    className="my-2"
                  />
                </td>
              </tr>
            </tbody>
          ) : holidayTableData && holidayTableData?.length > 0 ? (
            <tbody>
              {holidayTableData?.map((item, index) => {
                return (
                  <tr
                    key={item?._id}
                    className="w-full border-b hover:bg-slate-100"
                  >
                    <td className="p-2 text-sm font-medium">
                      {(currentPage - 1) * itemsPerPage + index + 1}
                    </td>
                    <td className="p-2 text-sm cursor-pointer underline ellipsis">
                      {item?.holiday_name}
                    </td>
                    <td className="p-2 text-sm">
                      {item?.holiday_date
                        ? dateFormatter(item?.holiday_date, {
                            format: "MMM DD, YYYY",
                          })
                        : "--"}
                    </td>
                    <td className="p-2 text-sm">{item?.holiday_type}</td>

                    <td className="p-2 flex justify-center items-center ">
                      <div
                        onClick={() =>
                          navigate("/holiday-form", {
                            state: { holiday: item },
                          })
                        }
                        className={`p-1 border border-custom-blue rounded-md cursor-pointer`}
                      >
                        <MdOutlineEdit className="text-custom-blue" />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td
                  colSpan={TableHeads.length}
                  className="text-center font-semibold text-sm p-4"
                >
                  <NoDataFound />
                </td>
              </tr>
            </tbody>
          )}
          <tfoot className="relative">
            <tr>
              <td className="text-slate-400 text-sm px-2 py-4" colSpan={6}>
                <PaginationText
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  dataCount={16}
                  dataLength={holidayTableData?.length}
                />
              </td>
              <td className="absolute right-2 top-1.5">
                <Pagination
                  handlePageClick={handlePageClick}
                  pageRangeDisplayed={itemsPerPage}
                  pageCount={pageCount}
                  name={"pageSize"}
                  onChange={(e) => setItemsPerPage(e?.target?.value)}
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </section>
    </div>
  );
};

export default HolidayList;

import React, { useContext, useState } from "react";
import { MdOutlineEdit } from "react-icons/md";
import ProjectHeading from "../../project/Heading";
import { useDispatch, useSelector } from "react-redux";
import { CustomModal } from "../../common";
import { dateFormatter } from "../../../helper/formatDate";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const ExternalUserView = ({
  externalInviteDetails,
  showExternalInviteViewModal,
  setShowExternalInviteViewModal,
  showExternalInviteFormModal,
  setShowExternalInviteFormModal,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  return (
    <CustomModal
      isOpen={showExternalInviteViewModal}
      onClose={() => setShowExternalInviteViewModal(false)}
      classname="w-10/12 lg:w-11/12 xl:w-3/4"
    >
      <div className="w-full ">
        <div className="w-full flex justify-between items-center pb-4 border-b border-slate-200">
          <h1 className="text-lg font-semibold">External User View</h1>
          <div className="flex justify-center items-center gap-4">
            {externalInviteDetails?.created_by?._id === user?._id && (
              <span
                onClick={() => {}}
                className={`p-1 border border-custom-blue text-custom-blue hover:text-custom-white hover:bg-custom-blue  hover:border-custom-white rounded-md cursor-pointer`}
              >
                <MdOutlineEdit size={16} />
              </span>
            )}
          </div>
        </div>

        <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 mt-4 max-h-[80vh] overflow-auto">
          <ProjectHeading
            label="Name"
            value={externalInviteDetails?.user_name ?? "--"}
          />
          <ProjectHeading
            label="Email"
            value={externalInviteDetails?.email ?? "--"}
          />
          <ProjectHeading
            label="Mobile Number"
            value={externalInviteDetails?.mobile_number ?? "--"}
          />
          <ProjectHeading
            label="Designation/Level of Seniority"
            value={externalInviteDetails?.designation ?? "--"}
          />
          <ProjectHeading
            link={true}
            label="LinkedIn Profile"
            value={externalInviteDetails?.linked_in_profile ?? "--"}
          />
          <ProjectHeading
            link={true}
            label="X Profile"
            value={externalInviteDetails?.x_profile ?? "--"}
          />
          <div className="col-span-1 md:col-span-2 xl:col-span-3 2xl:col-span-4">
            <ProjectHeading
              label="Biography"
              value={externalInviteDetails?.biography ?? "--"}
            />
          </div>
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 mt-4 max-h-[80vh] overflow-auto">
          <ProjectHeading
            label="Company Name"
            value={externalInviteDetails?.company_name ?? "--"}
          />
          <ProjectHeading
            link={true}
            label="Website URL"
            value={externalInviteDetails?.website_url ?? "--"}
          />

          <ProjectHeading
            label="Company Industry"
            value={externalInviteDetails?.company_industry ?? "--"}
          />
          <ProjectHeading
            label="Company CIN"
            value={externalInviteDetails?.CIN ?? "--"}
          />
          <ProjectHeading
            label="Company GSTN"
            value={externalInviteDetails?.GSTN ?? "--"}
          />
          <ProjectHeading
            label="Company PAN"
            value={externalInviteDetails?.PAN ?? "--"}
          />
          <ProjectHeading
            label="Company TAN"
            value={externalInviteDetails?.TAN ?? "--"}
          />
          <ProjectHeading
            label="Region of Interest"
            value={externalInviteDetails?.region_of_interest ?? "--"}
          />
          <ProjectHeading
            label="Sector"
            value={externalInviteDetails?.sector ?? "--"}
          />
          <ProjectHeading
            label="Stage Type"
            value={externalInviteDetails?.stage_type ?? "--"}
          />
          <ProjectHeading
            label="Type of Organization"
            value={externalInviteDetails?.type_of_organization ?? "--"}
          />
          <div className="col-span-1 md:col-span-2 xl:col-span-3 2xl:col-span-4">
            <ProjectHeading
              label="Company Detail"
              value={externalInviteDetails?.company_detail ?? "--"}
            />
          </div>
          <div className="col-span-1 md:col-span-2 xl:col-span-3 2xl:col-span-4">
            <ProjectHeading
              label="Company Address"
              value={externalInviteDetails?.company_address ?? "--"}
            />
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default ExternalUserView;

import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllProject } from "../../../redux/slice/projectSlice";
import { CustomAlertContext } from "../../../context";
import ProjectCard from "../../project/ProjectCard";
import { useNavigate } from "react-router-dom";
import { Roles } from "../../../constant/Constant";

const EmployeeProject = ({ employeeDetails = {} }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token, user } = useSelector((state) => state.auth);
  const { projectData } = useSelector((state) => state.project);
  const { setToastNotification } = useContext(CustomAlertContext);
  const fetchProjects = async () => {
    const queryParams = {};
    if (employeeDetails?._id) queryParams.members = employeeDetails?._id;
    try {
      await dispatch(
        getAllProject({
          token,
          queryParams,
        })
      ).unwrap();
    } catch (error) {
      setToastNotification(error?.message, "error");
    }
  };
  useEffect(() => {
    if (employeeDetails?._id) fetchProjects();
  }, [employeeDetails]);
  return (
    <div className="w-full mt-5 space-y-5 ">
      <h1 className="font-semibold">All Projects</h1>
      <div className="w-full h-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
        {projectData?.projects && projectData?.projects?.length > 0 ? (
          projectData?.projects?.map((project, index) => (
            <ProjectCard
              key={`project-${index}`}
              _id={project?._id}
              project_name={project?.project_name}
              project_manager={project?.project_manager}
              department={project?.department}
              project_id={project?.project_id}
              project_description={project?.project_description}
              start_date={project?.project_start_date}
              end_date={project?.project_deadline}
              assignees={project?.members}
              status={project?.projectStatus}
              onClick={() => {
                if (
                  user?.roleId === Roles?.admin ||
                  project?.project_manager?._id === user?._id ||
                  project?.team_lead?.some(
                    (item) =>
                      item?.user?._id === user?._id && item?.isActive === true
                  ) ||
                  project?.members?.some(
                    (item) =>
                      item?.user?._id === user?._id && item?.isActive === true
                  )
                )
                  navigate("/project/view", { state: { id: project?._id } });
              }}
              priority={project?.priority}
              progress={project?.project_progress}
              total_task={project?.total_task}
              completed_task={project?.completed_task}
              team_lead={project?.team_lead}
            />
          ))
        ) : (
          <div className="font-semibold text-sm">No Projects Available</div>
        )}
      </div>
    </div>
  );
};

export default EmployeeProject;

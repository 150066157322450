import React from "react";
import { useSelector } from "react-redux";
const Base_url = process.env.REACT_APP_BASE_URL;

const DashboardJoiningCard = ({ groupedJoinings }) => {
  const { user } = useSelector((state) => state.auth);

  return (
    <div className="rounded-md row-span-1 bg-[#F2F6F8]">
      <div className="w-full p-2 bg-[#BFCFD7] bg-opacity-20 rounded-t-md">
        <h2 className="font-semibold text-slate-700 ">
          Joining & Work Anniversary
        </h2>
      </div>
      <div className="w-full h-72 overflow-auto px-4">
        {Object?.entries(groupedJoinings)?.length > 0 ? (
          Object?.entries(groupedJoinings)?.map(([date, joinings]) => (
            <div key={date}>
              <h2 className="font-semibold text-xs flex items-center my-2">
                {date}
              </h2>
              {joinings?.map((item, index) => (
                <div
                  key={index}
                  className={`${
                    joinings?.length - 1 !== index &&
                    "border-b border-[#EFE9CF]"
                  }  w-full p-2 space-y-2 flex justify-between bg-custom-white rounded-md pr-2 my-2`}
                >
                  <div className="flex items-center gap-4">
                    <div className="w-10 h-10  rounded-full bg-custom-white">
                      <img
                        src={
                          item?.avatar
                            ? `${Base_url}/${item?.avatar}`
                            : `https://api.dicebear.com/5.x/initials/svg?seed=${item?.user_name}`
                        }
                        alt={`${item?.user_name}`}
                        className="w-full h-full rounded-full p-1"
                      />
                    </div>
                    <div className="flex flex-col text-left gap-1">
                      <span className="text-sm font-semibold">
                        {item?._id === user?._id ? "You" : item?.user_name}
                      </span>
                      <span className="text-xs text-slate-400 font-semibold">
                        {item?.designation
                          ? item?.designation?.designation
                          : "Admin"}
                      </span>
                    </div>
                  </div>
                  <span className="text-xs text-slate-400 font-semibold">
                    {item?.eventType ?? "--"}
                  </span>
                </div>
              ))}
            </div>
          ))
        ) : (
          <div className="text-center font-semibold text-sm ">
            No Data available
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardJoiningCard;

import React from "react";
import Select from "react-select";
import { Controller } from "react-hook-form";
import { BsInfoCircle } from "react-icons/bs";
import CommonTooltip from "./CommonTooltip";
import { tooltip } from "../../constant/TooltipContent";
import { Roles } from "../../constant/Constant";

const CustomSelect = ({
  name = "",
  label = "",
  control,
  required = false,
  error,
  placeholder = "Select...",
  labelClassName = "text-custom-black",
  classname = "",
  multiple = false,
  searchable = true,
  options,
  isEditable = false,
  showInfoButton = true,
  defaultValue,
  setDropDownId = () => {},
}) => {
  const formatOptionLabel = (option) => (
    <div className="flex justify-start items-center gap-2">
      {option?.avatar && (
        <img
          src={option?.avatar}
          alt={option?.name?.[0]}
          className="w-6 h-6 rounded-full p-0.5 bg-slate-300"
        />
      )}
      <div className="flex items-center gap-1 text-sm">
        <span>{option?.name}</span>
        {option?.designation && (
          <span className="text-gray-400">({option?.designation})</span>
        )}
        {option?.role && (
          <span className="text-gray-400">
            (
            {option?.role
              ? Object.keys(Roles).find((item) => Roles[item] === option?.role)
              : ""}
            )
          </span>
        )}
      </div>
    </div>
  );
  const tooltip_content = tooltip[name];
  return (
    <>
      <label
        htmlFor={name}
        className={`flex items-center gap-2 font-medium text-sm text-slate-600 ${labelClassName}`}
      >
        <span>
          {label}
          {required && <span className="text-red-500"> *</span>}
        </span>
        {showInfoButton && (
          <CommonTooltip tooltip={tooltip_content}>
            <BsInfoCircle />
          </CommonTooltip>
        )}
      </label>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, onBlur, value } }) => (
          <Select
            value={
              multiple
                ? options?.filter((option) => value?.includes(option.value))
                : options?.find((option) => option?.value === value) || null
            }
            onChange={(selected) => {
              if (multiple) {
                onChange(selected?.map((option) => option?.value));
              } else {
                setDropDownId(selected?.value);
                onChange(selected?.value);
              }
            }}
            options={options}
            isMulti={multiple}
            formatOptionLabel={formatOptionLabel}
            getOptionLabel={(option) => option?.name}
            placeholder={placeholder}
            isSearchable={searchable}
            hideSelectedOptions={false}
            closeMenuOnSelect={multiple ? false : true}
            isDisabled={isEditable}
            onBlur={onBlur}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                text: "#000000",
                primary25: "#E3EFF8",
                primary: "#2780C1",
                neutral80: "#000000",
              },
            })}
            className={`${classname} text-sm mt-2 rounded-md ${
              isEditable ? "bg-gray-100 text-custom-black" : ""
            }`}
            styles={{
              control: (provided, state) => ({
                ...provided,
                borderColor: state.isFocused
                  ? "#2780C1"
                  : state.isHovered
                  ? "#2780C1"
                  : "#E5E7EB",
                boxShadow: state.isFocused ? "none" : "none",
                borderRadius: "0.375rem",
                "&:hover": {
                  borderColor: "#E5E7EB",
                },
                background: isEditable ? "#F3F4F6" : "white",
                color: "#FFFFFF",
              }),
              singleValue: (provided) => ({
                ...provided,
                color: isEditable ? "#000000" : "#000000", // Adjust the text color here for non-editable
              }),
              multiValue: (provided) => ({
                ...provided,
                color: isEditable ? "#000000" : "#000000", // Adjust the text color for multi select
              }),
            }}
          />
        )}
      />
      {error && <p className="text-sm text-red-500">{error}</p>}
    </>
  );
};

export default CustomSelect;

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const authEndPoints = {
  LOGIN_API: BASE_URL + "/auth/login",
  FORGOT_PASSWORD_API: BASE_URL + "/auth/forgot-password",
  UPDATE_PASSWORD_API: BASE_URL + "/auth/update-password",
};

export const adminEndPoints = {
  CREATE_DEPARTMENT_API: BASE_URL + "/admin/department/create",
  UPDATE_DEPARTMENT_API: BASE_URL + "/admin/department",
  DELETE_DEPARTMENT_API: BASE_URL + "/admin/department",
  DELETE_PROJECT_API: BASE_URL + "/admin/project",
  CREATE_DESIGNATION_API: BASE_URL + "/admin/designation",
  DELETE_DESIGNATION_API: BASE_URL + "/admin/designation",
  OFFICE_LOCATION_API: BASE_URL + "/admin/officelocation",
};

export const companyEndPoints = {
  COMPANY_API: BASE_URL + "/company",
};
export const departmentEndPoints = {
  DEPARTMENT_API: BASE_URL + "/department",
};
export const designationEndPoints = {
  DESIGNATION_API: BASE_URL + "/designation",
};
export const officeLocationEndPoints = {
  OFFICE_LOCATION_API: BASE_URL + "/office",
};

export const employeeEndPoints = {
  EMPLOYEE_API: BASE_URL + "/employee",
  EMPLOYEE_PROFILE_API: BASE_URL + "/employee/profile",
  EMPLOYEE_CHANGE_PASSWORD_API: BASE_URL + "/employee/changepassword",
  EMPLOYEE_HIERARCHY_API: BASE_URL + "/hierarchy",
};

export const projectEndPoints = {
  PROJECT_API: BASE_URL + "/project",
  PROJECT_DASHBOARD_API: BASE_URL + "/project/dashboard",
  PROJECT_LOGS_API: BASE_URL + "/project/log",
  PROJECT_DETAILS_API: BASE_URL + "/project/details",
};

export const taskEndPoints = {
  TASK_API: BASE_URL + "/task",
  TEAM_TASK_API: BASE_URL + "/task/team",
  LOGS_API: BASE_URL + "/task/log",
};

export const subTaskEndPoints = {
  SUBTASK_API: BASE_URL + "/sub-task",
};

export const notesEndPoints = {
  NOTES_API: BASE_URL + "/notes",
  PRIVATE_NOTES_API: BASE_URL + "/notes/private",
};
export const commentEndPoints = {
  COMMENT_API: BASE_URL + "/comment",
};
export const milestoneEndPoints = {
  MILESTONE_API: BASE_URL + "/project/milestone",
};

export const documentEndPoints = {
  DOCUMENT_API: BASE_URL + "/files",
};
export const dashboardEndPoints = {
  DASHBOARD_API: BASE_URL + "/dashboard",
  COMPANY_WITH_MOST_USERS: BASE_URL + "/dashboard/company",
};

export const productEndPoints = {
  PRODUCT_API: BASE_URL + "/product",
};

export const meetingEndPoints = {
  MEETING_API: BASE_URL + "/meeting",
  UPCOMING_MEETING_API: BASE_URL + "/meeting/upcoming",
  MEETING_STATUS_UPDATE_API: BASE_URL + "/meeting/update-invitation",
  MEETING_CATEGORY_API: BASE_URL + "/meeting-category",
};

export const attendanceEndPoints = {
  ATTENDANCE_API: BASE_URL + "/attendance",
  WORKING_HOURS_API: BASE_URL + "/attendance/working-hours",
};

export const reminderEndPoints = {
  REMINDER_API: BASE_URL + "/reminder",
};
export const externalInviteEndPoints = {
  EXTERNAL_INVITE_API: BASE_URL + "/external-user",
};
export const shiftEndPoints = {
  SHIFT_SCHEDULE_API: BASE_URL + "/company-shift",
};

export const holidayEndPoints = {
  HOLIDAY_API: BASE_URL + "/holiday",
};
export const timesheetEndPoints = {
  TIMESHEET_API: BASE_URL + "/timesheet",
  TIMESHEET_FOR_APPROVAL_API: BASE_URL + "/timesheet/pending-approval",
};

export const reportEndPoints = {
  REPORT_PROJECT_API: BASE_URL + "/project/report",
};

/* Common APis */
export const countryEndPoints = {
  COUNTRY_API: BASE_URL + "/utils/country-list",
};
/* Common APis */

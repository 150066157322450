import React, { useContext, useEffect, useState } from "react";
import {
  CommonValidation,
  CustomButton,
  CustomInput,
  CustomModal,
  CustomSelect,
  CustomSwitch,
  FilterSelect,
} from "../common";
import { useDispatch, useSelector } from "react-redux";
import { CustomAlertContext } from "../../context";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { fetchAllEmployees } from "../../redux/slice/employeeSlice";
import { FaPlusCircle } from "react-icons/fa";
import { fetchMeetingCategory } from "../../redux/slice/meetingCategorySlice";
import { setLoading } from "../../redux/slice/loadingSlice";
import { meetingEndPoints } from "../../networking/Endpoints";
import { apiConnector } from "../../networking/ApiConnector";
import { useNavigate } from "react-router-dom";
import { fethAllExternalInvites } from "../../redux/slice/externalInviteSlice";
import { getFullDate, getFullDay } from "../../constant/Constant";
import { error } from "jodit/esm/core/helpers";
import { getAllProject } from "../../redux/slice/projectSlice";
import dayjs from "dayjs";

const baseUrl = process.env.REACT_APP_BASE_URL;

const durationOptions = [
  {
    name: "00:15 Mins",
    value: "00:15",
  },
  {
    name: "00:30 Mins",
    value: "00:30",
  },
  {
    name: "00:45 Mins",
    value: "00:45",
  },
  {
    name: "01:00 Hrs",
    value: "01:00",
  },
  {
    name: "01:30 Hrs",
    value: "01:30",
  },
  {
    name: "02:00 Hrs",
    value: "02:00",
  },
];

const RequestMeetingType = [
  {
    name: "One Time Schedule",
    value: "One Time",
  },
  {
    name: "Weekly Schedule",
    value: "Weekly",
  },
  {
    name: "Monthly Schedule",
    value: "Monthly",
  },
  {
    name: "Daily Schedule",
    value: "Daily",
  },
];

const RequestMeetingValidation = yup.object().shape({
  meeting_category: yup
    .string()
    .required("Meeting category is required *")
    .trim(),
  meeting_duration: yup.string().required("Duration is required *").trim(),
  meeting_title: yup.string().required("Title is required *").trim(),
  agenda: yup.string().required("Agenda is required *").trim(),
  date_time: yup.string().when("frequency", {
    is: (frequency) => {
      return frequency === "One Time";
    },
    then: (schema) => schema.required("Date Time is required"),
  }),
  link_of_meeting: yup.string().when("$mode", {
    is: (mode) => {
      return mode === 1;
    },
    then: (schema) => schema.required("Link is required *"),
  }),
  participants: yup
    .array()
    .test(
      "participants",
      "Either participants or external invites must be added *",
      function (value) {
        const { participants, external_invites } = this.parent;
        return (
          (participants && participants.length > 0) ||
          (external_invites && external_invites.length > 0)
        );
      }
    ),
  external_invites: yup
    .array()
    .test(
      "external_invites",
      "Either participants or external invites must be added *",
      function (value) {
        const { participants, external_invites } = this.parent;
        return (
          (participants && participants.length > 0) ||
          (external_invites && external_invites.length > 0)
        );
      }
    ),
  frequency: yup.string().required("Frequency is required *").trim(),
  startDate: yup.string().when("frequency", {
    is: (frequency) => {
      return (
        frequency === "Weekly" ||
        frequency === "Monthly" ||
        frequency === "Daily"
      );
    },
    then: (schema) => schema.required("Start Date is required"),
  }),
  endDate: yup.string().when("frequency", {
    is: (frequency) => {
      return (
        frequency === "Weekly" ||
        frequency === "Monthly" ||
        frequency === "Daily"
      );
    },
    then: (schema) => schema.required("End Date is required"),
  }),
  time: yup.string().when("frequency", {
    is: (frequency) => {
      return (
        frequency === "Weekly" ||
        frequency === "Monthly" ||
        frequency === "Daily"
      );
    },
    then: (schema) => schema.required("Time is required"),
  }),
  dayOfWeek: yup
    .array()
    .of(yup.number().required("Each item must be a valid number"))
    .when("frequency", {
      is: (frequency) => {
        return frequency === "Weekly";
      },
      then: (schema) => schema.required("Day is required"),
    }),
  dateOfMonth: yup
    .array()
    .of(yup.number().required("Each item must be a valid number"))
    .when("frequency", {
      is: (frequency) => {
        return frequency === "Monthly";
      },
      then: (schema) => schema.required("Date is required"),
    }),
  actual_meeting_start_date_time: yup.string().when("meeting_status", {
    is: (meeting_status) => {
      return meeting_status === "Completed";
    },
    then: (schema) =>
      schema.required("Actual Meeting Start Date & Time is required"),
  }),
  actual_meeting_end_date_time: yup.string().when("meeting_status", {
    is: (meeting_status) => {
      return meeting_status === "Completed";
    },
    then: (schema) =>
      schema.required("Actual Meeting End Date & Time is required"),
  }),
  remark: yup.string().when("meeting_status", {
    is: (meeting_status) => {
      return meeting_status === "Completed" || meeting_status === "Rejected";
    },
    then: (schema) => schema.required("Remark is required"),
  }),
});
const meetingStatusOptions = [
  {
    name: "Yes",
    value: "Completed",
  },
  {
    name: "No",
    value: "Rejected",
  },
];
const MeetingForm = ({
  meetingDetails,
  FetchAllMeetings,
  setShowMeetingForm,
  setShowMeetingView,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dropDownId, setDropDownId] = useState("One Time");
  const { token, user } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.loading);
  const { employeeData } = useSelector((state) => state.employee);
  const { projectData } = useSelector((state) => state.project);
  const { externalInviteData } = useSelector((state) => state.externalInvite);
  const { meetingCategoryData } = useSelector((state) => state.meetingCategory);
  const [projects, setProjects] = useState([]);

  const [tab, setTab] = useState(
    meetingDetails?.mode_of_meeting === "online"
      ? 1
      : meetingDetails?.mode_of_meeting === "offline"
      ? 2
      : 2
  );
  const [isMeetingCompleted, setIsMeetingCompleted] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const { setToastNotification } = useContext(CustomAlertContext);
  useEffect(() => {
    if (meetingDetails) {
      setIsEditable(true);
    }
  }, [meetingDetails]);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(RequestMeetingValidation),
    defaultValues: {
      meeting_title: meetingDetails?.title ?? undefined,
      project: meetingDetails?.project ?? undefined,
      agenda: meetingDetails?.agenda ?? undefined,
      description: meetingDetails?.description ?? undefined,
      date_time: dayjs(meetingDetails?.date_time?.split(".")[0])
        .add(5, "hours")
        .add(30, "minutes")
        .format("YYYY-MM-DDTHH:mm"),
      meeting_duration: meetingDetails?.meeting_duration ?? undefined,
      meeting_category: meetingDetails?.meeting_category?._id ?? undefined,
      location: meetingDetails?.meeting_location ?? undefined,
      link_of_meeting: meetingDetails?.meeting_link ?? undefined,
      participants: meetingDetails?.participants?.map(
        (item) => item?.user?._id
      ),
      external_invites: meetingDetails?.external_user?.map((item) => item?._id),
      frequency: "One Time",
      startDate: meetingDetails?.startDate ?? undefined,
      endDate: meetingDetails?.endDate ?? undefined,
      time: meetingDetails?.time ?? undefined,
      dateOfMonth: meetingDetails?.dateOfMonth ?? undefined,
      dayOfWeek: meetingDetails?.dayOfWeek ?? undefined,
    },
    context: { mode: tab },
  });
  const meeting_status = watch("meeting_status");

  const fetchEmployees = async () => {
    try {
      await dispatch(fetchAllEmployees({ token }));
    } catch (error) {
      setToastNotification(error?.message, "error");
    }
  };
  const fetchExternalInvites = async () => {
    try {
      await dispatch(fethAllExternalInvites({ token }));
    } catch (error) {
      setToastNotification(error?.message, "error");
    }
  };
  const fetchAllMeetingCategory = async () => {
    try {
      await dispatch(fetchMeetingCategory({ token })).unwrap();
    } catch (error) {
      setToastNotification(error?.message, "error");
    }
  };
  const fetchProjects = async () => {
    try {
      const response = await dispatch(getAllProject({ token })).unwrap();
      if (response?.data) {
        setProjects(response?.data?.projects);
      }
    } catch (error) {
      setToastNotification(error?.message, "error");
    }
  };
  useEffect(() => {
    if (
      !employeeData ||
      (employeeData?.page_number && employeeData?.page_size)
    ) {
      fetchEmployees();
    }
    if (
      !meetingCategoryData ||
      (meetingCategoryData?.page_number && meetingCategoryData?.page_size)
    ) {
      fetchAllMeetingCategory();
    }
    if (
      !externalInviteData ||
      (externalInviteData?.page_number && externalInviteData?.page_size)
    ) {
      fetchExternalInvites();
    }
    if (!projectData || (projectData?.page_number && projectData?.page_size)) {
      fetchProjects();
    }
  }, []);
  const projectOptions = projectData?.projects
    ?.filter((project) => project?.projectStatus !== "Cancelled")
    ?.map((project) => ({
      name: project?.project_name,
      value: project?._id,
    }));
  const employeeOptions = employeeData?.employees
    ?.filter((item) => item?._id !== user?._id)
    ?.map((item) => ({
      name: item?.user_name,
      value: item?._id,
      designation: item?.designation?.designation,
      avatar: item?.avatar
        ? `${baseUrl}/${item?.avatar}`
        : `https://api.dicebear.com/5.x/initials/svg?seed=${item?.user_name}`,
    }));
  const meetingCategoryOptions = meetingCategoryData?.Category?.filter(
    (item) => item?.isActive !== false
  )?.map((item) => ({
    name: item?.category,
    value: item?._id,
  }));
  const externalInvitesOptions = externalInviteData?.externalUser?.map(
    (item) => ({
      name: item?.user_name,
      value: item?._id,
      designation: item?.company_name,
    })
  );
  const CheckParticipants = (meetingParticipants, dataMembers) => {
    if (meetingParticipants?.length !== dataMembers?.length) {
      return false;
    }
    return meetingParticipants.every((item) =>
      dataMembers.includes(item?.user?._id)
    );
  };
  const meetingScheduleHandler = async (data) => {
    let payload = isEditable
      ? {
          mode_of_meeting: tab === 1 ? "online" : "offline",
          title:
            meetingDetails?.title !== data?.meeting_title
              ? data?.meeting_title
              : undefined,
          project:
            meetingDetails?.project !== data?.project
              ? data?.project
              : undefined,
          agenda:
            meetingDetails?.agenda !== data?.agenda ? data?.agenda : undefined,
          description:
            meetingDetails?.description !== data?.description
              ? data?.description
              : undefined,
          date_time:
            meetingDetails?.date_time?.split(".")[0] !== data?.date_time
              ? data?.date_time
              : undefined,
          startDate:
            meetingDetails?.startDate?.split(".")?.[0] !== data?.startDate
              ? data?.startDate
              : undefined,
          endDate:
            meetingDetails?.endDate?.split(".")?.[0] !== data?.endDate
              ? data?.endDate
              : undefined,
          time: meetingDetails?.time !== data?.time ? data?.time : undefined,
          dayOfWeek:
            meetingDetails?.dayOfWeek !== data?.dayOfWeek
              ? data?.dayOfWeek
              : undefined,
          dateOfMonth:
            meetingDetails?.dateOfMonth !== data?.dateOfMonth
              ? data?.dateOfMonth
              : undefined,
          meeting_duration:
            meetingDetails?.meeting_duration !== data?.meeting_duration
              ? data?.meeting_duration
              : undefined,
          meeting_link:
            meetingDetails?.meeting_link !== data?.link_of_meeting
              ? data?.link_of_meeting
              : undefined,
          meeting_location:
            meetingDetails?.meeting_location !== data?.location
              ? data?.location
              : undefined,
          meeting_category:
            meetingDetails?.meeting_category !== data?.meeting_category
              ? data?.meeting_category
              : undefined,
          frequency:
            meetingDetails?.frequency !== data?.frequency
              ? data?.frequency
              : undefined,
          meeting_status:
            meetingDetails?.meeting_status !== data?.meeting_status
              ? data?.meeting_status
              : undefined,
          actual_meeting_start_date_time:
            meetingDetails?.actual_meeting_start_date_time !==
            data?.actual_meeting_start_date_time
              ? data?.actual_meeting_start_date_time
              : undefined,
          actual_meeting_end_date_time:
            meetingDetails?.actual_meeting_end_date_time !==
            data?.actual_meeting_end_date_time
              ? data?.actual_meeting_end_date_time
              : undefined,
          remark:
            meetingDetails?.remark !== data?.remark ? data?.remark : undefined,
          participants: !CheckParticipants(
            meetingDetails?.participants,
            data?.participants
          )
            ? data?.participants?.map((item) => ({ user: item }))
            : undefined,
          participants:
            data?.participants?.length &&
            !CheckParticipants(meetingDetails?.participants, data?.participants)
              ? data?.participants?.map((item) => ({ user: item }))
              : undefined,
          external_invites:
            data?.external_invites?.length &&
            !CheckParticipants(
              meetingDetails?.external_invites,
              data?.external_invites
            )
              ? data?.external_invites?.map((item) => ({ user: item }))
              : undefined,
        }
      : {
          mode_of_meeting: tab === 1 ? "online" : "offline",
          title: data?.meeting_title,
          project: data?.project,
          agenda: data?.agenda,
          description: data?.description,
          date_time: data?.date_time,
          meeting_duration: data?.meeting_duration,
          meeting_category: data?.meeting_category,
          startDate: data?.startDate,
          endDate: data?.endDate,
          time: data?.time,
          dayOfWeek: data?.dayOfWeek,
          frequency: data?.frequency,
          dateOfMonth: data?.dateOfMonth,
          ...(tab === 1 &&
            data?.link_of_meeting && { meeting_link: data?.link_of_meeting }),
          ...(tab === 2 &&
            data?.location && { meeting_location: data?.location }),
          participants: data?.participants?.length
            ? data?.participants?.map((item) => ({ user: item }))
            : undefined,
          external_user: data?.external_invites?.length
            ? data?.external_invites
            : undefined,
        };
    dispatch(setLoading(true));
    try {
      const apiMethod = isEditable ? "PUT" : "POST";
      const apiUrl = isEditable
        ? `${meetingEndPoints?.MEETING_API}/${meetingDetails?._id}`
        : meetingEndPoints?.MEETING_API;

      const meeting_response = await apiConnector(apiMethod, apiUrl, payload, {
        Authorization: `Bearer ${token}`,
      });
      setToastNotification(meeting_response?.data?.message, "success");
      reset();
      if (FetchAllMeetings) {
        FetchAllMeetings();
      }
      if (meetingDetails) {
        setShowMeetingView(false);
      }
      setShowMeetingForm(false);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <div className="w-full">
      <h2 className="text-xl text-center font-semibold text-custom-black pb-2">
        Meeting Schedule
      </h2>
      <form
        className="w-full mt-2 max-h-[80vh] overflow-y-auto pr-5"
        noValidate
        onSubmit={handleSubmit(meetingScheduleHandler)}
      >
        <div className="w-full flex justify-between items-center gap-4">
          <CustomButton
            title={"Online"}
            buttonType="button"
            onClick={() => setTab(1)}
            classname={`w-full text-sm font-semibold border px-5 py-1 rounded-md ${
              tab === 1
                ? "bg-custom-blue text-custom-white"
                : "text-slate-400 border-slate-200"
            }`}
          />
          <CustomButton
            title={"Offline"}
            buttonType="button"
            onClick={() => setTab(2)}
            classname={` w-full text-sm font-semibold border   px-5 py-1 rounded-md ${
              tab === 2
                ? "bg-custom-blue text-custom-white"
                : "text-slate-400 border-slate-200"
            }`}
          />
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 mt-2">
          <div className="my-2">
            <CustomSelect
              name="meeting_category"
              label="Meeting Category"
              placeholder="Select meeting category"
              control={control}
              options={meetingCategoryOptions ?? []}
              error={errors?.meeting_category?.message}
            />
          </div>
          <div className="my-2">
            <CustomSelect
              name="project"
              label="Project"
              placeholder="Select a project"
              control={control}
              options={projectOptions ?? []}
              error={errors?.project?.message}
            />
          </div>
          <div className="my-2">
            <CustomInput
              name="meeting_title"
              label="Meeting Title"
              inputType="text"
              placeholder="Meeting Title"
              control={control}
              required={true}
              error={errors?.meeting_title?.message}
            />
          </div>
          <div className="my-2">
            <CustomInput
              name="agenda"
              label="Meeting Agenda"
              inputType="text"
              placeholder="Meeting Agenda"
              control={control}
              required={true}
              error={errors?.agenda?.message}
            />
          </div>
          <div className="my-2">
            <CustomSelect
              name="frequency"
              label="Meeting Frequency"
              placeholder="Select Meeting Frequency"
              control={control}
              options={RequestMeetingType ?? []}
              setDropDownId={setDropDownId}
              error={errors?.frequency?.message}
            />
          </div>

          {dropDownId == "One Time" ? (
            <div className="my-2">
              <CustomInput
                name="date_time"
                label="Meeting Date & Time"
                inputType="datetime-local"
                placeholder="Meeting Date & Time"
                control={control}
                required={true}
                error={errors?.date_time?.message}
              />
            </div>
          ) : dropDownId == "Monthly" ? (
            <>
              <div className="my-2">
                <CustomSelect
                  name="dateOfMonth"
                  label="Date"
                  inputType="text"
                  placeholder="Select Date "
                  multiple={true}
                  control={control}
                  options={getFullDate}
                  error={errors?.dateOfMonth?.message}
                />
              </div>
              <div className="my-2">
                <CustomInput
                  name="startDate"
                  label="Start Date"
                  inputType="date"
                  placeholder="Start Date"
                  control={control}
                  required={true}
                  error={errors?.startDate?.message}
                />
              </div>
              <div className="my-2">
                <CustomInput
                  name="endDate"
                  label="End Date"
                  inputType="date"
                  placeholder="End Date"
                  control={control}
                  required={true}
                  error={errors?.endDate?.message}
                />
              </div>
              <div className="my-2">
                <CustomInput
                  name="time"
                  label="Meeting Time"
                  inputType="time"
                  placeholder="Meeting Time"
                  control={control}
                  required={true}
                  error={errors?.time?.message}
                />
              </div>
            </>
          ) : dropDownId == "Daily" ? (
            <>
              {/* <div className="my-2">
                <CustomSelect
                  name="dayOfWeek"
                  label="Day"
                  inputType="text"
                  placeholder="Select a Day"
                  control={control}
                  options={getFullDay}
                  error={errors?.dayOfWeek?.message}
                />
              </div> */}
              <div className="my-2">
                <CustomInput
                  name="startDate"
                  label="Start Date"
                  inputType="date"
                  placeholder="Start Date"
                  control={control}
                  required={true}
                  error={errors?.startDate?.message}
                />
              </div>
              <div className="my-2">
                <CustomInput
                  name="endDate"
                  label="End Date"
                  inputType="date"
                  placeholder="End Date"
                  control={control}
                  required={true}
                  error={errors?.endDate?.message}
                />
              </div>
              <div className="my-2">
                <CustomInput
                  name="time"
                  label="Meeting Time"
                  inputType="time"
                  placeholder="Meeting Time"
                  control={control}
                  required={true}
                  error={errors?.time?.message}
                />
              </div>
            </>
          ) : dropDownId == "Weekly" ? (
            <>
              <div className="my-2">
                <CustomSelect
                  name="dayOfWeek"
                  label="Day"
                  inputType="text"
                  multiple={true}
                  placeholder="Select a Day"
                  control={control}
                  options={getFullDay}
                  error={errors?.dayOfWeek?.message}
                />
              </div>
              <div className="my-2">
                <CustomInput
                  name="startDate"
                  label="Start Date"
                  inputType="date"
                  placeholder="Start Date"
                  control={control}
                  required={true}
                  error={errors?.startDate?.message}
                />
              </div>
              <div className="my-2">
                <CustomInput
                  name="endDate"
                  label="End Date"
                  inputType="date"
                  placeholder="End Date"
                  control={control}
                  required={true}
                  error={errors?.endDate?.message}
                />
              </div>
              <div className="my-2">
                <CustomInput
                  name="time"
                  label="Meeting Time"
                  inputType="time"
                  placeholder="Meeting Time"
                  control={control}
                  required={true}
                  error={errors?.time?.message}
                />
              </div>
            </>
          ) : (
            ""
          )}
          <div className="w-full my-2">
            <CustomSelect
              name="meeting_duration"
              label="Duration"
              inputType="text"
              placeholder="Select a duration"
              control={control}
              options={durationOptions}
              error={errors?.meeting_duration?.message}
            />
          </div>

          <div className="my-2">
            <CustomSelect
              name="participants"
              label="Add Participants"
              placeholder="Select participant"
              control={control}
              options={employeeOptions}
              multiple={true}
              error={errors?.participants?.message}
            />
          </div>

          <div className="relative my-2">
            <CustomButton
              title={"Add"}
              buttonType="button"
              onClick={() => navigate("/external_invites")}
              classname="text-[#5A6872] absolute right-0 top-7 p-2.5 text-xs font-bold border border-[#5A6872] rounded-md"
            />
            <CustomSelect
              name="external_invites"
              label="Add External Invites"
              placeholder="Select external invites"
              control={control}
              options={externalInvitesOptions ?? []}
              multiple={true}
              classname="w-10/12"
              error={errors?.external_invites?.message}
            />
          </div>

          <div className="my-2 col-span-3">
            {tab === 1 ? (
              <div className="w-full my-2">
                <CustomInput
                  name="link_of_meeting"
                  label="Meeting Link"
                  inputType="text"
                  placeholder="Link"
                  control={control}
                  required={true}
                  error={errors?.link_of_meeting?.message}
                />
              </div>
            ) : (
              <div className="w-full my-2">
                <CustomInput
                  name="location"
                  label="Location"
                  inputType="text"
                  placeholder="Location"
                  control={control}
                  error={errors?.location?.message}
                />
              </div>
            )}
          </div>
          <div className="my-2 col-span-3">
            <CustomInput
              name="description"
              label="Description"
              inputType="text-area"
              placeholder="Description"
              control={control}
              error={errors?.description?.message}
            />
          </div>
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 mt-2 border-t border-dashed border-slate-300">
          {isEditable && (
            <div className="my-2">
              <CustomSelect
                name="meeting_status"
                label="Meeting Status"
                inputType="text"
                placeholder="Select an option"
                control={control}
                options={meetingStatusOptions ?? []}
                error={errors?.meeting_status?.message}
              />
            </div>
          )}
          {isEditable && meeting_status === "Completed" ? (
            <>
              <div className="my-2">
                <CustomInput
                  name="actual_meeting_start_date_time"
                  label="Actual Start Date & Time"
                  inputType="datetime-local"
                  placeholder="Meeting Date & Time"
                  control={control}
                  required={true}
                  error={errors?.actual_meeting_start_date_time?.message}
                />
              </div>
              <div className="my-2">
                <CustomInput
                  name="actual_meeting_end_date_time"
                  label="Actual End Date & Time"
                  inputType="datetime-local"
                  placeholder="Meeting Date & Time"
                  control={control}
                  required={true}
                  error={errors?.actual_meeting_end_date_time?.message}
                />
              </div>
              <div className="my-2 col-span-1 md:col-span-2 xl:col-span-3">
                <CustomInput
                  name="remark"
                  label="Remark"
                  inputType="text-area"
                  placeholder="Add a remark"
                  control={control}
                  required={true}
                  error={errors?.remark?.message}
                />
              </div>
            </>
          ) : isEditable && meeting_status === "Rejected" ? (
            <>
              <div className="my-2 col-span-1 md:col-span-2">
                <CustomInput
                  name="remark"
                  label="Remark"
                  inputType="text-area"
                  placeholder="Add a remark"
                  required={true}
                  control={control}
                  error={errors?.remark?.message}
                />
              </div>
            </>
          ) : (
            ""
          )}
        </div>
        <div className="flex justify-end items-center gap-5">
          <CustomButton
            title={"Cancel"}
            buttonType="button"
            onClick={() => setShowMeetingForm(false)}
            classname="text-[#5A6872] font-semibold border border-[#5A6872]  px-5 py-1 rounded-md"
          />
          <CustomButton
            title={
              loading ? (
                <svg
                  aria-hidden="true"
                  className="w-4 h-4 me-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              ) : (
                "Save"
              )
            }
            buttonType="submit"
            disabled={loading}
            classname="bg-custom-blue font-semibold text-custom-white px-5 py-1 rounded-md"
          />
        </div>
      </form>
    </div>
  );
};

export default MeetingForm;

import React, { useContext, useEffect, useState } from "react";
import ProjectJason from "../../dummyJason/ProductJason";
import { productEndPoints } from "../../networking/Endpoints";
import { CustomAlertContext } from "../../context";
import { apiConnector } from "../../networking/ApiConnector";
import { useSelector } from "react-redux";
import { CustomButton } from "../common";
import { useNavigate } from "react-router-dom";
import { CiEdit } from "react-icons/ci";
const baseUrl = process.env.REACT_APP_BASE_URL;

const CompanyCard = ({ item }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        if (item?.link) {
          window.open(item?.link, "_blank");
        }
      }}
      className="p-4 cursor-pointer border border-[#E3F3FF] hover:bg-gray-100 rounded-md space-y-2 shadow-[2px_3px_10px_6px_#257CBB0F]"
    >
      <div className="flex justify-between items-center">
        <div className="flex justify-start items-center gap-4">
          <img
            src={item?.logo ? `${baseUrl}/${item?.logo}` : ""}
            alt="Logo"
            className="w-14 md:w-14 h-14 md:h-14 rounded-full"
          />
          <div>
            <span className="block  text-black font-bold text-base">
              {item?.name ? item?.name : "--"}
            </span>
          </div>
        </div>
        <div
          onClick={(event) => {
            event.stopPropagation();
            navigate("/add_products", {
              state: item,
            });
          }}
          className="w-7 h-7 rounded-full border text-[#626262] font-semibold flex justify-center items-center"
        >
          <CiEdit />
        </div>
      </div>
      <div className="w-full h-[1px] border border-dashed" />
      <div className="w-full space-y-2 text-sm">
        <span className="block text-xs text-slate-400 line-clamp-4 h-44">
          {item?.description ? item?.description : "--"}
        </span>
      </div>
    </div>
  );
};
const Products = () => {
  const [projectData, setProjectData] = useState([]);
  const [projectLoading, setProjectLoading] = useState(false);
  const { setToastNotification } = useContext(CustomAlertContext),
    { token, user } = useSelector((state) => state.auth);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    setProjectLoading(true);
    try {
      const product_response = await apiConnector(
        "GET",
        `${productEndPoints?.PRODUCT_API}`,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setProjectData(product_response?.data?.products);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      setProjectLoading(false);
    }
  };
  return (
    <div className="bg-custom-white p-4 rounded-md ">
      <h2 className="pb-4 font-semibold text-lg">SinghSoft Product's</h2>

      <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 gap-5  ">
        {projectData?.map((item) => (
          <CompanyCard item={item} />
        ))}
      </div>
    </div>
  );
};

export default Products;

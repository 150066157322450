import React, { useContext, useEffect, useState } from "react";
import { TimesheetForm } from ".";
import { useDispatch, useSelector } from "react-redux";
import { apiConnector } from "../../networking/ApiConnector";
import { timesheetEndPoints } from "../../networking/Endpoints";
import { CustomAlertContext } from "../../context";
import { setLoading } from "../../redux/slice/loadingSlice";
import { CustomButton, TableHeader } from "../common";
import dayjs from "dayjs";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const TimsheetForApproval = () => {
  const dispatch = useDispatch();
  const { token, user } = useSelector((state) => state.auth);
  const { setToastNotification } = useContext(CustomAlertContext);
  const [timesheetForApprovalData, setTimesheetForApprovalData] = useState([]);

  function generateWeeksFromTimesheet(timesheetData) {
    return timesheetData?.map((entry) => {
      const startDate = dayjs(entry.week_start_date);
      const endDate = dayjs(entry.week_end_date);
      const week = [];

      let currentDate = startDate;
      while (
        currentDate.isBefore(endDate) ||
        currentDate.isSame(endDate, "day")
      ) {
        week.push(currentDate.format("YYYY-MM-DD"));
        currentDate = currentDate.add(1, "day");
      }

      return week;
    });
  }
  const fetchTimesheetForApproval = async () => {
    dispatch(setLoading(true));
    try {
      const timesheet_response = await apiConnector(
        "GET",
        timesheetEndPoints?.TIMESHEET_FOR_APPROVAL_API,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setTimesheetForApprovalData(timesheet_response?.data?.data);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };
  useEffect(() => {
    fetchTimesheetForApproval();
  }, []);
  console.log(timesheetForApprovalData, "timesheetForApprovalData");

  const weeks = generateWeeksFromTimesheet(timesheetForApprovalData);
  return (
    <div className="w-full h-full bg-white rounded-lg shadow-md p-4">
      <section className="flex flex-col sm:flex-row justify-between items-center">
        <h1 className="font-semibold text-xl">Timesheet For Approval</h1>
      </section>
      <TimesheetForm weeks={weeks} timesheetData={timesheetForApprovalData} />
    </div>
  );
};

export default TimsheetForApproval;

import React from "react";
import { CustomButton, CustomModal } from "../../common";
import { Heading } from "../../project";

const OfficeLocationViewModal = ({
  officeLocationDetails = {},
  setOfficeLocationDetails = () => {},
  showOfficeLocationViewModal,
  setShowOfficeLocationViewModal,
}) => {
  return (
    <CustomModal
      isOpen={showOfficeLocationViewModal}
      onClose={() => {
        setOfficeLocationDetails(null);
        setShowOfficeLocationViewModal(false);
      }}
    >
      <div className="w-full p-4">
        <div className="relative font-semibold bg-gradient-custom-light-blue rounded-lg p-2 border">
          <div className="font-semibold">Office Location Details</div>
          <CustomButton
            title={"Edit"}
            buttonType={"button"}
            onClick={() => {
              // setOpenAddSubTaskForm(true);
              // setOpenSubTaskViewModal(false);
            }}
            classname="absolute right-0 top-0 underline text-[#2E6790] px-5 py-1 rounded-md"
          />
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-10 my-5">
          <Heading label="City" value={officeLocationDetails?.city} />
          <Heading label="Address" value={officeLocationDetails?.address} />
        </div>
      </div>
    </CustomModal>
  );
};

export default OfficeLocationViewModal;

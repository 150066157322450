import React, { useState } from "react";
import {
  motion,
  useTransform,
  AnimatePresence,
  useMotionValue,
  useSpring,
} from "framer-motion";
import { setActiveTabInProject } from "../../redux/slice/projectSlice";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { TbXboxXFilled } from "react-icons/tb";
import { FaCheck } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
const baseUrl = process.env.REACT_APP_BASE_URL;
const CustomAssignees = ({
  meeting = false,
  assignees,
  maxLimit = 4,
  classname = "w-8 h-8",
}) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const springConfig = { stiffness: 100, damping: 5 };
  // going to set this value on mouse move
  const x = useMotionValue(0);
  // rotate the tooltip
  const rotate = useSpring(
    useTransform(x, [-100, 100], [-45, 45]),
    springConfig
  );
  // translate the tooltip
  const translateX = useSpring(
    useTransform(x, [-100, 100], [-50, 50]),
    springConfig
  );
  const handleMouseMove = (event) => {
    const halfWidth = event.target.offsetWidth / 2;
    x.set(event.nativeEvent.offsetX - halfWidth); // set the x value, which is then used in transform and rotate
  };
  return (
    <div className="flex items-center -ml-3 px-3">
      {assignees?.length
        ? assignees?.slice(0, maxLimit)?.map((item) => (
            <div
              className="-mr-2 relative group cursor-pointer"
              key={item?._id}
              onMouseEnter={() => setHoveredIndex(item?._id)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <AnimatePresence mode="popLayout">
                {hoveredIndex === item?._id && (
                  <motion.div
                    initial={{ opacity: 0, y: 20, scale: 0.6 }}
                    animate={{
                      opacity: 1,
                      y: 0,
                      scale: 1,
                      transition: {
                        type: "spring",
                        stiffness: 260,
                        damping: 10,
                      },
                    }}
                    exit={{ opacity: 0, y: 20, scale: 0.6 }}
                    style={{
                      translateX: translateX,
                      rotate: rotate,
                      whiteSpace: "nowrap",
                    }}
                    className="absolute -top-16 -left-1/2 translate-x-1/2 flex text-xs  flex-col items-center justify-center rounded-md bg-black z-[999] shadow-xl px-4 py-2"
                  >
                    <div className="absolute inset-x-10 z-30 w-[20%] -bottom-px bg-gradient-to-r from-transparent via-emerald-500 to-transparent h-px " />
                    <div className="absolute left-10 w-[40%] z-30 -bottom-px bg-gradient-to-r from-transparent via-sky-500 to-transparent h-px " />
                    <div className="font-bold text-white relative z-30 text-base">
                      {item?.user_name}
                    </div>
                    <div className="text-white text-xs">
                      {item?.designation?.designation}
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
              {meeting && (
                <div
                  className={`absolute z-50 -top-2 right-0 w-4 h-4 rounded-full flex justify-center items-center  ${
                    typeof item?.is_invitation_accepted === "boolean"
                      ? item?.is_invitation_accepted
                        ? "bg-green-500 text-custom-white rounded-full"
                        : "bg-red-500 text-custom-white rounded-full"
                      : ""
                  }`}
                >
                  {typeof item?.is_invitation_accepted === "boolean" ? (
                    item?.is_invitation_accepted ? (
                      <FaCheck size={12} />
                    ) : (
                      <RxCross2 size={12} />
                    )
                  ) : (
                    ""
                  )}
                </div>
              )}
              <img
                onMouseMove={handleMouseMove}
                src={
                  item?.avatar
                    ? `${baseUrl}/${item?.avatar}`
                    : `https://api.dicebear.com/5.x/initials/svg?backgroundColor=00897b,00acc1,43a047&seed=${item?.user_name}`
                }
                alt={item?.user_name?.[0]}
                className={`object-cover ${classname} object-top rounded-full border-2 border-white relative transition duration-500`}
              />
            </div>
          ))
        : "--"}
      {assignees?.length - maxLimit > 0 && (
        <div
          className={`${classname} rounded-full bg-custom-light-blue text-sm flex justify-center items-center font-semibold text-custom-blue `}
        >
          +{assignees?.length - maxLimit}
        </div>
      )}
    </div>
  );
};

export default CustomAssignees;

import dayjs from "dayjs";

export const Roles = {
  super_admin: 1,
  admin: 2,
  project_manager: 3,
  employee: 4,
  hr: 5,
  team_lead: 6,
};
export const TaskLabels = [
  "Not Started Task",
  "In Progress Task",
  "On Hold Task",
  "Completed Task",
  "Cancelled Task",
];

export const Status = {
  in_progress: "In Progress",
  completed: "Completed",
  on_hold: "On Hold",
  cancelled: "Cancelled",
  not_started: "Not Started",
};

export const statusOptions = [
  {
    name: (
      <div className="text-[#B1A000] font-semibold flex justify-start items-center gap-2">
        <p className="w-2 h-2 rounded-full bg-[#B1A000]"></p>
        <span>In Progress</span>
      </div>
    ),
    value: "In Progress",
  },
  {
    name: (
      <div className="text-[#47BA11] font-semibold flex justify-start items-center gap-2">
        <p className="w-2 h-2 rounded-full bg-[#47BA11]"></p>
        <span>Completed</span>
      </div>
    ),
    value: "Completed",
  },
  {
    name: (
      <div className="text-[#3085C3] font-semibold flex justify-start items-center gap-2">
        <p className="w-2 h-2 rounded-full bg-[#3085C3]"></p>
        <span>On Hold</span>
      </div>
    ),
    value: "On Hold",
  },
  {
    name: (
      <div className="text-[#FF0000] font-semibold flex justify-start items-center gap-2">
        <p className="w-2 h-2 rounded-full bg-[#FF0000]"></p>
        <span>Cancelled</span>
      </div>
    ),
    value: "Cancelled",
  },
  {
    name: (
      <div className="text-[#909090] font-semibold flex justify-start items-center gap-2">
        <p className="w-2 h-2 rounded-full bg-[#909090]"></p>
        <span>Not Started</span>
      </div>
    ),
    value: "Not Started",
  },
];

export const priorityOptions = [
  {
    name: (
      <div className="text-[#858585] font-semibold flex justify-start items-center gap-2">
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.7207 1.83203V20.1654"
            stroke="#F44336"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.7207 3.66797H14.9874C17.4624 3.66797 18.0124 5.04297 16.2707 6.78464L15.1707 7.88464C14.4374 8.61797 14.4374 9.80964 15.1707 10.4513L16.2707 11.5513C18.0124 13.293 17.3707 14.668 14.9874 14.668H4.7207"
            fill="#F44336"
          />
          <path
            d="M4.7207 3.66797H14.9874C17.4624 3.66797 18.0124 5.04297 16.2707 6.78464L15.1707 7.88464C14.4374 8.61797 14.4374 9.80964 15.1707 10.4513L16.2707 11.5513C18.0124 13.293 17.3707 14.668 14.9874 14.668H4.7207"
            stroke="#F44336"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <span>High</span>
      </div>
    ),
    value: "High",
  },
  {
    name: (
      <div className="text-[#858585] font-semibold flex justify-start items-center gap-2">
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.7207 1.83203V20.1654"
            stroke="#2780C1"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.7207 3.66797H14.9874C17.4624 3.66797 18.0124 5.04297 16.2707 6.78464L15.1707 7.88464C14.4374 8.61797 14.4374 9.80964 15.1707 10.4513L16.2707 11.5513C18.0124 13.293 17.3707 14.668 14.9874 14.668H4.7207"
            fill="#2780C1"
          />
          <path
            d="M4.7207 3.66797H14.9874C17.4624 3.66797 18.0124 5.04297 16.2707 6.78464L15.1707 7.88464C14.4374 8.61797 14.4374 9.80964 15.1707 10.4513L16.2707 11.5513C18.0124 13.293 17.3707 14.668 14.9874 14.668H4.7207"
            stroke="#2780C1"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <span>Medium</span>
      </div>
    ),
    value: "Medium",
  },
  // {
  //   name: (
  //     <div className="text-[#909090] font-semibold flex justify-start items-center gap-2">
  //       <svg
  //         width="22"
  //         height="22"
  //         viewBox="0 0 22 22"
  //         fill="none"
  //         xmlns="http://www.w3.org/2000/svg"
  //       >
  //         <path
  //           d="M4.7207 1.83203V20.1654"
  //           stroke="#E7D327"
  //           stroke-width="1.5"
  //           stroke-miterlimit="10"
  //           stroke-linecap="round"
  //           stroke-linejoin="round"
  //         />
  //         <path
  //           d="M4.7207 3.66797H14.9874C17.4624 3.66797 18.0124 5.04297 16.2707 6.78464L15.1707 7.88464C14.4374 8.61797 14.4374 9.80964 15.1707 10.4513L16.2707 11.5513C18.0124 13.293 17.3707 14.668 14.9874 14.668H4.7207"
  //           fill="#E7D327"
  //         />
  //         <path
  //           d="M4.7207 3.66797H14.9874C17.4624 3.66797 18.0124 5.04297 16.2707 6.78464L15.1707 7.88464C14.4374 8.61797 14.4374 9.80964 15.1707 10.4513L16.2707 11.5513C18.0124 13.293 17.3707 14.668 14.9874 14.668H4.7207"
  //           stroke="#E7D327"
  //           stroke-width="1.5"
  //           stroke-miterlimit="10"
  //           stroke-linecap="round"
  //           stroke-linejoin="round"
  //         />
  //       </svg>

  //       <span>Normal</span>
  //     </div>
  //   ),
  //   value: "Normal",
  // },
  {
    name: (
      <div className="text-[#909090] font-semibold flex justify-start items-center gap-2">
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.7207 1.83203V20.1654"
            stroke="#919EA7"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.7207 3.66797H14.9874C17.4624 3.66797 18.0124 5.04297 16.2707 6.78464L15.1707 7.88464C14.4374 8.61797 14.4374 9.80964 15.1707 10.4513L16.2707 11.5513C18.0124 13.293 17.3707 14.668 14.9874 14.668H4.7207"
            fill="#919EA7"
          />
          <path
            d="M4.7207 3.66797H14.9874C17.4624 3.66797 18.0124 5.04297 16.2707 6.78464L15.1707 7.88464C14.4374 8.61797 14.4374 9.80964 15.1707 10.4513L16.2707 11.5513C18.0124 13.293 17.3707 14.668 14.9874 14.668H4.7207"
            stroke="#919EA7"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <span>Low</span>
      </div>
    ),
    value: "Low",
  },
];

export const chartColors = {
  not_started: "#B0C4DE",
  in_progress: "#FAB111",
  on_hold: "#5175F4",
  completed: "#2BB92B",
  cancelled: "#FF0B0B",
};

export const filterStatusOptions = [
  {
    name: "All",
    value: "",
  },
  {
    name: "In Progress",
    value: "In Progress",
  },
  {
    name: "Completed",
    value: "Completed",
  },
  {
    name: "On Hold",
    value: "On Hold",
  },
  {
    name: "Cancelled",
    value: "Cancelled",
  },
  {
    name: "Not Started",
    value: "Not Started",
  },
];

export const filterPriorityOptions = [
  {
    name: "All",
    value: "",
  },
  {
    name: "High",
    value: "High",
  },
  {
    name: "Medium",
    value: "Medium",
  },
  {
    name: "Low",
    value: "Low",
  },
];
const currentYear = new Date().getFullYear();

export const defaultYear = { name: currentYear, value: currentYear };
export const defaultMonth = {
  name: dayjs().format("MMMM"),
  value: dayjs().format("MMMM"),
};
export const yearOptions = [
  { name: currentYear, value: currentYear },
  { name: currentYear - 1, value: currentYear - 1 },
  { name: currentYear - 2, value: currentYear - 2 },
  { name: currentYear - 3, value: currentYear - 3 },
];

//Month Options
export const getFullMonths = [
  { name: "January", value: "01" },
  { name: "February", value: "02" },
  { name: "March", value: "03" },
  { name: "April", value: "04" },
  { name: "May", value: "05" },
  { name: "June", value: "06" },
  { name: "July", value: "07" },
  { name: "August", value: "08" },
  { name: "September", value: "09" },
  { name: "October", value: "10" },
  { name: "November", value: "11" },
  { name: "December", value: "12" },
];
export const getFullDay = [
  { name: "Monday", value: 1 },
  { name: "Tuesday", value: 2 },
  { name: "Wednesday", value: 3 },
  { name: "Thursday", value: 4 },
  { name: "Friday", value: 5 },
  { name: "Saturday", value: 6 },
  { name: "Sunday", value: 0 },
  ,
];
export const getFullDate = [
  { name: "1", value: 1 },
  { name: "2", value: 2 },
  { name: "3", value: 3 },
  { name: "4", value: 4 },
  { name: "5", value: 5 },
  { name: "6", value: 6 },
  { name: "7", value: 7 },
  { name: "8", value: 8 },
  { name: "9", value: 9 },
  { name: "10", value: 10 },
  { name: "11", value: 11 },
  { name: "12", value: 12 },
  { name: "13", value: 13 },
  { name: "14", value: 14 },
  { name: "15", value: 15 },
  { name: "16", value: 16 },
  { name: "17", value: 17 },
  { name: "18", value: 18 },
  { name: "19", value: 19 },
  { name: "20", value: 20 },
  { name: "21", value: 21 },
  { name: "22", value: 22 },
  { name: "23", value: 23 },
  { name: "24", value: 24 },
  { name: "25", value: 25 },
  { name: "26", value: 26 },
  { name: "27", value: 27 },
  { name: "28", value: 28 },
  { name: "29", value: 29 },
  { name: "30", value: 30 },

  ,
];
export const levelOfSeniorityOptions = [
  {
    name: "Owner/Board Director",
    value: "Owner/Board Director",
  },
  {
    name: "Chairman/Board Member",
    value: "Chairman/Board Member",
  },
  {
    name: "C-level/President/Chairman",
    value: "C-level/President/Chairman",
  },
  {
    name: "CEO/MD",
    value: "CEO/MD",
  },
  {
    name: "Senior GOVT Officials",
    value: "Senior GOVT Officials",
  },
  {
    name: "Director",
    value: "Director",
  },
  {
    name: "HOD",
    value: "HOD",
  },
  {
    name: "Senior Manager/Manager",
    value: "Senior Manager/Manager",
  },
  {
    name: "Executive",
    value: "Executive",
  },
  {
    name: "Consultant/Freelance",
    value: "Consultant/Freelance",
  },
  {
    name: "Assistant/Secretary",
    value: "Assistant/Secretary",
  },
  {
    name: "Other",
    value: "Other",
  },
];

export const companyIndustryOptions = [
  {
    name: "Agriculture",
    value: "Agriculture",
  },
  {
    name: "Banking & Finance",
    value: "Banking & Finance",
  },
  {
    name: "Bio Technology",
    value: "Bio Technology",
  },
  {
    name: "Consultancy",
    value: "Consultancy",
  },
  {
    name: "Education",
    value: "Education",
  },
  {
    name: "Energy",
    value: "Energy",
  },
  {
    name: "Entertainment & Leisure",
    value: "Entertainment & Leisure",
  },
  {
    name: "FMCG",
    value: "FMCG",
  },
  {
    name: "Fintech",
    value: "Fintech",
  },
  {
    name: "Food",
    value: "Food",
  },
  {
    name: "Government",
    value: "Government",
  },
  {
    name: "Healthcare",
    value: "Healthcare",
  },
  {
    name: "Human Resource",
    value: "Human Resource",
  },
  {
    name: "Investor",
    value: "Investor",
  },
  {
    name: "Law",
    value: "Law",
  },
  {
    name: "Logistic & Supply Chain",
    value: "Logistic & Supply Chain",
  },
  {
    name: "Manufacturing",
    value: "Manufacturing",
  },
  {
    name: "Maritime",
    value: "Maritime",
  },
  {
    name: "Media",
    value: "Media",
  },
  {
    name: "Media & Marketing",
    value: "Media & Marketing",
  },
  {
    name: "Project Management",
    value: "Project Management",
  },
  {
    name: "Real Estate",
    value: "Real Estate",
  },
  {
    name: "Research & Development",
    value: "Research & Development",
  },
  {
    name: "Retail",
    value: "Retail",
  },
  {
    name: "Technology",
    value: "Technology",
  },
  {
    name: "Telecommunication",
    value: "Telecommunication",
  },
  {
    name: "Transportation",
    value: "Transportation",
  },
  {
    name: "Travel & Tourism",
    value: "Travel & Tourism",
  },
  {
    name: "Utilities Services",
    value: "Utilities Services",
  },
  {
    name: "Other",
    value: "Other",
  },
];

export const regionOfInterestOptions = [
  {
    name: "Africa",
    value: "Africa",
  },
  {
    name: "Asia",
    value: "Asia",
  },
  {
    name: "Canada",
    value: "Canada",
  },
  {
    name: "Caribbean",
    value: "Caribbean",
  },
  {
    name: "Central / East Asia",
    value: "Central / East Asia",
  },
  {
    name: "Europe",
    value: "Europe",
  },
  {
    name: "LATAM",
    value: "LATAM",
  },
  {
    name: "Middle East",
    value: "Middle East",
  },
  {
    name: "North America",
    value: "North America",
  },
  {
    name: "Oceania",
    value: "Oceania",
  },
  {
    name: "South East Asia",
    value: "South East Asia",
  },
  {
    name: "UK & Ireland",
    value: "UK & Ireland",
  },
  {
    name: "US",
    value: "US",
  },
];

export const sectorOptions = [
  {
    name: "Artificial Intelligence",
    value: "Artificial Intelligence",
  },
  {
    name: "Clean Tech",
    value: "Clean Tech",
  },
  {
    name: "Cloud Tech",
    value: "Cloud Tech",
  },
  {
    name: "Creative Economy",
    value: "Creative Economy",
  },
  {
    name: "Edutech",
    value: "Edutech",
  },
  {
    name: "Fintech",
    value: "Fintech",
  },
  {
    name: "Future Energy",
    value: "Future Energy",
  },
  {
    name: "Gaming",
    value: "Gaming",
  },
  {
    name: "Health Tech",
    value: "Health Tech",
  },
  {
    name: "Investment",
    value: "Investment",
  },
  {
    name: "Retail Tech",
    value: "Retail Tech",
  },
  {
    name: "Robotics",
    value: "Robotics",
  },
  {
    name: "Smart Cities",
    value: "Smart Cities",
  },
  {
    name: "Space Tech",
    value: "Space Tech",
  },
  {
    name: "Sports",
    value: "Sports",
  },
  {
    name: "Startup",
    value: "Startup",
  },
  {
    name: "V/R",
    value: "V/R",
  },
  {
    name: "Other",
    value: "Other",
  },
];

export const matchMakingReasonOptions = [
  {
    name: "Business Development",
    value: "Business Development",
  },
  {
    name: "Fund Raising",
    value: "Fund Raising",
  },
  {
    name: "Knowledge Sharing",
    value: "Knowledge Sharing",
  },
  {
    name: "Market Expansion",
    value: "Market Expansion",
  },
  {
    name: "Networking",
    value: "Networking",
  },
  {
    name: "Recruitment",
    value: "Recruitment",
  },
  {
    name: "Strategic Partnership",
    value: "Strategic Partnership",
  },
];

export const StageOptions = [
  {
    name: "Acedemia/Students",
    value: "Acedemia/Students",
  },
  {
    name: "Corporate",
    value: "Corporate",
  },
  {
    name: "Early Stage Startups",
    value: "Early Stage Startups",
  },
  {
    name: "Exhibitors",
    value: "Exhibitors",
  },
  {
    name: "Government",
    value: "Government",
  },
  {
    name: "Growth/Scaleups",
    value: "Growth/Scaleups",
  },
  {
    name: "Investors",
    value: "Investors",
  },
];

export const fundingStageOptions = [
  {
    name: "Growth",
    value: "Growth",
  },
  {
    name: "Pre Seed",
    value: "Pre Seed",
  },
  {
    name: "Scale",
    value: "Scale",
  },
  {
    name: "Seed",
    value: "Seed",
  },
  {
    name: "Series A",
    value: "Series A",
  },
  {
    name: "Series B",
    value: "Series B",
  },
  {
    name: "Series C",
    value: "Series C",
  },
  {
    name: "Series D",
    value: "Series D",
  },
  {
    name: "Series E",
    value: "Series E",
  },
];

export const typeOfOrganizationOptions = [
  {
    name: "LLP",
    value: "LLP",
  },
  {
    name: "OPC",
    value: "OPC",
  },
  {
    name: "Partnership",
    value: "Partnership",
  },
  {
    name: "Private Limited",
    value: "Private Limited",
  },
  {
    name: "Proprietorship",
    value: "Proprietor",
  },
  {
    name: "Public Limited",
    value: "Public Limited",
  },
];

export const externalInviteDesignationOptions = [
  {
    name: "Director",
    value: "Director",
  },
  {
    name: "Propritor",
    value: "Propritor",
  },
  {
    name: "Partner",
    value: "Partner",
  },
  {
    name: "Share Holder",
    value: "Share Holder",
  },
  {
    name: "Board Member",
    value: "Board Member",
  },
  {
    name: "Other",
    value: "Other",
  },
];

import React from "react";
import EmployeeChart from "./EmployeeChart";

const OrganisationalChart = () => {
  return (
    <div className="w-full h-full p-4 bg-custom-white">
      <h1>Organisational Chart</h1>
      <div className="w-full h-[80vh]">
        <EmployeeChart />
      </div>
    </div>
  );
};

export default OrganisationalChart;

import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { CustomChart } from "../../common";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const Labels = ["Present", "Absent", "Late"];

const EmployeeAttendancePieChart = ({
  employeeDetails = {},
  workingHours = [],
  workingHoursLoading = false,
}) => {
  const [attendanceCount, setAttendanceCount] = useState({
    present: 0,
    absent: 0,
    late: 0,
  });

  const currentMonth = dayjs().format("MMMM");
  const attendanceTotal = dayjs().date();
  const currentDay = dayjs().isoWeekday() - 1;

  // Get shift clock-in time & buffer
  const hours =
    employeeDetails?.shift?.timing?.[currentDay]?.clock_in ?? "09:30";
  const bufferTime = employeeDetails?.shift?.buffer_time || 0;
  let [hour, minute] = hours ? hours?.split(":")?.map(Number) : 0;

  const shiftHours =
    hours && bufferTime
      ? dayjs().hour(hour).minute(minute).add(bufferTime, "minute")
      : dayjs();

  useEffect(() => {
    if (!workingHours?.length || !shiftHours) return;

    let lateCount = 0;
    let presentCount = 0;

    workingHours.forEach((item) => {
      const clockInUTC = item?.clock_in_time;
      if (!clockInUTC) return;

      presentCount++;

      // Convert UTC to local time
      const clockInLocal = dayjs.utc(clockInUTC).tz("Asia/Kolkata");

      // Extract hour and minute for comparison
      const clockInTime = dayjs()
        .set("hour", clockInLocal.hour())
        .set("minute", clockInLocal.minute())
        .set("second", 0);
      const shiftStartTime = dayjs()
        .set("hour", shiftHours.hour())
        .set("minute", shiftHours.minute())
        .set("second", 0);

      if (clockInTime.isAfter(shiftStartTime)) {
        lateCount++;
      }
    });

    setAttendanceCount({
      present: presentCount,
      absent: attendanceTotal - presentCount,
      late: lateCount,
    });
  }, [workingHours, attendanceTotal]);

  const data = [
    attendanceCount?.present,
    attendanceCount?.absent,
    attendanceCount?.late,
  ];

  return (
    <div className="border border-[#E9EDF0] rounded-lg p-4 flex flex-col justify-between items-center">
      <div className="w-full flex justify-between items-center">
        <h1 className="font-semibold text-sm my-2 text-slate-500">
          Attendance
        </h1>
        <div className="p-1 border bg-slate-300 font-semibold text-sm rounded-md">
          {currentMonth}
        </div>
      </div>
      {workingHoursLoading ? (
        <Skeleton width={250} height={150} />
      ) : workingHours?.length > 0 ? (
        <CustomChart
          labelName={""}
          chartType="pie"
          labels={Labels}
          data={data}
          colors={["#08C5FF", "#C2D732", "#E9B5B5"]}
        />
      ) : (
        <span>No Attendance Available</span>
      )}
      <h1>Total Attendance: {attendanceTotal}</h1>
    </div>
  );
};

export default EmployeeAttendancePieChart;

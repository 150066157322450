// import React, { useContext, useEffect, useRef, useState } from "react";
// import {
//   CommonTooltip,
//   CustomActionDropDown,
//   CustomAssignees,
//   CustomButton,
//   CustomDrawer,
//   CustomGanttChart,
//   CustomInput,
//   CustomModal,
//   CustomSelect,
//   CustomStatusButton,
//   CustomStatusDropdown,
//   FilterSelect,
//   NoDataFound,
//   Pagination,
//   PaginationText,
//   TableHeader,
// } from "../common";
// import Skeleton from "react-loading-skeleton";
// import { HiOutlineDotsVertical } from "react-icons/hi";
// import { useDispatch, useSelector } from "react-redux";
// import useOnClickOutside from "../../helper/onClickOutside";
// import { IoMdArrowDropdown, IoMdSearch } from "react-icons/io";
// import {
//   MdKeyboardArrowDown,
//   MdOutlineEdit,
//   MdOutlineFilterAlt,
// } from "react-icons/md";
// import { FaPlusCircle, FaRegQuestionCircle } from "react-icons/fa";
// import { useNavigate } from "react-router-dom";

// import {
//   dateFormatter,
//   getDateDifferenceStatus,
// } from "../../helper/formatDate";
// import {
//   filterPriorityOptions,
//   filterStatusOptions,
//   Roles,
//   Status,
// } from "../../constant/Constant";
// import { setLoading } from "../../redux/slice/loadingSlice";
// import { apiConnector } from "../../networking/ApiConnector";
// import { taskEndPoints } from "../../networking/Endpoints";
// import { toast } from "react-toastify";
// import { IoSwapHorizontal } from "react-icons/io5";
// import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as yup from "yup";
// import { GrView } from "react-icons/gr";
// import { BsDiagram2, BsInfoCircle } from "react-icons/bs";
// import { setActiveTabInTask } from "../../redux/slice/taskSlice";
// import {
//   getAllProject,
//   getAllProjectMilestone,
//   setActiveTabInProject,
// } from "../../redux/slice/projectSlice";
// import { fetchAllEmployees } from "../../redux/slice/employeeSlice";
// import { setTaskFilter } from "../../redux/slice/filterSlice";
// import { CustomAlertContext } from "../../context";
// import { ViewMode } from "gantt-task-react";
// const baseUrl = process.env.REACT_APP_BASE_URL;
// const statusUpdateFormValidation = yup.object().shape({
//   hours_logged: yup
//     .string()
//     .matches(
//       /^(?:\d{2}|\d{3}):([0-5]\d)$/,
//       "Hours Logged must be in the format HH:MM"
//     )
//     .required("Hours Logged is required"),
//   completed_on: yup.string().required("Completed_on is required"),
//   completed_by: yup
//     .array()
//     .of(yup.string().required("Each member must be a valid string"))
//     .required("Completed_by is required"),
// });

// const CommonTaskTable = ({
//   currentPage = 1,
//   itemsPerPage = 10,
//   setCurrentPage = () => {},
//   setItemsPerPage = () => {},
//   tasks = [],
//   projectInfo = {},
//   tasksTableData = [],
//   setTasksTableData,
//   taskCount = 0,
//   defaultProject = "",
//   fetchAllTasks,
// }) => {
//   const drawerRef = useRef(),
//     { setToastNotification } = useContext(CustomAlertContext),
//     statusRef = useRef(),
//     statusModalRef = useRef(),
//     navigate = useNavigate(),
//     dispatch = useDispatch(),
//     { token, user } = useSelector((state) => state.auth),
//     { employeeData } = useSelector((state) => state.employee),
//     { projectData } = useSelector((state) => state.project),
//     { taskLoading } = useSelector((state) => state.task),
//     { taskFilter } = useSelector((state) => state.filter),
//     [openFilterDrawer, setOpenFilterDrawer] = useState(false),
//     // [filterValue, setFilterValue] = useState(taskFilter ? taskFilter : null),
//     [searchString, setSearchString] = useState(""),
//     [taskId, setTaskId] = useState(""),
//     [taskStatusCompletedDetails, setTaskStatusCompletedDetails] =
//       useState(null),
//     [statusIndex, setStatusIndex] = useState(""),
//     [openStatus, setOpenStatus] = useState(false),
//     [statusModal, setStatusModal] = useState(false),
//     [selectedStatus, setSelectedStatus] = useState(""),
//     [projects, setProjects] = useState([]),
//     [projectMileStones, setProjectMilestones] = useState([]),
//     [taskAssignees, setTaskAssignees] = useState([]);
//   const {
//     control,
//     handleSubmit,
//     reset,
//     watch,
//     formState: { errors },
//   } = useForm({
//     resolver: yupResolver(statusUpdateFormValidation),
//   });
//   useEffect(() => {
//     reset({
//       hours_logged: taskStatusCompletedDetails
//         ? taskStatusCompletedDetails?.hours_logged
//         : undefined,
//       completed_on: taskStatusCompletedDetails
//         ? taskStatusCompletedDetails?.completed_on
//         : undefined,
//       completed_by: taskStatusCompletedDetails
//         ? taskStatusCompletedDetails?.completed_by
//         : undefined,
//     });
//   }, [taskStatusCompletedDetails]);
//   const handleStatusChange = async (newStatus) => {
//     setSelectedStatus(newStatus);
//     setOpenStatus(false);
//     setStatusModal(true);
//   };
//   const TableHeads = [
//     "Task ID",
//     "Name",
//     "Effort(hrs)",
//     "Resources",
//     // "Gantt",
//     // "Start Date",
//     // "Due Date",
//     // "Assign By",
//     // "Status",
//     // "Actions",
//   ];

//   useOnClickOutside(drawerRef, () => setOpenFilterDrawer(false));
//   useOnClickOutside(statusRef, () => setStatusIndex(null));
//   useOnClickOutside(statusModalRef, () => setStatusModal(false));

//   const updateTaskStatusHandler = async (data) => {
//     dispatch(setLoading(true));
//     try {
//       const task_response = await apiConnector(
//         "PUT",
//         `${taskEndPoints?.TASK_API}/${taskId}`,
//         selectedStatus === "Completed" ? data : { status: selectedStatus },

//         {
//           Authorization: `Bearer ${token}`,
//         }
//       );
//       setToastNotification(task_response?.data?.message, "success");
//       if (fetchAllTasks) {
//         fetchAllTasks();
//       }
//       setStatusModal(false);
//     } catch (error) {
//       setToastNotification(error?.message, "error");
//     } finally {
//       dispatch(setLoading(false));
//     }
//   };

//   const pageCount = Math.ceil(taskCount / itemsPerPage);
//   const handlePageClick = (event) => {
//     setCurrentPage(event?.selected + 1);
//   };
//   const onSubmitHandler = (data) => {
//     const payload = {
//       status: selectedStatus,
//       hours_logged: data?.hours_logged ? data?.hours_logged : undefined,
//       completed_on: data?.completed_on ? data?.completed_on : undefined,
//       completed_by: data?.completed_by ? data?.completed_by : [],
//     };
//     updateTaskStatusHandler(payload);
//   };
//   const filterTask = (e) => {
//     const val = e?.target?.value;
//     setSearchString(e?.target?.value);
//     if (val?.length > 0) {
//       const filterTasks = tasks?.filter(
//         (item) =>
//           item?.title.toLowerCase().includes(val.toLowerCase()) ||
//           item?.status.toLowerCase().includes(val.toLowerCase()) ||
//           dateFormatter(item?.start_date)
//             .toLowerCase()
//             .includes(val.toLowerCase()) ||
//           dateFormatter(item?.due_date)
//             .toLowerCase()
//             .includes(val.toLowerCase())
//       );
//       setTasksTableData(filterTasks);
//     } else {
//       setTasksTableData(tasks);
//     }
//   };
//   const fetchProjects = async () => {
//     try {
//       const response = await dispatch(getAllProject({ token })).unwrap();
//       if (response?.data) {
//         setProjects(response?.data?.projects);
//       }
//     } catch (error) {
//       setToastNotification(error?.message, "error");
//     }
//   };
//   const fetchEmployees = async () => {
//     try {
//       await dispatch(fetchAllEmployees({ token }));
//     } catch (error) {
//       setToastNotification(error?.message, "error");
//     }
//   };
//   const fetchMilestone = async () => {
//     try {
//       const projectId = defaultProject;
//       const response = await dispatch(
//         getAllProjectMilestone({
//           token,
//           projectId,
//         })
//       ).unwrap();

//       if (response?.data) {
//         setProjectMilestones(response?.data?.milestone);
//       }
//     } catch (error) {
//       setToastNotification(error?.message, "error");
//     }
//   };

//   useEffect(() => {
//     if (defaultProject && user?.roleId !== Roles?.employee) {
//       fetchMilestone();
//     }
//   }, [defaultProject]);

//   useEffect(() => {
//     if (
//       !defaultProject ||
//       !projectData ||
//       (projectData?.page_number && projectData?.page_size)
//     ) {
//       fetchProjects();
//     }
//     if (
//       user?.roleId !== Roles?.employee &&
//       (!employeeData || (employeeData?.page_number && employeeData?.page_size))
//     ) {
//       fetchEmployees();
//     }
//   }, []);

//   const projectOptions = projectData?.projects
//     ?.filter((project) => project?.projectStatus !== "Cancelled")
//     ?.map((project) => ({
//       name: project?.project_name,
//       value: project?._id,
//     }));
//   projectOptions?.unshift({ name: "All", value: "" });

//   const assign_to_options = [];

//   employeeData?.employees
//     ?.filter((item) => item?.isActive === true)
//     ?.map((member) =>
//       assign_to_options.push({
//         name: member?.user_name,
//         value: member?._id,
//         designation: member?.designation?.designation,
//         avatar: member?.avatar
//           ? `${baseUrl}/${member?.avatar}`
//           : `https://api.dicebear.com/5.x/initials/svg?seed=${member?.user_name}`,
//       })
//     );

//   assign_to_options?.unshift({ name: "All", value: "" });
//   const assign_by_options = [];

//   employeeData?.employees
//     ?.filter(
//       (item) => item?.roleId !== Roles?.employee && item?.isActive === true
//     )
//     ?.map((member) =>
//       assign_by_options.push({
//         name: member?.user_name,
//         value: member?._id,
//         designation: member?.designation?.designation,
//         avatar: member?.avatar
//           ? `${baseUrl}/${member?.avatar}`
//           : `https://api.dicebear.com/5.x/initials/svg?seed=${member?.user_name}`,
//       })
//     );

//   assign_by_options?.unshift({ name: "All", value: "" });
//   const milestoneOptions = projectMileStones?.map((item) => ({
//     name: item?.milestone_name,
//     value: item?._id,
//   }));
//   milestoneOptions?.unshift({ name: "All", value: "" });

//   const filterTaskApiHandler = () => {
//     const { project, milestone, assign_to, assign_by, status, priority } =
//       taskFilter;
//     fetchAllTasks(project, milestone, assign_to, assign_by, status, priority);
//   };

//   const showButton = projectInfo
//     ? user?.roleId == Roles?.admin ||
//       projectInfo?.project_manager === user?._id ||
//       projectInfo?.team_lead?.some((tl) => tl?.user === user?._id)
//     : user?.roleId !== Roles?.hr && user?.roleId !== Roles?.employee;

//   const colors = {
//     in_progress: "#FFEEC9",
//     on_hold: "#E0EEFF",
//     not_started: "#F0F0F0",
//     completed: "#53D653",
//     cancelled: "#FFEDED",
//   };
//   const getStatusColor = (status) => {
//     switch (status) {
//       case Status?.in_progress:
//         return colors?.in_progress;
//       case Status?.on_hold:
//         return colors?.on_hold;
//       case Status?.cancelled:
//         return colors?.cancelled;
//       case Status?.completed:
//         return colors?.completed;
//       case Status?.not_started:
//         return colors?.not_started;
//       default:
//         return "";
//     }
//   };
//   console.log(tasksTableData, "tasksTableData");
//   const formattedTask =
//     tasksTableData?.length &&
//     tasksTableData?.map((item) => {
//       return {
//         start: item?.start_date ? new Date(item?.start_date) : new Date(),
//         end: item?.due_date ? new Date(item?.due_date) : new Date(),
//         name: item?.title,
//         id: item?._id,
//         progress: 30,
//         type: "task",
//         isDisabled: true,
//         styles: {
//           progressSelectedColor: "#56D156",
//           progressColor: "#56D156",
//           backgroundColor: getStatusColor(item?.status),
//           backgroundSelectedColor: getStatusColor(item?.status),
//           fontWeight: 700,

//           // backgroundSelectedColor: "#000000",
//         },
//       };
//     });
//   return (
//     <div className="w-full bg-custom-white rounded-md">
//       <section className="flex sm:flex-row flex-col justify-between items-center p-4 py-2 rounded-md">
//         <h1 className="font-semibold">Task List</h1>
//         <div className="flex flex-col md:flex-row justify-center items-center gap-4">
//           <div className="relative ">
//             <IoMdSearch className="absolute z-10 top-3 left-1 text-slate-400" />
//             <input
//               name="searchString"
//               label=""
//               type="text"
//               placeholder="Search"
//               onChange={(e) => filterTask(e)}
//               value={searchString}
//               className="bg-custom-white px-6 py-2 font-semibold text-sm border outline-none rounded-md"
//             />
//           </div>
//           <CustomButton
//             title={
//               <div className="relative flex justify-center items-center font-semibold">
//                 {(taskFilter?.project ||
//                   taskFilter?.milestone ||
//                   taskFilter?.assign_to ||
//                   taskFilter?.assign_by ||
//                   taskFilter?.status ||
//                   taskFilter?.priority) && (
//                   <div className="absolute w-5 h-5 flex justify-center items-center rounded-full bg-green-500 -right-4 -top-4 text-xs font-semibold text-custom-white">
//                     {
//                       Object.entries(taskFilter).filter(
//                         ([key, value]) => value !== ""
//                       ).length
//                     }
//                   </div>
//                 )}
//                 <MdOutlineFilterAlt size={20} />
//                 <span>Filters</span>
//               </div>
//             }
//             onClick={() =>
//               setOpenFilterDrawer((openFilterDrawer) => !openFilterDrawer)
//             }
//             buttonType="submit"
//             classname="bg-custom-white text-slate-400 p-2 font-semibold text-sm rounded-md border"
//           />

//           {showButton ? (
//             <CustomButton
//               title={
//                 <div className="flex justify-center items-center gap-2 ">
//                   <FaPlusCircle />
//                   <span>Add Task</span>
//                 </div>
//               }
//               buttonType="button"
//               onClick={() =>
//                 navigate("/task/create", {
//                   state: { project: defaultProject },
//                 })
//               }
//               classname="bg-gradient-custom text-custom-white font-semibold text-sm px-5 py-2 rounded-md"
//             />
//           ) : (
//             ""
//           )}
//         </div>
//         {openFilterDrawer && (
//           <CustomDrawer
//             ref={drawerRef}
//             open={openFilterDrawer}
//             setOpen={setOpenFilterDrawer}
//             filterName="Task Filter"
//           >
//             {!defaultProject ? (
//               <div className="my-4">
//                 <FilterSelect
//                   name="project"
//                   label="Project"
//                   placeholder="Select a project"
//                   options={projectOptions}
//                   defaultValue={taskFilter?.project}
//                   onChange={(selected) => {
//                     // setFilterValue((prev) => ({
//                     //   ...prev,
//                     //   project: selected?.value,
//                     // }));
//                     dispatch(
//                       setTaskFilter({
//                         project: selected?.value,
//                       })
//                     );
//                   }}
//                 />
//               </div>
//             ) : (
//               user?.roleId !== Roles?.employee && (
//                 <div className="my-4">
//                   <FilterSelect
//                     name="milestone"
//                     label="Milestone"
//                     placeholder="Select a milestone"
//                     options={milestoneOptions}
//                     defaultValue={taskFilter?.milestone}
//                     onChange={(selected) => {
//                       // setFilterValue((prev) => ({
//                       //   ...prev,
//                       //   milestone: selected?.value,
//                       // }));

//                       dispatch(
//                         setTaskFilter({
//                           milestone: selected?.value,
//                         })
//                       );
//                     }}
//                   />
//                 </div>
//               )
//             )}
//             {user?.roleId !== Roles?.employee && (
//               <div className="my-4">
//                 <FilterSelect
//                   name="assign_to"
//                   label="Assign To"
//                   placeholder="Select a assign_to"
//                   options={assign_to_options}
//                   defaultValue={taskFilter?.assign_to}
//                   onChange={(selected) => {
//                     // setFilterValue((prev) => ({
//                     //   ...prev,
//                     //   assign_to: selected?.value,
//                     // }));

//                     dispatch(
//                       setTaskFilter({
//                         assign_to: selected?.value,
//                       })
//                     );
//                   }}
//                 />
//               </div>
//             )}
//             {user?.roleId !== Roles?.employee && (
//               <div className="my-4">
//                 <FilterSelect
//                   name="assign_by"
//                   label="Assign By"
//                   placeholder="Select a assign_by"
//                   options={assign_by_options}
//                   defaultValue={taskFilter?.assign_by}
//                   onChange={(selected) => {
//                     // setFilterValue((prev) => ({
//                     //   ...prev,
//                     //   assign_by: selected?.value,
//                     // }));

//                     dispatch(
//                       setTaskFilter({
//                         assign_by: selected?.value,
//                       })
//                     );
//                   }}
//                 />
//               </div>
//             )}
//             <div className="my-4">
//               <FilterSelect
//                 name="status"
//                 label="Status"
//                 placeholder="Select a status"
//                 options={filterStatusOptions}
//                 defaultValue={taskFilter?.status}
//                 onChange={(selected) => {
//                   // setFilterValue((prev) => ({
//                   //   ...prev,
//                   //   status: selected?.value,
//                   // }));

//                   dispatch(
//                     setTaskFilter({
//                       status: selected?.value,
//                     })
//                   );
//                 }}
//               />
//             </div>
//             <div className="my-4">
//               <FilterSelect
//                 name="priority"
//                 label="Priority"
//                 placeholder="Select a priority"
//                 options={filterPriorityOptions}
//                 defaultValue={taskFilter?.priority}
//                 onChange={(selected) => {
//                   // setFilterValue((prev) => ({
//                   //   ...prev,
//                   //   priority: selected?.value,
//                   // }));

//                   dispatch(
//                     setTaskFilter({
//                       priority: selected?.value,
//                     })
//                   );
//                 }}
//               />
//             </div>
//             <div className="w-full absolute bottom-0 right-0 p-4">
//               <div className="w-full h-[1px] bg-slate-200 my-2" />
//               <div className="flex items-center justify-between ">
//                 <CustomButton
//                   title={"Clear"}
//                   onClick={() => {
//                     setCurrentPage(1);
//                     dispatch(setTaskFilter(null));
//                     fetchAllTasks("", "", "", "", "", "");
//                     setOpenFilterDrawer(false);
//                   }}
//                   buttonType="submit"
//                   classname={"border px-5 py-1 rounded-md"}
//                 />
//                 <CustomButton
//                   title={"Apply"}
//                   onClick={() => {
//                     // dispatch(setTaskFilter(filterValue));
//                     setCurrentPage(1);
//                     filterTaskApiHandler();
//                     setOpenFilterDrawer(false);
//                   }}
//                   buttonType="submit"
//                   classname={
//                     "border px-5 py-1 rounded-md bg-custom-status-completed text-custom-white"
//                   }
//                 />
//               </div>
//             </div>
//           </CustomDrawer>
//         )}
//       </section>
//       <section className="w-full h-full mobilescroll">
//         <table className="w-full border rounded-lg">
//           <thead>{/* <TableHeader TableHeads={TableHeads} /> */}</thead>
//           {taskLoading ? (
//             <tbody>
//               <tr>
//                 <td
//                   colSpan={TableHeads?.length}
//                   className="text-center font-semibold text-sm p-3"
//                 >
//                   <Skeleton
//                     count={5}
//                     width={"100%"}
//                     height={"22px"}
//                     className="my-2"
//                   />
//                 </td>
//               </tr>
//             </tbody>
//           ) : tasksTableData && tasksTableData?.length > 0 ? (
//             <>
//               <tbody>
//                 <div className="w-full clearfix">
//                   <div className="w-full TaskListtable-left">
//                     <TableHeader TableHeads={TableHeads} />
//                     {tasksTableData?.map((item, index) => {
//                       return (
//                         <tr
//                           key={item?._id}
//                           className="border-b border-r hover:bg-slate-100"
//                         >
//                           <td className="text-sm p-2">{item?.taskId}</td>
//                           <td className="text-sm ellipsis p-2">
//                             <div className="flex flex-col justify-start cursor-pointer text-sm">
//                               <span
//                                 onClick={() => {
//                                   dispatch(setActiveTabInTask("task_details"));
//                                   navigate("/task/view", {
//                                     state: { id: item?._id },
//                                   });
//                                 }}
//                                 className="text-custom-black font-medium flex items-center gap-2"
//                               >
//                                 <h2 className="ellipsis">{item?.title}</h2>
//                                 <CommonTooltip tooltip={"Subtask"}>
//                                   <div
//                                     onClick={(e) => {
//                                       e.stopPropagation();
//                                       dispatch(setActiveTabInTask("sub_task"));
//                                       navigate("/task/view", {
//                                         state: {
//                                           id: item?._id,
//                                           // showButton: showButton,
//                                           // assign: item?.assign_to,
//                                         },
//                                       });
//                                     }}
//                                     className="border p-0.5 rounded-md flex items-center"
//                                   >
//                                     <span>
//                                       <BsDiagram2 size={16} />
//                                     </span>
//                                     <span className="text-[13px]">
//                                       {item?.completed_subtask_count}/
//                                       {item?.subtask_count}
//                                     </span>
//                                   </div>
//                                 </CommonTooltip>
//                               </span>
//                               <span
//                                 onClick={(e) => {
//                                   e.stopPropagation();
//                                   dispatch(setActiveTabInProject("overview"));
//                                   navigate("/project/view", {
//                                     state: { id: item?.project?._id },
//                                   });
//                                 }}
//                                 className="text-[#525252] text-xs underline"
//                               >
//                                 {item?.project?.project_name}
//                               </span>
//                             </div>
//                           </td>
//                           <td className="text-sm p-2">
//                             {item?.hours_logged ?? "--"}
//                           </td>
//                           <td className=" text-sm p-2">
//                             {(
//                               <CustomAssignees
//                                 assignees={item?.assign_to}
//                                 maxLimit={2}
//                               />
//                             ) ?? "--"}
//                           </td>

//                           {/* <td className="px-2 text-sm">
//                         {item?.start_date
//                           ? dateFormatter(item?.start_date, {
//                               format: "MMM DD, YYYY",
//                               includeTimeForToday: false,
//                             })
//                           : "--"}
//                       </td>
//                       <td className="px-2 text-sm">
//                         {item?.due_date ? (
//                           <div className="flex items-center gap-2">
//                             <span
//                               className={`${
//                                 new Date() >
//                                   new Date(
//                                     item?.status !== "Completed"
//                                       ? item?.due_date
//                                       : item?.completed_on
//                                   ) && item?.status !== "Completed"
//                                   ? "text-red-500"
//                                   : ""
//                               }`}
//                             >
//                               {dateFormatter(item?.due_date, {
//                                 format: "MMM DD, YYYY",
//                                 includeTimeForToday: false,
//                               })}
//                             </span>
//                             <CommonTooltip
//                               tooltip={getDateDifferenceStatus(
//                                 item?.due_date,
//                                 item?.status === "Completed"
//                                   ? item?.completed_on
//                                   : null
//                               )}
//                               tooltipClass={
//                                 "bg-custom-white dark:bg-custom-white text-lg"
//                               }
//                             >
//                               <FaRegQuestionCircle className="text-slate-400" />
//                             </CommonTooltip>
//                           </div>
//                         ) : (
//                           "--"
//                         )}
//                       </td>

//                       <td className="px-2 text-sm">
//                         <div className="flex justify-start items-center gap-3">
//                           {item?.assign_by?._id === user?._id
//                             ? "You"
//                             : item?.assign_by?.user_name}
//                         </div>
//                       </td>
//                       <td
//                         onClick={() => {
//                           if (
//                             user?.roleId == Roles?.admin ||
//                             user?._id == item?.project_manager?._id ||
//                             item?.team_lead?.some(
//                               (lead) => lead?._id === user?._id
//                             ) ||
//                             // user?.roleId === Roles?.admin ||
//                             // user?._id == item?.project_manager?._id ||
//                             // item?.team_lead?.some(
//                             //   (lead) => lead?._id !== user?._id
//                             // ) ||
//                             // // user?.roleId === Roles?.team_lead ||
//                             item?.assign_to?.find(
//                               (element) => element?._id === user?._id
//                             )
//                           ) {
//                             setStatusIndex(index);
//                             setTaskId(item?._id);
//                             setTaskAssignees(item?.assign_to);
//                             setTaskStatusCompletedDetails({
//                               hours_logged: item?.hours_logged,
//                               completed_on: item?.completed_on?.split("T")[0],
//                               completed_by: item?.completed_by?.map(
//                                 (item) => item?._id
//                               ),
//                             });
//                             setOpenStatus(!openStatus);
//                           }
//                         }}
//                         className={`text-sm cursor-pointer`}
//                       >
//                         <div
//                           className={`w-fit relative p-2 cursor-pointer rounded-lg flex justify-between items-center gap-2  `}
//                         >
//                           <CustomStatusButton
//                             selectedStatus={item?.status}
//                             isDropdown={true}
//                           />
//                           {openStatus && statusIndex === index && (
//                             <CustomStatusDropdown
//                               statusRef={statusRef}
//                               handleStatusChange={handleStatusChange}
//                             />
//                           )}
//                         </div>
//                       </td> */}
//                           {/* <td className="cursor-pointer">
//                         <div className="wh-17 flex justify-center items-center gap-2 ">
//                           <div
//                             onClick={() => {
//                               dispatch(setActiveTabInTask("task_details"));
//                               navigate("/task/view", {
//                                 state: { id: item?._id },
//                               });
//                             }}
//                             className="p-1 border border-green-600 text-green-600 hover:bg-green-600 hover:text-custom-white rounded-md cursor-pointer"
//                           >
//                             <GrView />
//                           </div>
//                           {user?.roleId == Roles?.admin ||
//                           user?._id == item?.project_manager?._id ||
//                           item?.team_lead?.some(
//                             (lead) => lead?._id === user?._id
//                           ) ? (
//                             <div
//                               onClick={() =>
//                                 navigate("/task/create", {
//                                   state: { task: item },
//                                 })
//                               }
//                               className="p-1 border border-custom-blue text-custom-blue hover:bg-custom-blue hover:text-custom-light-blue rounded-md cursor-pointer"
//                             >
//                               <MdOutlineEdit />
//                             </div>
//                           ) : (
//                             ""
//                           )}
//                         </div>
//                       </td> */}
//                         </tr>
//                       );
//                     })}
//                   </div>
//                   <div className="TaskListtable-Right">
//                     <CustomGanttChart
//                       tasks={formattedTask}
//                       viewMode={"Day"}
//                       columnWidth={60}
//                       listCellWidth=""
//                       headerHeight={40}
//                       rowHeight={58}
//                       hideChildren={true}
//                     />
//                   </div>
//                 </div>
//               </tbody>
//             </>
//           ) : (
//             <tbody>
//               <tr>
//                 <td
//                   colSpan={TableHeads?.length}
//                   className="text-center font-semibold text-sm p-4"
//                 >
//                   <NoDataFound />
//                 </td>
//               </tr>
//             </tbody>
//           )}
//           <tfoot className="relative border-t">
//             <tr className="w-full">
//               <td className="text-slate-400 text-sm px-2 py-4" colSpan={6}>
//                 <PaginationText
//                   currentPage={currentPage}
//                   itemsPerPage={itemsPerPage}
//                   dataCount={taskCount}
//                   dataLength={tasks?.length}
//                 />
//               </td>
//               <td className="absolute right-2 top-1.5">
//                 <Pagination
//                   handlePageClick={handlePageClick}
//                   pageRangeDisplayed={itemsPerPage}
//                   pageCount={pageCount}
//                   name={"pageSize"}
//                   onChange={(e) => setItemsPerPage(e?.target?.value)}
//                 />
//               </td>
//             </tr>
//           </tfoot>
//         </table>
//       </section>
//       {statusModal && (
//         <CustomModal
//           isOpen={statusModal}
//           onClose={() => setStatusModal(false)}
//           ref={statusModalRef}
//         >
//           {selectedStatus === "Completed" ? (
//             <form
//               noValidate
//               onSubmit={handleSubmit(onSubmitHandler)}
//               className="w-full"
//             >
//               <div className="my-2 sm:my-4 ">
//                 <CustomInput
//                   name="hours_logged"
//                   label="Hours Logged"
//                   inputType="text"
//                   placeholder="00:00"
//                   control={control}
//                   required={true}
//                   error={errors?.hours_logged?.message}
//                 />
//               </div>
//               <div className="my-2 sm:my-4 ">
//                 <CustomInput
//                   name="completed_on"
//                   label="Completed On"
//                   inputType="date"
//                   placeholder="Select..."
//                   control={control}
//                   required={true}
//                   error={errors?.completed_on?.message}
//                 />
//               </div>
//               <div className="my-2 sm:my-4 ">
//                 <CustomSelect
//                   name="completed_by"
//                   label="Completed By"
//                   inputType="text"
//                   placeholder="Select..."
//                   control={control}
//                   multiple={true}
//                   required={true}
//                   options={
//                     taskAssignees?.map((member) => ({
//                       name:
//                         member?._id === user?._id ? "You" : member?.user_name,
//                       value: member?._id,
//                       designation: member?.designation?.designation,
//                       avatar: member?.avatar
//                         ? `${baseUrl}/${member?.avatar}`
//                         : `https://api.dicebear.com/5.x/initials/svg?seed=${member?.user_name}`,
//                     })) ?? []
//                   }
//                   classname="bg-custom-white"
//                   error={errors?.completed_by?.message}
//                 />
//               </div>
//               <div className="flex justify-end items-center gap-5 mt-5">
//                 <CustomButton
//                   title={"Cancel"}
//                   onClick={() => setStatusModal(false)}
//                   buttonType={"button"}
//                   classname={"px-2 py-1 bg-custom-white border rounded-md "}
//                 />
//                 <CustomButton
//                   title={"Continue"}
//                   buttonType={"submit"}
//                   classname={
//                     "bg-gradient-custom text-custom-white px-2 py-1 rounded-md"
//                   }
//                 />
//               </div>
//             </form>
//           ) : (
//             <>
//               <div className="text-center text-custom-black">
//                 <h2 className="text-2xl font-bold my-4">Change Status</h2>
//               </div>
//               <div className="w-20 h-20 bg-[#C3E6FF] p-2 rounded-full flex justify-center items-center">
//                 <div className="bg-custom-blue rounded-full w-16 h-16 flex justify-center items-center">
//                   <IoSwapHorizontal size={32} className="text-custom-white" />
//                 </div>
//               </div>
//               <div className="text-center text-[#747474]">
//                 <h2 className="font-medium my-4">
//                   are you sure you want to change status?
//                 </h2>
//               </div>

//               <div className="flex justify-end items-center gap-5 mt-5">
//                 <CustomButton
//                   title={"Cancel"}
//                   onClick={() => setStatusModal(false)}
//                   buttonType={"button"}
//                   classname={"px-2 py-1 bg-custom-white border rounded-md "}
//                 />
//                 <CustomButton
//                   title={"Continue"}
//                   onClick={() => {
//                     updateTaskStatusHandler();
//                   }}
//                   buttonType={"button"}
//                   classname={
//                     "bg-gradient-custom text-custom-white px-2 py-1 rounded-md"
//                   }
//                 />
//               </div>
//             </>
//           )}
//         </CustomModal>
//       )}
//     </div>
//   );
// };

// export default CommonTaskTable;

import React, { useContext, useEffect, useRef, useState } from "react";
import {
  CommonTooltip,
  CustomActionDropDown,
  CustomAssignees,
  CustomButton,
  CustomDrawer,
  CustomGanttChart,
  CustomInput,
  CustomModal,
  CustomSelect,
  CustomStatusButton,
  CustomStatusDropdown,
  FilterSelect,
  NoDataFound,
  Pagination,
  PaginationText,
  TableHeader,
} from "../common";
import Skeleton from "react-loading-skeleton";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import useOnClickOutside from "../../helper/onClickOutside";
import { IoMdArrowDropdown, IoMdSearch } from "react-icons/io";
import {
  MdKeyboardArrowDown,
  MdOutlineEdit,
  MdOutlineFilterAlt,
} from "react-icons/md";
import { FaPlusCircle, FaRegQuestionCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import {
  dateFormatter,
  getDateDifferenceStatus,
} from "../../helper/formatDate";
import {
  filterPriorityOptions,
  filterStatusOptions,
  Roles,
  Status,
} from "../../constant/Constant";
import { setLoading } from "../../redux/slice/loadingSlice";
import { apiConnector } from "../../networking/ApiConnector";
import { taskEndPoints } from "../../networking/Endpoints";
import { toast } from "react-toastify";
import { IoSwapHorizontal } from "react-icons/io5";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { GrView } from "react-icons/gr";
import { BsDiagram2, BsInfoCircle } from "react-icons/bs";
import { setActiveTabInTask } from "../../redux/slice/taskSlice";
import {
  getAllProject,
  getAllProjectMilestone,
  setActiveTabInProject,
} from "../../redux/slice/projectSlice";
import { fetchAllEmployees } from "../../redux/slice/employeeSlice";
import { setTaskFilter } from "../../redux/slice/filterSlice";
import { CustomAlertContext } from "../../context";
import { ViewMode } from "gantt-task-react";
const baseUrl = process.env.REACT_APP_BASE_URL;
const statusUpdateFormValidation = yup.object().shape({
  hours_logged: yup
    .string()
    .matches(
      /^(?:\d{2}|\d{3}):([0-5]\d)$/,
      "Hours Logged must be in the format HH:MM"
    )
    .required("Hours Logged is required"),
  completed_on: yup.string().required("Completed_on is required"),
  completed_by: yup
    .array()
    .of(yup.string().required("Each member must be a valid string"))
    .required("Completed_by is required"),
});

const CommonTaskTable = ({
  currentPage = 1,
  itemsPerPage = 10,
  setCurrentPage = () => {},
  setItemsPerPage = () => {},
  tasks = [],
  projectInfo = {},
  tasksTableData = [],
  setTasksTableData,
  taskCount = 0,
  defaultProject = "",
  fetchAllTasks,
}) => {
  const drawerRef = useRef(),
    { setToastNotification } = useContext(CustomAlertContext),
    statusRef = useRef(),
    statusModalRef = useRef(),
    navigate = useNavigate(),
    dispatch = useDispatch(),
    { token, user } = useSelector((state) => state.auth),
    { employeeData } = useSelector((state) => state.employee),
    { projectData } = useSelector((state) => state.project),
    { taskLoading } = useSelector((state) => state.task),
    { taskFilter } = useSelector((state) => state.filter),
    [openFilterDrawer, setOpenFilterDrawer] = useState(false),
    // [filterValue, setFilterValue] = useState(taskFilter ? taskFilter : null),
    [searchString, setSearchString] = useState(""),
    [taskId, setTaskId] = useState(""),
    [taskStatusCompletedDetails, setTaskStatusCompletedDetails] =
      useState(null),
    [statusIndex, setStatusIndex] = useState(""),
    [openStatus, setOpenStatus] = useState(false),
    [statusModal, setStatusModal] = useState(false),
    [selectedStatus, setSelectedStatus] = useState(""),
    [projects, setProjects] = useState([]),
    [projectMileStones, setProjectMilestones] = useState([]),
    [taskAssignees, setTaskAssignees] = useState([]);
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(statusUpdateFormValidation),
  });
  useEffect(() => {
    reset({
      hours_logged: taskStatusCompletedDetails
        ? taskStatusCompletedDetails?.hours_logged
        : undefined,
      completed_on: taskStatusCompletedDetails
        ? taskStatusCompletedDetails?.completed_on
        : undefined,
      completed_by: taskStatusCompletedDetails
        ? taskStatusCompletedDetails?.completed_by
        : undefined,
    });
  }, [taskStatusCompletedDetails]);
  const handleStatusChange = async (newStatus) => {
    setSelectedStatus(newStatus);
    setOpenStatus(false);
    setStatusModal(true);
  };
  // const TableHeads = [
  //   "Task ID",
  //   "Name",
  //   "Effort(hrs)",
  //   "Resources",
  //   // "Gantt",
  //   // "Start Date",
  //   // "Due Date",
  //   // "Assign By",
  //   // "Status",
  //   // "Actions",
  // ];
  const TableHeads = [
    "Task ID",
    "Name",
    "Start Date",
    "Due Date",
    "Resources",
    "Assign By",
    "Effort(hrs)",
    "Status",
    "Actions",
  ];

  useOnClickOutside(drawerRef, () => setOpenFilterDrawer(false));
  useOnClickOutside(statusRef, () => setStatusIndex(null));
  useOnClickOutside(statusModalRef, () => setStatusModal(false));

  const updateTaskStatusHandler = async (data) => {
    dispatch(setLoading(true));
    try {
      const task_response = await apiConnector(
        "PUT",
        `${taskEndPoints?.TASK_API}/${taskId}`,
        selectedStatus === "Completed" ? data : { status: selectedStatus },

        {
          Authorization: `Bearer ${token}`,
        }
      );
      setToastNotification(task_response?.data?.message, "success");
      if (fetchAllTasks) {
        fetchAllTasks();
      }
      setStatusModal(false);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };

  const pageCount = Math.ceil(taskCount / itemsPerPage);
  const handlePageClick = (event) => {
    setCurrentPage(event?.selected + 1);
  };
  const onSubmitHandler = (data) => {
    const payload = {
      status: selectedStatus,
      hours_logged: data?.hours_logged ? data?.hours_logged : undefined,
      completed_on: data?.completed_on ? data?.completed_on : undefined,
      completed_by: data?.completed_by ? data?.completed_by : [],
    };
    updateTaskStatusHandler(payload);
  };
  const filterTask = (e) => {
    const val = e?.target?.value;
    setSearchString(e?.target?.value);
    if (val?.length > 0) {
      const filterTasks = tasks?.filter(
        (item) =>
          item?.title.toLowerCase().includes(val.toLowerCase()) ||
          item?.status.toLowerCase().includes(val.toLowerCase()) ||
          dateFormatter(item?.start_date)
            .toLowerCase()
            .includes(val.toLowerCase()) ||
          dateFormatter(item?.due_date)
            .toLowerCase()
            .includes(val.toLowerCase())
      );
      setTasksTableData(filterTasks);
    } else {
      setTasksTableData(tasks);
    }
  };
  const fetchProjects = async () => {
    try {
      const response = await dispatch(getAllProject({ token })).unwrap();
      if (response?.data) {
        setProjects(response?.data?.projects);
      }
    } catch (error) {
      setToastNotification(error?.message, "error");
    }
  };
  const fetchEmployees = async () => {
    try {
      await dispatch(fetchAllEmployees({ token }));
    } catch (error) {
      setToastNotification(error?.message, "error");
    }
  };
  const fetchMilestone = async () => {
    try {
      const projectId = defaultProject;
      const response = await dispatch(
        getAllProjectMilestone({
          token,
          projectId,
        })
      ).unwrap();

      if (response?.data) {
        setProjectMilestones(response?.data?.milestone);
      }
    } catch (error) {
      setToastNotification(error?.message, "error");
    }
  };

  useEffect(() => {
    if (defaultProject && user?.roleId !== Roles?.employee) {
      fetchMilestone();
    }
  }, [defaultProject]);

  useEffect(() => {
    if (
      !defaultProject ||
      !projectData ||
      (projectData?.page_number && projectData?.page_size)
    ) {
      fetchProjects();
    }
    if (
      user?.roleId !== Roles?.employee &&
      (!employeeData || (employeeData?.page_number && employeeData?.page_size))
    ) {
      fetchEmployees();
    }
  }, []);

  const projectOptions = projectData?.projects
    ?.filter((project) => project?.projectStatus !== "Cancelled")
    ?.map((project) => ({
      name: project?.project_name,
      value: project?._id,
    }));
  projectOptions?.unshift({ name: "All", value: "" });

  const assign_to_options = [];

  employeeData?.employees
    ?.filter((item) => item?.isActive === true)
    ?.map((member) =>
      assign_to_options.push({
        name: member?.user_name,
        value: member?._id,
        designation: member?.designation?.designation,
        avatar: member?.avatar
          ? `${baseUrl}/${member?.avatar}`
          : `https://api.dicebear.com/5.x/initials/svg?seed=${member?.user_name}`,
      })
    );

  assign_to_options?.unshift({ name: "All", value: "" });
  const assign_by_options = [];

  employeeData?.employees
    ?.filter(
      (item) => item?.roleId !== Roles?.employee && item?.isActive === true
    )
    ?.map((member) =>
      assign_by_options.push({
        name: member?.user_name,
        value: member?._id,
        designation: member?.designation?.designation,
        avatar: member?.avatar
          ? `${baseUrl}/${member?.avatar}`
          : `https://api.dicebear.com/5.x/initials/svg?seed=${member?.user_name}`,
      })
    );

  assign_by_options?.unshift({ name: "All", value: "" });
  const milestoneOptions = projectMileStones?.map((item) => ({
    name: item?.milestone_name,
    value: item?._id,
  }));
  milestoneOptions?.unshift({ name: "All", value: "" });

  const filterTaskApiHandler = () => {
    const { project, milestone, assign_to, assign_by, status, priority } =
      taskFilter;
    fetchAllTasks(project, milestone, assign_to, assign_by, status, priority);
  };

  const showButton = projectInfo
    ? user?.roleId == Roles?.admin ||
      projectInfo?.project_manager === user?._id ||
      projectInfo?.team_lead?.some((tl) => tl?.user === user?._id)
    : user?.roleId !== Roles?.hr && user?.roleId !== Roles?.employee;

  const colors = {
    in_progress: "#FFEEC9",
    on_hold: "#E0EEFF",
    not_started: "#F0F0F0",
    completed: "#53D653",
    cancelled: "#FFEDED",
  };
  const getStatusColor = (status) => {
    switch (status) {
      case Status?.in_progress:
        return colors?.in_progress;
      case Status?.on_hold:
        return colors?.on_hold;
      case Status?.cancelled:
        return colors?.cancelled;
      case Status?.completed:
        return colors?.completed;
      case Status?.not_started:
        return colors?.not_started;
      default:
        return "";
    }
  };
  console.log(tasksTableData, "tasksTableData");
  const formattedTask =
    tasksTableData?.length &&
    tasksTableData?.map((item) => {
      return {
        start: item?.start_date ? new Date(item?.start_date) : new Date(),
        end: item?.due_date ? new Date(item?.due_date) : new Date(),
        name: item?.title,
        id: item?._id,
        progress: 30,
        type: "task",
        isDisabled: true,
        styles: {
          progressSelectedColor: "#56D156",
          progressColor: "#56D156",
          backgroundColor: getStatusColor(item?.status),
          backgroundSelectedColor: getStatusColor(item?.status),
          fontWeight: 700,

          // backgroundSelectedColor: "#000000",
        },
      };
    });
  return (
    <div className="w-full bg-custom-white rounded-md">
      <section className="flex sm:flex-row flex-col justify-between items-center p-4 py-2 rounded-md">
        <h1 className="font-semibold">Task List</h1>
        <div className="flex flex-col md:flex-row justify-center items-center gap-4">
          <div className="relative ">
            <IoMdSearch className="absolute z-10 top-3 left-1 text-slate-400" />
            <input
              name="searchString"
              label=""
              type="text"
              placeholder="Search"
              onChange={(e) => filterTask(e)}
              value={searchString}
              className="bg-custom-white px-6 py-2 font-semibold text-sm border outline-none rounded-md"
            />
          </div>
          <CustomButton
            title={
              <div className="relative flex justify-center items-center font-semibold">
                {(taskFilter?.project ||
                  taskFilter?.milestone ||
                  taskFilter?.assign_to ||
                  taskFilter?.assign_by ||
                  taskFilter?.status ||
                  taskFilter?.priority) && (
                  <div className="absolute w-5 h-5 flex justify-center items-center rounded-full bg-green-500 -right-4 -top-4 text-xs font-semibold text-custom-white">
                    {
                      Object.entries(taskFilter).filter(
                        ([key, value]) => value !== ""
                      ).length
                    }
                  </div>
                )}
                <MdOutlineFilterAlt size={20} />
                <span>Filters</span>
              </div>
            }
            onClick={() =>
              setOpenFilterDrawer((openFilterDrawer) => !openFilterDrawer)
            }
            buttonType="submit"
            classname="bg-custom-white text-slate-400 p-2 font-semibold text-sm rounded-md border"
          />

          {showButton ? (
            <CustomButton
              title={
                <div className="flex justify-center items-center gap-2 ">
                  <FaPlusCircle />
                  <span>Add Task</span>
                </div>
              }
              buttonType="button"
              onClick={() =>
                navigate("/task/create", {
                  state: { project: defaultProject },
                })
              }
              classname="bg-gradient-custom text-custom-white font-semibold text-sm px-5 py-2 rounded-md"
            />
          ) : (
            ""
          )}
        </div>
        {openFilterDrawer && (
          <CustomDrawer
            ref={drawerRef}
            open={openFilterDrawer}
            setOpen={setOpenFilterDrawer}
            filterName="Task Filter"
          >
            {!defaultProject ? (
              <div className="my-4">
                <FilterSelect
                  name="project"
                  label="Project"
                  placeholder="Select a project"
                  options={projectOptions}
                  defaultValue={taskFilter?.project}
                  onChange={(selected) => {
                    // setFilterValue((prev) => ({
                    //   ...prev,
                    //   project: selected?.value,
                    // }));
                    dispatch(
                      setTaskFilter({
                        project: selected?.value,
                      })
                    );
                  }}
                />
              </div>
            ) : (
              user?.roleId !== Roles?.employee && (
                <div className="my-4">
                  <FilterSelect
                    name="milestone"
                    label="Milestone"
                    placeholder="Select a milestone"
                    options={milestoneOptions}
                    defaultValue={taskFilter?.milestone}
                    onChange={(selected) => {
                      // setFilterValue((prev) => ({
                      //   ...prev,
                      //   milestone: selected?.value,
                      // }));

                      dispatch(
                        setTaskFilter({
                          milestone: selected?.value,
                        })
                      );
                    }}
                  />
                </div>
              )
            )}
            {user?.roleId !== Roles?.employee && (
              <div className="my-4">
                <FilterSelect
                  name="assign_to"
                  label="Assign To"
                  placeholder="Select a assign_to"
                  options={assign_to_options}
                  defaultValue={taskFilter?.assign_to}
                  onChange={(selected) => {
                    // setFilterValue((prev) => ({
                    //   ...prev,
                    //   assign_to: selected?.value,
                    // }));

                    dispatch(
                      setTaskFilter({
                        assign_to: selected?.value,
                      })
                    );
                  }}
                />
              </div>
            )}
            {user?.roleId !== Roles?.employee && (
              <div className="my-4">
                <FilterSelect
                  name="assign_by"
                  label="Assign By"
                  placeholder="Select a assign_by"
                  options={assign_by_options}
                  defaultValue={taskFilter?.assign_by}
                  onChange={(selected) => {
                    // setFilterValue((prev) => ({
                    //   ...prev,
                    //   assign_by: selected?.value,
                    // }));

                    dispatch(
                      setTaskFilter({
                        assign_by: selected?.value,
                      })
                    );
                  }}
                />
              </div>
            )}
            <div className="my-4">
              <FilterSelect
                name="status"
                label="Status"
                placeholder="Select a status"
                options={filterStatusOptions}
                defaultValue={taskFilter?.status}
                onChange={(selected) => {
                  // setFilterValue((prev) => ({
                  //   ...prev,
                  //   status: selected?.value,
                  // }));

                  dispatch(
                    setTaskFilter({
                      status: selected?.value,
                    })
                  );
                }}
              />
            </div>
            <div className="my-4">
              <FilterSelect
                name="priority"
                label="Priority"
                placeholder="Select a priority"
                options={filterPriorityOptions}
                defaultValue={taskFilter?.priority}
                onChange={(selected) => {
                  // setFilterValue((prev) => ({
                  //   ...prev,
                  //   priority: selected?.value,
                  // }));

                  dispatch(
                    setTaskFilter({
                      priority: selected?.value,
                    })
                  );
                }}
              />
            </div>
            <div className="w-full absolute bottom-0 right-0 p-4">
              <div className="w-full h-[1px] bg-slate-200 my-2" />
              <div className="flex items-center justify-between ">
                <CustomButton
                  title={"Clear"}
                  onClick={() => {
                    setCurrentPage(1);
                    dispatch(setTaskFilter(null));
                    fetchAllTasks("", "", "", "", "", "");
                    setOpenFilterDrawer(false);
                  }}
                  buttonType="submit"
                  classname={"border px-5 py-1 rounded-md"}
                />
                <CustomButton
                  title={"Apply"}
                  onClick={() => {
                    // dispatch(setTaskFilter(filterValue));
                    setCurrentPage(1);
                    filterTaskApiHandler();
                    setOpenFilterDrawer(false);
                  }}
                  buttonType="submit"
                  classname={
                    "border px-5 py-1 rounded-md bg-custom-status-completed text-custom-white"
                  }
                />
              </div>
            </div>
          </CustomDrawer>
        )}
      </section>
      <section className="w-full h-full mobilescroll">
        <table className="w-full border rounded-lg">
          <thead>
            <TableHeader TableHeads={TableHeads} />
          </thead>
          {taskLoading ? (
            <tbody>
              <tr>
                <td
                  colSpan={TableHeads?.length}
                  className="text-center font-semibold text-sm p-3"
                >
                  <Skeleton
                    count={5}
                    width={"100%"}
                    height={"22px"}
                    className="my-2"
                  />
                </td>
              </tr>
            </tbody>
          ) : tasksTableData && tasksTableData?.length > 0 ? (
            <>
              <tbody>
                {/* <div className="w-full clearfix">
                  <div className="w-full TaskListtable-left">
                    <TableHeader TableHeads={TableHeads} /> */}
                {tasksTableData?.map((item, index) => {
                  return (
                    <tr
                      key={item?._id}
                      className="border-b border-r hover:bg-slate-100"
                    >
                      <td className="text-sm p-2">{item?.taskId}</td>
                      <td className="text-sm ellipsis p-2">
                        <div className="flex flex-col justify-start cursor-pointer text-sm">
                          <span
                            onClick={() => {
                              dispatch(setActiveTabInTask("task_details"));
                              navigate("/task/view", {
                                state: { id: item?._id },
                              });
                            }}
                            className="text-custom-black font-medium flex items-center gap-2"
                          >
                            <h2 className="ellipsis">{item?.title}</h2>
                            <CommonTooltip tooltip={"Subtask"}>
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                  dispatch(setActiveTabInTask("sub_task"));
                                  navigate("/task/view", {
                                    state: {
                                      id: item?._id,
                                      // showButton: showButton,
                                      // assign: item?.assign_to,
                                    },
                                  });
                                }}
                                className="border p-0.5 rounded-md flex items-center"
                              >
                                <span>
                                  <BsDiagram2 size={16} />
                                </span>
                                <span className="text-[13px]">
                                  {item?.completed_subtask_count}/
                                  {item?.subtask_count}
                                </span>
                              </div>
                            </CommonTooltip>
                          </span>
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              dispatch(setActiveTabInProject("overview"));
                              navigate("/project/view", {
                                state: { id: item?.project?._id },
                              });
                            }}
                            className="text-[#525252] text-xs underline"
                          >
                            {item?.project?.project_name}
                          </span>
                        </div>
                      </td>
                      {/* <td className="text-sm p-2">
                        {item?.hours_logged ?? "--"}
                      </td>
                      <td className=" text-sm p-2">
                        {(
                          <CustomAssignees
                            assignees={item?.assign_to}
                            maxLimit={2}
                          />
                        ) ?? "--"}
                      </td> */}

                      <td className="px-2 text-sm">
                        {item?.start_date
                          ? dateFormatter(item?.start_date, {
                              format: "MMM DD, YYYY",
                              includeTimeForToday: false,
                            })
                          : "--"}
                      </td>
                      <td className="px-2 text-sm">
                        {item?.due_date ? (
                          <div className="flex items-center gap-2">
                            <span
                              className={`${
                                new Date() >
                                  new Date(
                                    item?.status !== "Completed"
                                      ? item?.due_date
                                      : item?.completed_on
                                  ) && item?.status !== "Completed"
                                  ? "text-red-500"
                                  : ""
                              }`}
                            >
                              {dateFormatter(item?.due_date, {
                                format: "MMM DD, YYYY",
                                includeTimeForToday: false,
                              })}
                            </span>
                            <CommonTooltip
                              tooltip={getDateDifferenceStatus(
                                item?.due_date,
                                item?.status === "Completed"
                                  ? item?.completed_on
                                  : null
                              )}
                              tooltipClass={
                                "bg-custom-white dark:bg-custom-white text-lg"
                              }
                            >
                              <FaRegQuestionCircle className="text-slate-400" />
                            </CommonTooltip>
                          </div>
                        ) : (
                          "--"
                        )}
                      </td>
                      <td className="text-sm p-2">
                        {(
                          <CustomAssignees
                            assignees={item?.assign_to}
                            maxLimit={4}
                          />
                        ) ?? "--"}
                      </td>
                      <td className="px-2 text-sm">
                        <div className="flex justify-start items-center gap-3">
                          {item?.assign_by?._id === user?._id
                            ? "You"
                            : item?.assign_by?.user_name}
                        </div>
                      </td>
                      <td className="text-sm p-2">
                        {item?.hours_logged ?? "--"}
                      </td>
                      <td
                        onClick={() => {
                          if (
                            user?.roleId == Roles?.admin ||
                            user?._id == item?.project_manager?._id ||
                            item?.team_lead?.some(
                              (lead) => lead?._id === user?._id
                            ) ||
                            // user?.roleId === Roles?.admin ||
                            // user?._id == item?.project_manager?._id ||
                            // item?.team_lead?.some(
                            //   (lead) => lead?._id !== user?._id
                            // ) ||
                            // // user?.roleId === Roles?.team_lead ||
                            item?.assign_to?.find(
                              (element) => element?._id === user?._id
                            )
                          ) {
                            setStatusIndex(index);
                            setTaskId(item?._id);
                            setTaskAssignees(item?.assign_to);
                            setTaskStatusCompletedDetails({
                              hours_logged: item?.hours_logged,
                              completed_on: item?.completed_on?.split("T")[0],
                              completed_by: item?.completed_by?.map(
                                (item) => item?._id
                              ),
                            });
                            setOpenStatus(!openStatus);
                          }
                        }}
                        className={`text-sm cursor-pointer`}
                      >
                        <div
                          className={`w-fit relative p-2 cursor-pointer rounded-lg flex justify-between items-center gap-2  `}
                        >
                          <CustomStatusButton
                            selectedStatus={item?.status}
                            isDropdown={true}
                          />
                          {openStatus && statusIndex === index && (
                            <CustomStatusDropdown
                              statusRef={statusRef}
                              handleStatusChange={handleStatusChange}
                            />
                          )}
                        </div>
                      </td>
                      <td className="cursor-pointer">
                        <div className="wh-17 flex justify-center items-center gap-2 ">
                          <div
                            onClick={() => {
                              dispatch(setActiveTabInTask("task_details"));
                              navigate("/task/view", {
                                state: { id: item?._id },
                              });
                            }}
                            className="p-1 border border-green-600 text-green-600 hover:bg-green-600 hover:text-custom-white rounded-md cursor-pointer"
                          >
                            <GrView />
                          </div>
                          {user?.roleId == Roles?.admin ||
                          user?._id == item?.project_manager?._id ||
                          item?.team_lead?.some(
                            (lead) => lead?._id === user?._id
                          ) ? (
                            <div
                              onClick={() =>
                                navigate("/task/create", {
                                  state: { task: item },
                                })
                              }
                              className="p-1 border border-custom-blue text-custom-blue hover:bg-custom-blue hover:text-custom-light-blue rounded-md cursor-pointer"
                            >
                              <MdOutlineEdit />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
                {/* </div>
                  <div className="TaskListtable-Right">
                    <CustomGanttChart
                      tasks={formattedTask}
                      viewMode={"Day"}
                      columnWidth={60}
                      listCellWidth=""
                      headerHeight={40}
                      rowHeight={58}
                      hideChildren={true}
                    />
                  </div>
                </div> */}
              </tbody>
            </>
          ) : (
            <tbody>
              <tr>
                <td
                  colSpan={TableHeads?.length}
                  className="text-center font-semibold text-sm p-4"
                >
                  <NoDataFound />
                </td>
              </tr>
            </tbody>
          )}
          <tfoot className="relative border-t">
            <tr className="w-full">
              <td className="text-slate-400 text-sm px-2 py-4" colSpan={6}>
                <PaginationText
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  dataCount={taskCount}
                  dataLength={tasks?.length}
                />
              </td>
              <td className="absolute right-2 top-1.5">
                <Pagination
                  handlePageClick={handlePageClick}
                  pageRangeDisplayed={itemsPerPage}
                  pageCount={pageCount}
                  name={"pageSize"}
                  onChange={(e) => setItemsPerPage(e?.target?.value)}
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </section>
      {statusModal && (
        <CustomModal
          isOpen={statusModal}
          onClose={() => setStatusModal(false)}
          ref={statusModalRef}
        >
          {selectedStatus === "Completed" ? (
            <form
              noValidate
              onSubmit={handleSubmit(onSubmitHandler)}
              className="w-full"
            >
              <div className="my-2 sm:my-4 ">
                <CustomInput
                  name="hours_logged"
                  label="Hours Logged"
                  inputType="text"
                  placeholder="00:00"
                  control={control}
                  required={true}
                  error={errors?.hours_logged?.message}
                />
              </div>
              <div className="my-2 sm:my-4 ">
                <CustomInput
                  name="completed_on"
                  label="Completed On"
                  inputType="date"
                  placeholder="Select..."
                  control={control}
                  required={true}
                  error={errors?.completed_on?.message}
                />
              </div>
              <div className="my-2 sm:my-4 ">
                <CustomSelect
                  name="completed_by"
                  label="Completed By"
                  inputType="text"
                  placeholder="Select..."
                  control={control}
                  multiple={true}
                  required={true}
                  options={
                    taskAssignees?.map((member) => ({
                      name:
                        member?._id === user?._id ? "You" : member?.user_name,
                      value: member?._id,
                      designation: member?.designation?.designation,
                      avatar: member?.avatar
                        ? `${baseUrl}/${member?.avatar}`
                        : `https://api.dicebear.com/5.x/initials/svg?seed=${member?.user_name}`,
                    })) ?? []
                  }
                  classname="bg-custom-white"
                  error={errors?.completed_by?.message}
                />
              </div>
              <div className="flex justify-end items-center gap-5 mt-5">
                <CustomButton
                  title={"Cancel"}
                  onClick={() => setStatusModal(false)}
                  buttonType={"button"}
                  classname={"px-2 py-1 bg-custom-white border rounded-md "}
                />
                <CustomButton
                  title={"Continue"}
                  buttonType={"submit"}
                  classname={
                    "bg-gradient-custom text-custom-white px-2 py-1 rounded-md"
                  }
                />
              </div>
            </form>
          ) : (
            <>
              <div className="text-center text-custom-black">
                <h2 className="text-2xl font-bold my-4">Change Status</h2>
              </div>
              <div className="w-20 h-20 bg-[#C3E6FF] p-2 rounded-full flex justify-center items-center">
                <div className="bg-custom-blue rounded-full w-16 h-16 flex justify-center items-center">
                  <IoSwapHorizontal size={32} className="text-custom-white" />
                </div>
              </div>
              <div className="text-center text-[#747474]">
                <h2 className="font-medium my-4">
                  are you sure you want to change status?
                </h2>
              </div>

              <div className="flex justify-end items-center gap-5 mt-5">
                <CustomButton
                  title={"Cancel"}
                  onClick={() => setStatusModal(false)}
                  buttonType={"button"}
                  classname={"px-2 py-1 bg-custom-white border rounded-md "}
                />
                <CustomButton
                  title={"Continue"}
                  onClick={() => {
                    updateTaskStatusHandler();
                  }}
                  buttonType={"button"}
                  classname={
                    "bg-gradient-custom text-custom-white px-2 py-1 rounded-md"
                  }
                />
              </div>
            </>
          )}
        </CustomModal>
      )}
    </div>
  );
};

export default CommonTaskTable;

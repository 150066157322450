import React, { useContext, useEffect, useState } from "react";
import { CustomChart, FilterSelect } from "../../common";
import dayjs from "dayjs";
import { apiConnector } from "../../../networking/ApiConnector";
import { setLoading } from "../../../redux/slice/loadingSlice";
import { attendanceEndPoints } from "../../../networking/Endpoints";
import { useDispatch, useSelector } from "react-redux";
import { CustomAlertContext } from "../../../context";

const typeOptions = [
  { name: "Weekly", value: "week" },
  // { name: "Month", value: "month" },
];
const EmployeeAttendanceChart = ({
  employeeDetails = {},
  workingHours = [],
  workingHoursLoading = false,
}) => {
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { setToastNotification } = useContext(CustomAlertContext);
  const [attendanceDataType, setAttendanceDataType] = useState("week");
  const [loading, setLoading] = useState(false);
  let [labels, setLabels] = useState([]);
  const year = dayjs().year();
  const month = dayjs().month() + 1;
  const getWeeksInMonth = (year, month) => {
    const startOfMonth = dayjs(`${year}-${month}-01`);
    const endOfMonth = startOfMonth.endOf("month");

    let weeks = [];
    let currentWeekStart = startOfMonth.startOf("isoWeek");
    let i = 1;
    while (currentWeekStart.isBefore(endOfMonth)) {
      let weekStart = currentWeekStart.format("MMM DD");
      let weekEnd = currentWeekStart.endOf("isoWeek").format("MMM DD");
      weeks.push(`${weekStart} - ${weekEnd}`);

      currentWeekStart = currentWeekStart.add(1, "week");
    }

    return weeks;
  };

  function getCurrentWeekDates() {
    const givenDate = dayjs();
    const startOfWeek = givenDate.startOf("isoWeek"); // Start of the ISO week
    const endOfWeek = givenDate.endOf("isoWeek"); // End of the ISO week
    let weeks = [];
    for (let i = 0; i < 6; i++) {
      weeks.push(startOfWeek.add(i, "day").format("MMM DD"));
    }
    return weeks;
  }

  useEffect(() => {
    if (attendanceDataType === "week") {
      const weeks = getCurrentWeekDates();
      setLabels(weeks);
    } else {
      const weeks = getWeeksInMonth(year, month);
      setLabels(weeks);
    }
  }, [attendanceDataType]);

  function convertToDecimalHours(loggedHours) {
    const [hours, minutes, seconds] = loggedHours.split(":").map(Number);

    // Convert to decimal hours
    let decimalHours = hours + minutes / 60 + seconds / 3600;

    // Round to nearest 0.1
    decimalHours = Math.round(decimalHours * 100) / 100;

    return decimalHours;
  }

  const hoursData = Array(labels?.length).fill(0);
  // const startofweek = dayjs().startOf("isoWeek");
  // for (let i = 0; i < workingHours?.length; i++) {
  //   if (
  //     startofweek.isSameOrAfter(dayjs(workingHours[i]?.date)) &&
  //     hoursData?.length < 7
  //   ) {
  //     hoursData.push(convertToDecimalHours(workingHours[i]?.loggedHoures));
  //   } else {
  //     hoursData.push(0);
  //   }
  // }

  // Update hourdata based on workingHours
  workingHours.forEach((entry) => {
    let dateFormatted = dayjs(entry.date).format("MMM DD"); // Convert to "24 Mar" format
    let index = labels.indexOf(dateFormatted); // Find index in weeks array

    if (index !== -1) {
      hoursData[index] = convertToDecimalHours(entry.loggedHoures);
    }
  });
  console.log(hoursData);
  return (
    <div className="w-full relative border border-[#E9EDF0] rounded-lg p-4 flex flex-col justify-between items-center">
      <div className="w-full flex justify-between items-center">
        <h1 className="font-semibold text-sm my-2 text-slate-500">
          Working Hours
        </h1>
        <div className="p-1 border bg-slate-300 font-semibold text-sm rounded-md capitalize">
          {attendanceDataType}
        </div>
        {/* <FilterSelect
          name="type"
          label=""
          icon={false}
          options={typeOptions ?? []}
          defaultValue={attendanceDataType}
          onChange={(selected) => {
            setLabels([]);
            setAttendanceDataType(selected?.value);
          }}
        /> */}
      </div>
      <CustomChart
        labelName={"Working Hours"}
        chartType="bar"
        labels={labels}
        data={
          attendanceDataType === "week"
            ? hoursData
            : [48.23, 36.24, 16.28, 53.78, 50, 49.78]
        }
        colors={["#5FB2EE", "#FF2568", "#A3B3C7"]}
        position="top"
        borderRadius={16}
        officeHourLimit={attendanceDataType === "week" ? 9 : 50}
      />
    </div>
  );
};

export default EmployeeAttendanceChart;

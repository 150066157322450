import React, { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { CustomSidebar, Footer, Header } from "../components/common";
import {
  AiOutlineCheckSquare,
  AiOutlineProject,
  AiOutlineUser,
  AiOutlineCalendar,
  AiOutlineClockCircle,
  AiOutlineFile,
  AiTwotoneFolderAdd,
} from "react-icons/ai";
import { useSelector } from "react-redux";
import { FaRegBuilding, FaRegClock } from "react-icons/fa";
import { IoSettingsOutline } from "react-icons/io5";
import { Roles } from "../constant/Constant";
import { MdOutlineDashboard, MdOutlineLocationOn } from "react-icons/md";
import {
  PiUserCircleCheckBold,
  PiUserList,
  PiUsersThree,
} from "react-icons/pi";
import { BiUserPin } from "react-icons/bi";
import { GrCart } from "react-icons/gr";
import { SiGotomeeting } from "react-icons/si";
import { GiMeepleGroup, GiOrganigram } from "react-icons/gi";
import { LuClipboardList } from "react-icons/lu";

const Home = () => {
  const { user } = useSelector((state) => state.auth);
  let menuItems = [];
  if (user?.roleId === Roles?.super_admin) {
    menuItems = [
      {
        id: 1,
        name: "Dashboard",
        path: "/dashboard",
        active: ["dashboard"],
        icon: <MdOutlineDashboard />,
      },
      {
        id: 2,
        name: "Company",
        path: "/company",
        active: ["company", "/company/create", "/company-view"],
        icon: <FaRegBuilding />,
      },
    ];
  } else if (user?.roleId === Roles?.admin) {
    menuItems = [
      {
        id: 1,
        name: "Dashboard",
        path: "/dashboard",
        active: ["dashboard"],
        icon: <MdOutlineDashboard />,
      },
      {
        id: 2,
        name: "Employees",
        path: "/employees",
        active: ["employees", "/employees/create", "/employees/view"],
        icon: <AiOutlineUser />,
      },
      {
        id: 3,
        name: "Project",
        path: "/project",
        active: ["/project", "/project/create", "/project/view"],
        icon: <AiOutlineProject />,
      },
      {
        id: 4,
        name: "Task",
        path: "/task",
        active: ["task", "/task/create", "/task/view"],
        icon: <AiOutlineCheckSquare />,
      },
      {
        id: 5,
        name: "Calender",
        path: "/calender",
        active: ["calender"],
        icon: <AiOutlineCalendar />,
      },
      {
        id: 6,
        name: "Meeting",
        icon: <PiUsersThree />,
        subItems: [
          {
            id: 1,
            name: "Meeting List",
            path: "/meeting",
            active: ["meeting"],
            icon: <LuClipboardList />,
          },
          {
            id: 2,
            name: "External Invites",
            path: "/external_invites",
            active: ["external_invites", "external_invites_form"],
            icon: <BiUserPin />,
          },

          {
            id: 3,
            name: "Meeting Category",
            path: "/meeting_category",
            active: ["meeting_category"],
            icon: <SiGotomeeting />,
          },
        ],
      },
      {
        id: 7,
        name: "Attendance",
        icon: <PiUserCircleCheckBold />,
        subItems: [
          {
            id: 1,
            name: "Attendance List",
            path: "/attendance",
            active: ["attendance"],
            icon: <PiUserList />,
          },
          // {
          //   id: 2,
          //   name: "Leave List",
          //   path: "/leave",
          //   active: ["leave"],
          //   icon: <PiUserList />,
          // },
          {
            id: 3,
            name: "Holidays",
            path: "/holiday",
            active: ["holiday"],
            icon: <PiUserList />,
          },
        ],
      },
      {
        id: 8,
        name: "Setting",
        icon: <IoSettingsOutline />,
        subItems: [
          {
            id: 1,
            name: "Department",
            path: "/department",
            active: ["department"],
            icon: <PiUsersThree />,
          },
          {
            id: 2,
            name: "Designation",
            path: "/designation",
            active: ["designation"],
            icon: <BiUserPin />,
          },
          {
            id: 3,
            name: "Office Location",
            path: "/office_location",
            active: ["office_location"],
            icon: <MdOutlineLocationOn />,
          },
          {
            id: 4,
            name: "Company",
            path: "/company-profile",
            active: ["/company-profile"],
            icon: <FaRegBuilding />,
          },
          {
            id: 5,
            name: "Company Shift",
            path: "/shift-setting",
            active: ["/shift-setting", "/shift-setting-form"],
            icon: <FaRegClock />,
          },
        ],
      },
      {
        id: 9,
        name: "Products",
        icon: <GrCart />,

        subItems: [
          {
            id: 1,
            name: "Add Products",
            path: "/add_products",
            active: ["add_products"],
            icon: <AiTwotoneFolderAdd />,
          },
          {
            id: 2,
            name: "Our Products",
            path: "/our_products",
            active: ["our_products"],
            icon: <PiUserList />,
          },
        ],
      },
    ];
  } else if (user?.roleId === Roles?.project_manager) {
    menuItems = [
      {
        id: 1,
        name: "Dashboard",
        path: "/dashboard",
        active: ["dashboard"],
        icon: <MdOutlineDashboard />,
      },
      {
        id: 2,

        name: "Employees",
        path: "/employees",
        active: ["employees", "/employees/create", "/employees/view"],
        icon: <AiOutlineUser />,
      },
      {
        id: 3,

        name: "Project",
        path: "/project",
        active: ["project", "/project/create", "/project/view"],
        icon: <AiOutlineProject />,
      },
      {
        id: 4,

        name: "Task",
        path: "/task",
        active: ["task", "/task/create", "/task/view"],
        icon: <AiOutlineCheckSquare />,
      },
      {
        id: 5,
        name: "Calender",
        path: "/calender",
        active: ["calender"],
        icon: <AiOutlineCalendar />,
      },
      {
        id: 6,
        name: "Meeting",
        icon: <PiUsersThree />,
        subItems: [
          {
            id: 1,
            name: "Meeting List",
            path: "/meeting",
            active: ["meeting"],
            icon: <LuClipboardList />,
          },
          {
            id: 2,
            name: "External Invites",
            path: "/external_invites",
            active: ["external_invites", "external_invites_form"],
            icon: <BiUserPin />,
          },
        ],
      },
      {
        id: 7,
        name: "Attendance",
        icon: <PiUserCircleCheckBold />,
        subItems: [
          {
            id: 1,
            name: "Attendance List",
            path: "/attendance",
            active: ["attendance"],
            icon: <PiUserList />,
          },
          // {
          //   id: 2,
          //   name: "Leave List",
          //   path: "/leave",
          //   active: ["leave"],
          //   icon: <PiUserList />,
          // },
        ],
      },
      // {
      //   id: 8,
      //   name: "Timesheet",
      //   icon: <PiUserCircleCheckBold />,
      //   subItems: [
      //     {
      //       id: 1,
      //       name: "My Timesheet",
      //       path: "/timesheet",
      //       active: ["timesheet"],
      //       icon: <PiUserList />,
      //     },
      //     {
      //       id: 2,
      //       name: "Timesheet For ✅",
      //       path: "/timesheet_for_approval",
      //       active: ["leave"],
      //       icon: <PiUserList />,
      //     },
      //   ],
      // },
      {
        id: 9,
        name: "Report",
        path: "/report",
        active: ["report"],
        icon: <AiOutlineFile />,
      },
    ];
  } else if (user?.roleId === Roles?.employee) {
    menuItems = [
      {
        id: 1,

        name: "Dashboard",
        path: "/dashboard",
        active: ["dashboard"],
        icon: <MdOutlineDashboard />,
      },
      // {
      //   name: "Employees",
      //   path: "/employees",
      //   active: ["employees", "/employees/create", "/employees/view"],
      //   icon: <AiOutlineUser />,
      // },
      {
        id: 2,

        name: "Project",
        path: "/project",
        active: ["/project", "/project/create", "/project/view"],
        icon: <AiOutlineProject />,
      },
      {
        id: 3,

        name: "Task",
        path: "/task",
        active: ["task", "/task/create", "/task/view"],
        icon: <AiOutlineCheckSquare />,
      },
      {
        id: 4,

        name: "Calender",
        path: "/calender",
        active: ["calender"],
        icon: <AiOutlineCalendar />,
      },
      // {
      //   id: 5,
      //   name: "Timesheet",
      //   icon: <PiUserCircleCheckBold />,
      //   subItems: [
      //     {
      //       id: 1,
      //       name: "My Timesheet",
      //       path: "/timesheet",
      //       active: ["timesheet"],
      //       icon: <PiUserList />,
      //     },
      //     {
      //       id: 2,
      //       name: "Timesheet For ✅",
      //       path: "/timesheet_for_approval",
      //       active: ["leave"],
      //       icon: <PiUserList />,
      //     },
      //   ],
      // },
      {
        id: 6,
        name: "Meeting",
        icon: <PiUsersThree />,
        subItems: [
          {
            id: 1,
            name: "Meeting List",
            path: "/meeting",
            active: ["meeting"],
            icon: <LuClipboardList />,
          },
          {
            id: 2,
            name: "External Invites",
            path: "/external_invites",
            active: ["external_invites", "external_invites_form"],
            icon: <BiUserPin />,
          },
        ],
      },
      {
        id: 7,
        name: "Attendance",
        icon: <PiUserCircleCheckBold />,
        subItems: [
          {
            id: 1,
            name: "Attendance List",
            path: "/attendance",
            active: ["attendance"],
            icon: <PiUserList />,
          },
          // {
          //   id: 2,
          //   name: "Leave List",
          //   path: "/leave",
          //   active: ["leave"],
          //   icon: <PiUserList />,
          // },
        ],
      },
      {
        id: 8,

        name: "Report",
        path: "/report",
        active: ["report"],
        icon: <AiOutlineFile />,
      },
      {
        id: 9,
        name: "Organizational Chart",
        path: "/organizational_chart",
        active: ["organizational_chart"],
        icon: <GiOrganigram />,
      },
    ];
  } else if (user?.roleId === Roles?.hr) {
    menuItems = [
      {
        id: 1,

        name: "Dashboard",
        path: "/dashboard",
        active: ["dashboard"],
        icon: <MdOutlineDashboard />,
      },
      {
        id: 2,

        name: "Employees",
        path: "/employees",
        active: ["employees", "/employees/create", "/employees/view"],
        icon: <AiOutlineUser />,
      },
      {
        id: 3,
        name: "Calender",
        path: "/calender",
        active: ["calender"],
        icon: <AiOutlineCalendar />,
      },
      // {
      //   id: 4,
      //   name: "Timesheet",
      //   icon: <PiUserCircleCheckBold />,
      //   subItems: [
      //     {
      //       id: 1,
      //       name: "My Timesheet",
      //       path: "/timesheet",
      //       active: ["timesheet"],
      //       icon: <PiUserList />,
      //     },
      //     {
      //       id: 2,
      //       name: "Timesheet For ✅",
      //       path: "/timesheet_for_approval",
      //       active: ["leave"],
      //       icon: <PiUserList />,
      //     },
      //   ],
      // },
      {
        id: 5,
        name: "Meeting",
        icon: <PiUsersThree />,
        subItems: [
          {
            id: 1,
            name: "Meeting List",
            path: "/meeting",
            active: ["meeting"],
            icon: <LuClipboardList />,
          },
          {
            id: 2,
            name: "External Invites",
            path: "/external_invites",
            active: ["external_invites", "external_invites_form"],
            icon: <BiUserPin />,
          },
        ],
      },
      {
        id: 6,
        name: "Attendance",
        icon: <PiUserCircleCheckBold />,
        subItems: [
          {
            id: 1,
            name: "Attendance List",
            path: "/attendance",
            active: ["attendance"],
            icon: <PiUserList />,
          },
          {
            id: 3,
            name: "Holidays",
            path: "/holiday",
            active: ["holiday"],
            icon: <PiUserList />,
          },
          // {
          //   id: 2,
          //   name: "Leave List",
          //   path: "/leave",
          //   active: ["leave"],
          //   icon: <PiUserList />,
          // },
        ],
      },
      {
        id: 7,
        name: "Setting",
        icon: <IoSettingsOutline />,
        subItems: [
          {
            id: 1,
            name: "Department",
            path: "/department",
            active: ["department"],
            icon: <PiUsersThree />,
          },
          {
            id: 2,
            name: "Designation",
            path: "/designation",
            active: ["designation"],
            icon: <BiUserPin />,
          },
          {
            id: 3,
            name: "Office Location",
            path: "/office_location",
            active: ["office_location"],
            icon: <MdOutlineLocationOn />,
          },
          {
            id: 4,
            name: "Company Shift",
            path: "/shift-setting",
            active: ["/shift-setting", "/shift-setting-form"],
            icon: <FaRegClock />,
          },
        ],
      },
    ];
  } else if (user?.roleId === Roles?.team_lead) {
    menuItems = [
      {
        id: 1,

        name: "Dashboard",
        path: "/dashboard",
        active: ["dashboard"],
        icon: <MdOutlineDashboard />,
      },

      {
        id: 2,

        name: "Project",
        path: "/project",
        active: ["/project", "/project/create", "/project/view"],
        icon: <AiOutlineProject />,
      },
      {
        id: 3,

        name: "Task",
        path: "/task",
        active: ["task", "/task/create", "/task/view"],
        icon: <AiOutlineCheckSquare />,
      },
      {
        id: 4,

        name: "Calender",
        path: "/calender",
        active: ["calender"],
        icon: <AiOutlineCalendar />,
      },
      {
        id: 5,
        name: "Meeting",
        icon: <PiUsersThree />,
        subItems: [
          {
            id: 1,
            name: "Meeting List",
            path: "/meeting",
            active: ["meeting"],
            icon: <LuClipboardList />,
          },
          {
            id: 2,
            name: "External Invites",
            path: "/external_invites",
            active: ["external_invites", "external_invites_form"],
            icon: <BiUserPin />,
          },
        ],
      },
      {
        id: 6,
        name: "Attendance",
        icon: <PiUserCircleCheckBold />,
        subItems: [
          {
            id: 1,
            name: "Attendance List",
            path: "/attendance",
            active: ["attendance"],
            icon: <PiUserList />,
          },
          // {
          //   id: 2,
          //   name: "Leave List",
          //   path: "/leave",
          //   active: ["leave"],
          //   icon: <PiUserList />,
          // },
        ],
      },
      {
        id: 7,
        name: "Report",
        path: "/report",
        active: ["report"],
        icon: <AiOutlineFile />,
      },
      // {
      //   id: 8,
      //   name: "Timesheet",
      //   icon: <PiUserCircleCheckBold />,
      //   subItems: [
      //     {
      //       id: 1,
      //       name: "My Timesheet",
      //       path: "/timesheet",
      //       active: ["timesheet"],
      //       icon: <PiUserList />,
      //     },
      //     {
      //       id: 2,
      //       name: "Timesheet For ✅",
      //       path: "/timesheet_for_approval",
      //       active: ["leave"],
      //       icon: <PiUserList />,
      //     },
      //   ],
      // },
      {
        id: 9,
        name: "Organizational Chart",
        path: "/organizational_chart",
        active: ["organizational_chart"],
        icon: <GiOrganigram />,
      },
    ];
  } else {
    <Navigate to={"/login"} />;
  }

  return (
    <div className="w-screen h-screen">
      <Header />
      <div className="w-full relative flex min-h-[calc(100vh-4rem)] overflow-hidden">
        <div className="h-[89vh]">
          <CustomSidebar menuItems={menuItems} />
        </div>

        <div
          className={`w-full h-[calc(100vh-4rem)] flex-1 overflow-x-hidden p-2 sm:p-6 bg-custom-light-blue overflow-y-auto`}
        >
          <div className="mx-auto w-full">
            <Outlet />
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Home;

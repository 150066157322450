import React from "react";
import { Heading } from "../../project";
import { Roles } from "../../../constant/Constant";
import { dateFormatter } from "../../../helper/formatDate";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MdOutlineEdit } from "react-icons/md";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const EmployeeDetails = ({ employeeDetails = {} }) => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  return (
    <div className="w-full border border-slate-200 bg-[#F8F8F8] rounded-md p-2">
      <div className="flex justify-between items-center border-b border-slate-300 py-2">
        <div className="w-full flex item-center  gap-5">
          <img
            src={
              employeeDetails?.avatar
                ? `${BASE_URL}/${employeeDetails?.avatar}`
                : `https://api.dicebear.com/5.x/initials/svg?seed=${employeeDetails?.user_name}`
            }
            alt={employeeDetails?.user_name?.[0] ?? "Profile Image"}
            loading="lazy"
            className="w-16 md:w-20  h-16 md:h-20 rounded-full"
          />
          <div className="flex flex-col justify-center font-semibold">
            <div>{employeeDetails?.user_name}</div>
            <div className="text-sm text-slate-400 capitalize">
              Role :-
              {Object.keys(Roles)
                ?.find((key) => Roles[key] === employeeDetails?.roleId)
                ?.replace("_", " ")}
            </div>
          </div>
        </div>
        <div className="flex justify-end items-center">
          {(user?.roleId === Roles?.admin || user?.roleId === Roles?.hr) &&
            employeeDetails?.roleId !== Roles?.admin && (
              <div
                onClick={() => {
                  navigate("/employees/create", {
                    state: {
                      employee: employeeDetails,
                    },
                  });
                }}
                className="p-1 border border-custom-blue text-custom-blue hover:text-custom-white hover:bg-custom-blue  hover:border-custom-white rounded-md cursor-pointer"
              >
                <MdOutlineEdit size={16} />
              </div>
            )}
        </div>
      </div>
      <section className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 py-4">
        <Heading label="Employee ID" value={employeeDetails?.emp_id} />
        <Heading label="Name" value={employeeDetails?.user_name} />
        <Heading label="Email" value={employeeDetails?.email} />
        <Heading label="Mobile Number" value={employeeDetails?.mobile_number} />
        <Heading
          label="Reporting Manager"
          value={employeeDetails?.reporting_manager?.user_name}
        />
        <Heading
          label="Department"
          value={
            employeeDetails?.roleId === Roles?.admin
              ? "Admin"
              : employeeDetails?.department?.department_name ?? "-"
          }
        />
        <Heading
          label="Designation"
          value={
            employeeDetails?.roleId === Roles?.admin
              ? "Admin"
              : employeeDetails?.designation?.designation ?? "-"
          }
        />
        <Heading label="Skills" value={employeeDetails?.skills ?? "--"} />
        <Heading
          label="Experience"
          value={employeeDetails?.experience ?? "--"}
        />
        <Heading label="Work Mode" value={employeeDetails?.work_mode ?? "--"} />
        <Heading
          label="Office Location"
          value={employeeDetails?.office_location?.city ?? "--"}
        />
        <Heading
          label="Shift"
          value={employeeDetails?.shift?.shift_name ?? "--"}
        />
        <Heading
          label="Joining Date"
          value={
            dateFormatter(employeeDetails?.joining_date, {
              format: "MMM DD,YYYY",
              includeTimeForToday: true,
            }) ?? "--"
          }
        />
        <div className="w-full col-span-3">
          <Heading label="About" value={employeeDetails?.about ?? "--"} />
        </div>
      </section>
    </div>
  );
};

export default EmployeeDetails;
